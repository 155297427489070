import { Box, Typography } from '@mui/material';

import PageWrapper from '../common/layouts/PageWrapper';
import { PageTitle } from '../common/constants/features-constants';
import FaqAccordionsHelp from '../common/components/help/FaqAccordionsHelp';

export const Help = () => {
  return (
    <PageWrapper title={PageTitle.HELP} headTitle={PageTitle.HELP}>
      <Typography variant="h4" fontWeight={600}>
        UCC Frequently Asked Questions
      </Typography>
      <Box height={24} />
      <FaqAccordionsHelp />
    </PageWrapper>
  );
};
