import { useState } from 'react';
import { AccordionDetails } from '@mui/material';
import { CustomAccordion } from '../../../../common/components/accordion/CustomAccordion';
import { AcknowledgementInfoForm } from '../../forms/acknowledgement-info-form/AcknowledgementInfoForm';
import { AcknowledgementInfoView } from './AcknowledgementInfoView';
import { useMyAccount } from '../../../../common/context';

export const AcknowledgementInfoAccordion = () => {
  const [contactName, setContactName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    openAccordion,
    handleChangeAccordionAck,
    editModeAccordion,
    handleChangeEditMode,
    myAccountData,
    updateAcknowledgementInfo,
    handleCancel,
  } = useMyAccount();

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSubmitting(true);
    await updateAcknowledgementInfo({ contactName, phoneNumber, email });
    setIsSubmitting(false);
  };

  return (
    <CustomAccordion
      title={'Acknowledgement Information'}
      isOpen={openAccordion.acknowledgementInfo}
      handleChange={handleChangeAccordionAck}
    >
      <AccordionDetails>
        {editModeAccordion.acknowledgementInfo ? (
          <AcknowledgementInfoForm
            setEmail={setEmail}
            setContactName={setContactName}
            setPhoneNumber={setPhoneNumber}
            setIsValid={setIsValid}
          />
        ) : (
          <AcknowledgementInfoView
            contactName={myAccountData.acknowledgementInformation.contactName}
            email={myAccountData.acknowledgementInformation.email}
            phoneNumber={myAccountData.acknowledgementInformation.phoneNumber}
          />
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
};
