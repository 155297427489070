import { useEffect, useRef } from 'react';

export const useFilingFormCleanup = <T,>(cleanupFunc: T, shouldClean: boolean) => {
  const cleanup = useRef<T | null>(cleanupFunc);

  useEffect(
    () => () => {
      (cleanup.current as any)?.();
    },
    []
  );

  useEffect(() => {
    if (!shouldClean) {
      cleanup.current = null;
    }
  }, [shouldClean]);
};
