import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { YesNoModal } from '../../../../common/yes-no-modal';
import { CloseIconButton } from '../../../../common/global-styles/CloseIconButton';
import { useModal } from '../../../../common/context';
import { useSnackbar } from '../../../../common/notification';
import UpdateConfirmCodeFormUI from './UpdateConfirmCodeFormUI';
import { UpdateEmailFormActions } from './UpdateEmailFormActions';

import { IUpdateConfirmCodeFormProps } from './types';
import { schema, FormData } from './schema';

export const UpdateConfirmCodeForm = ({ onSubmit }: IUpdateConfirmCodeFormProps) => {
  const { handleSubmit, setFocus, formState, control } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });
  const { Snack } = useSnackbar();
  const { handleSetModalActions, handleModalClose, handleSetModalXButton } = useModal();
  const [shouldOpenUnsavedChangesModal, setShouldOpenUnsavedChangesModal] = useState(false);
  const { isValid, isSubmitting, isDirty } = formState;

  const yesAction = () => {
    setShouldOpenUnsavedChangesModal(false);
    handleModalClose();
    Snack.info('Action canceled.');
  };

  const cancelHandler = () => {
    if (isDirty) {
      setShouldOpenUnsavedChangesModal(true);
    } else {
      handleModalClose();
    }
  };

  const xButtonAction = <CloseIconButton onClick={cancelHandler} />;

  useEffect(() => {
    handleSetModalXButton && handleSetModalXButton(xButtonAction);
    handleSetModalActions &&
      handleSetModalActions(
        <UpdateEmailFormActions
          onSubmit={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
          isValid={isValid}
          onModalClose={cancelHandler}
          primaryButtonText="OK"
          secondaryButton
        />
      );
  }, [isValid, isSubmitting, isDirty]);

  useEffect(() => {
    setFocus('code');
  }, []);

  return (
    <>
      <UpdateConfirmCodeFormUI
        onSubmit={handleSubmit(onSubmit)}
        formState={formState}
        control={control}
      />
      <YesNoModal
        open={shouldOpenUnsavedChangesModal}
        handleModalClose={() => setShouldOpenUnsavedChangesModal(false)}
        noAction={() => setShouldOpenUnsavedChangesModal(false)}
        yesAction={yesAction}
      />
    </>
  );
};
