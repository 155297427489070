import { Select, styled } from '@mui/material';
import { colors } from '../../constants';

export const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: 0,
  textAlign: 'center',
  background: 'none',
  borderTop: `3px solid ${colors.highlightSelectionColor}`,
  '.MuiSelect-select': {
    background: 'none',
    border: 'none',
    paddingTop: theme.convert.pxToRem(10),
    paddingBottom: theme.convert.pxToRem(10),
  },
  '.MuiSelect-iconFilled': {
    color: `${theme.palette.text.primary}`,
    right: 75,
  },
}));
