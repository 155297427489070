import { API } from 'aws-amplify';
import {
  apiName,
  emptyInit,
  getAccInfoPath,
  cartDetailsPath,
  verifyCartPath,
} from '../../common/constants/api';

export const getMyAccountInfo = async () => {
  try {
    const res = await API.get(apiName, getAccInfoPath, emptyInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const verifyCart = async () => {
  try {
    const res = await API.get(apiName, verifyCartPath, emptyInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const getCartDetails = async () => {
  try {
    const res = await API.get(apiName, cartDetailsPath, emptyInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};
