import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Typography } from '@mui/material';
import { CognitoUser } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { useSnackbar } from '../../../common/notification';
import { StyledModalSubmitButton } from '../../../common/global-styles';
import CreatePasswordFormUI from './CreatePasswordFormUI';
import { useModal } from '../../../common/context';
import { useAuth } from '../../../common/context';

import { passwordValidatorHelper } from '../../../common/helpers';
import { ParamsType } from './types';
import { schema } from './schema';
import { FormData } from './schema';
import { passwordsMatch } from '../../../common/helpers';

const CreatePasswordForm = ({
  user,
  parameters,
}: {
  user: CognitoUser | null;
  parameters: ParamsType;
}) => {
  const { Snack } = useSnackbar();
  const { createNewPassword, forgotPassword, signIn } = useAuth();
  const { handleModalOpen, handleModalClose } = useModal();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { formState, control, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const newPassword = watch('newPassword');
  const confirmPassword = watch('confirmPassword');

  const arePasswordsMatching = useMemo(
    () => passwordsMatch(newPassword, confirmPassword),
    [newPassword, confirmPassword]
  );

  const errors = useMemo(
    () =>
      newPassword
        ? passwordValidatorHelper(newPassword)
        : {
            lowerCase: true,
            upperCase: true,
            specialChar: true,
            oneNumber: true,
            minLength: true,
          },
    [newPassword]
  );

  const onHandleSubmit = async (e: any) => {
    setIsSubmitting(true);
    e.preventDefault();
    if (user?.challengeName === 'NEW_PASSWORD_REQUIRED' && parameters.type === 'confirm-account') {
      const code = newPassword;
      createNewPassword({ user, code });
    }
    if (parameters.type === 'forgot-password') {
      const username = parameters.email;
      const code = parameters.code;
      const res = await forgotPassword({ username, code, newPassword: newPassword });
      if (res) {
        Snack.success('Your password has been successfully updated.');
        signIn({ email: username, password: newPassword });
      } else {
        handleModalOpen({
          title: 'Your link has expired',
          modalContent: (
            <Typography>
              It seems that the password reset link is expired and is no longer valid. Please
              request a new link.
            </Typography>
          ),
          modalActions: (
            <StyledModalSubmitButton variant="outlined" onClick={handleModalClose}>
              Close
            </StyledModalSubmitButton>
          ),
        });
        setIsSubmitting(false);
        navigate('/onlinefiling/login?resetPassword=true');
      }
    }
  };

  return (
    <CreatePasswordFormUI
      customErrors={errors}
      onHandleSubmit={onHandleSubmit}
      isSubmitting={isSubmitting}
      control={control}
      formState={formState}
      arePasswordsMatching={arePasswordsMatching}
    />
  );
};
export default CreatePasswordForm;
