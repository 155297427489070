import { CountryType, NameType } from '../../../common/models/features/models';

export const defaultValues = {
  nameType: NameType.organization,
  name: '',
  lastName: '',
  firstName: '',
  middleName: '',
  suffix: '',
  isForeign: false,
  address1: '',
  address2: '',
  city: '',
  zip: '',
  country: CountryType.US,
  state: '',
};
