import { styled, Table, TableProps } from '@mui/material';
import theme from '../../../common/theme';

interface StyledTableProps extends TableProps {
  disableFilter?: boolean;
}

export const StyledTable = styled(Table)<StyledTableProps>`
  border: ${({ disableFilter }) =>
    disableFilter ? 'none' : `1px solid ${theme.palette.grey[200]}`};
  fill: ${theme.palette.common.white};
  filter: ${({ disableFilter }) =>
    disableFilter ? 'none' : 'drop-shadow(0px 0px 8px rgba(176, 192, 237, 0.5))'};
  th {
    font-weight: 600;
    border-bottom: 'none';
    background-color: ${theme.palette.grey[200]};
  }

  td {
    vertical-align: top;
  }

  th,
  td {
    color: black;
    font-size: 16px;
    line-height: 24px;
  }
`;
