import { useState } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import StartUcc3Form from './StartUcc3Form';
import StartUcc3FormComplete from './StartUcc3FormComplete';
import { StyledHeaderContainer } from '../styled-items/StyledHeaderContainer';
import { StyledHeaderTitle } from '../styled-items/StyledHeaderTitle';
import { StyledBoxTableWrap } from '../styled-items/StyledBoxTableWrapper';
import { listDebtorSecuredNames } from '../utils';
import { formatDate, TimeZone } from '../../../common/helpers/format-date';
import { FilingsStatus, IDebSecDataTypes, IFileData } from '../models';

const StartUcc3FormComponent = () => {
  const [fileData, setFileData] = useState<IFileData | null>(null);
  const [debSecData, setDebtorsData] = useState<IDebSecDataTypes | null>(null);
  const [verifyNumber, setVerifyNumber] = useState<string | null>(null);
  const theme = useTheme();

  return (
    <>
      <StartUcc3Form
        setFileData={setFileData}
        setDebtorsData={setDebtorsData}
        setVerifyNumber={setVerifyNumber}
      />
      {fileData && debSecData ? (
        <Grid
          item
          md={10}
          mx="auto"
          sm={11}
          sx={{
            paddingLeft: { xs: 3, md: 0, lg: 4 },
            paddingRight: { xs: 1, md: 0, lg: 4 },
            paddingTop: {
              xs: theme.convert.pxToRem(21),
              md: theme.convert.pxToRem(21),
              lg: theme.convert.pxToRem(41),
              xl: theme.convert.pxToRem(42),
            },
            width: { xs: '100%', md: '96%' },
          }}
        >
          <StyledBoxTableWrap>
            <Box minWidth={600}>
              <Box
                padding={theme.convert.pxToRem(20)}
                borderLeft={`15px solid ${theme.palette.info.dark}`}
              >
                <Typography
                  variant="h4"
                  sx={{ maxWidth: { xs: theme.convert.pxToRem(300), md: 'unset' } }}
                  style={{ fontWeight: 600 }}
                >
                  Financing Statement File# {fileData.ucc1FilingNumber}
                </Typography>
              </Box>
              <StyledHeaderContainer container>
                <Grid item md={4} sm={4}>
                  <StyledHeaderTitle>Filing Status</StyledHeaderTitle>
                </Grid>
                <Grid item md={4} sm={4}>
                  <StyledHeaderTitle>Filing Expiry Date</StyledHeaderTitle>
                </Grid>
              </StyledHeaderContainer>
              <Grid
                container
                paddingY={theme.convert.pxToRem(13)}
                paddingX={theme.convert.pxToRem(35)}
                height={theme.convert.pxToRem(48)}
              >
                <Grid item md={4} sm={4}>
                  <Typography variant="h6">
                    {FilingsStatus[fileData.status as keyof typeof FilingsStatus]}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={4}>
                  <Typography variant="h6">
                    {formatDate(fileData.expireDate ?? '', TimeZone.EST)}
                  </Typography>
                </Grid>
              </Grid>
              <StyledHeaderContainer container>
                <Grid item md={4}>
                  <StyledHeaderTitle>Debtor(s)</StyledHeaderTitle>
                </Grid>
              </StyledHeaderContainer>
              <Grid container>
                {listDebtorSecuredNames(debSecData.debNames, theme.palette.grey[200])}
              </Grid>
              <StyledHeaderContainer container>
                <Grid item md={4}>
                  <StyledHeaderTitle>Secured party(s)</StyledHeaderTitle>
                </Grid>
              </StyledHeaderContainer>
              <Grid container>
                {listDebtorSecuredNames(debSecData.secNames, theme.palette.grey[200])}
              </Grid>
            </Box>
          </StyledBoxTableWrap>
        </Grid>
      ) : null}
      <Box height={theme.convert.pxToRem(8)}></Box>
      <Grid item md={10} sm={11} sx={{ paddingLeft: { xs: 1, md: 4 } }}>
        <StartUcc3FormComplete
          filingId={fileData?.ucc1FilingNumber ?? ''}
          verifyNumber={verifyNumber}
        />
      </Grid>
    </>
  );
};

export default StartUcc3FormComponent;
