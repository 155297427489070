import { Fragment, useMemo } from 'react';
import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

import Loader from '../common/components/loader';
import { useFees } from '../features/fees/use-fees';
import PageWrapper from '../common/layouts/PageWrapper';
import { PageTitle } from '../common/constants/features-constants';
import { StyledTableContainer } from '../features/start-ucc-filing/styled-items/StyledTableContainer';
import { StyledTable } from '../features/start-ucc-filing/styled-items/StyledTable';

const tHeadNames = ['Form', 'Fees', 'Description', 'Included'];

export const Fees = () => {
  const theme = useTheme();
  const { content, loading } = useFees();

  const leftPadding = useMemo(
    () => ({
      xs: theme.convert.pxToRem(8),
      md: theme.convert.pxToRem(28),
    }),
    [theme.convert]
  );

  return (
    <PageWrapper title={PageTitle.FEES} headTitle={PageTitle.FEES}>
      <Typography variant="body1">
        All UCC fees are nonrefundable processing fees. No refunds will be issued by Florida UCC,
        LLC for UCC documents that cannot be filed.
      </Typography>
      {loading ? (
        <Box display={'flex'} justifyContent={'center'} marginTop={10}>
          <Loader color="blue" size={50} />
        </Box>
      ) : (
        <StyledTableContainer
          sx={{ marginTop: theme.convert.pxToRem(24) }}
          component={Paper}
          variant="outlined"
        >
          <StyledTable
            disableFilter
            style={{
              minWidth: theme.convert.pxToRem(1050),
            }}
          >
            <TableHead>
              <TableRow>
                {tHeadNames.map((name, idx) => (
                  <TableCell key={name}>
                    <Typography
                      fontSize={theme.typography.pxToRem(16)}
                      lineHeight={theme.typography.pxToRem(24)}
                      paddingLeft={idx ? 0 : leftPadding}
                      fontWeight={600}
                    >
                      {name}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {content.map(({ name, fees }, idx) => (
                <Fragment key={name}>
                  <TableRow>
                    <TableCell rowSpan={fees.length + 1}>
                      <Typography noWrap variant="body3" fontWeight={600} paddingLeft={leftPadding}>
                        {name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {fees.map(({ fee, description, included }, idxFee) => (
                    <TableRow key={`Desc_${idxFee}`}>
                      <TableCell>
                        <Typography variant="body1" marginRight={5}>{`$${fee.toFixed(
                          2
                        )}`}</Typography>
                      </TableCell>
                      <TableCell>
                        {description.map((d, idxDesc) => (
                          <Typography key={`Desc_${idxFee}_${idxDesc}`} variant="body1">
                            {d}
                          </Typography>
                        ))}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{included}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </Fragment>
              ))}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
      )}
    </PageWrapper>
  );
};
