import { Grid, Typography } from '@mui/material';
import type { UpdateAcknowledgementInfo } from '../../../../common/context/types';
import { EditButton } from '../EditButton';

export const AcknowledgementInfoView = ({
  contactName,
  phoneNumber,
  email,
}: UpdateAcknowledgementInfo) => {
  return (
    <Grid container padding={2}>
      <Grid item xs={12} md={10} display="flex" marginBottom={2}>
        <Grid container>
          <Grid item xs={12} md={3}>
            <Typography variant="h5">Contact name</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography>{contactName}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} display="flex" marginBottom={2}>
        <Grid container>
          <Grid item xs={12} md={5}>
            <Typography variant="h5">Phone number</Typography>
          </Grid>
          <Grid item xs={12} md={7} paddingRight={2}>
            <Typography>{phoneNumber}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} display="flex">
        <Grid container>
          <Grid item xs={12} md={5} paddingRight={2}>
            <Typography variant="h5">Contact email </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography>{email}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="flex-end" mx={1}>
        <EditButton name={'acknowledgementInfo'} />
      </Grid>
    </Grid>
  );
};
