import { useState } from 'react';
import { TextFieldProps } from '@mui/material';
import { PasswordInputUI } from './PasswordInputUI';

type InputProps = {
  showIcon?: boolean;
} & Omit<TextFieldProps, 'type' | 'size'>;

export const PasswordInput = (props: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <PasswordInputUI
      showPassword={showPassword}
      handleClickShowPassword={handleClickShowPassword}
      {...props}
    />
  );
};
