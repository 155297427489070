import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Ucc3Ui from './ucc3-ui/Ucc3Ui';
import { parseErrorMessages } from '../../common/helpers';
import { cancelUCC3, updateUCC3 } from './ucc3.services';
import { useSnackbar } from '../../common/notification';
import useUnsavedChangesModal from '../../common/components/modals/unsaved-changes';
import { useUserInfo } from '../../common/context';
import { parseSubmitPayload } from './utils';

import { expandActionLabels } from '../../common/constants/features-constants';
import {
  Accordion,
  accordionCollapseAllState,
  accordionExpandAllState,
  initialAccordionState,
} from './constants';
import { type FormDataUCC3, saveSchema, submitSchema } from './schema';
import type { IUCC3Props } from './models';
import { CollateralChangeType } from './models';
import { ActionType, IFilingAlert } from '../ucc1/models';
import { routes } from '../../common/constants';
import { UnsavedHandlerContext } from '../shared/ucc/unsaved-handler-context/UnsavedHandlerContext';
import { useAuthedForm } from '../../common/hooks/useAuthedForm';
import { useHasInvalidFields } from '../../common/hooks/useHasInvalidFields';
import { useFilingFormCleanup } from '../shared/ucc/useFilingFormCleanup';
import { useBlocker } from '../../common/hooks/usePrompt';

const Ucc3 = ({
  filingId,
  dataUCC3,
  entityTypes,
  debtors,
  securedParties,
  collateralChangeTypesData,
  getData,
  attachments,
  updateAttachmentsList,
  isAttachmentListLoading,
}: IUCC3Props) => {
  const navigate = useNavigate();
  const { Snack } = useSnackbar();
  const { getCartDetails, setDelayFilingsFetch } = useUserInfo();
  const { handleUnsavedChanges } = useUnsavedChangesModal();
  const [accordionState, setAccordionState] = useState(initialAccordionState);
  const isPublishValidationRef = useRef(true);
  const [isOffensiveLanguageOverrided, setIsOffensiveLanguageOverrided] = useState(false);
  const [isSavingUCC3, setIsSavingUCC3] = useState<boolean>(false);
  const [filingAlerts, setFilingAlerts] = useState<IFilingAlert[] | null>(null);
  const {
    setIsSaved,
    setIsCompleted,
    setIsCanceled,
    hasPendingUploads,
    isCanceled,
    isSaved,
    isCompleted,
  } = useContext(UnsavedHandlerContext);

  const {
    reset,
    getValues,
    watch,
    handleSubmit,
    formState,
    control,
    setFocus,
    setValue,
    clearErrors,
    resetField,
  } = useAuthedForm({
    isPublishValidationRef: isPublishValidationRef,
    submitSchema,
    saveSchema,
    defaultValues: {
      ackContactName: dataUCC3?.ackContactName ?? '',
      ackContactPhone: dataUCC3?.ackContactPhone ?? '',
      ackContactEmail: dataUCC3?.ackContactEmail ?? '',
      isFinancingStatement: dataUCC3?.isFinancingStatement ?? false,
      eventTermination: dataUCC3?.eventTermination,
      eventContinuation: dataUCC3?.eventContinuation,
      eventAssignment: dataUCC3?.eventAssignment ?? false,
      eventAmendment: dataUCC3?.eventAmendment ?? false,
      eventAmendReleaseCollateral: dataUCC3?.eventAmendReleaseCollateral ?? false,
      collateralChangeType: dataUCC3?.collateralChangeType ?? CollateralChangeType.None,
      collateralChangeDescription: dataUCC3?.collateralChangeDescription ?? '',
      filerRefData: dataUCC3?.filerRefData ?? '',
      authEntityType: dataUCC3?.authEntityType ?? '',
      authDebType: dataUCC3?.authEntityType === 'Debtor' ? dataUCC3.authEntityType : '',
      authSecType: dataUCC3?.authEntityType === 'Secured' ? dataUCC3.authEntityType : '',
      debSecType: dataUCC3?.authEntityType === 'None' ? dataUCC3.authEntityType : '',
      authEntitySequenceNumber:
        dataUCC3?.authEntitySequenceNumber === 0 || dataUCC3?.authEntitySequenceNumber === null
          ? null
          : dataUCC3?.authEntitySequenceNumber,
      authDebtors:
        dataUCC3?.authEntityType === 'Debtor'
          ? dataUCC3?.authEntitySequenceNumber
            ? dataUCC3?.authEntitySequenceNumber?.toString()
            : '0'
          : '',
      authSecured:
        dataUCC3?.authEntityType === 'Secured'
          ? dataUCC3?.authEntitySequenceNumber
            ? dataUCC3?.authEntitySequenceNumber?.toString()
            : '0'
          : '',
    },
  });
  const eventAssignment = watch('eventAssignment', false) ?? false;

  const eventAmendment = watch('eventAmendment', false) ?? false;

  const hasInvalidFields = useHasInvalidFields(formState);
  const doFormCancel = useCallback(
    async (withRedirection = false) => {
      if (dataUCC3 && dataUCC3.id) {
        setIsCanceled(true);
        setIsSaved(false);
        setIsCompleted(false);
        const res = await cancelUCC3(filingId);
        if (res.notOk || Object.keys(res).length === 0) {
          Snack.error(res.messages[0] || 'Something went wrong, please try again later');
        }
      }
      getCartDetails();
      withRedirection && navigate('/onlinefiling/dashboard');
    },
    [dataUCC3, filingId, setIsCanceled, setIsCompleted, setIsSaved]
  );

  const shouldCleanOnLeave = !isSaved && !isCompleted && !isCanceled;
  useEffect(() => {
    setDelayFilingsFetch(shouldCleanOnLeave);
  }, [setDelayFilingsFetch, shouldCleanOnLeave]);

  const [showBlocker, setShowBlocker] = useState<boolean>(false);

  useFilingFormCleanup(async () => {
    await doFormCancel(false);
    setDelayFilingsFetch(false);
  }, shouldCleanOnLeave && !showBlocker);

  useBlocker(
    showBlocker,
    useMemo(() => {
      return async () => {
        await doFormCancel();
        setDelayFilingsFetch(false);
      };
    }, [doFormCancel, setDelayFilingsFetch])
  );

  const handleFormCancel = useCallback(async () => {
    if (isCanceled || formState.isSubmitted || isCompleted) {
      getCartDetails();
      return navigate('/onlinefiling/dashboard');
    }
    if (!formState.isDirty && filingAlerts === null && !hasPendingUploads) {
      doFormCancel(true);
      Snack.info('Action canceled.');
    } else {
      handleUnsavedChanges({
        onProceed: async () => {
          doFormCancel(true);
        },
      });
    }
  }, [formState.isDirty, formState.isSubmitted]);

  const handleAccordionState = (accordion: Accordion) =>
    setAccordionState((prevState) =>
      prevState.map((state, i) => (i === accordion ? !state : state))
    );

  const handleCheckboxAccordionState = (accordion: Accordion) => {
    if (!accordionState[accordion]) {
      handleAccordionState(accordion);
    }
  };

  const expandActionLabel = accordionState.every(Boolean) ? 'Collapse All' : 'Expand All';
  const expandAction = () => {
    if (expandActionLabel === expandActionLabels.CollapseAll) {
      setAccordionState(accordionCollapseAllState);
    } else {
      setAccordionState(accordionExpandAllState);
    }
  };

  const handleOnComplete = (e: React.SyntheticEvent) => {
    e.preventDefault();
    isPublishValidationRef.current = true;
    setIsCompleted(true);

    if (hasInvalidFields) {
      Snack.error(parseErrorMessages());
    }

    handleSubmit(async (data: FormDataUCC3) => {
      if (eventAssignment && securedParties?.length === 0) {
        return Snack.error(
          'Unable to proceed with assignment. Please add, edit, or modify at least one secured party before submitting the assignment.'
        );
      }
      if (eventAmendment && (securedParties?.length === 0 || debtors?.length === 0)) {
        return Snack.error(
          'Unable to proceed with amendment. Please add at least one new debtor or secured party to continue.'
        );
      }
      if (eventAssignment && eventAmendment) {
        return Snack.error('You cannot file Assignment and Amendment at the same time.');
      }
      const payload = {
        ...parseSubmitPayload(data),
        actionType: ActionType.Complete,
        isOffensiveLanguageOverrided,
      };
      const updateResponse = await updateUCC3({ filingId, payload });
      const currentValues = getValues();
      reset(currentValues, {
        keepValues: true,
        keepDirty: false,
        keepTouched: false,
        keepIsSubmitted: false,
        keepSubmitCount: false,
      });
      if (!updateResponse.notOk) {
        Snack.success('UCC3 filing successfully completed.');
        setFilingAlerts(null);
        setIsOffensiveLanguageOverrided(false);
        setDelayFilingsFetch(false);
        navigate(routes.cart);
      } else if (
        updateResponse.payload?.filingAlerts !== null &&
        updateResponse.payload?.filingAlerts?.length > 0
      ) {
        setFilingAlerts(updateResponse.payload?.filingAlerts);
      } else {
        Snack.error(parseErrorMessages(updateResponse.messages));
      }
      setIsOffensiveLanguageOverrided(false);
      isPublishValidationRef.current = true;
    })();
  };

  const handleOnSave = (e: React.SyntheticEvent) => {
    e.preventDefault();
    isPublishValidationRef.current = false;
    setIsSaved(true);

    if (hasInvalidFields) {
      Snack.error(parseErrorMessages());
    }

    handleSubmit(async (data: FormDataUCC3, evt) => {
      const payload = {
        ...parseSubmitPayload(data),
        actionType: ActionType.Save,
        isOffensiveLanguageOverrided,
      };
      setIsSavingUCC3(true);
      const res = await updateUCC3({ filingId, payload });
      const currentValues = getValues();
      reset(currentValues, {
        keepValues: true,
        keepDirty: false,
        keepTouched: false,
        keepIsSubmitted: false,
        keepSubmitCount: false,
      });

      if (!res.notOk) {
        Snack.success('UCC3 filing successfully saved.');
      } else {
        Snack.error(parseErrorMessages(res.messages));
      }

      setIsSavingUCC3(false);
      isPublishValidationRef.current = true;
    })();
  };

  useEffect(() => {
    setFocus('ackContactName');
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (isSaved) setIsSaved(false);
      if (isCompleted) setIsCompleted(false);
      if (isCanceled) setIsCanceled(false);
    });
    return () => subscription.unsubscribe();
  }, [watch, formState.isDirty]);

  return (
    <Ucc3Ui
      formState={formState}
      isCompleteFormValid={
        formState.isValid && isPublishValidationRef.current && !hasPendingUploads
      }
      eventAssignment={eventAssignment}
      control={control}
      setValue={setValue}
      filingId={filingId}
      expandAction={expandAction}
      expandActionLabel={expandActionLabel}
      handleAccordionState={handleAccordionState}
      handleCheckboxAccordionState={handleCheckboxAccordionState}
      handleOnComplete={handleOnComplete}
      handleOnSave={handleOnSave}
      handleFormCancel={handleFormCancel}
      accordionState={accordionState}
      ucc1Number={dataUCC3?.ucc1Number ?? ''}
      entityTypes={entityTypes}
      debtors={debtors}
      securedParties={securedParties}
      collateralChangeTypesData={collateralChangeTypesData}
      getData={getData}
      attachments={attachments}
      updateAttachmentsList={updateAttachmentsList}
      eventAmendment={eventAmendment}
      isOffensiveLanguageOverrided={isOffensiveLanguageOverrided}
      setIsOffensiveLanguageOverrided={setIsOffensiveLanguageOverrided}
      filingAlerts={filingAlerts}
      isSavingUCC3={isSavingUCC3}
      setAccordionState={setAccordionState}
      clearErrors={clearErrors}
      resetField={resetField}
      getValues={getValues}
      isDirty={formState.isDirty}
      isSaved={isSaved}
      isCanceled={isCanceled}
      isCompleted={isCompleted}
      hasPendingUploads={hasPendingUploads}
      isAttachmentListLoading={isAttachmentListLoading}
      doFormCancel={doFormCancel}
      showBlocker={showBlocker}
      setShowBlocker={setShowBlocker}
    />
  );
};

export default Ucc3;
