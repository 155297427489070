import {
  DateTimeFormatOptions,
  DebtorOrSecuredType,
  IUCC3ResponsePayload,
  UCC3Type,
} from './models';
import { FormDataUCC3 } from './schema';

export const parseUcc3 = (data: IUCC3ResponsePayload): UCC3Type => {
  const {
    id,
    ackContactName,
    ackContactEmail,
    ackContactPhone,
    type,
    uccNumber,
    packetId,
    isActive,
    isPosted,
    isBulkload,
    isSkipped,
    filerRefData,
    status,
    authEntitySequenceNumber,
    ucc1Number,
    collateralChangeDescription,
    isFinancingStatement,
    eventTermination,
    eventContinuation,
    eventAmendReleaseCollateral,
    eventAssignment,
    eventAmendment,
    lastCheckDate,
    collateralChangeType,
    authEntityType,
    additionalPartyPages,
    attachmentPages,
    totalFilingFee,
    qaUserId,
    qaDate,
    postDate,
    unredactedImageFilePath,
    redactedImageFilePath,
    updatedAt,
    updatedBy,
    deletedAt,
    deletedBy,
    createdAt,
    createdBy,
  } = data;
  return {
    id,
    ackContactName,
    ackContactEmail,
    ackContactPhone,
    type,
    status,
    uccNumber,
    packetId,
    isActive,
    isPosted,
    isBulkload,
    isSkipped,
    filerRefData,
    authEntitySequenceNumber,
    ucc1Number,
    collateralChangeDescription,
    isFinancingStatement,
    eventTermination,
    eventContinuation,
    eventAmendReleaseCollateral,
    eventAssignment,
    eventAmendment,
    lastCheckDate,
    collateralChangeType,
    authEntityType,
    additionalPartyPages,
    attachmentPages,
    totalFilingFee,
    qaUserId,
    qaDate,
    postDate,
    unredactedImageFilePath,
    redactedImageFilePath,
    updatedAt,
    updatedBy,
    deletedAt,
    deletedBy,
    createdAt,
    createdBy,
  };
};

export const divideDebSec = (data: DebtorOrSecuredType[]) => {
  const debtors: DebtorOrSecuredType[] = [];
  const securedParties: DebtorOrSecuredType[] = [];
  data.forEach((el) => {
    if (el.type === 'Debtor') {
      debtors.push(el);
    } else {
      securedParties.push(el);
    }
  });

  return { debtors, securedParties };
};

export const getPartyName = (partyList: DebtorOrSecuredType[], selected: string) => {
  const selectedParty = partyList.find((party) => party.sequenceNumber === Number(selected));
  if (selectedParty) {
    if (selectedParty.isOrganization) {
      return selectedParty.name;
    } else {
      return `${selectedParty.firstName} ${selectedParty.lastName} ${
        selectedParty.middleName ?? ''
      }${selectedParty.suffix ? `, ${selectedParty.suffix}` : ''}`;
    }
  }
  return '';
};

function omitObjectKeyValue(obj: any, ...props: any) {
  const result = { ...obj };
  props.forEach(function (prop: any) {
    delete result[prop];
  });
  return result;
}

export const parseSubmitPayload = (data: FormDataUCC3): FormDataUCC3 => {
  const authEntityType = data.debSecType || data.authDebType || data.authSecType || null;
  let authEntitySequenceNumber;
  switch (authEntityType) {
    case 'Debtor':
      authEntitySequenceNumber = data.authDebtors || null;
      break;
    case 'Secured':
      authEntitySequenceNumber = data.authSecured || null;
      break;
    default:
      authEntitySequenceNumber = null;
  }

  let payload = omitObjectKeyValue(
    data,
    'authDebType',
    'authDebtors',
    'authSecType',
    'authSecured',
    'debSecType'
  );
  payload = {
    ...payload,
    authEntityType,
    authEntitySequenceNumber,
  };
  return payload;
};

export const formatDateToLocaleString = (
  date: string,
  { dateFormat, timeFormat, excludeSeconds, removeCommaBetweenDateAndTime }: DateTimeFormatOptions
): string => {
  const dateFormatValue = dateFormat ?? '2-digit';
  const dateTimeString = new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    day: dateFormatValue,
    month: dateFormatValue,
    hour: timeFormat,
    minute: timeFormat,
    second: excludeSeconds ? undefined : timeFormat,
  });
  if (removeCommaBetweenDateAndTime) {
    const [dateString, timeString] = dateTimeString.split(', ');
    return `${dateString} ${timeString}`;
  }
  return dateTimeString;
};

export const convertLocalDateToUTC = (localDate: Date): Date => {
  return new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000); // offset is in minutes (converting to milliseconds)
};

export const formatAmountToAccountingFormat = (amount: number) => {
  const formattedAmount = amount.toFixed(2);
  const [integerPart, decimalPart] = formattedAmount.split('.');
  const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `$${integerWithCommas}.${decimalPart}`;
};
