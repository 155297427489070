import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import StartUcc3FormCompleteUi from './StartUcc3FormCompleteUi';
import { postUCC3 } from '../start-ucc.services';
import { useSnackbar } from '../../../common/notification';
import { useUserInfo } from '../../../common/context';

const schema = yup
  .object({
    check: yup
      .boolean()
      .required(
        'Confirm you want to file Amendment for the provided INITIAL FINANCING STATEMENT FILE #'
      )
      .test(
        'falsy',
        'Confirm you want to file Amendment for the provided INITIAL FINANCING STATEMENT FILE #',
        (val) => val !== false
      ),
  })
  .required();

export type FormData = yup.InferType<typeof schema>;

const StartUcc3FormComplete = ({
  filingId,
  verifyNumber,
}: {
  filingId: string | null;
  verifyNumber: string | null;
}) => {
  const { handleSubmit, formState, control } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      check: false,
    },
  });
  const { Snack } = useSnackbar();
  const navigate = useNavigate();
  const { getCartDetails } = useUserInfo();

  const onSubmit = async () => {
    if (verifyNumber !== filingId) {
      Snack.error(
        'The provided UCC number does not match verified UCC number. Verify the INITIAL FINANCING STATEMENT FILE # to continue'
      );
      return;
    }
    const res = await postUCC3(filingId);
    if (res.notOk || Object.keys(res).length === 0) {
      Snack.error(
        res.messages ? res?.messages[0]?.message : 'Something went wrong, please try again later'
      );
    } else {
      getCartDetails();
      navigate(`/onlinefiling/filing/ucc3/${res.payload}`);
    }
  };

  return (
    <StartUcc3FormCompleteUi
      onSubmit={handleSubmit(onSubmit)}
      formState={formState}
      control={control}
      filingId={filingId}
    />
  );
};

export default StartUcc3FormComplete;
