import { Typography, Box } from '@mui/material';
import { StyledModalSubmitButton } from '../global-styles';
import { Modal } from '../components/modals/Modal';
import { YesNoModalProps } from './models';
import { CloseIconButton } from '../global-styles/CloseIconButton';
import theme from '../theme';

const YesNoModal = ({
  open,
  handleModalClose,
  yesAction,
  noAction,
  yesButtonTitle = 'Proceed',
  noButtonTitle = 'Cancel',
  title = 'Unsaved Changes',
  content = 'All unsaved data will be lost if you continue.',
  startIcon,
  disabled,
}: YesNoModalProps) => {
  const xButtonAction = noAction ? <CloseIconButton onClick={noAction} /> : null;

  const modalActions = (
    <Box
      sx={{
        display: 'flex',
        gap: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(34) },
        flexDirection: 'row-reverse',
      }}
    >
      <StyledModalSubmitButton
        autoFocus
        disabled={disabled}
        startIcon={startIcon}
        variant="contained"
        onClick={() => {
          yesAction && yesAction();
        }}
      >
        {yesButtonTitle}
      </StyledModalSubmitButton>
      <StyledModalSubmitButton
        variant="outlined"
        onClick={() => {
          noAction && noAction();
        }}
      >
        {noButtonTitle}
      </StyledModalSubmitButton>
    </Box>
  );

  return (
    <Modal
      open={open}
      handleClose={handleModalClose}
      modalActions={modalActions}
      xButtonAction={xButtonAction}
      title={title}
    >
      <Typography>{content}</Typography>
    </Modal>
  );
};

export default YesNoModal;
