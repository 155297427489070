export enum Accordion {
  Pending = 0,
  Completed,
}

export const accordionInitialState = [true, true];

export const completedFilingsTableHeaders = [
  'Type',
  'For',
  'Pages',
  'Fees',
  'Quantity',
  'Amount',
  'Status',
  'Total',
  '',
];

export const completedFields = ['type', 'ackContactName', 'totalPagesCount'];
export const paymentSummaryFields = ['description', 'quantity', 'amount'];
