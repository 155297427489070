import { FC } from 'react';
import SearchGridWhiteHeading from './SearchGridWhiteHeading';
import { uccSearchDetailsSecuredPartiesURL } from '../../../common/constants/routes';
import { FileDetailsInterface } from '../types';
import { useViewAllRedirectUrl } from '../useViewAllRedirectUrl';
import DebtorSecuredList from './DebtorSecuredList';

const SecuredParties: FC<{ fileData: FileDetailsInterface }> = ({ fileData }) => {
  const { ucc1Number, securedPartiesTotalCount, secureds } = fileData;
  const { redirectUrl } = useViewAllRedirectUrl(ucc1Number);
  return (
    <>
      <SearchGridWhiteHeading
        title="Secured Parties"
        description="Current Secured Parties"
        result={securedPartiesTotalCount}
      />
      <DebtorSecuredList
        data={secureds}
        maxDisplay={3}
        viewAllUrl={uccSearchDetailsSecuredPartiesURL + redirectUrl}
      />
    </>
  );
};

export default SecuredParties;
