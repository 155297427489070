import React from 'react';

import useDebSecModal from '../../shared/ucc/modals/deb-sec-modal/useDebSecModal';
import useUndoActionModal from '../../shared/ucc/modals/undo-action-modal/useUndoActionModal';
import DebtorsOrSecuredParties from '../debtors-or-secured-parties/DebtorsOrSecuredParties';
import useRemoveDebSecModal from '../../shared/ucc/modals/remove-deb-sec-modal/useRemoveDebSecModal';

import { AssignmentProps } from '../models';
import { EntityType } from '../../../common/models/features/models';
import { useWatch } from 'react-hook-form';

const Assignment = ({ filingId, getData, securedParties, control }: AssignmentProps) => {
  const eventAssignment = useWatch({
    control,
    name: 'eventAssignment',
  });
  const { handleOpenModal } = useDebSecModal({
    filingId: filingId,
    type: EntityType.Secured,
    partyName: 'Secured Party',
    getData,
  });

  const { handleOpenModal: undoModalOpen } = useUndoActionModal({
    getData,
  });
  const { handleOpenModal: removeDebSec } = useRemoveDebSecModal({
    getData,
  });

  return (
    <>
      <DebtorsOrSecuredParties
        parties={securedParties}
        type={EntityType.Secured}
        isDisabled={!eventAssignment}
        partyName={'Secured Party'}
        filingId={filingId}
        doUndo={undoModalOpen}
        openDebSecModal={handleOpenModal}
        removeDebSec={removeDebSec}
        infoMessage={
          'Note - To do a full assignment, delete all secured parties before adding a new secured party.'
        }
      />
    </>
  );
};

export default Assignment;
