import { FormHelperText, Grid, Typography } from '@mui/material';
import { PasswordInput } from '../../../../common/components/inputs';

export const DebitInfoDisplayModalContent = ({
  password,
  onChange,
  onSubmit,
  isChanged,
  setIschanged,
}: {
  password: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  isChanged: boolean;
  setIschanged: React.Dispatch<boolean>;
}) => {
  return (
    <Grid container gap={2}>
      <Grid item xs={12} md={12}>
        <Typography variant="h4">
          Please enter your password to display the account number.
        </Typography>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={5.5} md={4}>
          <Typography variant="body3">Account number</Typography>
        </Grid>
        <Grid item xs={5} md={4}>
          <Typography variant="body3">**********</Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <Typography variant="body3">Password</Typography>
        </Grid>
        <Grid item xs={6.5}>
          <form onSubmit={(e) => onSubmit(e)}>
            <PasswordInput
              value={password}
              onChange={onChange}
              onBlur={() => setIschanged(true)}
              showIcon
              autoFocus
              error={password.length === 0 && isChanged}
              placeholder="Enter password"
            />
            {password.length === 0 && isChanged ? (
              <FormHelperText error={password.length === 0}>Password is required.</FormHelperText>
            ) : null}
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};
