import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import { ICheckBoxFormUI } from '../../../common/models';
import { FormDataUCC3 } from '../schema';

import { Accordion } from '../constants';

const TerminationCheckbox = ({
  control,
  formState: { errors },
  handleCheckboxAccordionState,
}: ICheckBoxFormUI<FormDataUCC3>) => {
  const eventTermination = useWatch({
    control,
    name: 'eventTermination',
  });

  useEffect(() => {
    if (eventTermination) {
      handleCheckboxAccordionState(Accordion.Termination);
    }
  }, [eventTermination]);
  return (
    <Controller
      control={control}
      name="eventTermination"
      render={({ field }) => (
        <FormGroup {...field}>
          <FormControlLabel control={<Checkbox checked={eventTermination} />} label="" />
        </FormGroup>
      )}
    />
  );
};

export default TerminationCheckbox;
