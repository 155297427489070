import { Grid, styled } from '@mui/material';

export const StyledClickHereGrid = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.convert.pxToRem(20),
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  [theme.breakpoints.up('xs')]: {
    width: theme.convert.pxToRem(351),
  },
  [theme.breakpoints.up('md')]: {
    width: theme.convert.pxToRem(688),
  },
  [theme.breakpoints.up('lg')]: {
    width: theme.convert.pxToRem(882),
  },
}));

export const StyledClickHereGridRegister = styled(StyledClickHereGrid)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    width: theme.convert.pxToRem(351),
  },
}));
