// Optional inputs Regex
export const phoneNumberRegexOptional = new RegExp(/^$|^[0-9]+$/);
export const emailRegexOptional = new RegExp(/^$|^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
export const nameRegexOptional = new RegExp(/^$|^[^\s]+(?:$|.*[^\s]+$)/);

// Required inputs Regex
export const phoneNumberRegex = new RegExp(/^[0-9]+$/);
export const emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
export const nameRegex = new RegExp(/^[^\s]+(?:$|.*[^\s]+$)/);
export const lettersNumbersRegex = new RegExp(/^[a-zA-Z0-9]+$/gi);

// Alphanumeric, letters, digits, special characters, spaces
// new RegExp(/^[a-zA-Z0-9!@#$%^&*)(+=._-\s]+$/gi);
// alternative: /^\w+(\s+\w+)*$/i
// new RegExp(/^$|^[a-zA-Z0-9!@#$%^&*)(+=._-\s]+$/gi);
