import { useState, useEffect } from 'react';
import { useModal, useMyAccount } from '../context';
import {
  DebitInfoDisplayAccountModalActions,
  DebitInfoDisplayAccountModalContent,
  DebitInfoDisplayModalContent,
  DebitInfoDisplayModalActions,
} from '../../features/my-account/my-account-accordions/debit-info';
import { ACCOUNT_NUMBER_TIMEOUT_SECONDS } from '../constants/timers';
import { CloseIconButton } from '../global-styles/CloseIconButton';

export const useDebitAccountModal = () => {
  const [accountNumber, setAccountNumber] = useState('');
  const [password, setPassword] = useState('');
  const [isChanged, setIschanged] = useState(false);
  const [counter, setCounter] = useState(ACCOUNT_NUMBER_TIMEOUT_SECONDS);
  const { getAccountNumber } = useMyAccount();
  const { handleModalOpen, handleModalClose, handleSetModalActions, handleSetModalContent, open } =
    useModal();

  const onHandleModalClose = () => {
    setIschanged(false);
    setPassword('');
    handleModalClose();
    setAccountNumber('');
  };
  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (password.length === 0) {
      setIschanged(true);
      return;
    }
    handleSetModalActions &&
      handleSetModalActions(
        <DebitInfoDisplayModalActions
          handleClose={onHandleModalClose}
          onSubmit={onSubmit}
          password={password}
          loading={true}
        />
      );
    const payload = await getAccountNumber({ password });
    if (!payload) {
      handleSetModalActions &&
        handleSetModalActions(
          <DebitInfoDisplayModalActions
            handleClose={onHandleModalClose}
            onSubmit={onSubmit}
            password={password}
            loading={false}
          />
        );
      return;
    }
    setCounter(ACCOUNT_NUMBER_TIMEOUT_SECONDS);
    setAccountNumber(payload.toString());
    handleModalOpen({
      title: 'Account Number',
      modalContent: (
        <DebitInfoDisplayAccountModalContent counter={counter} number={payload.toString()} />
      ),
      modalActions: <DebitInfoDisplayAccountModalActions handleClose={onHandleModalClose} />,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIschanged(true);
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (open) {
      handleModalOpen({
        title: 'Account Number',
        modalContent: (
          <DebitInfoDisplayModalContent
            password={password}
            isChanged={isChanged}
            onChange={handleChange}
            onSubmit={onSubmit}
            setIschanged={setIschanged}
          />
        ),
        xButtonAction: <CloseIconButton onClick={onHandleModalClose} />,
        modalActions: (
          <DebitInfoDisplayModalActions
            handleClose={onHandleModalClose}
            onSubmit={onSubmit}
            password={password}
          />
        ),
      });
    }
  }, [password, isChanged]);

  const onModalOpenHandler = () => {
    if (!accountNumber) {
      handleModalOpen({
        title: 'Account Number',
        xButtonAction: <CloseIconButton onClick={onHandleModalClose} />,
        modalContent: (
          <DebitInfoDisplayModalContent
            password={password}
            isChanged={isChanged}
            onChange={handleChange}
            onSubmit={onSubmit}
            setIschanged={setIschanged}
          />
        ),
        modalActions: (
          <DebitInfoDisplayModalActions
            handleClose={onHandleModalClose}
            onSubmit={onSubmit}
            password={password}
          />
        ),
      });
    } else {
      handleModalOpen({
        title: 'Account Number',
        xButtonAction: <CloseIconButton onClick={onHandleModalClose} />,
        modalContent: (
          <DebitInfoDisplayAccountModalContent counter={counter} number={accountNumber} />
        ),
        modalActions: <DebitInfoDisplayAccountModalActions handleClose={onHandleModalClose} />,
      });
    }
  };

  useEffect(() => {
    if (counter === 0) {
      setCounter(0);
      onHandleModalClose();
    }
    handleSetModalContent(
      <DebitInfoDisplayAccountModalContent counter={counter} number={accountNumber} />
    );
    handleSetModalActions &&
      handleSetModalActions(
        <DebitInfoDisplayAccountModalActions handleClose={onHandleModalClose} />
      );
  }, [counter]);

  useEffect(() => {
    if (accountNumber) {
      const intervalID = setInterval(() => {
        setCounter((prevCount) => {
          const counter = prevCount - 1;
          return counter;
        });
      }, 1000);
      return () => clearInterval(intervalID);
    }
  }, [accountNumber]);

  return { onModalOpenHandler };
};
