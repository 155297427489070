import { Typography, Grid, Button } from '@mui/material';
import { useAuth, useMyAccount } from '../../../../common/context';
import useUnsavedChangesModal from '../../../../common/components/modals/unsaved-changes/useUnsavedChangesModal';

export const DebitInfoView = ({ handleModalOpen }: { handleModalOpen: () => void }) => {
  const initEditState = {
    personalInfo: false,
    acknowledgementInfo: false,
  };

  const { isEditMode, setIsEditMode } = useAuth();
  const { setEditModeAccordion } = useMyAccount();
  const { handleUnsavedChanges } = useUnsavedChangesModal();

  const handleOpenAccountNumber = (e: React.MouseEvent) => {
    const onProceed = () => {
      setEditModeAccordion(initEditState);
      setIsEditMode(false);
      handleModalOpen();
    };
    if (isEditMode) {
      handleUnsavedChanges({ onProceed });
    } else {
      onProceed();
    }
  };
  return (
    <Grid container padding={2}>
      <Grid item xs={12}>
        <Grid container rowGap={1}>
          <Grid item xs={12} md={3} lg={2.5}>
            <Typography variant="h5">Account number</Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={9.5}>
            <Grid container>
              <Typography variant="h5" paddingRight={2}>
                **********
              </Typography>
              <Button sx={{ p: 0, minWidth: 0 }} onClick={handleOpenAccountNumber}>
                Display
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
