import React from 'react';
import { useSnackbar } from '../../../common/notification';
import { cancelUCC3UploadAttachments } from '../ucc3.services';
import { AttachmentListItemProps } from './models';
import { Box, Button, Grid, LinearProgress, Typography, useTheme } from '@mui/material';
import { itemDeviderBorder } from '../../../common/constants/colors';

const AttachmentListItem = ({
  attachment,
  uploadingAttachment,
  onRemove,
  setUploadingAttachments,
}: AttachmentListItemProps) => {
  const theme = useTheme();
  const { Snack } = useSnackbar();
  const onCancel = () => {
    if (uploadingAttachment?.promise && setUploadingAttachments) {
      cancelUCC3UploadAttachments({ promise: uploadingAttachment.promise });
      setUploadingAttachments((state) =>
        state.filter((item) => item.id !== uploadingAttachment.id)
      );
      Snack.success('Upload is successfully canceled.');
    }
  };

  const getFileSizeInfo = (fileSize: number) => {
    const sizeInKB = fileSize / 1000;
    const sizeInMB = sizeInKB / 1000;
    if (sizeInMB >= 1) {
      return `${sizeInMB.toFixed(1)}MB`;
    }
    return `${Math.round(sizeInKB)}kB`;
  };

  return (
    <Grid
      container
      sx={{
        borderBottom: `1px solid ${itemDeviderBorder}`,
        paddingY: theme.convert.pxToRem(28),
        paddingX: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(40) },
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Typography
          sx={{
            width: { xs: theme.convert.pxToRem(155), md: theme.convert.pxToRem(270) },
          }}
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
          variant="h5"
        >
          {attachment?.name || uploadingAttachment?.fileName}
        </Typography>
      </Box>
      <Box
        paddingX={1}
        flexBasis={{ xs: 'calc(10%)', md: 'calc(18%)', lg: 'calc(30%)', paddingRight: 0 }}
      >
        {uploadingAttachment?.promise ? (
          <LinearProgress
            sx={{
              width: { lg: theme.convert.pxToRem(308) },
              height: theme.convert.pxToRem(10),
              borderRadius: theme.convert.pxToRem(4),
            }}
          />
        ) : attachment?.fileSize ? (
          <Typography>{getFileSizeInfo(attachment?.fileSize)}</Typography>
        ) : null}
      </Box>
      <Box display="flex" justifyContent="flex-end">
        {uploadingAttachment?.promise ? (
          <Button sx={{ justifyContent: 'flex-end' }} onClick={onCancel} variant="text">
            Cancel
          </Button>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: { xs: theme.convert.pxToRem(0), md: theme.convert.pxToRem(20) },
              justifyContent: 'flex-end',
            }}
          >
            <Button component="a" href={attachment?.attachmentUrl} target="_blank" variant="text">
              Download
            </Button>
            <Button onClick={onRemove} variant="text">
              Remove
            </Button>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default AttachmentListItem;
