import { isTermsOfUseAcceptedCookieName } from './constants';

export const isTermsOfUseAcceptedValidCookie = () => {
  const expirationTime = 24 * 60 * 60 * 1000;
  const storedTermsOfUseCookie = localStorage.getItem(isTermsOfUseAcceptedCookieName);
  if (!storedTermsOfUseCookie) {
    return false;
  }
  const parsedData = JSON.parse(storedTermsOfUseCookie);
  const currentTime = new Date().getTime();
  if (currentTime - parsedData.timestamp > expirationTime) {
    localStorage.removeItem(isTermsOfUseAcceptedCookieName);
    return false;
  }
  return true;
};
