import { Grid, TextField, FormHelperText, Typography, InputLabel } from '@mui/material';
import Loader from '../../../../common/components/loader';
import { Controller } from 'react-hook-form';

import { PasswordInput } from '../../../../common/components/inputs';
import {
  StyledBoxWrapper,
  StyledSubmitButton,
} from '../../../../common/components/user-auth-components/common-auth-styles';
import type { FormData } from './PasswordLoginForm';
import type { ILoginFormUI } from '../../../../common/models';

interface IPasswordLoginFormUI extends ILoginFormUI<FormData> {
  isModalOpen: boolean;
}

const PasswordLoginFormUI = ({
  onSubmit,
  isModalOpen,
  control,
  formState: { errors, isValid, isSubmitting },
}: IPasswordLoginFormUI) => (
  <form onSubmit={onSubmit}>
    <StyledBoxWrapper>
      <Grid container alignItems="center">
        <Grid item xs={12} md={3} lg={3.2} paddingBottom={0.5}>
          <InputLabel htmlFor="email">
            <Typography variant="h4" fontWeight={400}>
              Email *
            </Typography>
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={9} lg={8.8}>
          <Controller
            name="email"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...field}
                inputRef={ref}
                placeholder="Enter email"
                error={!!errors.email?.message}
                type="email"
                autoFocus={!isModalOpen}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} md={3} lg={3.2} paddingBottom={0.5}></Grid>
        <Grid item xs={12} md={9} lg={8.8}>
          <FormHelperText error={!!errors.email?.message}>{errors.email?.message}</FormHelperText>
        </Grid>
      </Grid>
      <Grid container alignItems="center" pt="2.5rem">
        <Grid item xs={12} md={3} lg={3.2} paddingBottom={0.5}>
          <InputLabel htmlFor="password">
            <Typography variant="h4" noWrap fontWeight={400}>
              Password *
            </Typography>
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={9} lg={8.8}>
          <Controller
            name="password"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <PasswordInput
                {...field}
                inputRef={ref}
                error={!!errors.password?.message}
                placeholder="Enter password"
                showIcon
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} md={3} lg={3.2}></Grid>
        <Grid item xs={12} md={9} lg={8.8}>
          <FormHelperText error={!!errors.password?.message}>
            {errors.password?.message}
          </FormHelperText>
        </Grid>
      </Grid>
      <Grid container alignItems="center" pt="2.5rem">
        <Grid item xs={12}>
          <StyledSubmitButton
            disabled={!isValid || isSubmitting}
            variant="contained"
            type="submit"
            startIcon={isSubmitting ? <Loader /> : null}
            fullWidth
          >
            {isSubmitting ? 'Logging In' : 'Login'}
          </StyledSubmitButton>
        </Grid>
      </Grid>
    </StyledBoxWrapper>
  </form>
);

export default PasswordLoginFormUI;
