import { useCallback } from 'react';
import { useBeforeUnload } from 'react-router-dom';

import { MyAccountProvider, useAuth } from '../common/context';
import { useBlocker } from '../common/hooks/usePrompt';
import { PageTitle } from '../common/constants/features-constants';
import MyAccountUI from '../features/my-account/MyAccountUi';
import PageWrapper from '../common/layouts/PageWrapper';

const MyAccount = () => {
  const { isEditMode } = useAuth();

  useBeforeUnload(
    useCallback(
      (e) => {
        if (isEditMode) {
          e.preventDefault();
          e.returnValue = '';
          return true;
        }
      },
      [isEditMode]
    )
  );

  useBlocker(isEditMode);

  return (
    <PageWrapper title={PageTitle.MY_ACCOUNT}>
      <MyAccountProvider>
        <MyAccountUI />
      </MyAccountProvider>
    </PageWrapper>
  );
};

export default MyAccount;
