import { IFilingAlert } from '../models';

export const parseAlert = ({
  wordPosition,
  length,
  content,
}: Pick<IFilingAlert, 'wordPosition' | 'length' | 'content'>) => {
  const firstPart = content.slice(0, wordPosition);
  const secondPart = content.slice(wordPosition, wordPosition + length);
  const thirdPart = content.slice(wordPosition + length);
  return (
    <>
      {firstPart}
      <span style={{ textDecoration: 'underline' }}>{secondPart}</span>
      {thirdPart}
    </>
  );
};
