import { useNavigate } from 'react-router-dom';
import { Button, Grid, useTheme } from '@mui/material';
import { announcementsPageURL } from '../../common/constants/routes';
import { HomeAnnouncementsCarousel } from './HomeAnnouncementsCarousel';
import { PrimarySectionHeading } from './home-page-custom-styled-component';

const Announcements = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Grid
      container
      padding={{
        xs: theme.convert.pxToRem(36),
        md: theme.convert.pxToRem(80),
      }}
      flexDirection={'column'}
      gap={3}
    >
      <Grid item>
        <PrimarySectionHeading
          textAlign={'center'}
          fontSize={{
            xs: theme.typography.pxToRem(28),
          }}
          lineHeight={{
            xs: theme.typography.pxToRem(36),
          }}
        >
          UCC Announcements
        </PrimarySectionHeading>
      </Grid>
      <HomeAnnouncementsCarousel />
      <Grid item display={'flex'} justifyContent={'flex-end'}>
        <Button onClick={() => navigate(announcementsPageURL)}>View All Announcements</Button>
      </Grid>
    </Grid>
  );
};

export default Announcements;
