import { Box, useTheme } from '@mui/material';
import Loader from '../../../../common/components/loader';
import { StyledModalSubmitButton } from '../../../../common/global-styles';

interface UpdateEmailFormActionsProps {
  onSubmit: (e: any) => void;
  isValid?: boolean;
  isSubmitting?: boolean;
  onModalClose?: () => void;
  secondaryButton?: boolean;
  primaryButtonText?: string;
}

export const UpdateEmailFormActions = ({
  onSubmit,
  isValid,
  isSubmitting,
  onModalClose,
  secondaryButton,
  primaryButtonText,
}: UpdateEmailFormActionsProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        gap: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(34) },
        flexDirection: 'row-reverse',
      }}
    >
      <StyledModalSubmitButton
        variant="contained"
        startIcon={isSubmitting ? <Loader /> : null}
        disabled={!isValid || isSubmitting}
        type="submit"
        onClick={onSubmit}
      >
        {primaryButtonText ?? 'Save'}
      </StyledModalSubmitButton>
      {secondaryButton ? (
        <StyledModalSubmitButton variant="outlined" onClick={onModalClose}>
          Cancel
        </StyledModalSubmitButton>
      ) : null}
    </Box>
  );
};
