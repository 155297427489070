import { AccordionDetails } from '@mui/material';
import { DebitInfoView } from './DebitInfoView';
import { useDebitAccountModal } from '../../../../common/hooks/useDebitAccountModal';

export const DebitInfo = () => {
  const { onModalOpenHandler } = useDebitAccountModal();

  return (
    <AccordionDetails>
      <DebitInfoView handleModalOpen={onModalOpenHandler} />
    </AccordionDetails>
  );
};
