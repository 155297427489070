import { Grid, useTheme } from '@mui/material';
import { Divider } from '../Divider';
import { FooterInfo } from './footer-info/FooterInfo';
import { FooterCopyright } from './footer-copyright/FooterCopyright';

const Footer = () => {
  const theme = useTheme();
  return (
    <footer
      style={{
        boxShadow: '0px -4px 4px 0px rgba(176, 192, 237, 0.25)',
        paddingTop: theme.convert.pxToRem(40),
      }}
    >
      <Grid container flexDirection={'column'}>
        <Grid item xs={12}>
          <FooterInfo />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <FooterCopyright />
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
