import React, { useEffect } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography, useTheme } from '@mui/material';
import { Controller, useWatch } from 'react-hook-form';

import { checkResetEntities, resetEntities } from '../ucc3.services';
import { StyledModalSubmitButton } from '../../../common/global-styles';
import { useModal } from '../../../common/context';
import { useSnackbar } from '../../../common/notification';

import { Accordion } from '../constants';
import { AmendmentCheckboxProps } from './models';
import { CloseIconButton } from '../../../common/global-styles/CloseIconButton';

const AmendmentCheckbox = ({
  control,
  handleCheckboxAccordionState,
  filingId,
  setValue,
  getData,
}: AmendmentCheckboxProps) => {
  const theme = useTheme();
  const { Snack } = useSnackbar();
  const { handleModalOpen, handleModalClose } = useModal();
  const eventAmendment = useWatch({
    control,
    name: 'eventAmendment',
  });
  const eventAssignment = useWatch({
    control,
    name: 'eventAssignment',
  });
  useEffect(() => {
    if (eventAmendment) {
      handleCheckboxAccordionState(Accordion.Amendment);
    }
  }, [eventAmendment]);

  const doResetEntities = async () => {
    const res = await resetEntities({ filingId, entityType: null });
    if (!res.notOk) {
      setValue('eventAmendment', false);
      handleModalClose();
      Snack.success('Event is successfully unchecked.');
      getData(filingId);
    } else {
      setValue('eventAmendment', false);
      Snack.error('Event could not be unchecked. Please try again.');
    }
  };

  const doCloseModal = () => {
    setValue('eventAmendment', true);
    handleModalClose();
  };

  const doCheckResetEntities = async () => {
    const res = await checkResetEntities({ filingId });

    if (res.notOk) {
      Snack.error('Could not check the entity state.');
      return;
    }

    if (res.payload) {
      handleModalOpen({
        title: 'Unchecking Event',
        modalContent: (
          <Typography>
            Are you sure you want to uncheck this event? If you proceed all event data you updated
            will be lost.
          </Typography>
        ),
        xButtonAction: <CloseIconButton onClick={doCloseModal} />,
        modalActions: (
          <Box
            sx={{
              display: 'flex',
              gap: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(34) },
              flexDirection: 'row-reverse',
            }}
          >
            <StyledModalSubmitButton autoFocus variant="contained" onClick={doResetEntities}>
              Proceed
            </StyledModalSubmitButton>
            <StyledModalSubmitButton variant="outlined" onClick={doCloseModal}>
              Cancel
            </StyledModalSubmitButton>
          </Box>
        ),
      });
    }
  };

  const toggleAmendmentCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = event;

    if (checked) {
      setValue('eventAssignment', false);
      return;
    }

    doCheckResetEntities();
  };

  return (
    <Controller
      control={control}
      name="eventAmendment"
      render={({ field }) => (
        <FormGroup {...field}>
          <FormControlLabel
            control={
              <Checkbox
                checked={eventAmendment}
                disabled={eventAssignment}
                onChange={(event) => toggleAmendmentCheckbox(event)}
              />
            }
            label=""
          />
        </FormGroup>
      )}
      defaultValue={eventAmendment}
    />
  );
};

export default AmendmentCheckbox;
