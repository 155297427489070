import { useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';

import theme from '../../common/theme';
import {
  bottomHomeSectionContentPadding,
  GrayHomeSection,
  SecondarySectionHeading,
} from './home-page-custom-styled-component';
import {
  achAccountPageURL,
  downloadPageURL,
  formsPageURL,
  xmlImplementationGuidePageURL,
} from '../../common/constants/routes';

const ResourceAndServices = () => {
  const navigate = useNavigate();
  const resourceAndServiceLinks = [
    { text: 'UCC Forms', url: formsPageURL },
    { text: 'ACH Debit Accounts', url: achAccountPageURL },
    { text: 'XML Implementation Guide', url: xmlImplementationGuidePageURL },
    { text: 'UCC Secured Transactions Download', url: downloadPageURL },
  ];
  return (
    <GrayHomeSection>
      <SecondarySectionHeading>Resource and Service</SecondarySectionHeading>
      <Grid
        container
        justifyContent={'space-between'}
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        paddingX={bottomHomeSectionContentPadding}
      >
        {resourceAndServiceLinks.map(({ text, url }) => (
          <Grid
            item
            xs={12}
            md={6}
            key={`rns_${text}`}
            marginBottom={{
              xs: theme.convert.pxToRem(8),
              md: 2,
            }}
            alignItems={'flex-start'}
          >
            <Button onClick={() => navigate(url)} tabIndex={0} style={{ paddingLeft: 0 }}>
              {text}
            </Button>
          </Grid>
        ))}
      </Grid>
    </GrayHomeSection>
  );
};

export default ResourceAndServices;
