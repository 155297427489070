import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  StartUcc3FormComponent,
  StartUccForm,
  StartUccFilingSubtitleWrapper,
} from '../features/start-ucc-filing';
import { postUCC1 } from '../features/start-ucc-filing/start-ucc.services';
import { useSnackbar } from '../common/notification';
import { FilingType } from '../common/models/ucc';
import { useUserInfo } from '../common/context';
import { PageTitle } from '../common/constants/features-constants';
import PageWrapper from '../common/layouts/PageWrapper';

const StartUccFiling = () => {
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedForm, setSelectedForm] = useState('');
  const [isUCC1Loading, setIsUCC1Loading] = useState(false);
  const { Snack } = useSnackbar();
  const navigate = useNavigate();
  const { getCartDetails } = useUserInfo();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setSelectedForm(FilingType[value as keyof typeof FilingType]);
    setSelectedValue(value);
  };

  const onSubmitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (selectedValue === FilingType.UCC1) {
      setIsUCC1Loading(true);
      const res = await postUCC1();
      if (res.notOk) {
        Snack.error(
          res.messages ? res?.messages[0]?.message : 'Something went wrong, please try again later'
        );
      } else {
        getCartDetails();
        navigate(`/onlinefiling/filing/ucc1/${res.payload}`);
      }
      setIsUCC1Loading(false);
    }
    setSelectedForm(selectedValue);
  };

  return (
    <PageWrapper title={PageTitle.ONLINE_FILING}>
      <StartUccFilingSubtitleWrapper />
      <StartUccForm
        onSubmitHandler={onSubmitHandler}
        handleChange={handleChange}
        selectedForm={selectedForm}
        selectedValue={selectedValue}
        isUCC1Loading={isUCC1Loading}
      />
      {selectedForm === FilingType.UCC3 ? <StartUcc3FormComponent /> : null}
    </PageWrapper>
  );
};

export default StartUccFiling;
