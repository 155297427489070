export const publicSearchApiName = 'PublicSearchAPI';
export const apiName = 'OnlineFilingAPI';
export const getAccInfoPath = '/me';
export const updatePersonalInfoPath = '/personal-info';
export const updateAcknowledgementInfoPath = '/acknowledgment-info';
export const accountNumberPath = '/account-number';
export const updatePasswordAndSecurityPath = '/password-and-security';
export const verifyFilingPath = '/filings';
export const cartDetailsPath = '/cart-details';
export const verifyCartPath = '/verify-cart';
export const cartCheckoutPath = '/checkout';
export const emptyInit = {};
