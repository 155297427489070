import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import Loader from '../../common/components/loader';
import { useSnackbar } from '../../common/notification';
import { useUserInfo } from '../../common/context';
import DashboardAccordionsUI from './dashboard-accordions-ui/';
import { YesNoModal } from '../../common/yes-no-modal';
import { parseErrorMessages } from '../../common/helpers';
import {
  removeCartFiling,
  getAllUCCFormFilings,
} from '../shared/ucc/services/pending-filing.services';
import { getFilingHistory } from './dashboard.services';

import { IHandleActionFilingProps, IFilingsResponse } from '../shared/ucc/models';
import { Status } from '../../common/models/features/models';
import { Accordion, accordionInitialState, allowedSortColumns } from './constants';
import { saveSchema, type FormData } from './schema';

const DashboardAccordions = () => {
  const navigate = useNavigate();
  const { Snack } = useSnackbar();
  const { isLoadingCartDetails, getCartDetails } = useUserInfo();
  const [accordionState, setAccordionState] = useState(accordionInitialState);
  const [isFilingsDataLoading, setIsFilingsDataLoading] = useState(true);
  const [pendingFilingsData, setPendingFilingsData] = useState<IFilingsResponse[] | null>(null);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [filingToBeRemoved, setFilingToBeRemoved] = useState<IHandleActionFilingProps | null>(null);
  const [activeToolTip, setactiveTooltip] = useState<number | null>(null);
  const [filingHistoryData, setFilingHistoryData] = useState<any>(null);
  const [searchClicked, setSearchClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const defaultFromDate = moment().subtract(6, 'days').startOf('day');
  const defaultToDate = moment();

  const { handleSubmit, formState, control, getValues, reset, setValue } = useForm<FormData>({
    resolver: yupResolver(saveSchema),
    mode: 'onSubmit',
    defaultValues: {
      uccNumber: '',
      dateRange: [defaultFromDate, defaultToDate],
      orderColumnName: '',
      sortOrder: 'Descending',
    },
  });

  const formData = getValues();

  const onSubmit = (data: FormData) => {
    setSearchClicked(true);
    getFilingHistoryData(data);
  };

  const handleOnNewFiling = () => {
    navigate('/onlinefiling/filing');
  };

  const handleAccordionState = (accordion: Accordion) =>
    setAccordionState((prevState) =>
      prevState.map((state, i) => (i === accordion ? !state : state))
    );

  const handleEditFiling = ({ id, type }: IHandleActionFilingProps) => {
    navigate(`/onlinefiling/filing/${type.toLowerCase()}/${id}`);
  };

  const handleModalOpen = ({ id, type }: IHandleActionFilingProps) => {
    setFilingToBeRemoved({ id, type });
    setOpenRemoveModal(true);
  };

  const toggleTooltip = (id: number) => setactiveTooltip(activeToolTip === id ? null : id);

  const handleNoActionModal = () => {
    Snack.info('Action canceled.');
    setFilingToBeRemoved(null);
    setOpenRemoveModal(false);
  };

  const getPendingFilingsData = async () => {
    const request = getAllUCCFormFilings(Status.Pending);
    const response = await request;
    const filingsData: Record<string, IFilingsResponse[]> = {};

    const { notOk, messages, payload } = response;
    const status = Status.Pending;

    if (notOk) {
      Snack.error(
        parseErrorMessages(messages, `Something went wrong while trying to get ${status} filings.`)
      );
      return;
    }

    filingsData[status] = payload || [];

    const pendingFilingsData = Object.values(filingsData).flat();
    setPendingFilingsData(pendingFilingsData);
    setIsFilingsDataLoading(false);
  };

  const getFilingHistoryData = async (data: FormData) => {
    const isToday = moment(data.dateRange && data.dateRange[1]).isSame(moment(), 'day');
    const fromDate = data.dateRange
      ? moment(data.dateRange[0]).startOf('day').utcOffset(0, true).toDate().toISOString()
      : defaultFromDate.toDate().toISOString();
    const toDate = data.dateRange
      ? isToday
        ? moment().toDate().toISOString()
        : moment(data.dateRange[1]).endOf('day').utcOffset(0, true).toDate().toISOString()
      : defaultToDate.toDate().toISOString();
    try {
      const res = await getFilingHistory({
        fromDate,
        toDate,
        sortOrder: data.sortOrder || 'Descending',
        ...(data.uccNumber && { uccNumber: data.uccNumber }),
        ...(data.orderColumnName && { orderColumnName: data.orderColumnName }),
      });
      if (res.notOk) {
        throw new Error(
          parseErrorMessages(
            res.messages,
            `Something went wrong while trying to get ${res.status} filings.`
          )
        );
      }
      setFilingHistoryData(res.payload);
    } catch (error: any) {
      Snack.error(error.message);
    }
  };

  useEffect(() => {
    getPendingFilingsData();
    getFilingHistoryData(formData);
  }, []);

  const handleRemoveActionModal = async () => {
    const id = filingToBeRemoved?.id;
    const type = filingToBeRemoved?.type;
    if (id && type) {
      setIsLoading(true);
      const response = await removeCartFiling({ id, type });
      if (!response.notOk) {
        getCartDetails();
        getPendingFilingsData();
        Snack.success('UCC form is successfully removed.');
        setIsLoading(false);
      } else {
        Snack.error(
          parseErrorMessages(
            response.messages,
            'Something went wrong while trying to remove the UCC form.'
          )
        );
        setIsLoading(false);
        setOpenRemoveModal(false);
      }
    }
    setFilingToBeRemoved(null);
    setOpenRemoveModal(false);
  };

  const handleSortOrderChange = (sortOrder: string, column: string) => {
    getFilingHistoryData({
      ...formData,
      sortOrder: sortOrder,
      orderColumnName: allowedSortColumns[column] ? column : '',
    });
  };

  return isLoadingCartDetails || isFilingsDataLoading ? (
    <Grid container justifyContent="center">
      <Loader size={40} color="blue" />
    </Grid>
  ) : (
    <>
      <DashboardAccordionsUI
        accordionState={accordionState}
        handleAccordionState={handleAccordionState}
        pendingFilingsData={pendingFilingsData}
        handleEditFiling={handleEditFiling}
        handleModalOpen={handleModalOpen}
        toggleTooltip={toggleTooltip}
        activeToolTip={activeToolTip}
        control={control}
        formState={formState}
        reset={reset}
        setValue={setValue}
        getValues={getValues}
        onSubmit={handleSubmit(onSubmit)}
        handleOnNewFiling={handleOnNewFiling}
        filingHistoryData={filingHistoryData}
        handleSortOrderChange={handleSortOrderChange}
        searchClicked={searchClicked}
      />
      <YesNoModal
        open={openRemoveModal}
        handleModalClose={handleNoActionModal}
        noAction={handleNoActionModal}
        yesAction={handleRemoveActionModal}
        yesButtonTitle={isLoading ? 'Yes, removing' : 'Yes, remove'}
        noButtonTitle="No, cancel"
        disabled={isLoading}
        startIcon={isLoading ? <Loader /> : null}
        title="Remove UCC Form"
        content="Are you sure you want to remove this UCC form?"
      />
    </>
  );
};

export default DashboardAccordions;
