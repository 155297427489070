import { styled } from '@mui/material/styles';

export const StyledImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: theme.convert.pxToRem(93),
    height: theme.convert.pxToRem(28),
  },
  [theme.breakpoints.up('md')]: {
    width: theme.convert.pxToRem(80),
    height: theme.convert.pxToRem(24),
  },
}));
