import { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Section from '../common/layouts/Section';
import { StyledUCCButton } from '../features/start-ucc-filing/styled-items/StyledUccButton';
import { cart, dashboard } from '../common/constants/routes';
import { useSnackbar } from '../common/notification';
import PageWrapper from '../common/layouts/PageWrapper';
import { checkCanceledPayment } from '../features/payment/payment.service';
import { IToken } from '../features/payment/models';
import Loader from '../common/components/loader';
import { PageTitle } from '../common/constants/features-constants';
import { useReactToPrint } from 'react-to-print';
import PrintableArea from '../common/layouts/PrintableArea';

const PaymentCanceled = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { Snack } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const token = search.get('token');

  const init = async ({ token }: IToken) => {
    const res = await checkCanceledPayment({ token });

    if (res.notOk) {
      Snack.error('Ooops! Unexpected problem occurred. Please try again later.');
      return navigate(dashboard);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (token) {
      init({ token });
    } else {
      Snack.error('Token is missing.');
      return navigate(dashboard);
    }
  }, [token]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <Loader size={40} color="blue" />
      </Box>
    );
  }

  return (
    <PrintableArea ref={componentRef}>
      <PageWrapper title={PageTitle.CART}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          mb={0.5}
          className="no-print"
        >
          <Button variant="text" sx={{ padding: 0 }} onClick={handlePrint}>
            Print
          </Button>
        </Box>
        <Section title="Your Payment Was Cancelled">
          <Typography variant="body1">
            Please resubmit the payment with correct payment information. If you are having
            technical issues, please contact technical support at help@floridaucc.com or call
            850-222-8526.
          </Typography>
        </Section>
        <Box sx={{ height: (theme) => theme.convert.pxToRem(40) }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'flex-start',
            gap: 5,
          }}
          className="no-print"
        >
          <StyledUCCButton autoFocus onClick={() => navigate(cart)} variant="contained">
            View Cart
          </StyledUCCButton>
        </Box>
        <Box sx={{ height: 837 }} />
      </PageWrapper>
    </PrintableArea>
  );
};

export default PaymentCanceled;
