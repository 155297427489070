import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PersonalInfoFormUI } from './PersonalInfoFormUI';
import { useAuth, useMyAccount } from '../../../../common/context';
import { phoneNumberRegexOptional } from '../../../../common/constants/regex';

interface IPersonalFormDataProps {
  setIsValid: React.Dispatch<boolean>;
  setName: React.Dispatch<string>;
  setPhoneNumber: React.Dispatch<string>;
}

const schema = yup.object({
  name: yup.string().max(101, 'Max length is 100 characters.').required('Name is required.'),
  phoneNumber: yup
    .string()
    .max(25, 'Max length is 25 characters.')
    .test(
      'len',
      'Phone number must be at least 10 digits long.',
      (val = '') => val?.length >= 10 || val?.length === 0
    )
    .matches(
      phoneNumberRegexOptional,
      'Phone number must not contain any characters or special symbols.'
    ),
});

export type FormData = yup.InferType<typeof schema>;

export const PersonalInfoForm = ({
  setIsValid,
  setName,
  setPhoneNumber,
}: IPersonalFormDataProps) => {
  const { setIsEditMode } = useAuth();
  const { updatePersonalInfo } = useMyAccount();
  const { handleSubmit, formState, control, getValues } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const { isValid, isValidating } = formState;

  useEffect(() => {
    setIsEditMode(formState.isDirty);
    return () => {
      setIsEditMode(false);
    };
  }, [formState.isDirty]);

  const submitHandler = async () => {
    const { name, phoneNumber } = getValues();
    await updatePersonalInfo({ name, phoneNumber: phoneNumber ?? '' });
  };

  useEffect(() => {
    const { name, phoneNumber } = getValues();
    setIsValid(isValid);
    setName((name ?? '').replace(/\s+/g, ' ').trim() ?? '');
    setPhoneNumber(phoneNumber ?? '');
  }, [isValidating, isValid]);

  return (
    <PersonalInfoFormUI
      control={control}
      formState={formState}
      onSubmit={handleSubmit(submitHandler)}
    />
  );
};
