import { useWatch } from 'react-hook-form';
import {
  Box,
  Grid,
  FormHelperText,
  Typography,
  RadioGroup,
  FormControlLabel,
  FormControl,
  useTheme,
  InputLabel,
} from '@mui/material';
import { StyledTextAreaInput } from '../../../common/components/inputs';
import { StyledStartUccRadioButton } from '../../start-ucc-filing/styled-items/StyledStartUccFilingRadioButton';
import { Controller } from 'react-hook-form';
import { IFormUI } from '../../../common/models';
import { FinancingStatementCoverageType, DebtorFilintType, DebtorType } from '../models';
import { FormDataUCC1 } from '../schema';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error-icon-base.svg';

interface ICollateralInformation extends IFormUI<FormDataUCC1> {
  financingTypesData: FinancingStatementCoverageType[] | null;
  debtorTypesData: DebtorType[] | null;
  debtorFilingTypesData: DebtorFilintType[] | null;
}

const Addendum = ({
  control,
  formState: { errors },
  financingTypesData,
  debtorTypesData,
  debtorFilingTypesData,
}: ICollateralInformation) => {
  const theme = useTheme();

  const [
    miscellaneous,
    hasAddendum,
    additionalCollateralDescription,
    recordOwnerNameAddress,
    realEstateDescription,
  ] = useWatch({
    control,
    name: [
      'miscellaneous',
      'hasAddendum',
      'additionalCollateralDescription',
      'recordOwnerNameAddress',
      'realEstateDescription',
    ],
  });

  return (
    <Box
      sx={{
        padding: {
          sm: `${theme.convert.pxToRem(12)} ${theme.convert.pxToRem(0)} ${theme.convert.pxToRem(
            20
          )}`,
          md: `${theme.convert.pxToRem(12)} ${theme.convert.pxToRem(0)} ${theme.convert.pxToRem(
            40
          )}`,
        },
      }}
    >
      <Grid
        container
        sx={{
          paddingX: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(40) },
          paddingRight: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(40) },
        }}
        direction="column"
        gap={2}
      >
        <Grid item md={12} xs={12} xl={9} sx={{ paddingBottom: { xs: 1 } }} display="flex">
          <ErrorIcon
            color={theme.palette.error.main}
            style={{
              minWidth: theme.convert.pxToRem(20),
              minHeight: theme.convert.pxToRem(20),
            }}
          />
          <Typography variant="h5" color={theme.palette.error.main} marginLeft={1}>
            This section is optional. Checking the box will add a $3 fee, even if no data is
            entered.
          </Typography>
        </Grid>
        <Grid container gap={1}>
          <Grid item md={12} xs={12} sx={{ paddingBottom: { xs: 1 } }}>
            <InputLabel htmlFor="miscellaneous">
              <Typography variant="h5">Miscellaneous:</Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} lg={12} xl={9} position="relative">
            <Controller
              name="miscellaneous"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <FormControl error={!!errors.miscellaneous?.message} fullWidth>
                  <StyledTextAreaInput
                    disabled={!hasAddendum}
                    placeholder="Enter miscellaneous"
                    error={!!errors.miscellaneous?.message}
                    {...field}
                    minRows={3}
                  />
                </FormControl>
              )}
            />
            <Typography
              sx={{
                position: 'absolute',
                right: theme.convert.pxToRem(10),
                bottom: `${
                  errors.miscellaneous?.message
                    ? theme.convert.pxToRem(25)
                    : theme.convert.pxToRem(5)
                }`,
                fontSize: theme.typography.pxToRem(12),
                color: theme.palette.grey[500],
              }}
            >
              {miscellaneous?.length ?? 0} / 250 characters
            </Typography>
            <FormHelperText error={!!errors.miscellaneous?.message}>
              {errors.miscellaneous?.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={12} sx={{ paddingBottom: { xs: 1 } }}>
            <InputLabel htmlFor="financingStatementCoverageType">
              <Typography variant="h5">This financing statement covers:</Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              defaultValue={''}
              name="financingStatementCoverageType"
              render={({ field }) => (
                <FormControl
                  disabled={!hasAddendum}
                  error={!!errors.financingStatementCoverageType?.message}
                  fullWidth
                >
                  <RadioGroup {...field}>
                    <Grid container xl={9} display="flex" justifyContent="space-between">
                      {financingTypesData?.map((item: any) => {
                        return (
                          <Grid item key={item.id}>
                            <FormControlLabel
                              sx={{ display: 'flex', alignItems: 'baseline' }}
                              key={item.id}
                              value={item.id}
                              control={<StyledStartUccRadioButton />}
                              label={item.name}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </RadioGroup>
                </FormControl>
              )}
            />
            <FormHelperText error={!!errors.financingStatementCoverageType?.message}>
              {errors.financingStatementCoverageType?.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container gap={1}>
          <Grid item md={12} xs={12} sx={{ paddingBottom: { xs: 1 } }}>
            <InputLabel htmlFor="realEstateDescription">
              <Typography variant="h5">Description of real estate:</Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} lg={12} xl={9} position="relative">
            <Controller
              name="realEstateDescription"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <FormControl error={!!errors.realEstateDescription?.message} fullWidth>
                  <StyledTextAreaInput
                    disabled={!hasAddendum}
                    {...field}
                    error={!!errors.realEstateDescription?.message}
                    minRows={3}
                    placeholder="Enter description of real estate"
                  />
                </FormControl>
              )}
            />
            <Typography
              sx={{
                position: 'absolute',
                right: theme.convert.pxToRem(10),
                bottom: `${
                  errors.realEstateDescription?.message
                    ? theme.convert.pxToRem(25)
                    : theme.convert.pxToRem(5)
                }`,
                fontSize: theme.typography.pxToRem(12),
                color: theme.palette.grey[500],
              }}
            >
              {realEstateDescription?.length ?? 0} / 300 characters
            </Typography>

            <FormHelperText error={!!errors.realEstateDescription?.message}>
              {errors.realEstateDescription?.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container gap={1}>
          <Grid item md={12} xs={12} sx={{ paddingBottom: { xs: 1 } }}>
            <InputLabel htmlFor="recordOwnerNameAddress">
              <Typography variant="h5">
                Name and address of a record owner of above-described real estate (If Debtor does
                not have a record interest):
              </Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} lg={12} xl={9} position="relative">
            <Controller
              name="recordOwnerNameAddress"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <FormControl error={!!errors.recordOwnerNameAddress?.message} fullWidth>
                  <StyledTextAreaInput
                    disabled={!hasAddendum}
                    {...field}
                    error={!!errors.recordOwnerNameAddress?.message}
                    minRows={3}
                    placeholder="Enter name and address of a record owner"
                  />
                </FormControl>
              )}
            />
            <Typography
              sx={{
                position: 'absolute',
                right: theme.convert.pxToRem(10),
                bottom: `${
                  errors.recordOwnerNameAddress?.message
                    ? theme.convert.pxToRem(25)
                    : theme.convert.pxToRem(5)
                }`,
                fontSize: theme.typography.pxToRem(12),
                color: theme.palette.grey[500],
              }}
            >
              {recordOwnerNameAddress?.length ?? 0} / 450 characters
            </Typography>
            <FormHelperText error={!!errors.recordOwnerNameAddress?.message}>
              {errors.recordOwnerNameAddress?.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container gap={1}>
          <Grid item md={12} xs={12} sx={{ paddingBottom: { xs: 1 } }}>
            <Typography variant="h5">Additional collateral description:</Typography>
          </Grid>
          <Grid item xs={12} lg={12} xl={9} position="relative">
            <Controller
              name="additionalCollateralDescription"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <FormControl error={!!errors.additionalCollateralDescription?.message} fullWidth>
                  <StyledTextAreaInput
                    disabled={!hasAddendum}
                    {...field}
                    error={!!errors.additionalCollateralDescription?.message}
                    placeholder="Enter additional collateral description"
                    minRows={3}
                  />
                </FormControl>
              )}
            />
            <Typography
              sx={{
                position: 'absolute',
                right: theme.convert.pxToRem(10),
                bottom: `${
                  errors.additionalCollateralDescription?.message
                    ? theme.convert.pxToRem(25)
                    : theme.convert.pxToRem(5)
                }`,
                fontSize: theme.typography.pxToRem(12),
                color: theme.palette.grey[500],
              }}
            >
              {additionalCollateralDescription?.length ?? 0} / 500 characters
            </Typography>
            <FormHelperText error={!!errors.additionalCollateralDescription?.message}>
              {errors.additionalCollateralDescription?.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12} xs={12} sx={{ paddingBottom: { xs: 1 } }}>
            <InputLabel htmlFor="debtorType">
              <Typography variant="h5">Collateral is:</Typography>
            </InputLabel>
          </Grid>
          <Grid item sm={12}>
            <Controller
              control={control}
              defaultValue={''}
              name="debtorType"
              render={({ field }) => (
                <FormControl error={!!errors.debtorType?.message} fullWidth disabled={!hasAddendum}>
                  <RadioGroup {...field}>
                    <Grid
                      container
                      xl={9}
                      display="flex"
                      justifyContent={{
                        xs: 'flex-start',
                        md: 'flex-start',
                        lg: 'space-between',
                        xl: 'normal',
                      }}
                      columnGap={{ xs: 11.7, md: 5, xl: 13 }}
                    >
                      {debtorTypesData?.map((item: any) => {
                        // TODO: Fix any
                        return (
                          <Grid item key={item.id}>
                            <FormControlLabel
                              sx={{ display: 'flex', alignItems: 'baseline' }}
                              key={item.id}
                              value={item.id}
                              control={<StyledStartUccRadioButton />}
                              label={item.name}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </RadioGroup>
                </FormControl>
              )}
            />
            <FormHelperText error={!!errors.debtorType?.message}>
              {errors.debtorType?.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12} xs={12} sx={{ paddingBottom: { xs: 1 } }}>
            <InputLabel htmlFor="debtorFilingType">
              <Typography variant="h5">Debtor is:</Typography>
            </InputLabel>
          </Grid>
          <Grid item sm={12}>
            <Controller
              control={control}
              defaultValue={''}
              name="debtorFilingType"
              render={({ field }) => (
                <FormControl
                  error={!!errors.debtorFilingType?.message}
                  fullWidth
                  disabled={!hasAddendum}
                >
                  <RadioGroup {...field}>
                    <Grid
                      container
                      item
                      md={10}
                      lg={12}
                      xl={9}
                      display="flex"
                      justifyContent={{ xs: 'flex-start', xl: 'normal' }}
                      columnGap={{ xs: 3, lg: 10, xl: 9.5 }}
                    >
                      {debtorFilingTypesData?.map((item: any) => {
                        // TODO: Fix any
                        return (
                          <Grid item key={item.id}>
                            <FormControlLabel
                              sx={{ display: 'flex', alignItems: 'baseline' }}
                              key={item.id}
                              value={item.id}
                              control={<StyledStartUccRadioButton />}
                              label={item.name}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </RadioGroup>
                </FormControl>
              )}
            />
            <FormHelperText error={!!errors.debtorFilingType?.message}>
              {errors.debtorFilingType?.message}
            </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Addendum;
