import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Loader from '../common/components/loader';
import Section from '../common/layouts/Section';
import { StyledUCCButton } from '../features/start-ucc-filing/styled-items/StyledUccButton';
import { cart, dashboard } from '../common/constants/routes';
import { useSnackbar } from '../common/notification';
import PageWrapper from '../common/layouts/PageWrapper';
import { checkFailedPayment } from '../features/payment/payment.service';
import { IToken } from '../features/payment/models';
import { PageTitle } from '../common/constants/features-constants';

const PaymentFailed = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { Snack } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);

  const token = search.get('token');

  const init = async ({ token }: IToken) => {
    const res = await checkFailedPayment({ token });

    if (res.notOk) {
      Snack.error('Ooops! Unexpected problem occurred. Please try again later.');
      return navigate(dashboard);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (token) {
      init({ token });
    } else {
      Snack.error('Token is missing.');
      return navigate(dashboard);
    }
  }, [token]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <Loader size={40} color="blue" />
      </Box>
    );
  }

  return (
    <PageWrapper title={PageTitle.CART}>
      <Section title="Error in Processing Payment">
        <Typography variant="body1">
          Due to the system error, your payment was uncecessful. Please contact technical support at
          help@floridaucc.com or call 850-222-8526.
        </Typography>
      </Section>
      <Box sx={{ height: (theme) => theme.convert.pxToRem(40) }} />
      <Box
        sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', gap: 5 }}
      >
        <StyledUCCButton autoFocus onClick={() => navigate(cart)} variant="contained">
          View Cart
        </StyledUCCButton>
      </Box>
      <Box sx={{ height: 837 }} />
    </PageWrapper>
  );
};

export default PaymentFailed;
