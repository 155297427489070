import { Grid, Typography, FormHelperText } from '@mui/material';
import { PasswordInput } from '../../inputs';
import { hasErrors, passwordsMatch } from '../../../helpers';
import { PasswordValidatorHelperProps } from '../../../models';
import { StyledFormHelperText } from '../../user-auth-components/common-auth-styles';
import { PasswordValidationIcon } from '../../../../features/create-password/create-password-form/PasswordValidationIcon';

export const UpdatePasswordModalContent = ({
  errors,
  passwords,
  onPasswordChangeHandler,
  onHandleSubmit,
}: {
  errors: PasswordValidatorHelperProps;
  passwords: { oldPassword: string; newPassword: string; confirmPassword: string };
  onPasswordChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}) => {
  const isOldPasswordEmpty = passwords.oldPassword.trim() === '';

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onHandleSubmit(e);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container rowGap={2}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={5} paddingBottom={{ xs: 1 }}>
            <Typography variant="body3">Old Password</Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <PasswordInput
              required
              autoFocus
              showIcon
              error={isOldPasswordEmpty}
              value={passwords.oldPassword}
              name="oldPassword"
              onChange={onPasswordChangeHandler}
              placeholder="Enter old password"
            />
          </Grid>
          <Grid item xs={12} md={5} paddingBottom={{ xs: 1 }}></Grid>
          <Grid item xs={12} md={7}>
            {isOldPasswordEmpty && <FormHelperText error>Old password is required.</FormHelperText>}
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={5} paddingBottom={{ xs: 1 }}>
            <Typography variant="body3">New Password</Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <PasswordInput
              required
              showIcon
              value={passwords.newPassword}
              error={hasErrors(errors)}
              name="newPassword"
              onChange={onPasswordChangeHandler}
              placeholder="Enter new password"
            />
          </Grid>
          <Grid item xs={12} md={5} paddingBottom={{ xs: 1 }}></Grid>
          <Grid item xs={12} md={7}>
            <StyledFormHelperText>
              <PasswordValidationIcon error={errors.lowerCase} />
              <FormHelperText error={errors.lowerCase}> One lowercase character</FormHelperText>
            </StyledFormHelperText>
            <StyledFormHelperText>
              <PasswordValidationIcon error={errors.upperCase} />
              <FormHelperText error={errors.upperCase}>One uppercase character</FormHelperText>
            </StyledFormHelperText>
            <StyledFormHelperText>
              <PasswordValidationIcon error={errors.specialChar} />
              <FormHelperText error={errors.specialChar}>One special character</FormHelperText>
            </StyledFormHelperText>
            <StyledFormHelperText>
              <PasswordValidationIcon error={errors.oneNumber} />
              <FormHelperText error={errors.oneNumber}>One number</FormHelperText>
            </StyledFormHelperText>
            <StyledFormHelperText>
              <PasswordValidationIcon error={errors.minLength} />
              <FormHelperText error={errors.minLength}>12 characters minimum</FormHelperText>
            </StyledFormHelperText>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={5} paddingBottom={{ xs: 1 }}>
            <Typography variant="body3">Confirm Password</Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <PasswordInput
              required
              showIcon
              value={passwords.confirmPassword}
              name="confirmPassword"
              error={passwordsMatch(passwords.confirmPassword, passwords.newPassword)}
              onChange={onPasswordChangeHandler}
              placeholder="Confirm password"
            />

            {passwordsMatch(passwords.confirmPassword, passwords.newPassword) ? (
              <FormHelperText
                error={passwordsMatch(passwords.confirmPassword, passwords.newPassword)}
              >
                Password and confirm password must match.
              </FormHelperText>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <input type="submit" style={{ display: 'none' }} />
    </form>
  );
};
