import React, { useMemo } from 'react';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';

import { getDebtorOrSecuredName } from '../../ucc1/utils';
import { itemDeviderBorder } from '../../../common/constants/colors';
import { StyledAddButton } from '../../shared/ucc/styles';

import { ActionType } from '../../ucc1/models';
import { IDebtorsOrSecuredParties } from '../../../common/models/features/models';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info-icon.svg';

const DebtorsOrSecuredParties = ({
  parties,
  removeDebSec,
  openDebSecModal,
  partyName,
  filingId,
  type,
  doUndo,
  isDisabled,
  infoMessage,
}: IDebtorsOrSecuredParties) => {
  const theme = useTheme();
  const sortedParties = useMemo(
    () => parties?.sort((a, b) => a.sequenceNumber - b.sequenceNumber),
    [parties]
  );

  return (
    <Box>
      {infoMessage && (
        <Grid
          item
          md={12}
          xs={12}
          xl={9}
          sx={{
            paddingY: theme.convert.pxToRem(10),
            paddingX: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(40) },
          }}
          display="flex"
        >
          <InfoIcon
            color={theme.palette.error.main}
            style={{
              minWidth: theme.convert.pxToRem(20),
              minHeight: theme.convert.pxToRem(20),
            }}
          />
          <Typography variant="h5" color={theme.palette.primary.dark} marginLeft={1}>
            {infoMessage}
          </Typography>
        </Grid>
      )}{' '}
      <Box sx={{ maxHeight: theme.convert.pxToRem(580), overflowY: 'auto' }}>
        {sortedParties?.map((party) => (
          <Grid
            container
            key={party.id}
            sx={{
              paddingY: theme.convert.pxToRem(12),
              paddingX: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(40) },
              color:
                isDisabled || party.entityActionType === ActionType.Delete
                  ? theme.palette.grey[500]
                  : theme.palette.grey[900],
            }}
          >
            <Grid item xs={12} md={4} lg={2} paddingRight={1}>
              <Typography color="inherit">{getDebtorOrSecuredName(party)}</Typography>
            </Grid>
            <Grid item xs={12} md={3} lg={6.5}>
              <Typography variant="h6" color="inherit">
                {party.address.address1}
              </Typography>
              <Typography variant="h6" color="inherit">
                {party.address.address2}
              </Typography>
              <Typography variant="h6" color="inherit">
                {party.address.city}, {party.address.state} {party.address.zip}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              lg={3.5}
              sx={{
                display: 'flex',
                justifyContent: { md: 'flex-end' },
                alignItems: { md: 'baseline' },
              }}
            >
              {party.entityActionType === ActionType.Change && party.sequenceNumber > 0 && (
                <>
                  <Button
                    sx={{
                      minWidth: { xs: 0, md: 64 },
                      paddingLeft: 0,
                      p: { md: 0 },
                    }}
                    onClick={() =>
                      doUndo({ debsecId: party.id, filingId: party.filingId, action: 'Edit' })
                    }
                    disabled={isDisabled}
                  >
                    Undo Edit
                  </Button>
                  <Box width={{ xs: 20, md: 0 }} />
                </>
              )}

              {party.entityActionType === ActionType.Delete && party.sequenceNumber > 0 && (
                <>
                  <Button
                    sx={{
                      minWidth: { xs: 0, md: 64 },
                      paddingLeft: 0,
                      p: { md: 0 },
                    }}
                    onClick={() =>
                      doUndo({ debsecId: party.id, filingId: party.filingId, action: 'Remove' })
                    }
                    disabled={isDisabled}
                  >
                    Undo Remove
                  </Button>
                  <Box width={{ xs: 20, md: 0 }} />
                </>
              )}

              <Button
                sx={{
                  minWidth: { xs: 0, md: 64 },
                  paddingLeft: 0,
                  paddingRight: 0,
                  p: { md: 0 },
                }}
                onClick={() => openDebSecModal(true, party.id)}
                disabled={isDisabled || party.entityActionType === ActionType.Delete}
                variant="text"
              >
                Edit
              </Button>
              <Box width={{ xs: 20, md: 0 }} />
              <Button
                sx={{ minWidth: { xs: 0, md: 64 }, p: { md: 0 } }}
                onClick={() =>
                  removeDebSec({
                    partyName,
                    debsecId: party.id,
                    type,
                    filingId,
                  })
                }
                disabled={isDisabled || party.entityActionType === ActionType.Delete}
                variant="text"
              >
                Remove
              </Button>
            </Grid>
          </Grid>
        ))}
      </Box>
      <Box
        sx={{
          borderTop: `1px solid ${itemDeviderBorder}`,
        }}
      >
        <StyledAddButton
          variant="text"
          onClick={() => openDebSecModal(false)}
          disabled={isDisabled}
        >
          <Box sx={{ fontSize: theme.typography.pxToRem(20) }}>+</Box>
          <Box sx={{ width: theme.convert.pxToRem(12) }} />
          <Box sx={{ '&:hover': { textDecoration: 'underline' } }}>Add {partyName}</Box>
        </StyledAddButton>
      </Box>
    </Box>
  );
};

export default DebtorsOrSecuredParties;
