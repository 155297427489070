import React from 'react';

import { useModal } from '../../../../../common/context';
import { useSnackbar } from '../../../../../common/notification';
import { DebSecModalContent } from '../../../../ucc1/deb-sec-modal';
import { StyledActionButton } from '../../../../../common/global-styles';

import { IOnSuccess, UseDebSecModalArgs } from '../../../../ucc3/models';

const useDebSecModal = ({ filingId, partyName, type, getData }: UseDebSecModalArgs) => {
  const { handleModalOpen, handleModalClose } = useModal();
  const { Snack } = useSnackbar();
  const onSuccess = ({ filingId, isEdit, partyName }: IOnSuccess) => {
    handleModalClose();
    Snack.success(`${partyName} successfully ${isEdit ? 'updated' : 'added'}.`);
    getData(filingId);
  };

  const handleOpenModal = (isEdit: boolean, debSecId?: number) => {
    handleModalOpen({
      title: `${isEdit ? 'Edit' : 'Add'} ${partyName}`,
      modalContent: (
        <DebSecModalContent
          partyName={partyName}
          isEdit={isEdit}
          debsecId={debSecId}
          filingId={filingId}
          type={type}
          onSuccess={onSuccess}
        />
      ),
      modalActions: (
        <>
          <StyledActionButton
            onClick={(e) => {
              handleModalClose(e);
              Snack.info('Action canceled.');
            }}
            variant="outlined"
          >
            Cancel
          </StyledActionButton>
          <StyledActionButton form="debsec-modal-form" type="submit" variant="contained" disabled>
            Save
          </StyledActionButton>
        </>
      ),
    });
  };

  return { handleOpenModal };
};

export default useDebSecModal;
