import { useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Loader from '../common/components/loader';
import Section from '../common/layouts/Section';
import { StyledUCCButton } from '../features/start-ucc-filing/styled-items/StyledUccButton';
import { dashboard, filing } from '../common/constants/routes';
import { useSnackbar } from '../common/notification';
import PageWrapper from '../common/layouts/PageWrapper';
import { checkSuccessPayment } from '../features/payment/payment.service';
import { IPaymentSuccessful, IToken } from '../features/payment/models';
import { formatDateToLocaleString } from '../features/ucc3/utils';
import { useUserInfo } from '../common/context';
import { PageTitle } from '../common/constants/features-constants';

const PaymentSuccessful = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { Snack } = useSnackbar();
  const { getCartDetails, setCartFilingsDetails } = useUserInfo();
  const [isLoading, setIsLoading] = useState(true);
  const [paymentDetails, setPaymentDetails] = useState<IPaymentSuccessful | null>(null);

  const token = search.get('token');

  const init = async ({ token }: IToken) => {
    const res = await checkSuccessPayment({ token });

    if (res.notOk) {
      Snack.error('Ooops! Unexpected problem occurred. Please try again later.');
      return navigate(dashboard);
    }

    setPaymentDetails(res.payload);
    setIsLoading(false);
    setCartFilingsDetails(null);
  };

  useEffect(() => {
    if (token) {
      init({ token });
    } else {
      Snack.error('Token is missing.');
      return navigate(dashboard);
    }
  }, [token]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <Loader size={40} color="blue" />
      </Box>
    );
  }

  return (
    <PageWrapper title={PageTitle.CART}>
      <Section title="Payment Successful">
        <Typography variant="body1">
          Your payment was{' '}
          <Box fontWeight="bold" component={'span'}>
            SUCCESSFUL.
          </Box>
        </Typography>
        <Typography variant="body1">
          Documents have been submitted to FLORIDA UCC. The documents will be recorded after review.
          Once the documents are filed, you will receive an acknowledgment.
        </Typography>
        <Box sx={{ height: theme.convert.pxToRem(20) }} />
        <Typography variant="body1">
          Remittance ID: {paymentDetails?.remmitanceId || 'N/A'}
        </Typography>
        <Typography variant="body1">
          Filing date:{' '}
          {paymentDetails?.filingDate
            ? formatDateToLocaleString(paymentDetails.filingDate, {
                dateFormat: '2-digit',
              })
            : 'N/A'}
        </Typography>
      </Section>
      <Box sx={{ height: theme.convert.pxToRem(40) }} />
      <Box
        sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', gap: 5 }}
      >
        <StyledUCCButton autoFocus onClick={() => navigate(filing)} variant="contained">
          Start New Filing
        </StyledUCCButton>
      </Box>
      <Box sx={{ height: 768 }} />
    </PageWrapper>
  );
};

export default PaymentSuccessful;
