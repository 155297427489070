import { Controller } from 'react-hook-form';
import { Typography, Grid, TextField, FormHelperText, InputLabel } from '@mui/material';

import {
  StyledSubmitButton,
  StyledModalFormWrapper,
} from '../../../../common/components/user-auth-components/common-auth-styles';
import type { FormData } from './schema';
import type { ILoginFormUI } from '../../../../common/models';

const UpdateConfirmCodeFormUI = ({
  onSubmit,
  formState: { errors, isValid, isSubmitting },
  control,
}: ILoginFormUI<FormData>) => (
  <form onSubmit={onSubmit}>
    <Typography sx={{ pb: 2 }}>
      We’ve sent a 6-character code to your new email. Please enter it here.
    </Typography>
    <StyledModalFormWrapper>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={3}>
          <InputLabel htmlFor="code">
            <Typography variant="h4">Code *</Typography>
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Controller
            name="code"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...field}
                inputRef={ref}
                placeholder="Enter code"
                error={!!errors.code?.message}
                type="text"
                required
                autoFocus
              />
            )}
          />
          <FormHelperText error={!!errors.code?.message}>{errors.code?.message}</FormHelperText>
        </Grid>
      </Grid>
      <Grid display="none">
        <StyledSubmitButton disabled={!isValid || isSubmitting} type="submit">
          Submit
        </StyledSubmitButton>
      </Grid>
    </StyledModalFormWrapper>
  </form>
);

export default UpdateConfirmCodeFormUI;
