import { defaultValues } from './constants';
import type { IDebSecResponsePayload } from '../models';
import { FormData } from './models';
import { CountryType, NameType } from '../../../common/models/features/models';

export const mapper: {
  key: keyof FormData;
  action(data: IDebSecResponsePayload | null): FormData[keyof FormData];
}[] = [
  {
    key: 'nameType',
    action: (data) =>
      data?.isOrganization === false ? NameType.individual : NameType.organization,
  },
  { key: 'name', action: (data) => data?.name || defaultValues.name },
  { key: 'lastName', action: (data) => data?.lastName || defaultValues.lastName },
  {
    key: 'firstName',
    action: (data) => data?.firstName || defaultValues.firstName,
  },
  { key: 'middleName', action: (data) => data?.middleName || defaultValues.middleName },
  { key: 'suffix', action: (data) => data?.suffix || defaultValues.suffix },
  { key: 'isForeign', action: (data) => data?.isForeign || defaultValues.isForeign },
  { key: 'address1', action: (data) => data?.address.address1 || defaultValues.address1 },
  { key: 'address2', action: (data) => data?.address.address2 || defaultValues.address2 },
  { key: 'city', action: (data) => data?.address.city || defaultValues.city },
  { key: 'zip', action: (data) => data?.address.zip || defaultValues.zip },
  {
    key: 'country',
    action: (data) => (data?.isForeign ? CountryType.OTC : CountryType.US) || defaultValues.country,
  },
  { key: 'state', action: (data) => data?.address.state || defaultValues.state },
];

export const getValueForField = (field: keyof FormData, data: IDebSecResponsePayload | null) =>
  mapper.find(({ key }) => key === field)?.action(data);
