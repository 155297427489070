import { TextFieldProps, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import { ReactComponent as OpenedEyeIcon } from '../../../../assets/icons/open-eye-icon.svg';
import { ReactComponent as ClosedEyeIcon } from '../../../../assets/icons/close-eye-icon.svg';
import { useTheme } from '@mui/material/styles';

type InputProps = {
  showIcon?: boolean;
  showPassword?: boolean;
  handleClickShowPassword?: () => void;
} & Omit<TextFieldProps, 'type' | 'size'>;

const StyledPasswordInput = styled(TextField)({
  '.MuiInputBase-root': {
    paddingRight: 20,
  },
});

export const PasswordInputUI = ({
  handleClickShowPassword,
  showPassword,
  showIcon,
  ...props
}: InputProps) => {
  const theme = useTheme();

  return (
    <StyledPasswordInput
      {...props}
      onCopy={(e: React.ClipboardEvent<HTMLInputElement>) => e.preventDefault()}
      onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => e.preventDefault()}
      variant="outlined"
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: showIcon ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? (
                <OpenedEyeIcon color={theme.palette.grey[700]} />
              ) : (
                <ClosedEyeIcon color={theme.palette.grey[700]} />
              )}
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  );
};
