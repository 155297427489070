/* eslint-disable prettier/prettier */
import { API } from "aws-amplify";
import { publicSearchApiName } from "../../common/constants/api";
import { DownloadTypeEnum } from "./DownloadTypeEnum";
import { FormDataType } from "./FormDataType";
import { ResponseMessageType } from "../shared/types";
import moment from "moment";

type DownloadResponseMessageType = ResponseMessageType<URL | null>;

export const getDownload = async (request: FormDataType): Promise<DownloadResponseMessageType> => {
  let url = `/Downloads?downloadType=${request.downloadType}&fileType=${request.fileType}`;
  if (request.downloadType !== DownloadTypeEnum.FULL && request.fileDate) {
    const localDateTime = moment();
    const timeDifferenceInHours = localDateTime.utcOffset() / 60;
    url = url + `&fileDate=${request.fileDate.format('MM/DD/YYYY')}&utcOffset=${timeDifferenceInHours}`;
  }

  const res = await API.get(publicSearchApiName, url, {});
  return res;
};
