import { useEffect } from 'react';
import { Grid } from '@mui/material';
import Loader from '../../common/components/loader';
import { MyAccountAccordionsUI } from './my-account-accordions/MyAccountAccordionsUI';
import { useMyAccount } from '../../common/context';

const MyAccountUI = () => {
  const { getMyAccountInfo, loadingMyAccountData } = useMyAccount();
  useEffect(() => {
    getMyAccountInfo();
  }, []);

  return loadingMyAccountData ? (
    <Grid container justifyContent="center">
      <Loader color="blue" size={40} />
    </Grid>
  ) : (
    <MyAccountAccordionsUI />
  );
};

export default MyAccountUI;
