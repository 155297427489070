import { Typography, Grid, Box } from '@mui/material';
import { useTheme } from '@mui/material';

export const DebitInfoDisplayAccountModalContent = ({
  number,
  counter,
}: {
  number: string;
  counter: number;
}) => {
  const theme = useTheme();
  return (
    <Grid container gap={2}>
      <Grid container alignItems="center" item paddingBottom={2} xs={12}>
        <Grid container alignItems="center" paddingBottom={{ xs: 3, md: 0 }}>
          <Typography variant="h4">This window will be closed in:</Typography>
          <Box width={20} />
          <Typography
            variant="h2"
            display={{ xs: 'none', md: 'block' }}
            color={theme.palette.info.dark}
          >
            {counter} seconds
          </Typography>
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Typography
            align="center"
            color={theme.palette.info.dark}
            variant="h2"
            display={{ xs: 'block', md: 'none' }}
          >
            {counter} seconds
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={5} md={3}>
          <Typography variant="h5">Account number</Typography>
        </Grid>
        <Grid item xs={5} md={4}>
          <Typography variant="h5">{number}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
