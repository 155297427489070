import { Controller } from 'react-hook-form';
import { Typography, Grid, TextField, FormHelperText, InputLabel, Box } from '@mui/material';

import {
  StyledSubmitButton,
  StyledModalFormWrapper,
} from '../../../../common/components/user-auth-components/common-auth-styles';
import type { FormData } from './UpdateEmailForm';
import type { ILoginFormUI } from '../../../../common/models';

const UpdateEmailFormUI = ({
  onSubmit,
  formState: { errors, isValid, isSubmitting },
  control,
}: ILoginFormUI<FormData>) => (
  <form onSubmit={onSubmit}>
    <StyledModalFormWrapper>
      <Grid container rowGap={1}>
        <Grid container alignItems="center" rowGap={1}>
          <Grid item xs={12} md={4}>
            <InputLabel htmlFor="newEmail">
              <Typography variant="body3">New Email *</Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={7}>
            <Controller
              name="newEmail"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  placeholder="Enter New Email"
                  error={!!errors.newEmail?.message}
                  type="email"
                  required
                  autoFocus
                />
              )}
            />
            <FormHelperText error={!!errors.newEmail?.message}>
              {errors.newEmail?.message}
            </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
      <Box height={20} />
      <Grid container alignItems="center" rowGap={1}>
        <Grid item xs={12} md={4}>
          <InputLabel htmlFor="confirmEmail">
            <Typography variant="body3">Confirm Email *</Typography>
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={7}>
          <Controller
            name="confirmEmail"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...field}
                inputRef={ref}
                placeholder="Enter Confirm Email"
                error={!!errors.confirmEmail?.message}
                type="email"
                required
                autoFocus
              />
            )}
          />
          <FormHelperText error={!!errors.confirmEmail?.message}>
            {errors.confirmEmail?.message}
          </FormHelperText>
        </Grid>
      </Grid>
      <Grid display="none">
        <StyledSubmitButton disabled={!isValid || isSubmitting} type="submit">
          Submit
        </StyledSubmitButton>
      </Grid>
    </StyledModalFormWrapper>
  </form>
);

export default UpdateEmailFormUI;
