import { API } from 'aws-amplify';
import { Announcement, IResponseWithMeta } from '../models/features/models';
import { publicSearchApiName } from '../constants/api';

export const defaultPageSize = 10;

const sortParams = { orderColumnName: 'publishdate', sortOrder: 'descending' };

export const getAnnouncements = async (
  page: number
): Promise<IResponseWithMeta<Announcement[]>> => {
  const parameters = {
    queryStringParameters: {
      displayPage: 'announcements',
      pageSize: defaultPageSize,
      currentPage: page,
      ...sortParams,
    },
  };

  const res = await API.get(publicSearchApiName, '/announcements', parameters);
  return res;
};

export const getHomepageAnnouncements = async (): Promise<IResponseWithMeta<Announcement[]>> => {
  const parameters = {
    queryStringParameters: { all: true, displayPage: 'Home', ...sortParams },
  };

  const res = await API.get(publicSearchApiName, '/announcements', parameters);
  return res;
};
