import React from 'react';
import { Box, useTheme } from '@mui/material';
import { StyledActionButton } from '../global-styles';
import Loader from '../components/loader';

interface SubmitCloseButtonsProps {
  isLoading: boolean;
  isValid?: boolean;
  handleModalClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  Snack: {
    info: (message: string) => void;
  };
}

export const SubmitCloseButtons: React.FC<SubmitCloseButtonsProps> = ({
  isLoading,
  isValid = true,
  handleModalClose,
  Snack,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        gap: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(37) },
        flexDirection: 'row-reverse',
        width: '100%',
      }}
    >
      <StyledActionButton
        type="submit"
        variant="contained"
        form="code-modal-form"
        disabled={!isValid || isLoading}
        startIcon={isLoading ? <Loader /> : null}
      >
        {isLoading ? 'Submitting' : 'Submit'}
      </StyledActionButton>
      <StyledActionButton
        onClick={(e) => {
          Snack.info('Action canceled.');
          handleModalClose(e);
        }}
        variant="outlined"
      >
        Close
      </StyledActionButton>
    </Box>
  );
};
