import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TextInput } from '../../../../common/components/inputs';
import { Typography, Grid, FormHelperText, TextField, InputLabel } from '@mui/material';
import Loader from '../../../../common/components/loader';
import {
  StyledBoxWrapper,
  StyledSubmitButton,
} from '../../../../common/components/user-auth-components/common-auth-styles';
import { useRegisterModal } from '../../../../common/hooks';

const emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
const schema = yup
  .object({
    email: yup
      .string()
      .max(100, 'Max length is 100 characters.')
      .required('Email is required.')
      .matches(emailRegex, 'Please enter a valid email address.'),
    firstName: yup
      .string()
      .max(50, 'Max length is 50 characters.')
      .required('First name is required.'),
    lastName: yup
      .string()
      .max(50, 'Max length is 50 characters.')
      .required('Last name is required.'),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

const RegisterForm = () => {
  const { handleRegister } = useRegisterModal();

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    control,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  return (
    <form onSubmit={handleSubmit(handleRegister)}>
      <StyledBoxWrapper>
        <Typography variant="h1">Create Account</Typography>
        <Grid container alignItems="center" justifyContent="center" pt="2.3rem">
          <Grid item xs={12} md={3} xl={3.6} sx={{ paddingBottom: { xs: 1 } }}>
            <InputLabel htmlFor="email">
              <Typography variant="h4" fontWeight={400}>
                Email *
              </Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={9} xl={8.4}>
            <Controller
              name="email"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  placeholder="Enter email"
                  error={!!errors.email?.message}
                  type="email"
                  required
                  autoFocus
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={3} xl={3.6}></Grid>
          <Grid item xs={12} md={9} xl={8.4}>
            <FormHelperText error={!!errors.email?.message}>{errors.email?.message}</FormHelperText>
          </Grid>
        </Grid>
        <Grid container alignItems="center" pt="2.3rem">
          <Grid item xs={12} md={3} xl={3.6} sx={{ paddingBottom: { xs: 1 } }}>
            <InputLabel htmlFor="firstName">
              <Typography variant="h4" fontWeight={400}>
                First Name *
              </Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={9} xl={8.4}>
            <Controller
              name="firstName"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextInput
                  {...field}
                  inputRef={ref}
                  error={!!errors.firstName?.message}
                  placeholder="Enter first name"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={3} xl={3.6}></Grid>
          <Grid item xs={12} md={9} xl={8.4}>
            <FormHelperText error={!!errors.firstName?.message}>
              {errors.firstName?.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container alignItems="center" pt="2.3rem">
          <Grid item xs={12} md={3} xl={3.6} sx={{ paddingBottom: { xs: 1 } }}>
            <InputLabel htmlFor="lastName">
              <Typography variant="h4" fontWeight={400}>
                Last Name *
              </Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={9} xl={8.4}>
            <Controller
              name="lastName"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextInput
                  {...field}
                  inputRef={ref}
                  error={!!errors.lastName?.message}
                  placeholder="Enter last name"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={3} xl={3.6}></Grid>
          <Grid item xs={12} md={9} xl={8.4}>
            <FormHelperText error={!!errors.lastName?.message}>
              {errors.lastName?.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container alignItems="center" pt="2.3rem">
          <Grid item xs={12}>
            <StyledSubmitButton
              variant="contained"
              type="submit"
              fullWidth
              startIcon={isSubmitting ? <Loader /> : null}
              disabled={!isValid || isSubmitting}
            >
              {isSubmitting ? 'Registering' : 'Register'}
            </StyledSubmitButton>
          </Grid>
        </Grid>
      </StyledBoxWrapper>
    </form>
  );
};
export default RegisterForm;
