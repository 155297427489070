import { Auth } from 'aws-amplify';

const amplifyConfig = {
  Auth: {
    region: 'us-east-1',
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },

  API: {
    endpoints: [
      {
        name: 'OnlineFilingSignUpAPI',
        endpoint: `${process.env.REACT_APP_ONLINE_FILING_API}/Users`,
        region: 'us-east-1',
      },
      {
        name: 'OnlineFilingAPI',
        endpoint: process.env.REACT_APP_ONLINE_FILING_API,
        region: 'us-east-1',
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        }),
      },
      {
        name: 'PublicSearchAPI',
        endpoint: process.env.REACT_APP_PUBLIC_SEARCH_API,
        region: 'us-east-1',
      },
    ],
  },
};

export default amplifyConfig;
