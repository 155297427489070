import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAuth } from '../../../../common/context';
import ResetPasswordFormUI from './ResetPasswordFormUI';

const schema = yup.object({
  email: yup
    .string()
    .max(100, 'Max length is 100 characters.')
    .required('Email is required.')
    .email('Please enter a valid email address.')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Please enter a valid email address.'),
});

export type FormData = yup.InferType<typeof schema>;

const ResetPasswordForm = () => {
  const { resetPassword } = useAuth();

  const { handleSubmit, setFocus, formState, control } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  useEffect(() => {
    setFocus('email');
  }, []);

  return (
    <ResetPasswordFormUI
      onSubmit={handleSubmit(resetPassword)}
      control={control}
      formState={formState}
    />
  );
};
export default ResetPasswordForm;
