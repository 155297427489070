import { Component, ErrorInfo } from 'react';
import { Navigate } from 'react-router-dom';

class ErrorBoundary extends Component<{ children?: React.ReactNode }, { hasError: boolean }> {
  constructor(props: { children?: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Log to reporting services
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Navigate to="/error" />;
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
