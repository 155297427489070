import { TextDocument } from '../common/components/TextDocument';
import PageWrapper from '../common/layouts/PageWrapper';
import { data } from '../features/termsofUse/constants';
import { Content, MainTitle, SubTitle } from '../features/termsofUse/styledElements';

export const TermsOfUse = () => (
  <PageWrapper title="">
    <TextDocument data={data} SubTitle={SubTitle} Paragraph={Content} DocumentTitle={MainTitle} />
  </PageWrapper>
);
