import { useEffect, useState } from 'react';
import 'react-dates/initialize';
import moment from 'moment';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import {
  Box,
  Button,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Controller } from 'react-hook-form';

import { useSnackbar } from '../../../common/notification';
import { CustomAccordion } from '../../../common/components/accordion';
import { StyledGrid } from '../../shared/ucc/styles';
import { StyledUCCButton } from '../../start-ucc-filing/styled-items/StyledUccButton';
import { ReactComponent as FunnelFilterIcon } from '../../../assets/icons/funnel-filter.svg';
import { Modal } from '../../../common/components/modals/Modal';
import PendingFilings from '../pending-filings';
import FilingHistory from '../filing-history/FilingHistory';

import { IHandleModalClose } from '../../../common/models';
import { Accordion } from '../constants';
import { AccordionTitle, IDashboardAccordionsUIProps } from '../models';

const DashboardAccordionsUI = ({
  accordionState,
  handleAccordionState,
  control,
  formState,
  reset,
  onSubmit,
  getValues,
  pendingFilingsData,
  handleEditFiling,
  handleModalOpen,
  toggleTooltip,
  activeToolTip,
  handleOnNewFiling,
  filingHistoryData,
  handleSortOrderChange,
  searchClicked,
}: IDashboardAccordionsUIProps) => {
  const { Snack } = useSnackbar();
  const [open, setOpen] = useState(false);

  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
  const [cancelClicked, setCancelClicked] = useState(false);

  const today = moment().startOf('day');
  const todayEnd = moment().endOf('day');

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal: IHandleModalClose = (event, reason) => {
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) return;
    setOpen(false);
  };

  const handleBoxClick = (e: any) => {
    e.stopPropagation();
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (focusedInput === null && !cancelClicked) {
      const [fromDate, toDate] = getValues('dateRange');
      if (toDate !== null) {
        localStorage.setItem('fromDate', JSON.stringify(fromDate.toISOString()));
        localStorage.setItem('toDate', JSON.stringify(toDate.toISOString()));
      } else {
        localStorage.setItem('fromDate', JSON.stringify(moment().toISOString()));
        localStorage.setItem(
          'toDate',
          JSON.stringify(moment().subtract(6, 'days').startOf('day').toISOString())
        );
      }
    } else {
      setCancelClicked(false);
    }
  }, [focusedInput, cancelClicked]);

  const handleSearchSubmit = () => {
    const formData = getValues();

    if (!formData.dateRange[0] || !formData.dateRange[1]) {
      Snack.error('The "from" and "to" dates cannot be left blank.');
      return;
    } else {
      if (!accordionState[Accordion.FilingHistory]) {
        handleAccordionState(Accordion.FilingHistory);
      }
      onSubmit();
    }
  };

  const handleClear = () => {
    reset();
    handleSearchSubmit();
  };

  return (
    <>
      <StyledGrid container gap={3.5}>
        <CustomAccordion
          title={AccordionTitle.PENDING_UCC_FILINGS}
          isOpen={accordionState[Accordion.Pending]}
          handleChange={() => handleAccordionState(Accordion.Pending)}
        >
          <PendingFilings
            pendingFilingsData={pendingFilingsData}
            handleEditFiling={handleEditFiling}
            handleModalOpen={handleModalOpen}
            toggleTooltip={toggleTooltip}
            activeToolTip={activeToolTip}
          />
        </CustomAccordion>
        <CustomAccordion
          title={AccordionTitle.FILING_HISTORY}
          isOpen={accordionState[Accordion.FilingHistory]}
          handleChange={() => handleAccordionState(Accordion.FilingHistory)}
          searchFeature={
            matches ? (
              <form>
                <Box onClick={handleBoxClick} display="flex" alignItems="center">
                  <Controller
                    name="uccNumber"
                    defaultValue=""
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        placeholder="UCC Number"
                        inputRef={ref}
                        sx={{
                          borderRadius: '4px',
                          width: {
                            xs: theme.convert.pxToRem(110),
                            md: theme.convert.pxToRem(140),
                            lg: theme.convert.pxToRem(160),
                          },
                        }}
                      />
                    )}
                  />

                  <Box
                    marginX={{
                      xs: theme.convert.pxToRem(12),
                      lg: theme.convert.pxToRem(20),
                    }}
                  >
                    <Controller
                      control={control}
                      name="dateRange"
                      render={({ field }) => {
                        const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
                          event.preventDefault();
                          event.stopPropagation();

                          const fromDate = localStorage.getItem('fromDate');
                          const toDate = localStorage.getItem('toDate');
                          if (fromDate !== null && toDate !== null) {
                            const parsedFromDate = moment(JSON.parse(fromDate));
                            const parsedToDate = moment(JSON.parse(toDate));
                            field.onChange([parsedFromDate, parsedToDate]);
                          }
                          setFocusedInput(null);
                          setCancelClicked(true);
                        };
                        return (
                          <DateRangePicker
                            showClearDates
                            anchorDirection={'right'}
                            minimumNights={0}
                            transitionDuration={0}
                            startDate={field.value[0]}
                            startDateId="startDateId"
                            endDate={field.value[1]}
                            endDateId="endDateId"
                            onDatesChange={({ startDate, endDate }) => {
                              if (
                                (startDate && startDate.isSame(today)) ||
                                startDate?.isAfter(todayEnd) ||
                                endDate?.isAfter(todayEnd)
                              ) {
                                Snack.error('The "from" and "to" dates cannot be in the future.');
                                return;
                              }

                              if (startDate && endDate) {
                                const maxDate = moment(startDate).add(1, 'year');
                                if (endDate.isAfter(maxDate)) {
                                  Snack.error('The date range must not exceed one calendar year.');
                                  return;
                                }
                              }

                              field.onChange([startDate, endDate]);
                            }}
                            focusedInput={focusedInput}
                            onFocusChange={(newFocusedInput) => {
                              setFocusedInput(newFocusedInput);
                            }}
                            hideKeyboardShortcutsPanel
                            keepOpenOnDateSelect
                            small
                            isOutsideRange={() => false}
                            daySize={34}
                            renderCalendarInfo={() => (
                              <Box
                                width={1}
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                              >
                                <Box paddingRight={theme.convert.pxToRem(20)}>
                                  <Button onClick={handleCancel} onTouchStart={() => handleCancel}>
                                    Cancel
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      setFocusedInput(null);
                                    }}
                                  >
                                    Apply
                                  </Button>
                                </Box>
                              </Box>
                            )}
                          />
                        );
                      }}
                    />
                  </Box>
                  <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                    <Button
                      sx={{
                        p: theme.convert.pxToRem(0),
                        minWidth: 0,
                        marginRight: {
                          xs: theme.convert.pxToRem(12),
                          lg: theme.convert.pxToRem(20),
                        },
                      }}
                      variant="text"
                      onClick={handleSearchSubmit}
                    >
                      Search
                    </Button>
                    <Button
                      sx={{
                        p: theme.convert.pxToRem(0),
                        minWidth: 0,
                        marginRight: theme.convert.pxToRem(28),
                      }}
                      variant="text"
                      disabled={!formState.isDirty}
                      onClick={handleClear}
                    >
                      Clear
                    </Button>
                  </Box>
                </Box>
              </form>
            ) : (
              <>
                <FunnelFilterIcon onClick={handleOpenModal} style={{ paddingTop: 6 }} />
                <Modal
                  title="Filter"
                  hasCalendar
                  open={open}
                  handleClose={handleCloseModal}
                  contentHeight={focusedInput ? 600 : undefined}
                  modalActions={
                    <Box display="flex" justifyContent="space-between" width={1}>
                      <Button
                        sx={{ width: 120 }}
                        variant="outlined"
                        onClick={() => {
                          handleCloseModal();
                          reset();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{ width: 120 }}
                        variant="contained"
                        onClick={() => {
                          handleSearchSubmit();
                          handleCloseModal();
                        }}
                      >
                        Apply
                      </Button>
                    </Box>
                  }
                >
                  <form>
                    <Box display="flex" flexDirection="column" gap={1}>
                      <InputLabel>
                        <Typography variant="body3">UCC Number</Typography>
                      </InputLabel>
                      <Controller
                        name="uccNumber"
                        defaultValue=""
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <TextField
                            {...field}
                            placeholder="UCC Number"
                            inputRef={ref}
                            fullWidth
                            sx={{ paddingBottom: 1 }}
                          />
                        )}
                      />
                      <InputLabel>
                        <Typography variant="body3">Filing Date Range</Typography>
                      </InputLabel>
                      <Controller
                        control={control}
                        name="dateRange"
                        render={({ field }) => {
                          return (
                            <DateRangePicker
                              anchorDirection={'right'}
                              readOnly
                              minimumNights={0}
                              orientation="vertical"
                              verticalSpacing={18}
                              startDate={field.value[0]}
                              startDateId="startDateId"
                              endDate={field.value[1]}
                              endDateId="endDateId"
                              onDatesChange={({ startDate, endDate }) => {
                                if (
                                  (startDate && startDate.isSame(today)) ||
                                  startDate?.isAfter(todayEnd) ||
                                  endDate?.isAfter(todayEnd)
                                ) {
                                  Snack.error('The "from" and "to" dates cannot be in the future.');
                                  return;
                                }

                                if (startDate && endDate) {
                                  const maxDate = moment(startDate).add(1, 'year');
                                  if (endDate.isAfter(maxDate)) {
                                    Snack.error(
                                      'The date range must not exceed one calendar year.'
                                    );
                                    return;
                                  }
                                }

                                field.onChange([startDate, endDate]);
                              }}
                              focusedInput={focusedInput}
                              onFocusChange={(newFocusedInput) => {
                                setFocusedInput(newFocusedInput);
                              }}
                              hideKeyboardShortcutsPanel
                              keepOpenOnDateSelect
                              small
                              isOutsideRange={() => false}
                              daySize={28}
                              renderCalendarInfo={() => (
                                <Box width={1} display="flex" justifyContent="flex-end">
                                  <Box paddingRight={theme.convert.pxToRem(20)}>
                                    <Button
                                      onClick={() => {
                                        const fromDate = localStorage.getItem('fromDate');
                                        const toDate = localStorage.getItem('toDate');
                                        if (fromDate !== null && toDate !== null) {
                                          const parsedFromDate = moment(JSON.parse(fromDate));
                                          const parsedToDate = moment(JSON.parse(toDate));
                                          field.onChange([parsedFromDate, parsedToDate]);
                                        }
                                        setCancelClicked(true);
                                        setFocusedInput(null);
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        setFocusedInput(null);
                                      }}
                                    >
                                      Apply
                                    </Button>
                                  </Box>
                                </Box>
                              )}
                            />
                          );
                        }}
                      />
                    </Box>
                  </form>
                </Modal>
              </>
            )
          }
        >
          <FilingHistory
            filingHistoryData={filingHistoryData}
            handleSortOrderChange={handleSortOrderChange}
            searchClicked={searchClicked}
          />
        </CustomAccordion>
      </StyledGrid>
      <Box sx={{ height: { xs: 10, md: 20 }, width: '100%' }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: { md: 'flex-start', sm: 'center' },
          alignItems: 'flex-end',
          width: '100%',
          margin: '0 auto',
        }}
      >
        <StyledUCCButton variant="contained" onClick={handleOnNewFiling}>
          Add New Filing
        </StyledUCCButton>
      </Box>
      <Box sx={{ height: 267, width: '100%' }} />
    </>
  );
};

export default DashboardAccordionsUI;
