import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { AcknowledgementInfoFormUI } from './AcknowledgementInfoFormUI';
import { useAuth, useMyAccount } from '../../../../common/context';
import { emailRegexOptional, phoneNumberRegexOptional } from '../../../../common/constants/regex';

interface IAcknowledgementFormDataProps {
  setIsValid: React.Dispatch<boolean>;
  setContactName: React.Dispatch<string>;
  setEmail: React.Dispatch<string>;
  setPhoneNumber: React.Dispatch<string>;
}

const schema = yup.object({
  contactName: yup.string().max(75, 'Max length is 75 characters.'),
  phoneNumber: yup
    .string()
    .max(25, 'Max length is 25 characters.')
    .test(
      'len',
      'Phone number must be at least 10 digits long.',
      (val = '') => val?.length >= 10 || val?.length === 0
    )
    .matches(
      phoneNumberRegexOptional,
      'Phone number must not contain any characters or special symbols.'
    ),
  email: yup
    .string()
    .max(100, 'Max length is 100 characters.')
    .matches(emailRegexOptional, 'Please enter a valid email address.'),
});

export type FormData = yup.InferType<typeof schema>;

export const AcknowledgementInfoForm = ({
  setEmail,
  setIsValid,
  setContactName,
  setPhoneNumber,
}: IAcknowledgementFormDataProps) => {
  const { setIsEditMode } = useAuth();
  const { updateAcknowledgementInfo } = useMyAccount();
  const { handleSubmit, formState, control, getValues } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const { isValid, isValidating } = formState;

  useEffect(() => {
    setIsEditMode(formState.isDirty);
    return () => {
      setIsEditMode(false);
    };
  }, [formState.isDirty]);

  useEffect(() => {
    const { contactName, phoneNumber, email } = getValues();
    setIsValid(isValid);
    setContactName(contactName?.replace(/\s+/g, ' ').trim() ?? '');
    setEmail(email ?? '');
    setPhoneNumber(phoneNumber ?? '');
  }, [isValidating, isValid]);

  const submitHandler = async () => {
    const { contactName, phoneNumber, email } = getValues();
    await updateAcknowledgementInfo({
      contactName: contactName ?? '',
      phoneNumber: phoneNumber ?? '',
      email: email ?? '',
    });
  };

  return (
    <AcknowledgementInfoFormUI
      control={control}
      formState={formState}
      onSubmit={handleSubmit(submitHandler)}
    />
  );
};
