import { API } from 'aws-amplify';
import {
  IResponse,
  IFilingId,
  IResponseCRUDInfo,
  EntityType,
} from '../../common/models/features/models';
import { IUCC3ResponsePayload } from './models';
import {
  IDebSecFilingId,
  IDebSecIdRequestPayload,
  IRequestPayload,
  IRequestUCC1Payload,
  IAttachment,
} from '../ucc1/models';
import {
  IAttachmentRequest,
  IUploadingFile,
  UploadAttachmentRequest,
} from '../ucc1/attachments/models';

const apiName = 'OnlineFilingAPI';
const primaryPath = 'filings';
const secondaryPath = 'ucc3';
const myInit = {};

export const getUCC3ById = async ({
  filingId,
}: IFilingId): Promise<IResponse<IUCC3ResponsePayload>> => {
  try {
    const res = await API.get(apiName, `/${secondaryPath}/${filingId}`, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const getAmendReleaseCollateralData = async () => {
  try {
    const res = await API.get(apiName, '/ucc3-collateral-change-types', myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};
export const getEntityTypes = async () => {
  try {
    const res = API.get(apiName, '/entity-types', myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data };
  }
};
export const getUCC3DebSec = ({ filingId }: IFilingId): Promise<IResponse<never>> => {
  return API.get(apiName, `/${primaryPath}/${filingId}/debsecs?all=true`, myInit);
};

export const getUCC3Sec = ({ filingId }: IFilingId): Promise<IResponse<never>> => {
  return API.get(
    apiName,
    `/${primaryPath}/${filingId}/debsecs?isActive=true&entityType=Secured&all=true`,
    myInit
  );
};

export const getUCC3DebSecById = async ({ filingId, debsecId }: IDebSecFilingId) => {
  try {
    return await API.get(apiName, `/${primaryPath}/${filingId}/debsecs/${debsecId}`, myInit);
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const createUCC3DebSec = async ({
  filingId,
  payload,
}: IDebSecIdRequestPayload<IRequestPayload>): Promise<IResponseCRUDInfo> => {
  try {
    return await API.post(apiName, `/${primaryPath}/${filingId}/debsecs`, { body: payload });
  } catch (err: any) {
    return { ...err.response.data, notOk: true };
  }
};

export const getListOfStates = async () => {
  try {
    return await API.get(apiName, '/states', myInit);
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const updateUCC3 = async ({
  filingId,
  payload,
}: IDebSecIdRequestPayload<IRequestUCC1Payload>) => {
  try {
    const res = await API.put(apiName, `/${secondaryPath}/${filingId}`, {
      body: payload,
    });
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const updateUCC3DebSec = async ({
  filingId,
  debsecId,
  payload,
}: IDebSecIdRequestPayload<IRequestPayload>) => {
  try {
    return await API.put(apiName, `/${primaryPath}/${filingId}/debsecs/${debsecId}`, {
      body: payload,
    });
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const deleteUCC3DebSec = async ({ filingId, debsecId }: IDebSecFilingId) => {
  try {
    const res = await API.del(apiName, `/${primaryPath}/${filingId}/debsecs/${debsecId}`, myInit);
    return { notOk: res.notOk };
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const undoAction = async ({ filingId, debsecId }: IDebSecFilingId) => {
  try {
    const res = await API.put(
      apiName,
      `/${primaryPath}/${filingId}/debsecs/${debsecId}/undo`,
      myInit
    );
    return { notOk: res.notOk };
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const checkResetEntities = async ({
  filingId,
  entityType,
}: IDebSecFilingId & { entityType?: EntityType }): Promise<any> => {
  try {
    const res = await API.get(
      apiName,
      `/${secondaryPath}/${filingId}/check-reset-entities${
        entityType ? `?entityType=${entityType}` : ''
      }`,
      myInit
    );
    return { notOk: res.notOk, payload: res.payload };
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const resetEntities = async ({
  filingId,
  entityType,
}: IDebSecFilingId & { entityType: EntityType | null }): Promise<any> => {
  try {
    const res = await API.put(apiName, `/${secondaryPath}/${filingId}/reset-entities`, {
      body: {
        entityType,
      },
    });
    return { notOk: res.notOk };
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const getUCC3Attachments = async ({ filingId }: IFilingId) => {
  try {
    const res = await API.get(apiName, `/attachments?filingId=${filingId}&all=true`, myInit);
    return res;
  } catch (error: any) {
    return { ...error?.response?.data, notOk: true };
  }
};

export const removeUCC3Attachment = async ({
  filingId,
  attachmentId,
}: IAttachmentRequest): Promise<IResponse<boolean>> => {
  try {
    const res = await API.del(
      apiName,
      `/${primaryPath}/${filingId}/attachments/${attachmentId}`,
      myInit
    );
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const downloadUCC3Attachment = async ({
  filingId,
  attachmentId,
}: IAttachmentRequest): Promise<IResponse<IAttachment>> => {
  try {
    const res = await API.get(
      apiName,
      `/${primaryPath}/${filingId}/attachments/${attachmentId}/download`,
      myInit
    );
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const uploadUCC3Attachment = ({
  filingId,
  payload,
}: UploadAttachmentRequest): Promise<IResponse<IAttachment>> => {
  return API.post(apiName, `/${primaryPath}/${filingId}/attachments`, {
    body: payload,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const cancelUCC3UploadAttachments = ({ promise }: Pick<IUploadingFile, 'promise'>): void => {
  API.cancel(promise, 'File upload cancelled');
};

export const cancelUCC3 = async (id: number) => {
  try {
    const res = await API.put(apiName, `/ucc3/${id}/cancel`, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const getUCC3PaymentById = async (id: number) => {
  try {
    const res = await API.get(apiName, `/payments/${id}`, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const updateLastCheckDate = async ({ filingId }: IFilingId) => {
  try {
    const res = await API.put(apiName, `/${secondaryPath}/${filingId}/lastCheckDate`, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};
