import { Accordion } from './constants';
import { ILoginFormUI } from '../../common/models';
import { FieldValues, UseFormReset, UseFormSetValue, UseFormGetValues } from 'react-hook-form';
import { IFilingsActions } from '../shared/ucc/models';
import { IFilingsResponse } from '../shared/ucc/models';

export interface IDashboardAccordionsUIProps extends IFilingsActions, ILoginFormUI<FieldValues> {
  accordionState: boolean[];
  handleAccordionState(prop: Accordion): void;
  pendingFilingsData: IFilingsResponse[] | null;
  toggleTooltip(id: number): void;
  activeToolTip: number | null;
  reset: UseFormReset<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  handleOnNewFiling: () => void;
  filingHistoryData: IFilingsResponse[] | null;
  handleSortOrderChange: (sortOrder: string, column: string) => void;
  searchClicked: boolean;
}

export interface IFilingHistory {
  filingHistoryData: IFilingsResponse[] | null;
  handleSortOrderChange: (sortOrder: string, column: string) => void;
  searchClicked: boolean;
}

export interface IFilingHistoryQueryParams {
  fromDate: string;
  toDate: string;
  sortOrder: string;
  orderColumnName?: string;
  uccNumber?: string;
}

export enum AccordionTitle {
  PENDING_UCC_FILINGS = 'Pending UCC Filings',
  FILING_HISTORY = 'Filing History',
}
