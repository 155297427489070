import { useEffect } from 'react';

import { useAuth, useUserInfo } from '../../context';

import { ILayoutWrapperProps } from '../../models';
import { AuthedState } from '../../context/types';

const AppInitializer = ({ children }: ILayoutWrapperProps) => {
  const { checkAuthUser, authed, getUserSettings } = useAuth();
  const { getUserInfo, getCartDetails } = useUserInfo();

  useEffect(() => {
    checkAuthUser();
  }, []);

  useEffect(() => {
    if (authed === AuthedState.AUTHED) {
      getUserSettings();
      getUserInfo();
      getCartDetails();
    }
  }, [authed]);

  return <>{children}</>;
};

export default AppInitializer;
