import { Theme } from '@mui/material/styles';
import { colors } from '../constants';

const ButtonStyles = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      fontFamily: 'Open Sans',
      borderRadius: theme.convert.pxToRem(5),
      fontWeight: 600,
      '&.MuiButton-root': {
        textTransform: 'none',
      },
    }),

    contained: ({ theme }: { theme: Theme }) => ({
      '&:hover': {
        background: theme.palette.primary.main,
        boxShadow: '0 8px 8px 0 rgba(176, 192, 237, 0.5)',
      },

      '&:focus': {
        border: `2px solid ${theme.palette.primary.main}`,
        boxShadow: '0 0 10px 2px rgba(46, 115, 220, 0.6)',
      },

      '&.Mui-disabled': {
        background: theme.palette.grey[200],
        color: theme.palette.grey[700],
      },
    }),

    outlined: ({ theme }: { theme: Theme }) => ({
      border: `1px solid ${theme.palette.info.main}`,
      color: theme.palette.primary.main,

      '&:hover': {
        color: theme.palette.info.main,
        boxShadow: '0 8px 8px 0 rgba(176, 192, 237, 0.5)',
      },

      '&:focus': {
        color: theme.palette.info.main,
        boxShadow: '0 0 10px 2px rgba(46, 115, 220, 0.6)',
      },

      '&.Mui-disabled': {
        border: `1px solid ${theme.palette.grey[500]}`,
        color: theme.palette.grey[500],
      },
    }),

    text: ({ theme }: { theme: Theme }) => ({
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(22),
      color: `${colors.textButtonDefaultColor} !important`,
      '&:hover': {
        background: 'none',
        textDecoration: 'underline',
        textDecorationColor: `${colors.textButtonHoverColor} !important`,
        color: `${colors.textButtonHoverColor} !important`,
      },
      '&.Mui-disabled': {
        textDecoration: 'none',
        color: `${colors.textButtonDisabledColor} !important`,
      },
    }),
  },
};

export default ButtonStyles;
