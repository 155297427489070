import { styled } from '@mui/material/styles';

export const StyledOrderedList = styled('ol')`
  list-style: none;
  counter-reset: list;

  & > li {
    position: relative;

    &::before {
      content: counter(list) ') ';
      counter-increment: list;
      position: absolute;
      left: -20px;
    }
  }

  & > li:not(:last-child) {
    margin-bottom: 20px;
  }
`;
