import { Typography, Box, useTheme } from '@mui/material';
import { CloseIconButton } from '../../../global-styles/CloseIconButton';
import { useModal } from '../../../context';
import { useSnackbar } from '../../../notification';
import { StyledModalSubmitButton } from '../../../global-styles';
import type { UnsavedChangesHandler } from '../../../models';
import { useContext } from 'react';
import { UnsavedHandlerContext } from '../../../../features/shared/ucc/unsaved-handler-context/UnsavedHandlerContext';

const useUnsavedChangesModal = () => {
  const theme = useTheme();
  const { handleModalOpen, handleModalClose } = useModal();
  const { hasPendingUploads } = useContext(UnsavedHandlerContext);
  const { Snack } = useSnackbar();

  const handleUnsavedChanges: UnsavedChangesHandler = ({ onProceed, onCancel } = {}) => {
    const xButtonAction = onCancel ? (
      <CloseIconButton
        onClick={() => {
          onCancel();
          handleModalClose();
        }}
      />
    ) : null;

    handleModalOpen({
      title: 'Unsaved Changes',
      modalContent: (
        <Typography variant="h4">All unsaved data will be lost if you continue.</Typography>
      ),
      xButtonAction,
      modalActions: (
        <Box
          sx={{
            display: 'flex',
            gap: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(34) },
            flexDirection: 'row-reverse',
          }}
        >
          <StyledModalSubmitButton
            autoFocus
            variant="contained"
            onClick={() => {
              // User should be in view mode OR Move to other page OR Logout
              handleModalClose();
              Snack.info('Action canceled.');
              if (hasPendingUploads) {
                Snack.success('Upload is successfully canceled.');
              }
              onProceed && onProceed();
            }}
          >
            Proceed
          </StyledModalSubmitButton>
          <StyledModalSubmitButton
            variant="outlined"
            onClick={() => {
              // User should be in edit mode AND Remain on page AND Not logged out
              handleModalClose();
              onCancel && onCancel();
            }}
          >
            Cancel
          </StyledModalSubmitButton>
        </Box>
      ),
    });
  };

  return { handleUnsavedChanges };
};

export default useUnsavedChangesModal;
