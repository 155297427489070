import { forwardRef } from 'react';
import { Box, useTheme } from '@mui/material';

import logo from '../../assets/icons/ucclogo.png';

import { IPrintableAreaProps } from '../models/features/models';

const PrintableArea = forwardRef<HTMLDivElement, IPrintableAreaProps>(function PrintableArea(
  { children },
  ref
) {
  const theme = useTheme();
  return (
    <Box ref={ref}>
      <Box
        className="print-only"
        maxWidth={['93.6%', '86.5%', '79.2%']}
        width={['93.6%', '86.5%', '79.2%']}
        marginX="auto"
        marginY={theme.convert.pxToRem(40)}
      >
        <img src={logo} alt="ucc logo" />
      </Box>
      {children}
    </Box>
  );
});

export default PrintableArea;
