import { Button, Grid, Typography } from '@mui/material';
import { IEmail } from '../../../../common/models';
import { useUpdateAccountEmail, useUpdatePasswordModal } from '../../../../common/hooks';
import { useAuth, useMyAccount } from '../../../../common/context';
import useUnsavedChangesModal from '../../../../common/components/modals/unsaved-changes/useUnsavedChangesModal';

export const PasswordAndSecurityView = ({ email }: IEmail) => {
  const { onHandleOpenModal } = useUpdateAccountEmail();
  const { handleOpenModal } = useUpdatePasswordModal();

  const initEditState = {
    personalInfo: false,
    acknowledgementInfo: false,
  };

  const { isEditMode, setIsEditMode } = useAuth();
  const { setEditModeAccordion } = useMyAccount();
  const { handleUnsavedChanges } = useUnsavedChangesModal();

  const handleModalOpen = (e: React.MouseEvent, openModal: () => void) => {
    const onProceed = () => {
      setEditModeAccordion(initEditState);
      setIsEditMode(false);
      openModal();
    };
    if (isEditMode) {
      handleUnsavedChanges({ onProceed });
    } else {
      onProceed();
    }
  };

  return (
    <Grid container padding={2} gap={1}>
      <Grid container>
        <Grid item xs={12} md={2.5}>
          <Typography variant="h5">Email</Typography>
        </Grid>
        <Grid container item xs={12} md={3.5} sx={{ display: 'flex', flexWrap: 'noWrap' }}>
          <Grid item>
            <Typography sx={{ wordBreak: 'break-word' }} paddingRight={3}>
              {email}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={{ p: 0, minWidth: 0 }}
              variant="text"
              onClick={(e) => handleModalOpen(e, onHandleOpenModal)}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={1.5}>
        <Grid item xs={12}>
          <Button sx={{ p: 0 }} variant="text" onClick={(e) => handleModalOpen(e, handleOpenModal)}>
            Update password
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
