import * as yup from 'yup';

export const saveSchema = yup.object({
  uccNumber: yup.string(),
  dateRange: yup.array(),
  focusedInput: yup.string().nullable(),
  orderColumnName: yup.string(),
  sortOrder: yup.string(),
});

export type FormData = yup.InferType<typeof saveSchema>;
