import { Box, Typography, useTheme } from '@mui/material';
import { Fragment, useCallback, useState } from 'react';
import { CustomAccordion } from './accordion';

interface Accordion {
  id: number;
  title: string;
  content: string | JSX.Element;
}

interface ListAccordionsProps {
  accordions: Accordion[];
  initiallyOpen?: number[];
}

const ListAccordions = ({ accordions, initiallyOpen = [] }: ListAccordionsProps) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<Record<number, boolean>>(
    accordions.reduce(
      (accordion, currentAccordion) => ({
        ...accordion,
        [currentAccordion.id]: initiallyOpen.includes(currentAccordion.id),
      }),
      {}
    )
  );

  const handleChange = useCallback(
    (accordionId: number) => () => {
      setExpanded((prevState) => ({ ...prevState, [accordionId]: !prevState[accordionId] }));
    },
    []
  );

  return (
    <>
      {accordions.map((accordion, index) => (
        <Fragment key={accordion.id}>
          <CustomAccordion
            title={accordion.title}
            isOpen={expanded[accordion.id]}
            handleChange={handleChange(accordion.id)}
            noFixedHeight
          >
            <Box
              sx={{
                paddingY: theme.convert.pxToRem(28),
                paddingLeft: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(40) },
                paddingRight: {
                  xs: theme.convert.pxToRem(20),
                  md: theme.convert.pxToRem(40),
                },
              }}
            >
              <Typography variant="body1" component="div">
                {accordion.content}
              </Typography>
            </Box>
          </CustomAccordion>
          {index + 1 !== accordions.length && <Box height={28} />}
        </Fragment>
      ))}
    </>
  );
};

export default ListAccordions;
