import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import { ReactComponent as ImageAPILogo } from '../../../../assets/icons/imageapi.svg';
import { privacyPolicyPageURL, termsOfUsePageURL } from '../../../constants/routes';

import pj from '../../../../../package.json';

const links = [
  { text: 'Terms of Use', url: termsOfUsePageURL },
  { text: 'Privacy Policy', url: privacyPolicyPageURL },
];

export function FooterCopyright() {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Grid
      container
      item
      alignItems={'center'}
      paddingX={theme.convert.pxToRem(60)}
      paddingBottom={{ sm: theme.convert.pxToRem(12), md: theme.convert.pxToRem(8) }}
      paddingTop={theme.convert.pxToRem(8)}
      textAlign={{ xs: 'center', md: 'inherit' }}
    >
      <Grid xs={12} md={2.5} lg={4} item>
        <ImageAPILogo title="ImageAPI" />
      </Grid>
      <Grid xs={12} md={5.5} lg={4} item>
        <Typography variant="body2" textAlign="center" marginX="auto" width={270}>
          {`${pj.version} Copyright © 2023 IMAGE API, LLC`}
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={4}
        lg={4}
        item
        display="flex"
        gap={2}
        justifyContent={{ xs: 'center', md: 'flex-end' }}
      >
        {links.map(({ text, url }) => (
          <Typography variant="body3" component="div" key={text}>
            <Button onClick={() => navigate(url)} style={{ padding: 0, paddingBottom: 2 }}>
              {text}
            </Button>
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
}
