import { FC, MouseEvent, useCallback, useState } from 'react';
import {
  achAccountPageURL,
  announcementsPageURL,
  downloadPageURL,
  feesPageURL,
  formsPageURL,
  helpPageURL,
  xmlImplementationGuidePageURL,
} from '../../constants/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledButton } from '../logged-in-user-menu/StyledButton';
import { Box, Collapse, List, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as Polygon } from '../../../assets/icons/close-polygon.svg';
import { StyledListItemButton } from './StyledListItemButton';
import { colors } from '../../constants';

const items: { text: string; url: string }[] = [
  { text: 'Help', url: helpPageURL },
  { text: 'Fees', url: feesPageURL },
  { text: 'Forms', url: formsPageURL },
  { text: 'Download', url: downloadPageURL },
  { text: 'ACH Accounts', url: achAccountPageURL },
  { text: 'Announcements', url: announcementsPageURL },
  {
    text: 'XML Implementation Guide',
    url: xmlImplementationGuidePageURL,
  },
];

interface Props {
  handleCloseDrawer?: () => void;
}

export const InformationMenuSmallScreen: FC<Props> = ({ handleCloseDrawer }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const isActive = items.some((item) => item.url === location.pathname);

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (!isSmallScreen) {
        setAnchorEl(event.currentTarget);
      } else {
        if (anchorEl) {
          setAnchorEl(null);
        } else {
          setAnchorEl(event.currentTarget);
        }
      }
    },
    [anchorEl, isSmallScreen]
  );

  return (
    <Box
      position={'relative'}
      display="flex"
      paddingBottom={{ xs: theme.convert.pxToRem(2), md: theme.convert.pxToRem(3) }}
      alignItems={{ xs: 'start', md: 'start', lg: 'center' }}
      flexDirection={{ xs: 'column', md: 'column', lg: 'row' }}
      borderBottom={!isSmallScreen ? (isActive ? `3px solid ${theme.palette.info.dark}` : '') : ''}
    >
      <StyledButton
        id="grow-button"
        aria-haspopup="true"
        onClick={handleClick}
        aria-expanded={open ? 'true' : 'false'}
        aria-controls={open ? 'grow-menu' : 'false'}
        style={{ padding: theme.convert.pxToRem(0) }}
      >
        <Typography variant="h4" maxWidth={theme.convert.pxToRem(200)} align="left">
          Information
        </Typography>
        <Box width={8} />
        <Polygon
          style={{
            color: '#646464',
            transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
            transition: 'transform 0.2s ease-in-out',
          }}
        />
      </StyledButton>
      <Box
        sx={{
          display: isSmallScreen && isActive ? 'block' : 'none',
          height: theme.convert.pxToRem(5.6),
          width: theme.convert.pxToRem(110),
          borderBottom: `3px solid ${theme.palette.info.dark}`,
        }}
      />
      {isSmallScreen && (
        <Collapse in={open}>
          <List
            disablePadding
            sx={{
              display: open ? 'block' : 'none',
              marginLeft: theme.convert.pxToRem(-40),
            }}
          >
            {items.map(({ url, text }) => {
              const isCurrentURL = location.pathname === url;
              return (
                <StyledListItemButton
                  key={`InfoMenu_${text}`}
                  onClick={() => {
                    navigate(url);
                    handleCloseDrawer?.();
                    setAnchorEl(null);
                  }}
                  sx={{
                    backgroundColor: isCurrentURL ? theme.palette.grey[200] : 'white',
                    borderLeft: isCurrentURL ? `4px solid ${colors.highlightSelectionColor}` : '',
                  }}
                >
                  <Typography>{text}</Typography>
                </StyledListItemButton>
              );
            })}
          </List>
        </Collapse>
      )}
    </Box>
  );
};
