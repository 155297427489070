import type { PasswordValidatorHelperProps } from '../models/validation';
export const passwordValidatorHelper = (value: string): PasswordValidatorHelperProps => {
  const lower = new RegExp('(?=.*[a-z])');
  const upper = new RegExp('(?=.*[A-Z])');
  const number = new RegExp('(?=.*[0-9])');
  const special = new RegExp('(?=.*[!@#$%^&*])');

  return {
    lowerCase: !lower.test(value),
    upperCase: !upper.test(value),
    specialChar: !special.test(value),
    oneNumber: !number.test(value),
    minLength: value.length < 12,
  };
};

export const hasErrors = (errors: PasswordValidatorHelperProps) => {
  const { lowerCase, upperCase, specialChar, oneNumber, minLength } = errors;
  return lowerCase || upperCase || specialChar || oneNumber || minLength;
};

export const passwordsMatch = (password: string, confirmPassword: string) => {
  return password !== confirmPassword;
};
