import { Box, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';

import { CloseIconButton } from '../../global-styles/CloseIconButton';
import { StyledActionButton } from '../user-auth-components/common-auth-styles';
import type { IModalProps } from '../../models/modal';

export const Modal = ({
  children,
  title,
  subtitle,
  additionalInfo,
  open,
  handleClose,
  modalActions,
  xButtonAction,
  contentHeight,
  hasCalendar,
}: IModalProps) => {
  const theme = useTheme();
  const footerModal = modalActions ?? (
    <StyledActionButton autoFocus variant="contained" onClick={handleClose}>
      Ok
    </StyledActionButton>
  );

  const closeButton = xButtonAction ?? <CloseIconButton onClick={handleClose} />;

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: theme.convert.pxToRem(20),
          paddingTop: theme.convert.pxToRem(20),
        }}
      >
        {closeButton}
      </Box>
      <Box>
        <Box
          sx={{
            paddingLeft: {
              xs: theme.convert.pxToRem(20),
              md: theme.convert.pxToRem(48),
              marginTop: theme.convert.pxToRem(-9),
            },
          }}
        >
          {title && (
            <DialogTitle sx={{ paddingLeft: 0, paddingTop: 0 }} variant="h2">
              {title}
            </DialogTitle>
          )}
        </Box>

        <Box sx={{ paddingLeft: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(48) } }}>
          {subtitle && (
            <DialogTitle variant="h4" component="h4" sx={{ paddingLeft: 0 }}>
              {subtitle}
            </DialogTitle>
          )}
        </Box>

        {additionalInfo && (
          <DialogContent
            sx={{
              paddingTop: 0,
              paddingLeft: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(48) },
              paddingRight: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(48) },
              paddingBottom: { xs: theme.convert.pxToRem(28), sm: theme.convert.pxToRem(36) },
              maxHeight: [
                theme.convert.pxToRem(800),
                theme.convert.pxToRem(800),
                theme.convert.pxToRem(500),
              ],
            }}
          >
            {additionalInfo}
          </DialogContent>
        )}

        <DialogContent
          sx={{
            paddingTop: theme.convert.pxToRem(1),
            paddingLeft: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(48) },
            paddingRight: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(48) },
            paddingBottom: { xs: theme.convert.pxToRem(28), md: theme.convert.pxToRem(36) },
            height: contentHeight ? contentHeight : 'auto',
            overflow: hasCalendar ? 'visible' : 'auto',
          }}
        >
          {children}
        </DialogContent>
      </Box>
      <DialogActions
        sx={{
          boxShadow: '0px 0px 8px rgba(176, 192, 237, 0.5)',
          paddingY: theme.convert.pxToRem(20),
          paddingRight: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(48) },
          paddingLeft: hasCalendar
            ? theme.convert.pxToRem(20)
            : { xs: theme.convert.pxToRem(62), md: theme.convert.pxToRem(246) },
        }}
      >
        {footerModal}
      </DialogActions>
    </Dialog>
  );
};
