import { Grid, Typography, FormHelperText, Box, InputLabel, useTheme } from '@mui/material';
import { Controller } from 'react-hook-form';

import Loader from '../../../common/components/loader';
import {
  StyledBoxWrapper,
  StyledSubmitButton,
  StyledFormHelperText,
} from '../../../common/components/user-auth-components/common-auth-styles';
import { PasswordInput } from '../../../common/components/inputs';
import { PasswordValidationIcon } from './PasswordValidationIcon';

import { hasErrors } from '../../../common/helpers';
import { FormData } from './schema';
import { ICreatePasswordFormUI } from './types';
import { validationConfig } from './validationConfig';

const CreatePasswordFormUI = ({
  customErrors,
  onHandleSubmit,
  isSubmitting,
  control,
  formState: { errors },
  arePasswordsMatching,
}: ICreatePasswordFormUI<FormData>) => {
  const theme = useTheme();
  return (
    <form onSubmit={onHandleSubmit}>
      <StyledBoxWrapper
        sx={{
          width: {
            xs: theme.convert.pxToRem(320),
            sm: theme.convert.pxToRem(350),
            md: theme.convert.pxToRem(470),
          },
          gap: '1rem',
        }}
      >
        <Typography variant="h1" marginBottom="2rem">
          Create New Password
        </Typography>
        <Grid container alignItems="center">
          <Grid item xs={12} md={5} paddingBottom={{ xs: 1 }}>
            <InputLabel htmlFor="newPassword">
              <Typography variant="h4">Create Password *</Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={7}>
            <Controller
              name="newPassword"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <PasswordInput
                  required
                  autoFocus
                  showIcon
                  {...field}
                  inputRef={ref}
                  placeholder="Enter password"
                  error={hasErrors(customErrors) || !!errors.newPassword?.message}
                  name="newPassword"
                />
              )}
            />
            <FormHelperText error={!!errors.newPassword?.message}>
              {errors.newPassword?.message}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} md={5} paddingBottom={{ xs: 1 }}></Grid>
          <Grid item xs={12} md={7}>
            {validationConfig(customErrors).map(({ error, text }) => (
              <StyledFormHelperText key={text}>
                <PasswordValidationIcon error={error} />
                <FormHelperText error={error}>{text}</FormHelperText>
              </StyledFormHelperText>
            ))}
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={5} paddingBottom={{ xs: 1 }}>
            <InputLabel htmlFor="confirmPassword">
              <Typography variant="h4">Confirm Password *</Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={7}>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <PasswordInput
                  required
                  showIcon
                  {...field}
                  inputRef={ref}
                  placeholder="Confirm password"
                  name="confirmPassword"
                  error={hasErrors(customErrors) || !!errors.confirmPassword?.message}
                />
              )}
            />
            <FormHelperText error={!!errors.confirmPassword?.message}>
              {errors.confirmPassword?.message}
            </FormHelperText>
          </Grid>
        </Grid>
      </StyledBoxWrapper>
      <StyledBoxWrapper width={{ xs: 320, sm: 350 }}>
        <Box height={48} />
        <Grid container alignItems={'center'}>
          <Grid item xs={12}>
            <StyledSubmitButton
              variant="contained"
              type="submit"
              fullWidth
              startIcon={isSubmitting ? <Loader /> : null}
              disabled={isSubmitting || hasErrors(customErrors) || arePasswordsMatching}
            >
              {isSubmitting ? 'Creating Password and Logging In' : 'Create Password and Login'}
            </StyledSubmitButton>
          </Grid>
        </Grid>
      </StyledBoxWrapper>
    </form>
  );
};
export default CreatePasswordFormUI;
