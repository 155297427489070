import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import useSessionModal from '../components/modals/session/useSessionModal';
import { useAuth } from '../context';
import {
  DEFAULT_PROMPT_TIME_IN_MILLISECONDS,
  DEFAULT_IDLE_TIME_IN_MINUTES,
  SESSION_TIMEOUT,
} from '../constants/timers';
import { getOnlineSetting } from '../services/online-settings.services';
import { parseErrorMessages } from '../helpers';

/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
const useIdleTimeout = ({ idleTime } = { idleTime: DEFAULT_IDLE_TIME_IN_MINUTES }) => {
  const { signOut } = useAuth();
  const { handleSessionExpired, handleSessionTimeout, handleModalClose } = useSessionModal();

  const [idleTimeout, setIdleTimeout] = useState<number>(1000 * 60 * idleTime);
  const [isIdle, setIdle] = useState(false);

  // Displays a confirmation prompt before calling onIdle
  const onPrompt = () => {
    handleSessionTimeout({
      pause: idleTimer.pause,
      activate: idleTimer.activate,
    });
  };

  // Called when user is idle
  const onIdle = () => {
    setIdle(true);
    idleTimer.pause();
    handleModalClose();
    handleSessionExpired();
    signOut();
  };

  useEffect(() => {
    (async () => {
      const res = await getOnlineSetting([SESSION_TIMEOUT]);
      if (res.notOk) {
        throw new Error(parseErrorMessages(res.messages));
      } else {
        setIdleTimeout(1000 * 60 * parseInt(res));
      }
    })();
  }, []);

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle: DEFAULT_PROMPT_TIME_IN_MILLISECONDS,
    onPrompt,
    onIdle,
    debounce: 500,
    startOnMount: true,
    crossTab: true,
    syncTimers: 3000, // duration of the throttle on the sync operation
    stopOnIdle: true,
  });

  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};
export default useIdleTimeout;
