import { useEffect, useState } from 'react';
import { getDocumentDownloadUrl } from '../search.services';
import { GenericErrorMessage } from '../../../common/constants/features-constants';
import { useSnackbar } from '../../../common/notification';

export const useFileImage = () => {
  const { Snack } = useSnackbar();
  const [downloadLink, setDownloadLink] = useState<string | null>(null);

  useEffect(() => {
    if (downloadLink && window !== undefined) {
      window.open(downloadLink);
    }
  }, [downloadLink]);

  const onDownloadClick = async (uccNumber: string) => {
    try {
      setDownloadLink(null);
      const downloadLinkResponse = await getDocumentDownloadUrl(uccNumber);
      setDownloadLink(downloadLinkResponse.payload);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      Snack.error(GenericErrorMessage);
    }
  };

  return {
    downloadLink,
    onDownloadClick,
  };
};
