export enum FilingsStatus {
  Filed = 'Filed',
  Lapsed = 'Lapsed',
  Cancelled = 'Cancelled',
}

export interface IDebSecDataTypes {
  debNames: DebSecNamesType[];
  secNames: DebSecNamesType[];
  ucc1Number: string | null;
}

export interface IFileData {
  ucc1FilingNumber: string | null;
  date: string;
  pages: number;
  totPages: number;
  status: keyof FilingsStatus;
  cancelDate: string | null;
  expireDate: string | null;
  userId: string | null;
  transmittingUtility: string | null;
  eventCount: number | null;
  seqNumber?: number;
  totalDebCount: number;
  totalSecCount: number;
  currentDebCount: number;
  currentSecCount: number;
  createDate: string | null;
  manHomeTranUpdated: number | null;
}

export type DebSecNamesType = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  compactName: string;
  country: string;
  filing: number | null;
  filingStatus: string;
  nameFormat: string;
  origParty: string;
  refNumber: number | null;
  relationToFiling: string;
  seqNumber: number;
  state: string;
  zipCode: string;
  id: number;
  name: string;
  ucc1FilingNumber: string;
};
