import { styled } from '@mui/material/styles';

export const StyledFormHelperText = styled('div')`
  display: flex;
  align-items: center;
  margin: 0.2rem 0;
  p {
    margin-left: 0.2rem;
  }
`;
