import { Theme } from '@mui/material/styles';
import { colors } from '../constants';

export const MuiTooltip = {
  styleOverrides: {
    tooltip: ({ theme }: { theme: Theme }) => ({
      background: colors.defaultBackground,
      color: theme.palette.text.primary,
      boxShadow: `0px 0px 6px ${theme.palette.primary.light}`,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      padding: `${theme.convert.pxToRem(13)} ${theme.convert.pxToRem(13)} ${theme.convert.pxToRem(
        13
      )} ${theme.convert.pxToRem(26)}`,
      [theme.breakpoints.down('xl')]: {
        maxWidth: theme.convert.pxToRem(340),
      },
      [theme.breakpoints.down('lg')]: {
        maxWidth: theme.convert.pxToRem(247),
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: theme.convert.pxToRem(204),
      },
    }),
    arrow: ({ theme }: { theme: Theme }) => ({
      color: colors.defaultBackground,
      '&:before': {
        boxShadow: `0px 0px 6px ${theme.palette.primary.light}`,
      },
    }),
  },
};
