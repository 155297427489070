import React, { useEffect } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography, useTheme } from '@mui/material';
import { Controller, useWatch } from 'react-hook-form';

import { checkResetEntities, resetEntities } from '../ucc3.services';
import { useSnackbar } from '../../../common/notification';
import { StyledModalSubmitButton } from '../../../common/global-styles';
import { useModal } from '../../../common/context';

import { Accordion } from '../constants';
import { EntityType } from '../../../common/models/features/models';
import { IAssignmentCheckboxProps } from '../models';
import { CloseIconButton } from '../../../common/global-styles/CloseIconButton';

const AssignmentCheckbox = ({
  control,
  handleCheckboxAccordionState,
  filingId,
  setValue,
  getData,
}: IAssignmentCheckboxProps) => {
  const theme = useTheme();
  const { Snack } = useSnackbar();
  const { handleModalOpen, handleModalClose } = useModal();
  const eventAssignment = useWatch({
    control,
    name: 'eventAssignment',
  });
  const eventAmendment = useWatch({
    control,
    name: 'eventAmendment',
  });
  useEffect(() => {
    if (eventAssignment) {
      handleCheckboxAccordionState(Accordion.Assigment);
    }
  }, [eventAssignment]);

  const doResetEntities = async () => {
    const res = await resetEntities({ filingId, entityType: EntityType.Secured });
    if (res.notOk) {
      setValue('eventAssignment', false);
      Snack.error('Could not reset form data.');
    } else {
      setValue('eventAssignment', false);
      handleModalClose();
      Snack.success('Event is successfully unchecked.');
      getData(filingId);
    }
  };

  const doCloseModal = () => {
    setValue('eventAssignment', true);
    handleModalClose();
  };

  const handleCheckboxChecked = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = event;
    if (!checked) {
      try {
        const res = await checkResetEntities({ filingId, entityType: EntityType.Secured });
        if (res && res.payload) {
          handleModalOpen({
            title: 'Unchecking Event',
            modalContent: (
              <Typography variant="h4">
                Are you sure you want to uncheck this event? If you proceed all event data you
                updated will be lost.
              </Typography>
            ),
            xButtonAction: <CloseIconButton onClick={doCloseModal} />,
            modalActions: (
              <Box
                sx={{
                  display: 'flex',
                  gap: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(34) },
                  flexDirection: 'row-reverse',
                }}
              >
                <StyledModalSubmitButton autoFocus variant="contained" onClick={doResetEntities}>
                  Proceed
                </StyledModalSubmitButton>
                <StyledModalSubmitButton variant="outlined" onClick={doCloseModal}>
                  Cancel
                </StyledModalSubmitButton>
              </Box>
            ),
          });
        }
      } catch (error: unknown) {
        Snack.error('Could not check the entity state.');
      }
    } else {
      setValue('eventAmendment', false);
    }
  };

  return (
    <Controller
      control={control}
      name="eventAssignment"
      render={({ field }) => (
        <>
          <FormGroup {...field}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={eventAssignment}
                  disabled={eventAmendment}
                  onChange={(event) => handleCheckboxChecked(event)}
                />
              }
              label=""
            />
          </FormGroup>
        </>
      )}
      defaultValue={eventAssignment}
    />
  );
};

export default AssignmentCheckbox;
