import useIdleTimeout from '../../hooks/useIdleTimeout';

interface IdleTimeProps {
  idleTime: number;
}

const IdleTimeComponent = ({ idleTime }: IdleTimeProps) => {
  useIdleTimeout({ idleTime });

  return null;
};

export default IdleTimeComponent;
