import { Typography } from '@mui/material';
import DashboardAccordions from '../features/dashboard/DashboardAccordions';
import { PageTitle } from '../common/constants/features-constants';
import { useUserInfo } from '../common/context';
import { formatDateToLocaleString } from '../features/ucc3/utils';
import PageWrapper from '../common/layouts/PageWrapper';

const Dashboard = () => {
  const { lastLogin } = useUserInfo();
  const subtitle = lastLogin ? (
    <Typography variant="body1">
      Last login:{' '}
      {formatDateToLocaleString(lastLogin, {
        dateFormat: '2-digit',
        timeFormat: 'numeric',
        excludeSeconds: true,
        removeCommaBetweenDateAndTime: true,
      })}
    </Typography>
  ) : undefined;

  return (
    <PageWrapper title={PageTitle.DASHBOARD} subtitle={subtitle}>
      <DashboardAccordions />
    </PageWrapper>
  );
};

export default Dashboard;
