import { useEffect } from 'react';
import { unstable_useBlocker } from 'react-router-dom';
import useUnsavedChangesModal from '../components/modals/unsaved-changes/useUnsavedChangesModal';

// IMPORTANT: There are edge cases with this behavior in which React Router
// cannot reliably access the correct location in the history stack. In such
// cases the user may attempt to stay on the page but the app navigates anyway,
// or the app may stay on the correct page but the browser's history stack gets
// out of whack. You should test your own implementation thoroughly to make sure
// the tradeoffs are right for your users.
export function useBlocker(blockCondition: boolean, onProceedCleanup?: () => void) {
  const { handleUnsavedChanges } = useUnsavedChangesModal();
  const blocker = unstable_useBlocker(blockCondition);
  const shouldBlock = blocker.state === 'blocked' && blockCondition;

  useEffect(() => {
    if (shouldBlock) {
      handleUnsavedChanges({
        onProceed: () => {
          onProceedCleanup?.();
          blocker.proceed();
        },
        onCancel: blocker.reset,
      });
    }
  }, [shouldBlock, onProceedCleanup]);
}
