import { createContext, useContext, useState } from 'react';
import { CognitoUser } from '@aws-amplify/auth';

import {
  answerCustomChallenge as amplifyAnswerCustomChallenge,
  checkAuthUser as amplifyCheckAuthUser,
  checkUniqueEmailAPI,
  checkUserSession as amplifyCheckUserSession,
  completeNewPassword,
  createNewPing,
  emailSignIn as amplifyEmailSignIn,
  forgotPasswordSubmit,
  getUserData,
  getUserSettings as amplifyGetUserSettings,
  resendMail as resendConfirmMail,
  resetPassword as amplifyResetPassword,
  signIn as amplifySignIn,
  signOut as amplifySignOut,
  signUp as userSignUp,
  updatePassword as amplifyUpdatePassword,
  updateUserEmailAPI,
  userActivate,
  verifyEmailUserCodeAPI,
} from '../../infrastructure/api/auth-api';
import { DEFAULT_IDLE_TIME_IN_MINUTES, SESSION_TIMEOUT } from '../constants/timers';
import { parseErrorMessage } from './helpers';
import { useSnackbar } from '../notification';

import type {
  IChallangeAnswer,
  IChallangeCode,
  IEmail,
  IForgotPassword,
  ILayoutWrapperProps,
  IResetPassword,
  ISignIn,
  ISignUp,
  IUpdatePassword,
  UnsavedChangesHandler,
} from '../models';
import type { IAuthContext } from './types';
import { AuthedState } from './types';

const AuthContext = createContext<IAuthContext | undefined>(undefined);

function AuthProvider({ children }: ILayoutWrapperProps) {
  const [authed, setAuthed] = useState<AuthedState>(AuthedState.CHECKING_AUTHORIZATION);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // TODO: move this to userInfoProvider & put Provider above ModalProvider
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [userIdleTime, setUserIdleTime] = useState<number | undefined>();
  const { Snack } = useSnackbar();

  const executeSignout = async () => {
    try {
      setIsLoadingUser(true);
      await amplifySignOut();
      setAuthed(AuthedState.NOT_AUTHED);
      setUser(null);
    } catch (e: unknown) {
      Snack.error(e instanceof Error ? e.message : 'Unknown error occured while signing out user.');
    } finally {
      setIsLoadingUser(false);
    }
  };

  const value = {
    isLoadingUser,
    user,
    authed,
    userIdleTime,
    isEditMode,
    setIsEditMode,
    setUser,
    async getUserSettings() {
      try {
        const { payload } = await amplifyGetUserSettings();
        const sessionTimoutSetting = payload?.find(
          (setting: Record<string, string>) => setting.key === SESSION_TIMEOUT
        );
        const idleTimeSetting = Number(sessionTimoutSetting?.value) || DEFAULT_IDLE_TIME_IN_MINUTES;
        setUserIdleTime(idleTimeSetting);
      } catch (error) {
        setUserIdleTime(DEFAULT_IDLE_TIME_IN_MINUTES);
      }
    },
    getUserData, // TODO: update user info from response
    async checkUserSession(skipErrorReporting = false) {
      try {
        await amplifyCheckUserSession();
        setAuthed(AuthedState.AUTHED);
      } catch (e: unknown) {
        setAuthed(AuthedState.NOT_AUTHED); // If user session has expired user should be redirected to Login page
        if (!skipErrorReporting) {
          Snack.error(
            e instanceof Error ? e.message : 'Unknown error occurred while checking user session.'
          );
        }
      }
    },
    async checkAuthUser() {
      try {
        const user = await amplifyCheckAuthUser();
        if (!user) {
          throw new Error('User is not authenticated.');
        }
        setAuthed(AuthedState.AUTHED);
        setUser(user);
      } catch (e: unknown) {
        // TODO: Check if user should be redirected to login here
        console.log('Error checking user Auth: ', e);
      }
    },
    async signUp(payload: ISignUp) {
      try {
        const response = await userSignUp(payload);
        return !response.notOk;
      } catch (err: any) {
        Snack.error(
          err.response.data.messages[0].message || 'Unknown error occured while trying to sign up.'
        );
        return false;
      }
    },
    async resendMail(payload: IEmail) {
      try {
        const response = await resendConfirmMail(payload);
        return !response.notOk;
      } catch (err: any) {
        Snack.error(
          err.response.data.messages[0].message ||
            'Unknown error occured while trying to resend the mail.'
        );
        return false;
      }
    },
    async createNewPassword({ user, code }: IChallangeAnswer) {
      try {
        const userResponse = await completeNewPassword({ user, code });
        await userActivate();
        Snack.success('Your password has been successfully updated.');
        setAuthed(AuthedState.AUTHED);
        setUser(userResponse);
      } catch (err: any) {
        Snack.error(err.message || 'Unknown error occured while trying to create password.');
      }
    },
    async forgotPassword({ username, code, newPassword }: IForgotPassword) {
      try {
        const userResponse = await forgotPasswordSubmit({ username, code, newPassword });
        if (userResponse) return true;
      } catch (err: any) {
        Snack.error(err.message || 'Unknown error occured while trying to create password.');
        return false;
      }
    },
    async resetPassword({ email }: IResetPassword) {
      try {
        await amplifyResetPassword({ email });
        Snack.success(
          "If the account exists, you'll receive an email with instructions to reset your password shortly. Please check your email."
        );
      } catch (e: any) {
        Snack.success(
          "If the account exists, you'll receive an email with instructions to reset your password shortly. Please check your email."
        );
      }
    },
    async updatePassword({ oldPassword, newPassword }: IUpdatePassword) {
      try {
        if (user) {
          await amplifyUpdatePassword({ user, oldPassword, newPassword });
          Snack.success('Your password has been successfully updated.');
          return true;
        }
      } catch (e: any) {
        Snack.error('Your old password does not match. Please try again.');
        return false;
      }
      return false;
    },

    createNewPing,
    async signIn({ email, password }: ISignIn) {
      setIsLoadingUser(true);
      try {
        const user = await amplifySignIn({ email, password });
        setAuthed(AuthedState.AUTHED);
        setUser(user);
      } catch ({ code, message }) {
        console.log(`Error occurred with code: ${code} and message: ${message}`);
        Snack.error(
          parseErrorMessage({
            code,
            message,
            defaultMessage: 'Invalid credentials. Please try again.',
          })
        );
      } finally {
        setIsLoadingUser(false);
      }
    },
    async answerCustomChallenge({ code }: IChallangeCode) {
      try {
        const cognitoUser = await amplifyAnswerCustomChallenge({ user, code });
        await amplifyCheckUserSession();
        setAuthed(AuthedState.AUTHED);
        setUser(cognitoUser);
        return true;
      } catch ({ code, message }) {
        console.log(`Error occurred with code: ${code} and message: ${message}`);
        Snack.error('The code is invalid. Please try again.');
        return false;
      }
    },
    async emailSignIn({ email }: IEmail) {
      try {
        const cognitoUser = await amplifyEmailSignIn({ email });
        setUser(cognitoUser);
      } catch (e: any) {
        let error;

        if (e.code === 'UserNotFoundException' || e.code === 'NotAuthorizedException') {
          error = 'One-time link not available. Please sign in with username/password.';
        } else if (e instanceof Error) {
          error = e.message;
        } else {
          error = 'Unknown error occurred while logging user with email.';
        }

        Snack.error(error);
      }
    },
    async updateUserEmail({ email }: IEmail) {
      try {
        await updateUserEmailAPI({ user, email });
        return true;
      } catch (error) {
        Snack.error(
          error instanceof Error
            ? error.message
            : 'Unknown error occurred while updating your email.'
        );
        return false;
      }
    },
    async checkUniqueEmail({ email }: IEmail) {
      try {
        const check = await checkUniqueEmailAPI({ email });
        return !check.notOk;
      } catch (err: any) {
        Snack.error(err.response.data.messages[0].message || 'Unknown error occurred.');
        return false;
      }
    },
    async verifyEmailUserCode({ code, email }: IChallangeCode & IEmail) {
      try {
        await verifyEmailUserCodeAPI({ code, email });
        return true;
      } catch (err: any) {
        Snack.error(
          err.response.data.messages[0].message || 'Unknown error occurred while trying to sign up.'
        );
        return false;
      }
    },
    async signOut(callback?: UnsavedChangesHandler) {
      if (isEditMode && callback) {
        callback({ onProceed: executeSignout });
      } else {
        executeSignout();
      }
    },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
