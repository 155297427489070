import { Navigate, useOutlet } from 'react-router-dom';
import Layout from './Layout';
import { useAuth } from '../context';
import { AuthedState } from '../context/types';

const LoggedOutLayout = () => {
  const { authed } = useAuth();
  const outlet = useOutlet();

  if (authed === AuthedState.AUTHED) {
    return <Navigate to="/onlinefiling/dashboard" replace />;
  }
  return <Layout>{outlet}</Layout>;
};

export default LoggedOutLayout;
