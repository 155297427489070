import { StyledActionButton } from '../../../../common/components/user-auth-components/common-auth-styles';

interface IModalActionsContentProps {
  handleClose: () => void;
}

export const DebitInfoDisplayAccountModalActions = ({ handleClose }: IModalActionsContentProps) => {
  return (
    <StyledActionButton autoFocus onClick={handleClose} variant="contained">
      Close
    </StyledActionButton>
  );
};
