import { lazy, Suspense, useEffect, useState } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { SnackbarProvider as NotistackProvider } from 'notistack';

import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import Loader from './common/components/loader';

import { AuthProvider, ModalProvider, UserInfoProvider } from './common/context';
import { NotFound } from './pages';
import ErrorBoundary from './common/components/error-boundary';
import AppInitializer from './common/components/app-initializer';
import amplifyConfig from './infrastructure/config';
import { iconVariant, NotificationComponents } from './common/notification';
import theme from './common/theme';
import 'react-dates/lib/css/_datepicker.css';
import './common/theme/react-dates-overrides.css';

import '@fontsource/roboto';
import '@fontsource/open-sans';
import UnexpectedError from './pages/UnexpectedError';
import { errorPage, notFound } from './common/constants/routes';
import { publicSearchRouteConfigs } from './infrastructure/routes/publicSearchRouteConfigs';
import { Layout } from './common/layouts';
import { Helmet } from 'react-helmet';

Amplify.configure(amplifyConfig);

const OnlineFiling = lazy(() => import('./infrastructure/routes/OnlineFiling'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorBoundary />}>
      {publicSearchRouteConfigs.map(({ path, element }) => (
        <Route
          key={path}
          path={path}
          errorElement={<UnexpectedError />}
          element={<Layout>{element}</Layout>}
        />
      ))}
      <Route
        path="/onlinefiling/*"
        element={
          <Suspense
            fallback={
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Loader color="blue" size={40} />
              </Box>
            }
          >
            <OnlineFiling />
          </Suspense>
        }
      />
      <Route path={notFound} element={<NotFound />} />
      <Route path="*" element={<Navigate to={notFound} />} />
      <Route path={errorPage} element={<UnexpectedError />} />
    </Route>
  )
);

function App() {
  const [hasFinishedLoadingFonts, setHasFinishedLoadingFonts] = useState(false);

  useEffect(() => {
    document.fonts.ready.then(() => {
      setHasFinishedLoadingFonts(true);
    });
  }, []);

  return (
    <div style={!hasFinishedLoadingFonts ? { visibility: 'hidden' } : {}}>
      <Helmet>
        <title>Welcome to the Florida Secured Transaction Registry</title>
      </Helmet>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <NotistackProvider
            maxSnack={5}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            iconVariant={iconVariant}
            Components={NotificationComponents}
          >
            <AuthProvider>
              <ModalProvider>
                <UserInfoProvider>
                  <RouterProvider router={router} />
                  <AppInitializer />
                </UserInfoProvider>
              </ModalProvider>
            </AuthProvider>
          </NotistackProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
