import {
  Grid,
  Box,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
  useTheme,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import type { FormData } from './StartUcc3FormComplete';
import type { ILoginFormUI } from '../../../common/models';
import { StyledSubmitButton } from '../../../common/components/user-auth-components/common-auth-styles';
import Loader from '../../../common/components/loader';

interface IStartUcc3FormCompleteUI extends ILoginFormUI<FormData> {
  filingId: string | null;
}

const StartUcc3FormCompleteUi = ({
  onSubmit,
  formState: { errors, isValid, isSubmitting },
  control,
  filingId,
}: IStartUcc3FormCompleteUI) => {
  const theme = useTheme();
  return (
    <Grid
      item
      md={12}
      sm={12}
      xs={12}
      sx={{ paddingLeft: { xs: 4, md: 0, lg: 3 }, paddingTop: { xs: 1, md: 1, lg: 0 } }}
    >
      <form onSubmit={onSubmit}>
        <Grid item md={12} lg={12} xl={12} pl={0}>
          <Controller
            control={control}
            name="check"
            render={({ field }) => (
              <FormGroup {...field}>
                <FormControlLabel
                  sx={{
                    alignItems: 'start',
                  }}
                  control={<Checkbox />}
                  label={
                    <Typography variant="h5" marginTop={theme.convert.pxToRem(7)}>
                      Check here if you want to file an Amendment Statement for the above mentioned
                      INITIAL FINANCING STATEMENT FILE NUMBER and click next button to continue.
                    </Typography>
                  }
                />
              </FormGroup>
            )}
          />
          <Box paddingLeft={4} mt={0}>
            <FormHelperText error={!!errors.check?.message}>{errors.check?.message}</FormHelperText>
          </Box>
        </Grid>
        <Box height={theme.convert.pxToRem(30)} />
        <Box display="flex" sx={{ justifyContent: { xs: 'center', sm: 'center', md: 'end' } }}>
          <Box width={220}>
            <StyledSubmitButton
              disabled={!isValid || isSubmitting || !filingId}
              type="submit"
              fullWidth
              startIcon={isSubmitting ? <Loader /> : null}
              variant="contained"
            >
              Next
            </StyledSubmitButton>
          </Box>
        </Box>
      </form>
    </Grid>
  );
};

export default StartUcc3FormCompleteUi;
