import { styled } from '@mui/material/styles';
import { colors } from '../../../constants';
import { Box } from '@mui/material';

interface StyledLogoBackgroundProps {
  background?: string;
}

export const StyledLogoBackground = styled(Box)<StyledLogoBackgroundProps>(
  ({ theme, background }) => ({
    textAlign: 'center',
    borderRadius: '50%',
    minWidth: theme.convert.pxToRem(24),
    minHeight: theme.convert.pxToRem(24),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: background || colors.defaultLogoBackground,
  })
);
