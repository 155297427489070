import { Box, styled } from '@mui/material';
import { colors } from '../../../common/constants';

export const StyledBoxTableWrap = styled(Box)`
  box-shadow: 0px 0px 8px rgba(${colors.boxShadowColor}, 0.5);
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.primaryLightGreyColor};
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.primaryDarkGreyColor};
    border-radius: 3px;
  }
`;
