import { useEffect, useState } from 'react';
import { UpdatePasswordModalContent } from '../../../common/components/modal-contents';

import { Box, useTheme } from '@mui/material';

import { hasErrors, passwordsMatch, passwordValidatorHelper } from '../../../common/helpers';
import type { PasswordValidatorHelperProps } from '../../../common/models';

import { useAuth, useModal } from '../../../common/context';
import { useSnackbar } from '../../../common/notification';
import { YesNoModal } from '../../../common/yes-no-modal';
import { StyledModalSubmitButton } from '../../../common/global-styles';
import { CloseIconButton } from '../../../common/global-styles/CloseIconButton';
import Loader from '../../../common/components/loader';

export const useUpdatePasswordModal = () => {
  const { handleModalOpen, handleModalClose, open } = useModal();
  const { updatePassword } = useAuth();
  const { Snack } = useSnackbar();
  const theme = useTheme();

  const [shouldOpenUnsavedChangesModal, setShouldOpenUnsavedChangesModal] = useState(false);

  const [errors, setErrors] = useState<PasswordValidatorHelperProps>({
    lowerCase: true,
    upperCase: true,
    specialChar: true,
    oneNumber: true,
    minLength: true,
  });
  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [isLoading, setisLoading] = useState(false);

  const { oldPassword, newPassword, confirmPassword } = passwords;

  const isOldPasswordEmpty = oldPassword.trim() === '';

  const yesAction = () => {
    setShouldOpenUnsavedChangesModal(false);
    handleModalClose();
    setPasswords({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
    setErrors({
      lowerCase: true,
      upperCase: true,
      specialChar: true,
      oneNumber: true,
      minLength: true,
    });
    Snack.info('Action canceled.');
  };

  const cancelHandler = () => {
    if (oldPassword !== '' || newPassword !== '' || confirmPassword !== '') {
      setShouldOpenUnsavedChangesModal(true);
    } else {
      handleModalClose();
    }
  };

  const xButtonAction = (
    <CloseIconButton
      onClick={() => {
        cancelHandler();
      }}
    />
  );

  useEffect(() => {
    if (open) {
      handleModalOpen({
        xButtonAction,
        modalContent: (
          <>
            <UpdatePasswordModalContent
              errors={errors}
              onPasswordChangeHandler={onPasswordChangeHandler}
              passwords={passwords}
              onHandleSubmit={onHandleSubmit}
            />
            <YesNoModal
              open={shouldOpenUnsavedChangesModal}
              handleModalClose={() => setShouldOpenUnsavedChangesModal(false)}
              noAction={() => setShouldOpenUnsavedChangesModal(false)}
              yesAction={yesAction}
            />
          </>
        ),
        modalActions: (
          <Box
            sx={{
              display: 'flex',
              gap: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(34) },
              flexDirection: 'row-reverse',
            }}
          >
            <StyledModalSubmitButton
              variant="contained"
              onClick={onHandleSubmit}
              startIcon={isLoading ? <Loader /> : null}
              disabled={
                isLoading ||
                isOldPasswordEmpty ||
                hasErrors(errors) ||
                passwordsMatch(newPassword, confirmPassword)
              }
            >
              {isLoading ? 'Saving' : 'Save'}
            </StyledModalSubmitButton>
            <StyledModalSubmitButton variant="outlined" onClick={cancelHandler}>
              Cancel
            </StyledModalSubmitButton>
          </Box>
        ),
      });
    }
  }, [oldPassword, newPassword, confirmPassword, errors, isLoading, shouldOpenUnsavedChangesModal]);

  const onPasswordChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setPasswords((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });

    if (name === 'newPassword') setErrors(passwordValidatorHelper(value));
  };

  const onHandleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { oldPassword, newPassword } = passwords;
    setisLoading(true);

    const res = await updatePassword({ oldPassword, newPassword });
    setisLoading(false);
    if (res) {
      handleModalClose();
      setPasswords({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
      setErrors({
        lowerCase: true,
        upperCase: true,
        specialChar: true,
        oneNumber: true,
        minLength: true,
      });
    }
  };

  const handleOpenModal = () => {
    handleModalOpen({
      title: 'Update Password',
      xButtonAction,
      modalContent: (
        <>
          <UpdatePasswordModalContent
            errors={errors}
            onPasswordChangeHandler={onPasswordChangeHandler}
            passwords={passwords}
            onHandleSubmit={onHandleSubmit}
          />
          <YesNoModal
            open={shouldOpenUnsavedChangesModal}
            handleModalClose={() => setShouldOpenUnsavedChangesModal(false)}
            noAction={() => setShouldOpenUnsavedChangesModal(false)}
            yesAction={yesAction}
          />
        </>
      ),
      modalActions: (
        <Box
          sx={{
            display: 'flex',
            gap: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(34) },
            flexDirection: 'row-reverse',
          }}
        >
          <StyledModalSubmitButton
            variant="contained"
            onClick={onHandleSubmit}
            startIcon={isLoading ? <Loader /> : null}
            disabled={
              isLoading ||
              isOldPasswordEmpty ||
              hasErrors(errors) ||
              passwordsMatch(newPassword, confirmPassword)
            }
          >
            Save
          </StyledModalSubmitButton>
          <StyledModalSubmitButton variant="outlined" onClick={cancelHandler}>
            Cancel
          </StyledModalSubmitButton>
        </Box>
      ),
    });
  };

  return { handleOpenModal };
};
