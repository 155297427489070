import { Typography, styled, Box, Button, useTheme } from '@mui/material';

import { ReactComponent as Sunbiz } from '../../../../assets/icons/sunbiz.svg';
import { ReactComponent as Bankers } from '../../../../assets/icons/bankers.svg';

interface StyledPartnerInfoProps {
  gap?: string;
}

const StyledPartnerInfo = styled(Box)<StyledPartnerInfoProps>(({ theme, gap }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: gap || theme.convert.pxToRem(7),
}));

export function PartnerInfo() {
  const theme = useTheme();
  return (
    <StyledPartnerInfo
      gap={theme.convert.pxToRem(15)}
      alignItems={{ xs: 'center', md: 'flex-start' }}
    >
      <Typography variant="h5" color="text.primary">
        Partner with
      </Typography>
      <a tabIndex={-1} href="https://dos.myflorida.com/sunbiz/" target="_blank" rel="noreferrer">
        <Button sx={{ padding: 0 }}>
          <Sunbiz title="Sunbiz" />
        </Button>
      </a>
      <a tabIndex={-1} href="https://www.floridabankers.com/" target="_blank" rel="noreferrer">
        <Button sx={{ padding: 0 }}>
          <Bankers title="Bankers" />
        </Button>
      </a>
    </StyledPartnerInfo>
  );
}
