import { Box, Typography, useTheme } from '@mui/material';
import { IPageWrapper } from '../models/features/models';
import { Helmet } from 'react-helmet';

const PageWrapper = ({ children, title, subtitle, headTitle, headDescription }: IPageWrapper) => {
  const theme = useTheme();
  return (
    <>
      <Helmet>
        {headTitle && <title>UCC {headTitle}</title>}
        {headDescription && <meta name="description" content={headDescription} />}
      </Helmet>
      <Box
        display="flex"
        flexDirection="column"
        paddingX={{
          xs: theme.convert.pxToRem(12),
          sm: theme.convert.pxToRem(12),
          md: theme.convert.pxToRem(28),
          lg: theme.convert.pxToRem(60),
          xl: theme.convert.pxToRem(150),
        }}
        paddingBottom={theme.convert.pxToRem(40)}
        marginX="auto"
      >
        {title && (
          <>
            <Typography variant="h1" component="h1" sx={{ textTransform: 'capitalize' }}>
              {title}
            </Typography>
            <Box sx={{ height: subtitle ? 'auto' : theme.convert.pxToRem(20) }} />
          </>
        )}
        {subtitle && (
          <>
            <Box
              sx={{
                height: theme.convert.pxToRem(20),
                display: 'flex',
                justifyContent: { xs: 'flex-start', md: 'flex-end' },
              }}
            >
              {subtitle}
            </Box>
            <Box sx={{ height: theme.convert.pxToRem(12) }} />
          </>
        )}
        {children}
      </Box>
    </>
  );
};
export default PageWrapper;
