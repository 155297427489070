import * as yup from 'yup';

export const schema = yup.object({
  newPassword: yup.string().required('Password is required.'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Password and confirm password must match.')
    .required('Confirm password is required.'),
});

export type FormData = yup.InferType<typeof schema>;
