import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import { colors } from '../../constants';

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  borderLeft: '4px solid transparent',
  paddingRight: theme.convert.pxToRem(35),
  height: theme.convert.pxToRem(45),
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
    borderLeft: `4px solid ${colors.highlightSelectionColor}`,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.grey[200],
    borderLeft: `4px solid ${colors.highlightSelectionColor}`,
  },
}));
