export const dashboard = '/onlinefiling/dashboard';
export const login = '/onlinefiling/login';
export const createPassword = '/onlinefiling/create-password';
export const myAccount = '/onlinefiling/my-account';
export const cart = '/onlinefiling/cart';
export const filing = '/onlinefiling/filing';
export const ucc1 = `${filing}/ucc1/:filingId`;
export const ucc3 = `${filing}/ucc3/:filingId`;
export const register = '/onlinefiling/register';
export const checkoutBase = `${cart}/checkout`;
export const checkout = `${checkoutBase}/:paymentId`;
export const confirmationBase = `${cart}/confirmation`;
export const confirmation = `${confirmationBase}/:paymentId`;
export const notFound = '/not-found';
export const paymentInProgress = '/onlinefiling/payment-in-progress';
export const errorPage = '/error';

//public search
export const homePageURL = '/';
export const feesPageURL = '/fees';
export const helpPageURL = '/help';
export const formsPageURL = '/forms';
export const uccSearchPageURL = '/search';
export const uccSearchDetailsPageURL = '/search/details';
export const uccSearchDetailsSecuredPartiesURL = '/search/details/secured-parties';
export const uccSearchDetailsFilingHistoryURL = '/search/details/filing-history';
export const uccSearchDetailsDebtorPartiesURL = '/search/details/debtor-parties';
export const downloadPageURL = '/download';
export const achAccountPageURL = '/ach-accounts';
export const termsOfUsePageURL = '/terms-of-use';
export const privacyPolicyPageURL = '/privacy-policy';
export const announcementsPageURL = '/announcements';
export const xmlImplementationGuidePageURL = '/xml-implementation-guide';
