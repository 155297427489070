import { useEffect, useState } from 'react';
import { useModal, useAuth } from '../../../common/context';
import { ACCOUNT_NUMBER_TIMEOUT_SECONDS } from '../../../common/constants/timers';
import type { IChallangeCode } from '../../../common/models';
import { CloseIconButton } from '../../../common/global-styles/CloseIconButton';
import { UpdateEmailTimerContent } from '../forms/personal-security-forms/UpdateEmailTimerContent';
import { UpdateEmailFormActions } from '../forms/personal-security-forms/UpdateEmailFormActions';
import { UpdateConfirmCodeForm } from '../forms/personal-security-forms/UpdateConfirmCodeForm';
import { UpdateEmailForm } from '../forms/personal-security-forms/UpdateEmailForm';

export const useUpdateAccountEmail = () => {
  const [emailState, setEmailState] = useState('');
  const [counter, setCounter] = useState(ACCOUNT_NUMBER_TIMEOUT_SECONDS);
  const [shouldCount, setShouldCount] = useState(false);
  const [shouldCountModalOpen, setShouldCountModalOpen] = useState(false);
  const { handleModalOpen, handleSetModalContent, handleModalClose, handleSetModalXButton } =
    useModal();
  const { updateUserEmail, verifyEmailUserCode, signOut, checkUniqueEmail } = useAuth();

  const onSignOut = () => {
    handleModalClose();
    signOut();
  };

  const xButtonAction = (
    <CloseIconButton
      onClick={() => {
        setShouldCountModalOpen(false);
        handleModalClose();
      }}
      aria-label="close"
    />
  );

  const onSubmitCodeForm = async (props: IChallangeCode) => {
    const email = emailState;
    const { code } = props;

    const res = await verifyEmailUserCode({ code, email });
    setEmailState('');
    if (!res) return;
    setCounter(ACCOUNT_NUMBER_TIMEOUT_SECONDS);
    setShouldCount(true);
    setShouldCountModalOpen(true);
    handleModalOpen({
      title: 'Sign in using your new email',
      xButtonAction: xButtonAction,
      modalContent: <UpdateEmailTimerContent counter={counter} />,
      modalActions: (
        <UpdateEmailFormActions onSubmit={onSignOut} primaryButtonText="Sign out now" isValid />
      ),
    });
  };

  const onSubmitEmail = async (props: { newEmail: string; confirmEmail: string }) => {
    const email = props.newEmail;
    const checkResponse = await checkUniqueEmail({ email });
    if (checkResponse) {
      const res = await updateUserEmail({ email });
      if (res) {
        setEmailState(email);
      }
    }
  };

  useEffect(() => {
    if (emailState) {
      handleModalOpen({
        title: 'Verify your new email',
        modalContent: <UpdateConfirmCodeForm onSubmit={onSubmitCodeForm} />,
      });
    }
  }, [emailState, setEmailState]);

  const onHandleOpenModal = () => {
    if (!shouldCount) {
      setEmailState('');
      handleModalOpen({
        title: 'Update Email',
        modalContent: <UpdateEmailForm onSubmit={onSubmitEmail} />,
      });
    } else {
      setShouldCountModalOpen(true);
      handleModalOpen({
        title: 'Sign in using your new email',
        xButtonAction: xButtonAction,
        modalContent: <UpdateEmailTimerContent counter={counter} />,
        modalActions: (
          <UpdateEmailFormActions onSubmit={onSignOut} primaryButtonText="Sign out now" isValid />
        ),
      });
    }
  };

  useEffect(() => {
    if (counter <= 0) {
      setCounter(0);
      setShouldCount(false);
      setShouldCountModalOpen(false);
      handleModalClose();
      signOut();
    }
    if (shouldCountModalOpen) {
      handleSetModalContent(<UpdateEmailTimerContent counter={counter} />);
      handleSetModalXButton && handleSetModalXButton(xButtonAction);
    }
  }, [counter]);

  useEffect(() => {
    if (shouldCount) {
      const intervalID = setInterval(() => {
        setCounter((prevCount) => {
          const counter = prevCount - 1;
          return counter;
        });
      }, 1000);
      return () => clearInterval(intervalID);
    }
  }, [shouldCount]);

  return { onHandleOpenModal };
};
