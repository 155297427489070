import { FC, Fragment } from 'react';

export interface TextSection {
  title?: string;
  paragraphs?: string[];
}

type ComponentWithChildren = FC<Record<string, unknown>>;

interface Props {
  data: TextSection[];
  SubTitle: ComponentWithChildren;
  Paragraph: ComponentWithChildren;
  DocumentTitle: ComponentWithChildren;
}

export const TextDocument = ({ data, Paragraph, DocumentTitle, SubTitle }: Props) => {
  return (
    <>
      {data.map(({ title, paragraphs }, idx) => (
        <Fragment key={title}>
          {title && (
            <div>{idx ? <SubTitle>{title}</SubTitle> : <DocumentTitle>{title}</DocumentTitle>}</div>
          )}
          {paragraphs?.map((p) => (
            <Paragraph key={p.slice(0, 10)}>{p.replace(/\s+/g, ' ')}</Paragraph>
          ))}
        </Fragment>
      ))}
    </>
  );
};
