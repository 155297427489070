import {
  Box,
  Grid,
  GridProps,
  TableContainer,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material';
import theme from '../../../common/theme';
import { NavLink } from 'react-router-dom';

export const StyledTableHeadingGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  height: theme.convert.pxToRem(48),
  padding: `${theme.convert.pxToRem(12)} ${theme.convert.pxToRem(40)}`,
  fontWeight: 600,
}));

export const StyledTableDescriptionHeadingGrid = styled(Grid)(({ theme }) => ({
  height: 48,
  padding: `${theme.convert.pxToRem(8)} ${theme.convert.pxToRem(40)}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'end',
}));

export const StyledTableContentGrid = styled(Grid)<GridProps>(({ theme }) => ({
  minHeight: 48,
  padding: `${theme.convert.pxToRem(12)} ${theme.convert.pxToRem(40)}`,
}));

export const StyledNavigationBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 1,
  alignItems: 'center',
}));

export const StyledNavigationTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.info.main,
  textDecoration: 'underline',
  fontWeight: 600,
}));

export const StyledTableContainer = styled(TableContainer)<{
  component?: React.ElementType;
  variant?: string;
}>`
  border: 1px solid ${theme.palette.grey[200]};
  background-color: ${theme.palette.common.white};
  filter: drop-shadow(0px 0px 8px rgba(176, 192, 237, 0.5));
`;
