import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

/*
This hook is designed to address particular types of input validation errors.
Specifically, it focuses on identifying cases where the input is considered invalid
due to the presence of a "is required" error.
By doing this, we are restricting our code's handling logic to specific situations,
such as those involving the "Save" validation of Ucc1 and Ucc3 forms.

In the context of Save validation, we display a snackbar with the message "Oops.." only if there are invalid fields.
However, in this particular case, we do not display the "required" error message since it is not considered "hard" invalid
case, but "soft" invalid case. Example of "hard" invalid cases "Phone number is not in the right format" or
"Email format is not correct".
 * */

type FormStateProps = ReturnType<typeof useForm>['formState'];
export const useHasInvalidFields = (formState: FormStateProps) => {
  const hasInvalidFields = useMemo(
    () => Object.values(formState.errors ?? {}).some((a) => a?.type !== 'required'),
    [formState]
  );
  return hasInvalidFields;
};
