import { StyledLogoBackground } from './StyledLogoBackground';
import { StyledLogoTextContainer } from './StyledLogoTextContainer';
import { Typography, styled, Box, useTheme } from '@mui/material';
import { colors } from '../../../constants';
import { StyledAnchor } from '../../header/StyledAnchor';

import { ReactComponent as Person } from '../../../../assets/icons/person.svg';
import { ReactComponent as Location } from '../../../../assets/icons/location.svg';
import { ReactComponent as Email } from '../../../../assets/icons/email.svg';
import { ReactComponent as Phone } from '../../../../assets/icons/phone.svg';
import { StyledTypography } from './StyledTypography';

const StyledPublicRecordsInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.convert.pxToRem(7),
}));

export function PublicRecordsInfo() {
  return (
    <StyledPublicRecordsInfo>
      <StyledLogoTextContainer>
        <StyledLogoBackground background={colors.defaultBackground} />
        <Typography variant="h5">Custodian of Public Records</Typography>
      </StyledLogoTextContainer>
      <StyledLogoTextContainer>
        <StyledLogoBackground>
          <Person title="Person" />
        </StyledLogoBackground>
        <StyledTypography whiteSpace="nowrap">
          Aaron Hall, Custodian of Public Records
        </StyledTypography>
      </StyledLogoTextContainer>
      <StyledLogoTextContainer>
        <StyledLogoBackground>
          <Location title="Location" />
        </StyledLogoBackground>
        <StyledTypography>
          2002 Old St. Augustine Rd. Bldg. D Tallahassee, FL 32301
        </StyledTypography>
      </StyledLogoTextContainer>
      <StyledLogoTextContainer>
        <StyledLogoBackground>
          <Email title="Email" />
        </StyledLogoBackground>
        <StyledAnchor tabIndex={-1} href="mailto:help@floridaucc.com">
          <StyledTypography>help@floridaUCC.com</StyledTypography>
        </StyledAnchor>
      </StyledLogoTextContainer>
      <StyledLogoTextContainer>
        <StyledLogoBackground>
          <Phone title="Phone" />
        </StyledLogoBackground>
        <StyledTypography>850.222.8526</StyledTypography>
      </StyledLogoTextContainer>
    </StyledPublicRecordsInfo>
  );
}
