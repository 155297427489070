import { useState, useEffect } from 'react';
import { useMediaQuery, Theme } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import { useLocation } from 'react-router-dom';
import ResetPasswordForm from './forms/reset-password-form';
import TabsUI from '../../common/components/tabs/TabsUI';
import PasswordLoginForm from './forms/password-login-form';
import EmailLoginForm from './forms/email-login-form';

const tabLabels = ['Sign In with Email', 'Sign In with Password', 'Reset Password'];
const tabPanels = [
  <EmailLoginForm key={0} />,
  <PasswordLoginForm key={1} />,
  <ResetPasswordForm key={2} />,
];

export default function LoginUI() {
  const [tabId, setTabId] = useState(1);
  const [showTabs, setShowTabs] = useState(true);
  const location = useLocation();

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    setShowTabs(!isSmallScreen);
  }, [isSmallScreen]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('resetPassword')) {
      setTabId(2);
    }
  }, [location]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabId(newValue);
  };

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    setTabId(Number(event.target.value as string));
  };

  return (
    <TabsUI
      tabLabels={tabLabels}
      tabPanels={tabPanels}
      handleChange={handleChange}
      handleSelectChange={handleSelectChange}
      tabId={tabId}
      showTabs={showTabs}
    />
  );
}
