import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

interface StyledNavLinkProps {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const StyledNavLink = styled(NavLink)<StyledNavLinkProps>(({ theme }) => ({
  textDecoration: 'none',
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.convert.pxToRem(3),
  },
  svg: {
    color: theme.palette.text.primary,
  },
  '&.active': {
    borderBottom: `3px solid ${theme.palette.info.dark}`,
    svg: {
      color: theme.palette.info.dark,
    },
  },
}));
