import { Button, ButtonProps, styled } from '@mui/material';

export const StyledUnderlineDownloadButtons = styled(Button)<ButtonProps>(({ theme }) => ({
  fontFamily: 'Open Sans, sans-serif',
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 400,
  lineHeight: theme.typography.pxToRem(24),
  textDecoration: 'underline',
  textTransform: 'none',
  padding: `0 0 ${theme.convert.pxToRem(4)} 0`,
  minWidth: 0,
  verticalAlign: 'unset',
}));
