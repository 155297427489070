import { useNavigate } from 'react-router-dom';
import { Grid, Button, Typography } from '@mui/material';
import { StyledClickHereGrid } from './StyledClickHereGrid';
import LoginUI from '../features/login/LoginUi';

const Login = () => {
  const navigate = useNavigate();
  return (
    <>
      <Typography align="center" variant="h1" pb={7} color="text.primary">
        Welcome to the Florida Secured Transaction Registry
      </Typography>
      <LoginUI />
      <StyledClickHereGrid>
        <Grid pt={0.5} pb={10} container alignItems="center">
          <Grid item xs={8} md={4} lg={3}>
            <Typography variant="h4" fontWeight={400}>
              Don&apos;t have an account?
            </Typography>
          </Grid>
          <Grid item xs={4} md={8} lg={9}>
            <Button
              sx={{ whiteSpace: 'nowrap' }}
              variant="text"
              onClick={() => navigate('/onlinefiling/register')}
            >
              Register Here
            </Button>
          </Grid>
        </Grid>
      </StyledClickHereGrid>
    </>
  );
};

export default Login;
