import { useState } from 'react';
import { getDownload } from './download.services';
import { FormDataType } from './FormDataType';
import { useSnackbar } from '../../common/notification';

export const useDownload = () => {
  const [downloadFile, setDownloadFile] = useState<URL | null>(null);

  const { Snack } = useSnackbar();
  const getDownloadFile = async (request: FormDataType) => {
    try {
      const result = await getDownload(request);
      setDownloadFile(result?.payload);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (errors: any) {
      setDownloadFile(null);
      Snack.error(errors?.response?.data?.friendlyMessageSummary);
    }
  };

  return { getDownloadFile, downloadFile };
};
