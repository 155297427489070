import React from 'react';
import { Grid } from '@mui/material';
import { TableCellItem } from './start-ucc3-filing/TableCellItem';
import { DebSecNamesType } from './models';
import theme from '../../common/theme';

export const listDebtorSecuredNames = (debSecData: DebSecNamesType[], borderColor: string) => {
  let counter = 0;
  const itemList: React.ReactNode[] = [];
  const borderCheck = debSecData.length % 2 === 0 ? 3 : 2;

  debSecData.forEach((item: DebSecNamesType, index: number) => {
    const border = debSecData.length - index < borderCheck ? 'none' : `1px solid ${borderColor}`;
    if (counter === 0 || counter % 3 === 0) {
      if (counter % 3 === 0) counter = 1;
      itemList.push(
        <React.Fragment key={item.id}>
          <Grid
            item
            md={4}
            xs={4}
            borderBottom={border}
            paddingY={theme.convert.pxToRem(15)}
          ></Grid>
          <TableCellItem
            border={border}
            name={item.name}
            addressLine1={item.addressLine1}
            addressLine2={item.addressLine2}
            city={item.city}
            state={item.state}
            zipCode={item.zipCode}
          />
        </React.Fragment>
      );
    } else {
      itemList.push(
        <TableCellItem
          key={item.id}
          border={border}
          name={item.name}
          addressLine1={item.addressLine1}
          addressLine2={item.addressLine2}
          city={item.city}
          state={item.state}
          zipCode={item.zipCode}
        />
      );
    }
    counter++;
  });
  return itemList;
};
