import { Box, Typography, useTheme } from '@mui/material';

import { itemDeviderBorder } from '../../../common/constants/colors';

import { IDebtorsOrSecuredPartiesHeaderProps } from '../models';

const DebtorsOrSecuredPartiesHeader = ({
  title,
  hasBorderTop,
}: IDebtorsOrSecuredPartiesHeaderProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderBottom: `1px solid ${itemDeviderBorder}`,
        borderTop: `${hasBorderTop ? `1px solid ${itemDeviderBorder}` : ''}`,
        paddingY: theme.convert.pxToRem(12),
        paddingX: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(40) },
      }}
    >
      <Typography variant="h5">{title}</Typography>
    </Box>
  );
};

export default DebtorsOrSecuredPartiesHeader;
