import { API, Auth } from 'aws-amplify';
import type {
  ISignIn,
  ISignUp,
  IEmail,
  IChallangeAnswer,
  IResetPassword,
  IForgotPassword,
  ICognitoUser,
  IChallangeCode,
  IUpdatePasswordAmplify,
} from '../../common/models';

const apiName = 'OnlineFilingAPI';
const path = '/Ping';
const myInit = {
  // headers: {
  //   Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
  // },
};

export const signIn = ({ email, password }: ISignIn) => {
  return Auth.signIn(email, password);
};

export const emailSignIn = ({ email }: IEmail) => {
  return Auth.signIn(email);
};

export const answerCustomChallenge = ({ user, code }: IChallangeAnswer) => {
  return Auth.sendCustomChallengeAnswer(user, code);
};

export const signOut = () => {
  return Auth.signOut();
};

export const createNewPing = () => {
  return API.post(apiName, path, myInit);
};

export const checkUserSession = () => {
  return Auth.currentSession();
};

export const checkAuthUser = () => {
  return Auth.currentAuthenticatedUser();
};

export const completeNewPassword = ({ user, code }: IChallangeAnswer) => {
  return Auth.completeNewPassword(user, code);
};

export const forgotPasswordSubmit = ({ username, code, newPassword }: IForgotPassword) => {
  return Auth.forgotPasswordSubmit(username, code, newPassword);
};

export const updatePassword = ({ user, oldPassword, newPassword }: IUpdatePasswordAmplify) => {
  return Auth.changePassword(user, oldPassword, newPassword);
};

export const getUserData = () => {
  return API.get(apiName, '/me', myInit);
};

export const signUp = (payload: ISignUp) => {
  return API.post('OnlineFilingSignUpAPI', '/signup', { body: payload });
};

export const resendMail = (payload: IEmail) => {
  return API.post('OnlineFilingSignUpAPI', '/resend-confirmation-mail', { body: payload });
};

export const resetPassword = ({ email }: IResetPassword) => {
  return Auth.forgotPassword(email);
};

export const getUserSettings = () => {
  return API.get(apiName, '/Settings', myInit);
};

export const userActivate = () => {
  return API.put(apiName, '/user-activate', myInit);
};

export const updateUserEmailAPI = ({ user, email }: ICognitoUser & IEmail) => {
  return Auth.updateUserAttributes(user, { email });
};

export const verifyEmailUserCodeAPI = async ({ code, email }: IChallangeCode & IEmail) => {
  const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
  const payload = {
    code,
    email,
    accessToken,
  };
  return API.post(apiName, '/Users/confirm-email-change', { body: payload });
};

export const checkUniqueEmailAPI = ({ email }: IEmail) => {
  return API.get(apiName, `/Users/${email}/unique-email`, myInit);
};
