import { Button, LinkProps, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { colors } from '../../../common/constants';

export const StyledInheritButton = styled(Button)(({ theme }) => ({
  padding: theme.convert.pxToRem(0),
  paddingBottom: theme.convert.pxToRem(2.5),
  minWidth: theme.convert.pxToRem(0),
  fontSize: 'inherit',
  lineHeight: 'inherit',
}));

export const StyledInheritLink = styled(Link)<LinkProps>(({ theme }) => ({
  textDecoration: 'none',
  color: colors.textButtonDefaultColor,
  fontWeight: theme.typography.fontWeightBold,
  transition: 'text-decoration 0.3s ease',
  '&:hover': {
    textDecoration: 'underline',
  },
}));
