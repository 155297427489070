import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, styled } from '@mui/material';

import { StyledLayout } from './StyledLayout';
import Header from '../components/header';
import Footer from '../components/footer';

import type { ILayoutWrapperProps } from '../models';

export const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    paddingTop: '16px',
  },
}));

const Layout = ({ children }: ILayoutWrapperProps) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [pathname, search]);

  return (
    <StyledLayout>
      <Header />
      <Box sx={{ height: { xs: 70, md: 122 } }} />
      <StyledBox sx={{ flexGrow: 1 }}>{children}</StyledBox>
      <Footer />
    </StyledLayout>
  );
};

export default Layout;
