import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { IDebSecModalContentUI } from './models';
import { StyledStartUccRadioButton } from '../../start-ucc-filing/styled-items/StyledStartUccFilingRadioButton';
import { CountryType, NameType } from '../../../common/models/features/models';

const DebSecModalContentUi = ({
  partyName,
  selectedNameType,
  statesList,
  isForeign,
  formState: { errors },
  control,
  handleSubmit,
  handleChange,
}: IDebSecModalContentUI) => {
  const theme = useTheme();
  const isOrganizationSelected = selectedNameType === NameType.organization;

  return (
    <form id="debsec-modal-form" onSubmit={handleSubmit}>
      <Typography variant="h4" style={{ fontWeight: 600 }}>
        {partyName} Details
      </Typography>
      <Box sx={{ height: 8 }} />
      <InputLabel htmlFor="nameType">
        <Typography variant="body2">
          Enter the {partyName.toLowerCase()}&#39;s name. Insert only one name per entry.
        </Typography>
      </InputLabel>
      <Box sx={{ height: 12 }} />
      <Controller
        name="nameType"
        control={control}
        render={({ field: { ref, ...field } }) => {
          return (
            <RadioGroup
              {...field}
              ref={ref}
              aria-labelledby="party-type-radio-buttons-group-label"
              value={selectedNameType}
              onChange={handleChange}
            >
              <FormControlLabel
                value={NameType.organization}
                label={<Typography variant="h5">{NameType.organization}</Typography>}
                control={<StyledStartUccRadioButton />}
                sx={{ width: 'fit-content' }}
              />
              <Box sx={{ height: 12 }} />
              <Grid container rowGap={0.5} sx={{ paddingLeft: theme.convert.pxToRem(30) }}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{ display: isOrganizationSelected ? 'flex' : 'none', alignItems: 'center' }}
                >
                  <InputLabel htmlFor="organization-name">
                    <Typography variant="h5">Organization Name *</Typography>
                  </InputLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  sx={{ display: isOrganizationSelected ? 'inline-block' : 'none' }}
                >
                  <Controller
                    name="name"
                    shouldUnregister={!isOrganizationSelected}
                    control={control}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <TextField
                          autoFocus
                          fullWidth
                          {...field}
                          inputRef={ref}
                          error={!!errors.name?.message}
                          id="organization-name"
                          placeholder="Enter organization name"
                          inputProps={{ style: { textTransform: 'uppercase' } }}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
              {isOrganizationSelected && !!errors.name?.message && (
                <Grid
                  container
                  alignItems="center"
                  sx={{
                    alignItems: 'center',
                    paddingLeft: theme.convert.pxToRem(30),
                  }}
                >
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={8}>
                    <FormHelperText
                      id="organization-name-helper-text"
                      error={!!errors.name?.message}
                    >
                      {(errors.name as any)?.message}
                    </FormHelperText>
                  </Grid>
                </Grid>
              )}
              <Box sx={{ height: { xs: 0, md: 20 } }} />
              <FormControlLabel
                value={NameType.individual}
                label={<Typography variant="h5">{NameType.individual}</Typography>}
                control={<StyledStartUccRadioButton />}
                sx={{ width: 'fit-content' }}
              />
              <Grid
                container
                sx={{
                  paddingLeft: theme.convert.pxToRem(30),
                  display: isOrganizationSelected ? 'none' : 'flex',
                }}
              >
                <Grid item xs={12} md={4.2} sx={{ display: 'flex', alignItems: 'center' }}>
                  <InputLabel htmlFor="lastName">
                    <Typography variant="h5">Surname *</Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={7.8}>
                  <Controller
                    name="lastName"
                    shouldUnregister={isOrganizationSelected}
                    control={control}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <TextField
                          autoFocus
                          fullWidth
                          {...field}
                          inputRef={ref}
                          error={!!errors.lastName?.message}
                          id="lastName"
                          placeholder="Enter surname"
                          inputProps={{ style: { textTransform: 'uppercase' } }}
                        />
                      );
                    }}
                  />
                </Grid>
                {!isOrganizationSelected && !!errors.lastName?.message && (
                  <Grid
                    container
                    alignItems="center"
                    sx={{
                      alignItems: 'center',
                    }}
                  >
                    <Grid item xs={12} md={4.2}></Grid>
                    <Grid item xs={12} md={7.8}>
                      <FormHelperText id="surname-helper-text" error={!!errors.lastName?.message}>
                        {(errors.lastName as any)?.message}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                )}
                <Box sx={{ marginTop: { xs: 0.5, md: 2.5 } }} width={1}></Box>
                <Grid item xs={12} md={4.2} sx={{ display: 'flex', alignItems: 'center' }}>
                  <InputLabel htmlFor="first-personal-name">
                    <Typography variant="h5">First Personal Name *</Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={7.8}>
                  <Controller
                    name="firstName"
                    shouldUnregister={isOrganizationSelected}
                    control={control}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <TextField
                          fullWidth
                          {...field}
                          inputRef={ref}
                          error={!!errors.firstName?.message}
                          id="first-personal-name"
                          placeholder="Enter first personal name"
                          inputProps={{ style: { textTransform: 'uppercase' } }}
                        />
                      );
                    }}
                  />
                </Grid>
                {!isOrganizationSelected && !!errors.firstName?.message && (
                  <Grid
                    container
                    alignItems="center"
                    sx={{
                      alignItems: 'center',
                    }}
                  >
                    <Grid item xs={12} md={4.2}></Grid>
                    <Grid item xs={12} md={7.8}>
                      <FormHelperText
                        id="first-personal-name-helper-text"
                        error={!!errors.firstName?.message}
                      >
                        {(errors.firstName as any)?.message}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                )}
                <Box sx={{ marginTop: { xs: 0.5, md: 2.5 } }} width={1}></Box>
                <Grid item xs={12} md={4.2} sx={{ display: 'flex', alignItems: 'center' }}>
                  <InputLabel htmlFor="additional-name">
                    <Typography variant="h5">Additional Name(s)/Initial(s)</Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={7.8}>
                  <Controller
                    name="middleName"
                    shouldUnregister={isOrganizationSelected}
                    control={control}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <TextField
                          fullWidth
                          {...field}
                          inputRef={ref}
                          error={!!errors.middleName?.message}
                          id="additional-name"
                          placeholder="Enter additional names or initials"
                          inputProps={{ style: { textTransform: 'uppercase' } }}
                        />
                      );
                    }}
                  />
                </Grid>
                {!isOrganizationSelected && !!errors.middleName?.message && (
                  <Grid
                    container
                    alignItems="center"
                    sx={{
                      alignItems: 'center',
                    }}
                  >
                    <Grid item xs={12} md={4.2}></Grid>
                    <Grid item xs={12} md={7.8}>
                      <FormHelperText
                        id="additional-name-helper-text"
                        error={!!errors.middleName?.message}
                      >
                        {(errors.middleName as any)?.message}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                )}
                <Box sx={{ marginTop: { xs: 0.5, md: 2.5 } }} width={1}></Box>
                <Grid item xs={12} md={4.2} sx={{ display: 'flex', alignItems: 'center' }}>
                  <InputLabel htmlFor="Suffix">
                    <Typography variant="h5">Suffix</Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={7.8}>
                  <Controller
                    name="suffix"
                    shouldUnregister={isOrganizationSelected}
                    control={control}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <TextField
                          fullWidth
                          {...field}
                          inputRef={ref}
                          error={!!errors.suffix?.message}
                          id="Suffix"
                          placeholder="Enter Suffix"
                          inputProps={{ style: { textTransform: 'uppercase' } }}
                        />
                      );
                    }}
                  />
                </Grid>
                {!isOrganizationSelected && !!errors.suffix?.message && (
                  <Grid
                    container
                    alignItems="center"
                    sx={{
                      alignItems: 'center',
                    }}
                  >
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={8}>
                      <FormHelperText id="Suffix-helper-text" error={!!errors.suffix?.message}>
                        {(errors.suffix as any)?.message}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </RadioGroup>
          );
        }}
      />
      <Box sx={{ height: { xs: 14, md: 28 } }} />
      <Typography variant="h4" style={{ fontWeight: 600 }}>
        Address Details
      </Typography>
      <Box sx={{ height: 8 }} />
      <Typography variant="body2">Enter the address associated with this {partyName}.</Typography>
      <Box sx={{ height: 12 }} />
      <FormGroup>
        <FormControlLabel
          sx={{ width: 'fit-content' }}
          control={
            <Controller
              name="isForeign"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => {
                return (
                  <Checkbox
                    {...field}
                    inputRef={ref}
                    checked={isForeign}
                    onChange={(e) => {
                      handleChange(e);
                      onChange(e);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                );
              }}
            />
          }
          label={<Typography variant="h5">Check if it&#39;s international address</Typography>}
        />
        <Box sx={{ height: 12 }} />
        <Grid container alignItems="center">
          <Grid item xs={12} md={2.2}>
            <InputLabel htmlFor="address-1">
              <Typography variant="h5">Address 1 *</Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={9.8}>
            <Controller
              name="address1"
              control={control}
              render={({ field: { ref, ...field } }) => {
                return (
                  <TextField
                    fullWidth
                    {...field}
                    inputRef={ref}
                    error={!!errors.address1?.message}
                    id="address-1"
                    placeholder="Enter address line 1"
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                  />
                );
              }}
            />
          </Grid>
          {!!errors.address1?.message && (
            <Grid container alignItems="center">
              <Grid item xs={12} md={2.2}></Grid>
              <Grid item xs={12} md={9.8}>
                <FormHelperText id="address-1-helper-text" error={!!errors.address1?.message}>
                  {errors.address1?.message}
                </FormHelperText>
              </Grid>
            </Grid>
          )}
          <Box sx={{ marginTop: { xs: 0.5, md: 2.5 } }} width={1}></Box>
          <Grid item xs={12} md={2.2}>
            <InputLabel htmlFor="address-2">
              <Typography variant="h5">Address 2</Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={9.8}>
            <Controller
              name="address2"
              control={control}
              render={({ field: { ref, ...field } }) => {
                return (
                  <TextField
                    fullWidth
                    {...field}
                    inputRef={ref}
                    error={!!errors.address2?.message}
                    id="address-2"
                    placeholder="Enter address line 2"
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                  />
                );
              }}
            />
          </Grid>
          {!!errors.address2?.message && (
            <Grid container alignItems="center">
              <Grid item xs={12} md={2.2}></Grid>
              <Grid item xs={12} md={9.8}>
                <FormHelperText id="address-1-helper-text" error={!!errors.address2?.message}>
                  {errors.address2?.message}
                </FormHelperText>
              </Grid>
            </Grid>
          )}
          <Box sx={{ marginTop: { xs: 0.5, md: 2.5 } }} width={1}></Box>
          <Grid item xs={12} md={2.2}>
            <InputLabel htmlFor="city">
              <Typography variant="h5">City *</Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={9.8}>
            <Controller
              name="city"
              control={control}
              render={({ field: { ref, ...field } }) => {
                return (
                  <TextField
                    fullWidth
                    {...field}
                    inputRef={ref}
                    error={!!errors.city?.message}
                    id="city"
                    placeholder="Enter city"
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                  />
                );
              }}
            />
          </Grid>
          {!!errors.city?.message && (
            <Grid container alignItems="center">
              <Grid item xs={12} md={2.2}></Grid>
              <Grid item xs={12} md={9.8}>
                <FormHelperText id="city-helper-text" error={!!errors.city?.message}>
                  {errors.city?.message}
                </FormHelperText>
              </Grid>
            </Grid>
          )}
          <Box sx={{ marginTop: { xs: 0.5, md: 2.5 } }} width={1}></Box>
          <Grid container item xs={12} md={6} alignItems="center">
            <Grid item xs={12} md={4.4}>
              <InputLabel htmlFor="zip-code">
                <Typography variant="h5">{`Zip Code ${isForeign ? '' : '*'}`}</Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={7.6}>
              <Controller
                name="zip"
                control={control}
                render={({ field: { ref, ...field } }) => {
                  return (
                    <TextField
                      fullWidth
                      {...field}
                      inputRef={ref}
                      error={!!errors.zip?.message}
                      id="zip-code"
                      placeholder="Enter zip code"
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid container alignItems="center" sx={{ minHeight: theme.convert.pxToRem(23) }}>
              <Grid item xs={12} md={4.4}></Grid>
              <Grid item xs={12} md={7.6}>
                <FormHelperText id="zip-code-helper-text" error={!!errors.zip?.message}>
                  {(errors.zip as any)?.message}
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={6} md={6} alignItems="center">
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                textAlign: { xs: 'start', md: 'end' },
                paddingRight: 2.5,
                visibility: isForeign ? 'hidden' : 'visible',
                alignItems: 'center',
              }}
            >
              <InputLabel htmlFor="state">
                <Typography variant="h5">State *</Typography>
              </InputLabel>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              paddingBottom={{ xs: 1, md: 0 }}
              sx={{
                visibility: isForeign ? 'hidden' : 'visible',
              }}
            >
              <FormControl variant="standard" sx={{ maxWidth: '100%', minWidth: '100%' }}>
                <Controller
                  name="state"
                  shouldUnregister={!isForeign}
                  control={control}
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <Select
                        {...field}
                        inputRef={ref}
                        displayEmpty
                        error={!!errors.state?.message}
                        variant="standard"
                        fullWidth
                        id="state"
                        labelId="select-state-label"
                        label="Select state"
                      >
                        <MenuItem disabled value="">
                          Select state
                        </MenuItem>
                        {statesList.map(({ id, name }) => (
                          <MenuItem key={id} value={id.toUpperCase()}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              container
              alignItems="center"
              sx={{ minHeight: { xs: theme.convert.pxToRem(0), md: theme.convert.pxToRem(23) } }}
            >
              <Grid item xs={12} md={2.2}></Grid>
              <Grid item xs={12} md={9.8}>
                <FormHelperText id="state-helper-text" error={!!errors.state?.message}>
                  {(errors.state as any)?.message}
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              marginTop: {
                xs: 0.5,
                md: !!errors.state?.message || !!errors.zip?.message ? 2.5 : 0,
              },
              display: {
                xs: 'none',
                md: 'block',
              },
            }}
            width={1}
          ></Box>
          <Grid
            container
            item
            gap={{ xs: 0.5, md: 0 }}
            xs={6}
            md={12}
            paddingLeft={{ xs: 1, md: 0 }}
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Grid
              item
              xs={12}
              md={2.2}
              sx={{
                visibility: isForeign ? 'hidden' : 'visible',
                alignSelf: { xs: 'auto', md: 'center' },
              }}
            >
              <InputLabel htmlFor="country">
                <Typography variant="h5">Country</Typography>
              </InputLabel>
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sx={{
                visibility: isForeign ? 'hidden' : 'visible',
                marginTop: { xs: 1.5, md: 0 },
                paddingBottom: { xs: 1.2, md: 0 },
              }}
            >
              <Controller
                name="country"
                control={control}
                render={({ field: { ref, ...field } }) => {
                  return (
                    <TextField
                      fullWidth
                      {...field}
                      inputRef={ref}
                      error={!!errors.country?.message}
                      id="country"
                      value={isForeign ? CountryType.OTC : CountryType.US}
                      disabled={!isForeign}
                      inputProps={{
                        style: {
                          textTransform: 'uppercase',
                          fontWeight: 600,
                          lineHeight: theme.typography.pxToRem(24),
                          fontSize: theme.typography.pxToRem(16),
                        },
                      }}
                      sx={{ backgroundColor: theme.palette.grey[200] }}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormGroup>
    </form>
  );
};

export default DebSecModalContentUi;
