import { Fragment, useEffect, useRef } from 'react';
import { Typography, Box, Grid, Checkbox, useTheme } from '@mui/material';

import { obscenitySSNBackground } from '../../../common/constants/colors';
import { ReactComponent as ExclamationCircleIcon } from '../../../assets/icons/exclamation-circle.svg';
import { parseAlert } from './Utils';
import { StyledGrid, StyledTableInfoWrapper } from './styles';
import type { IObesnitySSNTable } from './models';

const ObesnitySSNTable = ({ alerts, handleOffensiveLanguageOverrided }: IObesnitySSNTable) => {
  const theme = useTheme();
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (alerts.length > 0) {
      elementRef.current?.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' });
    }
  }, [alerts]);

  return (
    <Box
      ref={elementRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          boxShadow: '0px 0px 8px rgba(176, 192, 237, 0.5)',
          width: '99%',
        }}
      >
        <StyledTableInfoWrapper sx={{ backgroundColor: obscenitySSNBackground }}>
          <Box sx={{ padding: theme.convert.pxToRem(0), marginTop: theme.convert.pxToRem(3) }}>
            <Checkbox
              sx={{ padding: theme.convert.pxToRem(0), width: 20, height: 20 }}
              onChange={handleOffensiveLanguageOverrided}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
          <Typography variant="h4" style={{ fontWeight: 600 }} textAlign="start">
            Please make corrections if necessary or check here to override.
          </Typography>
        </StyledTableInfoWrapper>
        <StyledTableInfoWrapper>
          <Box sx={{ padding: theme.convert.pxToRem(0) }}>
            <ExclamationCircleIcon
              width={theme.convert.pxToRem(21)}
              height={theme.convert.pxToRem(21)}
            />
          </Box>
          <Typography variant="body1">
            The words underlined below have been identified as possible improper content. All
            documents are reviewed as part of the processing and may be redacted or rejected if they
            contain improper content.
          </Typography>
        </StyledTableInfoWrapper>
        <Box sx={{ width: '100%', overflowX: 'auto' }}>
          <Grid
            container
            style={{ minWidth: theme.convert.pxToRem(550), maxHeight: theme.convert.pxToRem(300) }}
          >
            <StyledGrid item xs={1.5}>
              <Typography variant="h5">No.</Typography>
            </StyledGrid>
            <StyledGrid item xs={4}>
              <Typography variant="h5">Field</Typography>
            </StyledGrid>
            <StyledGrid item xs={3}>
              <Typography variant="h5">Type</Typography>
            </StyledGrid>
            <StyledGrid item xs={3.5}>
              <Typography variant="h5">Content</Typography>
            </StyledGrid>
            {alerts.map(({ fieldName, wordPosition, length, content, type }, i) => {
              const underlinedContent = parseAlert({ wordPosition, length, content });
              return (
                <Fragment key={i}>
                  <StyledGrid item xs={1.5}>
                    {i + 1}
                  </StyledGrid>
                  <StyledGrid item xs={4}>
                    {fieldName}
                  </StyledGrid>
                  <StyledGrid item xs={3}>
                    {type}
                  </StyledGrid>
                  <StyledGrid item xs={3.5}>
                    {underlinedContent}
                  </StyledGrid>
                </Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default ObesnitySSNTable;
