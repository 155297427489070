import { Grid, Typography } from '@mui/material';
import { CenterContentGrid } from '../../common/global-styles';
import { StyledUccFilingSubtitleWrapper } from './styled-items/StyledUccFilingSubtitleWrapper';

export const StartUccFilingSubtitleWrapper = () => {
  return (
    <CenterContentGrid item sx={{ paddingX: { xs: 2, md: 0 }, width: '100%' }} marginX="auto">
      <Grid container>
        <StyledUccFilingSubtitleWrapper item>
          <Typography variant="h4" style={{ fontWeight: 600 }}>
            Filing Type
          </Typography>
        </StyledUccFilingSubtitleWrapper>
      </Grid>
    </CenterContentGrid>
  );
};
