import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { useSnackbar } from '../common/notification';
import { parseErrorMessages } from '../common/helpers';
import { cart, dashboard } from '../common/constants/routes';
import { CheckoutZeroCase, CheckoutRegular } from '../features/checkout';
import { getPaymentRecord } from '../features/checkout/checkout.services';
import Loader from '../common/components/loader';

import { IPaymentId } from '../features/checkout/models';
import { IPayment, PaymentStatus } from '../features/cart/models';
import { PageTitle } from '../common/constants/features-constants';
import PageWrapper from '../common/layouts/PageWrapper';

const Checkout = () => {
  const { paymentId: pId } = useParams();
  const navigate = useNavigate();
  const { Snack } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [transactionDetails, setTransactionDetails] = useState<IPayment | null>(null);

  const paymentId = Number(pId);

  const initCheckout = async ({ paymentId }: IPaymentId) => {
    const res = await getPaymentRecord({ paymentId });
    setIsLoading(false);

    if (res.notOk) {
      Snack.error(parseErrorMessages(res.messages));
      return navigate(cart);
    }

    if (res.payload.status !== PaymentStatus.PENDING) {
      Snack.error('Unexpected error occurred');
      return navigate(dashboard);
    }

    setTransactionDetails(res.payload);
  };

  useEffect(() => {
    if (paymentId) {
      initCheckout({ paymentId });
    }
  }, [paymentId]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <Loader size={40} color="blue" />
      </Box>
    );
  }

  return (
    <PageWrapper title={PageTitle.CART}>
      {transactionDetails?.transactionAmount === 0 ? (
        <CheckoutZeroCase paymentId={paymentId} />
      ) : (
        <CheckoutRegular transactionAmount={transactionDetails?.transactionAmount} />
      )}
    </PageWrapper>
  );
};

export default Checkout;
