import { StyledTableContainer } from '../../../features/start-ucc-filing/styled-items/StyledTableContainer';
import {
  Button,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { StyledTableHead } from '../../../features/start-ucc-filing/styled-items/StyledTableHead';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { downloadDocument } from '../../helpers/downloadDocument';

type Form = {
  name: string;
  pdf: string;
  word: string;
};

const rows: Form[] = [
  {
    name: 'UCC-1',
    pdf: 'FL.UCC1_Approved201305.pdf',
    word: 'FL.UCC1_Approved201305.doc',
  },
  {
    name: 'UCC-1 Addendum',
    pdf: 'FL.UCC1.AD_Approved201305.pdf',
    word: 'FL.UCC1.AD_Approved201305.doc',
  },
  {
    name: 'UCC-1 Additional Party',
    pdf: 'FL.UCC1.AP_Approved201305.pdf',
    word: 'FL.UCC1.AP_Approved201305.doc',
  },
  {
    name: 'UCC-1 Additional Information',
    pdf: 'FL.UCC1.AI_Approved201305.pdf',
    word: 'FL.UCC1.AI_Approved201305.doc',
  },
  {
    name: 'UCC-3',
    pdf: 'FL.UCC3_Approved201306.pdf',
    word: 'FL.UCC3_Approved201306.doc',
  },
  {
    name: 'UCC-3 Addendum',
    pdf: 'FL.UCC3.AD_Approved201305.pdf',
    word: 'FL.UCC3.AD_Approved201305.doc',
  },
  {
    name: 'UCC-3 Additional Party',
    pdf: 'FL.UCC3.AP_Approved201305.pdf',
    word: 'FL.UCC3.AP_Approved201305.doc',
  },
  {
    name: 'UCC-5 Information Statement',
    pdf: 'FL.UCC5_Approved201306.pdf',
    word: 'FL.UCC5_Approved201306.doc',
  },
];

const FormsTable = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledTableContainer component={Paper} variant="outlined">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <StyledTableHead>
          <TableRow>
            <TableCell align="left">
              <Typography fontWeight={600} variant="body1" paddingLeft={3.5}>
                Form
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} variant="body1">
                Download PDF
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} variant="body1">
                Download Word
              </Typography>
            </TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                <Typography variant="body3" fontWeight={600} paddingLeft={3.5}>
                  {row.name}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Button onClick={() => downloadDocument(row?.pdf)}>
                  <SvgIcon>
                    <DownloadIcon fill={theme.palette.info.main} />
                  </SvgIcon>
                </Button>
              </TableCell>
              <TableCell align="center">
                <Button
                  onClick={() => !isMobile && downloadDocument(row?.word)}
                  disabled={isMobile}
                  style={{ WebkitTapHighlightColor: 'transparent' }}
                >
                  <SvgIcon
                    sx={{
                      '&:active, &:focus, &:hover': {
                        background: 'none',
                      },
                    }}
                  >
                    <DownloadIcon
                      fill={isMobile ? theme.palette.grey[500] : theme.palette.info.main}
                    />
                  </SvgIcon>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default FormsTable;
