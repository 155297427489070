import { CenterContentGrid } from '../../common/global-styles';
import { Box, Grid } from '@mui/material';
import Loader from '../../common/components/loader';
import { StartUccFilingRadioButtonsWrapper } from './StartUccFilingRadioWrapper';
import { StyledSubmitButton } from '../../common/components/user-auth-components/common-auth-styles';
import type { SyntheticEvent } from '../../common/models';
import { FilingType } from '../../common/models/ucc';
import theme from '../../common/theme';

interface IStartUccFormProps {
  onSubmitHandler: SyntheticEvent;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedValue: string;
  selectedForm: string;
  isUCC1Loading: boolean;
}

const StartUccForm = ({
  onSubmitHandler,
  handleChange,
  selectedValue,
  selectedForm,
  isUCC1Loading,
}: IStartUccFormProps) => {
  return (
    <CenterContentGrid item sx={{ width: '100%' }} marginX="auto">
      <form onSubmit={onSubmitHandler}>
        <Grid container gap={2}>
          <StartUccFilingRadioButtonsWrapper
            handleChange={handleChange}
            selectedValue={selectedValue}
          />
        </Grid>
        <Grid
          item
          marginX="auto"
          marginTop={theme.convert.pxToRem(40)}
          justifyContent={{ xs: 'center', md: 'end' }}
          display={selectedForm === FilingType.UCC3 ? 'none' : 'flex'}
        >
          <Box width={220}>
            <StyledSubmitButton
              startIcon={isUCC1Loading ? <Loader /> : null}
              variant="contained"
              type="submit"
              fullWidth
              disabled={!selectedValue || isUCC1Loading}
            >
              Next
            </StyledSubmitButton>
          </Box>
        </Grid>
      </form>
    </CenterContentGrid>
  );
};
export default StartUccForm;
