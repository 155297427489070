import { FC } from 'react';
import { Grid, Skeleton, useTheme } from '@mui/material';

import {
  StyledTableContentGrid,
  StyledTableDescriptionHeadingGrid,
  StyledTableHeadingGrid,
} from './StyledComponents';

const DetailsSkeleton: FC = () => {
  const theme = useTheme();
  return (
    <>
      <StyledTableHeadingGrid item container>
        <Grid xs={2} item>
          <Skeleton width={50} height={24} />
        </Grid>
        <Grid xs={2} item>
          <Skeleton width={70} height={24} />
        </Grid>
        <Grid xs={2} item>
          <Skeleton width={55} height={24} />
        </Grid>
        <Grid xs={3} item>
          <Skeleton width={200} height={24} />
        </Grid>
        <Grid xs={3} item>
          <Skeleton width={150} height={24} />
        </Grid>
      </StyledTableHeadingGrid>
      <Grid
        item
        height={48}
        paddingX={theme.convert.pxToRem(40)}
        paddingY={theme.convert.pxToRem(12)}
        container
      >
        <Grid xs={2} item>
          <Skeleton width={50} height={24} />
        </Grid>
        <Grid xs={2} item>
          <Skeleton width={85} height={24} />
        </Grid>
        <Grid xs={2} item>
          <Skeleton width={85} height={24} />
        </Grid>
        <Grid xs={3} item>
          <Skeleton width={85} height={24} />
        </Grid>
        <Grid xs={3} item>
          <Skeleton width={110} height={24} />
        </Grid>
      </Grid>

      <Grid item container>
        <StyledTableDescriptionHeadingGrid item container>
          <Grid item>
            <Skeleton width={110} height={24} />
          </Grid>
          <Grid item>
            <Skeleton width={110} height={24} />
          </Grid>
        </StyledTableDescriptionHeadingGrid>
      </Grid>

      <StyledTableHeadingGrid item container display={'flex'} justifyContent={'end'}>
        <Skeleton width={120} height={24} />
      </StyledTableHeadingGrid>

      <Grid item container>
        <StyledTableDescriptionHeadingGrid item container>
          <Grid item>
            <Skeleton width={120} height={24} />
          </Grid>
          <Grid item>
            <Skeleton width={175} height={24} />
          </Grid>
        </StyledTableDescriptionHeadingGrid>
      </Grid>

      <StyledTableHeadingGrid item container display={'flex'} justifyContent={'space-between'}>
        <Grid item>
          <Skeleton width={110} height={24} />
        </Grid>
        <Grid item>
          <Skeleton width={100} height={24} />
        </Grid>
      </StyledTableHeadingGrid>
      <StyledTableContentGrid item xs={4}>
        <Skeleton width={300} height={24} />
        <Skeleton width={300} height={24} />
        <Skeleton width={300} height={24} />
        <Skeleton width={300} height={24} />
      </StyledTableContentGrid>

      <Grid item container>
        <StyledTableDescriptionHeadingGrid item container>
          <Grid item>
            <Skeleton width={110} height={24} />
          </Grid>
          <Grid item>
            <Skeleton width={170} height={24} />
          </Grid>
        </StyledTableDescriptionHeadingGrid>
      </Grid>
      <StyledTableHeadingGrid item container display={'flex'} justifyContent={'space-between'}>
        <Grid item>
          <Skeleton width={110} height={24} />
        </Grid>
        <Grid item>
          <Skeleton width={100} height={24} />
        </Grid>
      </StyledTableHeadingGrid>
      <StyledTableContentGrid item xs={4}>
        <Skeleton width={300} height={24} />
        <Skeleton width={300} height={24} />
        <Skeleton width={300} height={24} />
        <Skeleton width={300} height={24} />
      </StyledTableContentGrid>

      <Grid item container>
        <StyledTableDescriptionHeadingGrid item container>
          <Grid item>
            <Skeleton width={140} height={24} />
          </Grid>
          <Grid item>
            <Skeleton width={240} height={24} />
          </Grid>
        </StyledTableDescriptionHeadingGrid>
      </Grid>

      <StyledTableHeadingGrid item container>
        <Grid xs={4} item>
          <Skeleton width={130} height={24} />
        </Grid>
        <Grid xs={3} item>
          <Skeleton width={35} height={24} />
        </Grid>
        <Grid xs={3} item>
          <Skeleton width={75} height={24} />
        </Grid>
        <Grid xs={2} item>
          <Skeleton width={40} height={24} />
        </Grid>
      </StyledTableHeadingGrid>
      <Grid
        item
        height={48}
        paddingX={theme.convert.pxToRem(40)}
        paddingY={theme.convert.pxToRem(12)}
        container
      >
        <Grid xs={4} item>
          <Skeleton width={260} height={24} />
        </Grid>
        <Grid xs={3} item>
          <Skeleton width={45} height={24} />
        </Grid>
        <Grid xs={3} item>
          <Skeleton width={85} height={24} />
        </Grid>
        <Grid xs={2} item>
          <Skeleton width={25} height={24} />
        </Grid>
      </Grid>
    </>
  );
};

export default DetailsSkeleton;
