import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { StyledTableDescriptionHeadingGrid } from './StyledComponents';

const SearchGridWhiteHeading: FC<{ title: string; description: string; result?: number }> = ({
  title,
  description,
  result,
}) => {
  return (
    <Grid item container>
      <StyledTableDescriptionHeadingGrid item container>
        <Grid item>
          <Typography fontWeight={600}>{title}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            {description}: {result}
          </Typography>
        </Grid>
      </StyledTableDescriptionHeadingGrid>
    </Grid>
  );
};

export default SearchGridWhiteHeading;
