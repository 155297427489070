import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAuth, useModal } from '../../../../common/context';
import { useSnackbar } from '../../../../common/notification';
import AuthCodeForm from './auth-code-form';
import EmailLoginFormUI from './EmailLoginFormUI';
import type { IChallangeCode } from '../../../../common/models';
import { CloseIconButton } from '../../../../common/global-styles/CloseIconButton';
import { SubmitCloseButtons } from '../../../../common/submit-close-buttons/SubmitCloseButtons';

const schema = yup
  .object({
    email: yup
      .string()
      .max(100, 'Max length is 100 characters.')
      .required('Email is required.')
      .email('Please enter a valid email address.')
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Please enter a valid email address.'),
  })
  .required();

export type FormData = yup.InferType<typeof schema>;

const EmailLoginForm = () => {
  const { emailSignIn, answerCustomChallenge, user } = useAuth();
  const { handleModalClose, handleModalOpen, handleSetModalActions } = useModal();
  const { Snack } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, setFocus, formState, control } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const { isSubmitted } = formState;

  const onSubmitCodeForm = async (codeFormProps: IChallangeCode) => {
    setIsLoading(true);
    setTimeout(async () => {
      const isCustomChallageSuccessful = await answerCustomChallenge(codeFormProps);
      setIsLoading(false);
      if (isCustomChallageSuccessful) {
        handleModalClose();
      }
    });
  };

  const openModal = () =>
    handleModalOpen({
      title: 'Check your email for a code',
      modalContent: (
        <AuthCodeForm
          onSubmit={(codeFormProps: IChallangeCode) => {
            onSubmitCodeForm(codeFormProps);
          }}
          isLoading={isLoading}
        />
      ),
      modalActions: (
        <SubmitCloseButtons
          isLoading={isLoading}
          handleModalClose={handleModalClose}
          Snack={Snack}
        />
      ),
      xButtonAction: (
        <CloseIconButton
          onClick={(e) => {
            handleModalClose(e);
            Snack.info('Action canceled.');
          }}
        />
      ),
    });

  useEffect(() => {
    if (isSubmitted && user) {
      openModal(); // Defer modal open until user is verified with email
    }
  }, [isSubmitted, user]);

  useEffect(() => {
    handleSetModalActions(
      <SubmitCloseButtons isLoading={isLoading} handleModalClose={handleModalClose} Snack={Snack} />
    );
  }, [isLoading]);

  useEffect(() => {
    setFocus('email');
  }, []);

  return (
    <EmailLoginFormUI
      onSubmit={handleSubmit(emailSignIn)}
      control={control}
      formState={formState}
    />
  );
};
export default EmailLoginForm;
