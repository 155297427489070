import { useForm, UseFormProps } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  submitSchema as submitSchemaUCC3,
  saveSchema as saveSchemaUCC3,
  type FormDataUCC3,
} from '../../features/ucc3/schema';
import {
  submitSchema as submitSchemaUCC1,
  saveSchema as saveSchemaUCC1,
  type FormDataUCC1,
} from '../../features/ucc1/schema';
import { FilingType } from '../models/ucc';
import useEditMode from './useEditMode';

interface UseAuthedFormProps {
  isPublishValidationRef: any;
  submitSchema: typeof submitSchemaUCC3 | typeof submitSchemaUCC1;
  saveSchema: typeof saveSchemaUCC3 | typeof saveSchemaUCC1;
  defaultValues?: UseFormProps['defaultValues'];
  filingType?: FilingType;
}

export function useAuthedForm({
  isPublishValidationRef,
  defaultValues,
  submitSchema,
  saveSchema,
}: UseAuthedFormProps) {
  const form = useForm<FormDataUCC1 | FormDataUCC3>({
    resolver: (values, context, option) =>
      yupResolver(isPublishValidationRef.current ? submitSchema : saveSchema)(
        values,
        context,
        option
      ),
    mode: 'onChange',
    defaultValues,
  });

  useEditMode(form.formState.isDirty);

  return form;
}
