import { useSnackbar as useNotistackSnackbar } from 'notistack';

export interface ISnack {
  success(msg: string): void;
  error(msg: string): void;
  info(msg: string): void;
  warning(msg: string): void;
}

function useSnackbar() {
  const { enqueueSnackbar } = useNotistackSnackbar();

  const Snack: ISnack = {
    success(message) {
      enqueueSnackbar(message, {
        variant: 'success',
      });
    },
    error(message) {
      enqueueSnackbar(message, {
        variant: 'error',
      });
    },
    warning(message) {
      enqueueSnackbar(message, {
        variant: 'warning',
      });
    },
    info(message) {
      enqueueSnackbar(message, {
        variant: 'info',
      });
    },
  };

  return { Snack };
}

export { useSnackbar };
