import { createTheme } from '@mui/material/styles';
import MuiButton from './mui-button-styles';
import { MuiAlert } from './MuiAlertStyles';
import { MuiTextField, MuiOutlinedInput } from './mui-input-styles';
import { MuiDialog } from './mui-dialog';
import { MuiTooltip } from './MuiTooltipStyles';
import { MuiPagination } from './mui-pagination-styles';
import * as colors from '../constants/colors';

const BASE_REM_SIZE = 16;

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }

  interface Theme {
    convert: {
      pxToRem: (px: number) => string;
    };
  }

  interface ThemeOptions {
    convert?: {
      pxToRem: (px: number) => string;
    };
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

let theme = createTheme({
  palette: {
    primary: {
      main: colors.primaryBlueButtonColor,
      light: colors.primaryLightBlueColor,
    },
    grey: {
      200: colors.primaryLightGreyColor,
      300: colors.secondaryLightGreyColor,
      500: colors.primaryMediumGreyColor,
      700: colors.primaryDarkGreyColor,
      900: colors.lightboxBackdropsDefaultColor,
    },
    info: {
      main: colors.infoBlueColor,
      light: colors.infoBlueBackgroundColor,
      dark: colors.highlightSelectionColor,
    },
    success: {
      main: colors.successMainColor,
      light: colors.successLightColor,
    },
    warning: {
      main: colors.warningMainColor,
      light: colors.warningLightColor,
      dark: colors.warningDarkColor,
    },
    error: {
      main: colors.errorMainColor,
      light: colors.errorLightColor,
    },
    text: {
      primary: colors.regularTextColor,
      secondary: colors.blueTextColor,
    },
  },
  components: {
    MuiButton,
    MuiOutlinedInput,
    MuiTextField,
    MuiAlert,
    MuiDialog,
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: 'lowercase',
          '&:first-letter': {
            textTransform: 'capitalize',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          wordWrap: 'break-word',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          whiteSpace: 'pre-wrap',
          overflow: 'visible',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          flexDirection: 'row-reverse',
          background: theme.palette.primary.light,
          color: theme.palette.text.primary,
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            marginRight: 16,
            marginLeft: 24,
          },
        }),
      },
    },
    MuiTable: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiTableCell-head': {
            padding: theme.convert.pxToRem(12),
          },
          '& .MuiTableCell-body': {
            padding: theme.convert.pxToRem(12),
          },
        }),
      },
    },
    MuiTooltip,
    MuiPagination,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  convert: {
    pxToRem: (px) => `${px / BASE_REM_SIZE}rem`,
  },
});

theme = createTheme(theme, {
  typography: {
    fontFamily: ['Open Sans', 'Roboto'].join(','),
    fontSize: 16,
    fontWeightRegular: 'normal',
    fontWeightMedium: 500,
    fontWeightLight: 300,
    h1: {
      color: theme.palette.text.primary,
      fontFamily: 'Roboto',
      [theme.breakpoints.up('xs')]: {
        fontSize: theme.typography.pxToRem(28),
        lineHeight: theme.typography.pxToRem(36),
      },
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(28),
        lineHeight: theme.typography.pxToRem(36),
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: theme.typography.pxToRem(28),
        lineHeight: theme.typography.pxToRem(36),
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: theme.typography.pxToRem(32),
        lineHeight: theme.typography.pxToRem(40),
      },
    },
    h2: {
      color: theme.palette.text.primary,
      fontFamily: 'Open Sans',
      fontWeight: 400,
      [theme.breakpoints.up('xs')]: {
        fontSize: 20,
        lineHeight: theme.typography.pxToRem(24),
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 24,
        lineHeight: theme.typography.pxToRem(32),
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 24,
        lineHeight: theme.typography.pxToRem(32),
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 28,
        lineHeight: theme.typography.pxToRem(36),
      },
    },
    h3: {
      color: theme.palette.text.primary,
      fontFamily: 'Open Sans',
      fontWeight: 600,
      [theme.breakpoints.up('xs')]: {
        fontSize: 16,
        lineHeight: theme.typography.pxToRem(24),
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 20,
        lineHeight: theme.typography.pxToRem(28),
      },
      [theme.breakpoints.up('lg')]: {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: theme.typography.pxToRem(28),
      },
      [theme.breakpoints.up('xl')]: {
        fontWeight: 400,
        fontSize: 24,
        lineHeight: theme.typography.pxToRem(32),
      },
    },
    h4: {
      color: theme.palette.text.primary,
      fontFamily: 'Open Sans',
      fontWeight: 600,
      lineHeight: theme.typography.pxToRem(24),
      fontSize: 16,
      [theme.breakpoints.up('xl')]: {
        fontSize: 20,
        lineHeight: theme.typography.pxToRem(28),
      },
    },
    h5: {
      color: theme.palette.text.primary,
      fontFamily: 'Open Sans',
      fontWeight: 600,
      [theme.breakpoints.up('xs')]: {
        fontSize: 16,
        lineHeight: theme.typography.pxToRem(24),
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 16,
        lineHeight: theme.typography.pxToRem(28),
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
        lineHeight: theme.typography.pxToRem(24),
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 16,
        lineHeight: theme.typography.pxToRem(24),
      },
    },
    h6: {
      color: theme.palette.text.primary,
      fontFamily: 'Open Sans',
      [theme.breakpoints.up('xs')]: {
        fontSize: 16,
        lineHeight: theme.typography.pxToRem(24),
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 16,
        lineHeight: theme.typography.pxToRem(28),
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
        lineHeight: theme.typography.pxToRem(24),
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 16,
        lineHeight: theme.typography.pxToRem(24),
      },
    },
    body1: {
      color: theme.palette.text.primary,
      fontFamily: 'Open Sans',
      fontSize: 16,
      lineHeight: theme.typography.pxToRem(24),
    },
    body2: {
      color: theme.palette.text.primary,
      fontFamily: 'Open Sans',
      fontSize: 14,
      lineHeight: theme.typography.pxToRem(20),
    },
    body3: {
      color: theme.palette.text.primary,
      fontFamily: 'Open Sans',
      fontSize: 14,
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 600,
    },
  },
});

export default theme;
