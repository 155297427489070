import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useModal } from '../../../../../common/context';
import { useSnackbar } from '../../../../../common/notification';
import { yupResolver } from '@hookform/resolvers/yup';

import AuthCodeFormUI from './AuthCodeFormUI';

import { IAuthCodeFormProps } from './types';
import { schema, FormData } from './schema';
import { SubmitCloseButtons } from '../../../../../common/submit-close-buttons/SubmitCloseButtons';

const AuthCodeForm = ({ onSubmit, isLoading }: IAuthCodeFormProps) => {
  const { handleSubmit, setFocus, formState, control } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const { Snack } = useSnackbar();
  const { handleSetModalActions, handleModalClose } = useModal();
  const { isValid } = formState;

  useEffect(() => {
    setFocus('code');
  }, []);

  useEffect(() => {
    if (!isValid || isLoading) {
      handleSetModalActions(
        <SubmitCloseButtons
          isLoading={isLoading}
          isValid={isValid}
          handleModalClose={handleModalClose}
          Snack={Snack}
        />
      );
    } else {
      handleSetModalActions(
        <SubmitCloseButtons
          isLoading={isLoading}
          handleModalClose={handleModalClose}
          Snack={Snack}
        />
      );
    }
  }, [isValid, isLoading]);

  return (
    <AuthCodeFormUI onSubmit={handleSubmit(onSubmit)} formState={formState} control={control} />
  );
};

export default AuthCodeForm;
