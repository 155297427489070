type CheckFunction = (value: string) => boolean;

export const hasValue = (
  getValues: (field: string) => any,
  fields: string[],
  checks: Record<string, CheckFunction> = {}
): boolean => {
  return fields.some((field) => {
    const value = getValues(field);
    if (checks[field]) {
      return checks[field](value);
    } else {
      return value !== null && value !== undefined && value !== '';
    }
  });
};

export const ATTACHMENTS_TOTAL_SIZE_MESSAGE = 'Only PDF, TIFF files total size of 10MB';
