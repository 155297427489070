/**
 * Here should come all the colors used in project.
 * Variable name should be in pascal case, end with Color suffix
 * Hex colors should be with 6 digits
 * No color should repeat
 */

/**
 *  Color Palette
 */

// Info
export const infoBlueColor = '#2e73dc';
export const infoBlueBackgroundColor = '#e2f3ff';

// Primary
export const primaryBlueButtonColor = '#0f75bc';
export const primaryLightBlueColor = '#dae2ec';
export const mediumBlueLinkColor = infoBlueColor;

// Grey
export const primaryLightGreyColor = '#ececec';
export const secondaryLightGreyColor = '#cecece';
export const primaryMediumGreyColor = '#909090';
export const primaryDarkGreyColor = '#6b6b6b';
export const lightboxBackdropsDefaultColor = '#253245';

// Success
export const successMainColor = '#137c40';
export const successLightColor = '#eaf7f1';

// Warning
export const warningMainColor = '#fed357';
export const warningLightColor = '#fff4d3';
export const warningDarkColor = '#1a355d';

// Error
export const errorMainColor = '#d0021b';
export const errorLightColor = '#ffecee';

// Text
export const regularTextColor = '#242a42';
export const blueTextColor = '#0C3456';

//Background
export const dividerBackground = '176, 192, 237';
export const defaultLogoBackground = '#d9d9d2';
export const defaultBackground = '#ffffff';
// export const obscenitySSNBackground = '#f9a364';
export const obscenitySSNBackground = 'rgba(249, 163, 100, .3)';
export const gridLoadingBackdrop = 'rgba(255, 255, 255, .5)';

//Border
export const highlightSelectionColor = '#f26722';
export const itemDeviderBorder = '#cecece';

// button
export const textButtonDefaultColor = '#1F5FBD';
export const textButtonHoverColor = '#155AC3';
export const textButtonDisabledColor = '#909090';

// Upload
export const uploadFileColor = '#1f5fbd';

// Box
export const boxShadowColor = '176, 192, 237';

// Dialog
export const dialogShadowColor = '0, 0, 0';

// Grid
export const gridActiveRowColor = '#f3f6f9';
