import { Grid, FormControlLabel, RadioGroup, Typography, useTheme } from '@mui/material';
import { StyledStartUccRadioButton } from './styled-items/StyledStartUccFilingRadioButton';

interface IStartUccFilingRadioWrapperProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedValue: string;
}

export const StartUccFilingRadioButtonsWrapper = ({
  handleChange,
  selectedValue,
}: IStartUccFilingRadioWrapperProps) => {
  const theme = useTheme();
  return (
    <Grid
      item
      md={12}
      sm={12}
      xs={12}
      sx={{
        paddingX: { xs: 2, md: 4 },
        paddingTop: {
          xs: theme.convert.pxToRem(20),
          md: theme.convert.pxToRem(40),
          lg: theme.convert.pxToRem(40),
        },
      }}
    >
      <RadioGroup
        aria-labelledby="ucc-controlled-radio-buttons-group"
        name="ucc-controlled-radio-buttons-group"
        value={selectedValue}
        onChange={handleChange}
      >
        <Grid container gap={2}>
          <Grid item lg={12} xs={12} display="flex" alignItems="center">
            <FormControlLabel
              value="UCC1"
              control={<StyledStartUccRadioButton autoFocus />}
              label={<Typography variant="body1">UCC Financing Statement (UCC1)</Typography>}
            />
          </Grid>
          <Grid item lg={12} xs={12} display="flex" alignItems="center">
            <FormControlLabel
              value="UCC3"
              control={<StyledStartUccRadioButton />}
              label={
                <Typography variant="body1">UCC Financing Statement Amendment (UCC3)</Typography>
              }
            />
          </Grid>
        </Grid>
      </RadioGroup>
    </Grid>
  );
};
