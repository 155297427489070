import { Box } from '@mui/material';
import Loader from '../../../common/components/loader';
import { CustomAccordion } from '../../../common/components/accordion';
import PendingFilings from '../pending-filings';
import CompletedFilings from '../completed-filings';
import { Accordion } from '../constants';
import { StyledGrid } from '../../shared/ucc/styles';
import { StyledUCCButton } from '../../start-ucc-filing/styled-items/StyledUccButton';
import { ICartAccordionsUIProps } from '../models';

const CartAccordionsUI = ({
  accordionState,
  handleAccordionState,
  handleOnNewFiling,
  handleEditFiling,
  handleModalOpen,
  toggleTooltip,
  activeToolTip,
  pendingFilingsData,
  completedFilingsData,
  isCheckoutButtonDisabled,
  onCartCheckout,
  isCartCheckoutLoading,
}: ICartAccordionsUIProps) => {
  return (
    <>
      <StyledGrid container gap={3.5}>
        <CustomAccordion
          title={'Pending UCC Filings'}
          isOpen={accordionState[Accordion.Pending]}
          handleChange={() => handleAccordionState(Accordion.Pending)}
        >
          <PendingFilings
            pendingFilingsData={pendingFilingsData}
            handleEditFiling={handleEditFiling}
            handleModalOpen={handleModalOpen}
            toggleTooltip={toggleTooltip}
            activeToolTip={activeToolTip}
          />
        </CustomAccordion>
        <CustomAccordion
          title={'Completed UCC Filings'}
          isOpen={accordionState[Accordion.Completed]}
          handleChange={() => handleAccordionState(Accordion.Completed)}
        >
          <CompletedFilings
            completedFilingsData={completedFilingsData}
            handleEditFiling={handleEditFiling}
            handleModalOpen={handleModalOpen}
          />
        </CustomAccordion>
      </StyledGrid>
      <Box sx={{ height: { xs: 10, md: 20 }, width: '100%' }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row-reverse',
            lg: 'row-reverse',
            xl: 'row-reverse',
          },
          justifyContent: { md: 'flex-start', sm: 'center' },
          alignItems: 'flex-end',
          width: '100%',
          margin: '0 auto',
          gap: { xs: 0.5, md: 0.5, lg: 0, xl: 0 },
        }}
      >
        <StyledUCCButton
          type="submit"
          variant="contained"
          disabled={isCheckoutButtonDisabled || isCartCheckoutLoading}
          onClick={onCartCheckout}
          startIcon={isCartCheckoutLoading ? <Loader /> : null}
        >
          Checkout
        </StyledUCCButton>
        <Box sx={{ width: { xs: 20, md: 40 } }} />
        <StyledUCCButton variant="contained" onClick={handleOnNewFiling}>
          Add New Filing
        </StyledUCCButton>
      </Box>
      <Box sx={{ height: 267, width: '100%' }} />
    </>
  );
};

export default CartAccordionsUI;
