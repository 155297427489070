import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { Checkbox, FormGroup, FormControlLabel, Box, Typography, useTheme } from '@mui/material';
import { Controller } from 'react-hook-form';

import { useModal } from '../../../common/context';
import { useSnackbar } from '../../../common/notification';
import { StyledModalSubmitButton } from '../../../common/global-styles';
import { CloseIconButton } from '../../../common/global-styles/CloseIconButton';

import { FormDataUCC3 } from '../schema';
import { ICheckBoxFormWithFieldsUI } from '../../../common/models';
import { Accordion } from '../constants';
import { CollateralChangeType } from '../models';
import { hasValue } from '../../shared/ucc/utils';

const AmendReleaseCollateralCheckbox = ({
  control,
  setValue,
  handleCheckboxAccordionState,
  clearErrors,
  resetField,
  getValues,
}: ICheckBoxFormWithFieldsUI<FormDataUCC3>) => {
  const theme = useTheme();
  const eventAmendReleaseCollateral = useWatch({
    control,
    name: 'eventAmendReleaseCollateral',
  });
  const { Snack } = useSnackbar();
  const { handleModalOpen, handleModalClose, handleSetModalXButton } = useModal();

  useEffect(() => {
    if (eventAmendReleaseCollateral) {
      handleCheckboxAccordionState(Accordion.AmendReleaseCollateral);
    }
  }, [eventAmendReleaseCollateral]);

  const resetFields = () => {
    resetField('eventAmendReleaseCollateral', {
      keepDirty: false,
      keepTouched: false,
      keepError: false,
      defaultValue: false,
    });
    resetField('collateralChangeType', {
      keepDirty: false,
      keepTouched: false,
      keepError: false,
      defaultValue: CollateralChangeType.None,
    });
    resetField('collateralChangeDescription', {
      keepDirty: false,
      keepTouched: false,
      keepError: false,
      defaultValue: '',
    });
  };

  const fieldsToCheck = ['collateralChangeType', 'collateralChangeDescription'];

  const checks = {
    collateralChangeType: (value: string) => value !== CollateralChangeType.None,
  };

  const clearDescriptionErrors = () => {
    clearErrors('collateralChangeDescription');
  };

  const handleProceed = () => {
    resetFields();
    clearDescriptionErrors();
    handleModalClose();
    Snack.success('Event is successfully unchecked.');
  };

  const handleCancel = () => {
    control._updateValid();
    setValue('eventAmendReleaseCollateral', true);
    handleModalClose();
  };

  const xButtonAction = <CloseIconButton onClick={handleCancel} />;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      if (hasValue(getValues, fieldsToCheck, checks)) {
        handleModalOpen({
          title: 'Unchecking Event',
          modalContent: (
            <Typography variant="h4">
              Are you sure you want to uncheck this event? If you proceed all event data you updated
              will be lost.
            </Typography>
          ),
          modalActions: (
            <Box
              sx={{
                display: 'flex',
                gap: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(34) },
                flexDirection: 'row-reverse',
              }}
            >
              <StyledModalSubmitButton variant="contained" onClick={handleProceed} autoFocus>
                Proceed
              </StyledModalSubmitButton>
              <StyledModalSubmitButton variant="outlined" onClick={handleCancel}>
                Cancel
              </StyledModalSubmitButton>
            </Box>
          ),
        });
        handleSetModalXButton(xButtonAction);
      } else {
        resetFields();
        clearDescriptionErrors();
      }
    }
  };
  return (
    <Controller
      control={control}
      name="eventAmendReleaseCollateral"
      render={({ field }) => (
        <FormGroup {...field}>
          <FormControlLabel
            control={
              <Checkbox checked={eventAmendReleaseCollateral} onChange={handleCheckboxChange} />
            }
            label=""
          />
        </FormGroup>
      )}
    />
  );
};

export default AmendReleaseCollateralCheckbox;
