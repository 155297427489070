import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Loader from '../../common/components/loader';
import { cart } from '../../common/constants/routes';
import { StyledUCCButton } from '../start-ucc-filing/styled-items/StyledUccButton';
import { formatAmountToAccountingFormat } from '../ucc3/utils';
import { createPaymentLink } from './checkout.services';
import { useSnackbar } from '../../common/notification';
import { parseErrorMessages } from '../../common/helpers';
import { ICheckoutRegular } from './models';
import Section from '../../common/layouts/Section';

const CheckoutRegular = ({ transactionAmount }: ICheckoutRegular) => {
  const navigate = useNavigate();
  const { Snack } = useSnackbar();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const priceToNumber = Number(transactionAmount);
  const priceInDollars = !isNaN(priceToNumber)
    ? formatAmountToAccountingFormat(priceToNumber)
    : 'N/A';

  const submitPayment = async () => {
    setIsSubmitDisabled(true);

    const res = await createPaymentLink();

    if (!res.notOk) {
      window.location.href = res.payload;
      return;
    }

    setIsSubmitDisabled(false);
    Snack.error(parseErrorMessages(res.messages));
    navigate(cart);
  };

  return (
    <Box>
      <Section title="checkout">
        <Typography variant="body1">
          Please review the Total Balance due and click &quot;Make Payment&quot; to begin the
          transaction. You will be transferred to another page to complete your transaction and this
          session will close. Once you have completed the transaction you will be redirected back to
          receive your Remittance Number.
        </Typography>
        <Box sx={{ height: (theme) => theme.convert.pxToRem(20) }} />
        <Typography variant="h5">Total Balance Due: {priceInDollars}</Typography>
      </Section>
      <Box sx={{ height: { xs: 20, md: 40 }, width: '100%' }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'column', 'row-reverse', 'row-reverse'],
          justifyContent: 'flex-start',
          gap: [1.5, 1.5, 5, 5, 5],
        }}
      >
        <StyledUCCButton
          autoFocus
          onClick={submitPayment}
          variant="contained"
          startIcon={isSubmitDisabled ? <Loader /> : null}
          disabled={isSubmitDisabled}
        >
          {isSubmitDisabled ? 'Making Payment' : 'Make Payment'}
        </StyledUCCButton>
        <StyledUCCButton onClick={() => navigate(cart)} variant="outlined">
          Cancel
        </StyledUCCButton>
      </Box>
      <Box sx={{ height: 789 }} />
    </Box>
  );
};

export default CheckoutRegular;
