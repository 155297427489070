import { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Pagination, Stack, Typography, useTheme } from '@mui/material';

import Loader from '../common/components/loader';
import { useSnackbar } from '../common/notification';
import { Divider } from '../common/components/Divider';
import PageWrapper from '../common/layouts/PageWrapper';
import { AttentionContent } from '../features/home-page/HomeAnnouncementsCarousel';
import { defaultPageSize, getAnnouncements } from '../common/services/announcements.services';

import { Announcement } from '../common/models/features/models';
import { PageTitle } from '../common/constants/features-constants';
import { isoToFormattedDate } from '../common/helpers/format-date';
import '../features/start-ucc-filing/styled-items/announcementStyles.css';

export const Announcements = () => {
  const theme = useTheme();
  const { Snack } = useSnackbar();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  const handleChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
    window.scrollTo({ top: 0 });
    setPage(value);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getAnnouncements(page)
      .then((res) => {
        if (res.notOk) {
          Snack.error(res.friendlyMessageSummary);
          setIsLoading(false);
        } else {
          setTotalPages(Math.ceil(Number(res.meta.totalCount) / defaultPageSize));
          setAnnouncements(res.payload);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        Snack.error(err.message);
        setIsLoading(false);
      });
  }, [page]);

  return (
    <PageWrapper title={PageTitle.ANNOUNCEMENTS} headTitle={PageTitle.ANNOUNCEMENTS}>
      {isLoading ? (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          minHeight={theme.convert.pxToRem(500)}
        >
          <Loader color="blue" size={40} />
        </Box>
      ) : (
        announcements.map((announcement, index) => (
          <Fragment key={announcement.publishDate + index}>
            <Stack marginY={theme.convert.pxToRem(32)}>
              <Typography fontWeight={600}>
                {isoToFormattedDate(announcement.publishDate)}
              </Typography>
              <AttentionContent
                className="announcement-content"
                dangerouslySetInnerHTML={{ __html: announcement.content }}
                textAlign="justify"
              />
            </Stack>
            {index !== announcements.length - 1 && <Divider />}
          </Fragment>
        ))
      )}
      {totalPages !== 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'end' },
            marginTop: theme.convert.pxToRem(32),
          }}
        >
          <Pagination
            count={totalPages}
            siblingCount={1}
            shape="rounded"
            onChange={handleChange}
            page={page}
          />
        </Box>
      ) : null}
    </PageWrapper>
  );
};
