import { API } from 'aws-amplify';

import type {
  IDebSecFilingId,
  IDebSecIdRequestPayload,
  IUCC1ResponsePayload,
  IRequestPayload,
  IAttachment,
  IRequestUCC1Payload,
  IResponseDebSec,
} from './models';
import type {
  IAttachmentRequest,
  IUploadingFile,
  UploadAttachmentRequest,
} from './attachments/models';
import type { IResponse, IFilingId } from '../../common/models/features/models';

const apiName = 'OnlineFilingAPI';
const primaryPath = 'filings';
const secondaryPath = 'ucc1';
const myInit = {};

export const createNewUCC1 = async () => {
  try {
    const res = await API.post(apiName, primaryPath, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data };
  }
};

export const getAttachments = async ({
  filingId,
}: IFilingId): Promise<IResponse<IAttachment[]>> => {
  try {
    const res = await API.get(apiName, `/attachments?filingId=${filingId}&all=true`, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const cancelUploadAttachments = ({ promise }: Pick<IUploadingFile, 'promise'>): void => {
  API.cancel(promise, 'File upload cancelled');
};

export const uploadAttachment = ({
  filingId,
  payload,
}: UploadAttachmentRequest): Promise<IResponse<IAttachment>> => {
  return API.post(apiName, `/${primaryPath}/${filingId}/attachments`, {
    body: payload,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const removeAttachment = async ({
  filingId,
  attachmentId,
}: IAttachmentRequest): Promise<IResponse<boolean>> => {
  try {
    const res = await API.del(
      apiName,
      `/${primaryPath}/${filingId}/attachments/${attachmentId}`,
      myInit
    );
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const getUCC1ById = async ({
  filingId,
}: IFilingId): Promise<IResponse<IUCC1ResponsePayload>> => {
  try {
    const res = await API.get(apiName, `/${secondaryPath}/${filingId}`, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const updateUCC1 = async ({
  filingId,
  payload,
}: IDebSecIdRequestPayload<IRequestUCC1Payload>) => {
  try {
    const res = await API.put(apiName, `/${secondaryPath}/${filingId}`, {
      body: payload,
    });
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const getUCC1DebSec = async ({ filingId }: IFilingId) => {
  try {
    const res = await API.get(apiName, `/${primaryPath}/${filingId}/debsecs?all=true`, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const createUCC1DebSec = async ({
  filingId,
  payload,
}: IDebSecIdRequestPayload<IRequestPayload>): Promise<IResponse<IResponseDebSec>> => {
  try {
    const res = await API.post(apiName, `/${primaryPath}/${filingId}/debsecs`, { body: payload });
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const getUCC1DebSecById = async ({ filingId, debsecId }: IDebSecFilingId) => {
  try {
    const res = await API.get(apiName, `/${primaryPath}/${filingId}/debsecs/${debsecId}`, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const updateUCC1DebSec = async ({
  filingId,
  debsecId,
  payload,
}: IDebSecIdRequestPayload<IRequestPayload>): Promise<IResponse<IResponseDebSec>> => {
  try {
    const res = await API.put(apiName, `/${primaryPath}/${filingId}/debsecs/${debsecId}`, {
      body: payload,
    });
    return res;
  } catch (err: any) {
    return { ...err?.response?.data };
  }
};

export const deleteUCC1DebSec = async ({
  filingId,
  debsecId,
}: IDebSecFilingId): Promise<IResponse<boolean | null | undefined>> => {
  try {
    const res = await API.del(apiName, `/${primaryPath}/${filingId}/debsecs/${debsecId}`, myInit);
    return { ...res };
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const getListOfStates = async () => {
  try {
    const res = await API.get(apiName, '/states', myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const getCollateralAddendumData = async () => {
  try {
    const [designationTypes, docStampTax, financingTypes, debtorTypes, debtorFilingTypes] =
      await Promise.all([
        API.get(apiName, '/alt-designation-types', myInit),
        API.get(apiName, '/doc-stamp-tax-types', myInit),
        API.get(apiName, '/financing-statement-coverage-types', myInit),
        API.get(apiName, '/debtor-types', myInit),
        API.get(apiName, '/debtor-filing-types', myInit),
      ]);
    return { designationTypes, docStampTax, financingTypes, debtorTypes, debtorFilingTypes };
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const cancelUCC1 = async (id: number) => {
  try {
    const res = await API.put(apiName, `/ucc1/${id}/cancel`, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data };
  }
};

export const getFloridaDocStampTax = async () => {
  const parameters = {
    queryStringParameters: {
      settingKeys: ['FLORIDA_DOC_STAMP_HELP'],
    },
  };
  try {
    const res = await API.get(apiName, '/settings', parameters);
    return res.payload[0].value;
  } catch (err: any) {
    return { ...err?.response.data };
  }
};
