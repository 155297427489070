import { API } from 'aws-amplify';

import { IResponse } from '../../common/models/features/models';
import { IPaymentId } from './models';
import { IPayment } from '../cart/models';

const apiName = 'OnlineFilingAPI';
const primaryPath = 'payments';
const myInit = {};

export const getPaymentRecord = async ({ paymentId }: IPaymentId): Promise<IResponse<IPayment>> => {
  try {
    const res = await API.get(apiName, `/${primaryPath}/${paymentId}`, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const submitZeroCase = async ({ paymentId }: IPaymentId): Promise<IResponse<null>> => {
  try {
    const res = await API.put(apiName, `/${primaryPath}/${paymentId}/confirm-zero-amount`, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const createPaymentLink = async (): Promise<IResponse<string>> => {
  try {
    const res = await API.get(apiName, `/make-payment`, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};
