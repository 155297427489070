import { Typography, styled } from '@mui/material';

export const Content = styled(Typography)(({ theme }) => ({
  lineHeight: 1.5,
  textAlign: 'justify',
  marginBottom: theme.convert.pxToRem(10),
}));

export const SubTitle = styled(Content)(({ theme }) => ({
  width: '100%',
  lineHeight: 1,
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 'bold',
  textDecoration: 'underline',
  textAlign: 'inherit',
  marginTop: theme.convert.pxToRem(10),
}));

export const MainTitle = styled(SubTitle)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  textAlign: 'center',
  marginBottom: theme.convert.pxToRem(20),
  marginTop: 0,
}));
