import { PasswordValidatorHelperProps } from '../../../common/models';
import { MINIMUM_PASSWORD_LENGTH } from '../../../common/constants/lengths';

export const validationConfig = (customErrors: PasswordValidatorHelperProps) => [
  { error: customErrors.lowerCase, text: 'One lowercase character' },
  { error: customErrors.upperCase, text: 'One uppercase character' },
  { error: customErrors.specialChar, text: 'One special character' },
  { error: customErrors.oneNumber, text: 'One number' },
  { error: customErrors.minLength, text: `${MINIMUM_PASSWORD_LENGTH} characters minimum` },
];
