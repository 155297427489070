import { MouseEvent, useCallback, useState, useEffect, useContext } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import { IUCC3Props } from '../models';
import { useSnackbar } from '../../../common/notification';
import { useModal } from '../../../common/context';
import { IDownloadHandler, IOnRemoveHandler, IUploadingFile } from '../../ucc1/attachments/models';
import { FileRejection, useDropzone } from 'react-dropzone';
import { parseFileErrorRejections } from '../../ucc1/utils';
import { removeUCC3Attachment, uploadUCC3Attachment } from '../ucc3.services';
import { StyledActionButton } from '../../../common/global-styles';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error-icon-base.svg';
import AttachmentsUi from './AttachmentsUi';
import Loader from '../../../common/components/loader';

import { IMessage } from '../../../common/models/features/models';
import { UnsavedHandlerContext } from '../../shared/ucc/unsaved-handler-context/UnsavedHandlerContext';
import { cancelUploadAttachments } from '../../ucc1/ucc1.services';

const Attachments = ({
  attachments,
  filingId,
  updateAttachmentsList,
  isAttachmentListLoading,
}: Pick<
  IUCC3Props,
  'attachments' | 'filingId' | 'updateAttachmentsList' | 'isAttachmentListLoading'
>) => {
  const { Snack } = useSnackbar();
  const { handleModalOpen, handleModalClose, handleSetModalActions } = useModal();
  const theme = useTheme();
  const [uploadingAttachments, setUploadingAttachments] = useState<IUploadingFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setHasPendingUploads, hasPendingUploads } = useContext(UnsavedHandlerContext);

  const onDropRejected = (fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      fileRejections.forEach((fileRejection) =>
        Snack.error(parseFileErrorRejections(fileRejection))
      );
    }
  };

  const uploadAttachments = async (uploadedFiles: IUploadingFile[]) => {
    try {
      const pendingPromises = uploadedFiles.map(({ promise }) => promise);
      setHasPendingUploads(true);
      const results = await Promise.allSettled(pendingPromises);
      setUploadingAttachments((state) => {
        return state.filter(
          (attachmentFile) =>
            !uploadedFiles.find((uploadedFile) => uploadedFile.id === attachmentFile.id)
        );
      });

      results.forEach((result) => {
        if (result.status === 'rejected') {
          result.reason?.response?.data?.messages?.forEach((message: IMessage) =>
            Snack.error(message.message)
          );
        }
      });

      if (results.some((result) => result.status === 'fulfilled')) {
        updateAttachmentsList({ filingId });
        Snack.success('File(s) successfully uploaded.');
      }
      setHasPendingUploads(false);
    } catch (err) {
      // Snack.error('API Error happend while uploading file(s)');
      setHasPendingUploads(false);
    }
  };

  const onDropAccepted = useCallback((acceptedFiles: File[]) => {
    const allUploads: IUploadingFile[] = [];
    acceptedFiles.forEach((file) => {
      const formData = new FormData();
      formData.append('attachment', file);

      const promise = uploadUCC3Attachment({ filingId, payload: formData });

      const attachment: IUploadingFile = {
        id: Date.now() + Number(Math.random().toFixed(4)) * 10000,
        filingId: filingId,
        fileName: file.name,
        fileType: file.type,
        promise,
      };
      allUploads.push(attachment);
    });

    setUploadingAttachments((state) => [...allUploads, ...state]);
    uploadAttachments(allUploads);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    minSize: 0,
    maxSize: 10485760,
    accept: {
      'application/pdf': ['pdf'],
      'image/tiff': ['tiff', 'tif'],
    },
  });

  const onCancelHandler = (e: MouseEvent) => {
    handleModalClose(e);
    Snack.info('Action canceled.');
  };

  const onRemoveHandler = async ({ e, attachmentId, filingId }: IOnRemoveHandler) => {
    setIsLoading(true);
    e.currentTarget.setAttribute('disabled', 'true');

    const res = await removeUCC3Attachment({ attachmentId, filingId });
    if (res.notOk) {
      Snack.error('Error happened while deleting file');
      setIsLoading(false);
      handleModalClose(e);
    } else {
      updateAttachmentsList({ filingId });
      Snack.success('Attachment is successfully removed.');
      setIsLoading(false);
      handleModalClose(e);
    }
  };

  const cancelAllPendingUploads = async () => {
    uploadingAttachments.forEach((upload) => {
      if (upload?.promise) {
        cancelUploadAttachments({ promise: upload.promise });
        setUploadingAttachments((state) => state.filter((item) => item.id !== upload.id));
      }
    });
  };
  const onRemove = async ({
    attachmentId,
    filingId,
    fileName,
  }: Omit<IDownloadHandler, 'fileType'>) => {
    handleModalOpen({
      title: 'Remove Attachment',
      modalContent: (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.convert.pxToRem(10),
          }}
        >
          <Typography variant="body1">Are you sure you want to remove {fileName}?</Typography>
          <Box
            sx={{
              display: 'inline-flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: theme.convert.pxToRem(10),
            }}
          >
            {<ErrorIcon color={theme.palette.error.main} />} This action cannot be undone.
          </Box>
        </Box>
      ),
      modalActions: (
        <Box
          width="100%"
          sx={{
            display: 'flex',
            gap: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(37) },
            flexDirection: 'row-reverse',
          }}
        >
          <StyledActionButton
            autoFocus
            fullWidth
            onClick={(e) => onRemoveHandler({ e, attachmentId, filingId })}
            variant="contained"
          >
            Yes, remove
          </StyledActionButton>
          <StyledActionButton onClick={onCancelHandler} variant="outlined">
            No, cancel
          </StyledActionButton>
        </Box>
      ),
    });
  };

  useEffect(() => {
    handleSetModalActions(
      <Box
        width="100%"
        sx={{
          display: 'flex',
          gap: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(37) },
          flexDirection: 'row-reverse',
        }}
      >
        <StyledActionButton
          autoFocus
          fullWidth
          variant="contained"
          disabled={isLoading}
          startIcon={isLoading ? <Loader /> : null}
        >
          {isLoading ? 'Yes, removing' : 'Yes, remove'}
        </StyledActionButton>
        <StyledActionButton onClick={onCancelHandler} variant="outlined">
          No, cancel
        </StyledActionButton>
      </Box>
    );
  }, [isLoading]);

  useEffect(() => {
    return () => {
      if (hasPendingUploads) {
        (async () => await cancelAllPendingUploads())();
      }
    };
  }, [hasPendingUploads]);

  return (
    <AttachmentsUi
      filingId={filingId}
      attachments={attachments}
      uploadingAttachments={uploadingAttachments}
      isDragActive={isDragActive}
      onRemove={onRemove}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      setUploadingAttachments={setUploadingAttachments}
      isAttachmentListLoading={isAttachmentListLoading}
    />
  );
};

export default Attachments;
