export enum Accordion {
  SendAcknowledgementTo = 0,
  Termination,
  Continuation,
  AmendReleaseCollateral,
  Assigment,
  Amendment,
  Authorization,
  Attachments,
}

export const accordionExpandAllState = [true, true, true, true, true, true, true, true];
export const accordionCollapseAllState = [false, false, false, false, false, false, false, false];
export const initialAccordionState = [true, false, false, false, false, false, false, false];
