import { Box, Button, useTheme } from '@mui/material';
import { useBeforeUnload } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

import Loader from '../../../common/components/loader';
import Attachments from '../attachments';
import SendAcknowledgementTo from '../send-acknowledgement-to';
import CollateralInformation from '../collateral-information';
import Addendum from '../addendum';
import ObscenitySSNTable from '../obscenity-ssn-table';
import AddendumCheckbox from '../addendum/AddendumCheckbox';
import DebtorsOrSecuredParties from '../debtors-or-secured-parties';
import { CustomAccordion } from '../../../common/components/accordion';
import { StyledGrid } from '../../shared/ucc/styles';
import { Accordion } from '../constants';
import { StyledUCCButton } from '../../start-ucc-filing/styled-items/StyledUccButton';
import type { IUCC1UIProps } from '../models';
import { PageTitle } from '../../../common/constants/features-constants';
import { useBlocker } from '../../../common/hooks/usePrompt';

import { EntityType } from '../../../common/models/features/models';
import PageWrapper from '../../../common/layouts/PageWrapper';
import useEditMode from '../../../common/hooks/useEditMode';

const Ucc1Ui = ({
  accordionState,
  handleAccordionState,
  handleCheckboxAccordionState,
  expandActionLabel,
  expandAction,
  control,
  formState,
  setValue,
  clearErrors,
  resetField,
  handleOnSave,
  handleOnComplete,
  debtors,
  securedParties,
  attachments,
  updateAttachmentsList,
  filingId,
  filingAlerts,
  isSavingUCC1,
  isOffensiveLanguageOverrided,
  setIsOffensiveLanguageOverrided,
  handleFormCancel,
  removeDebSec,
  openDebSecModal,
  designationTypes,
  docStampTax,
  financingTypesData,
  debtorTypesData,
  debtorFilingTypesData,
  isDirty,
  isSaved,
  isCompleted,
  isCanceled,
  hasPendingUploads,
  getValues,
  isAttachmentListLoading,
  isCompleteFormValid,
  doFormCancel,
  showBlocker,
  setShowBlocker,
}: IUCC1UIProps) => {
  const theme = useTheme();
  useEditMode(hasPendingUploads);
  useBeforeUnload(
    useCallback(
      (e) => {
        if (showBlocker) {
          e.preventDefault();
          e.returnValue = '';
          return true;
        }
      },
      [showBlocker]
    )
  );

  /*
   * There are few different cases but the major one is:
   * - If the form is dirty and still not saved nor completed nor canceled - show blocker
   * - Any other case you might think of will not trigger the blocker
   * */
  useEffect(() => {
    if ((isDirty && !isSaved && !isCompleted && !isCanceled) || hasPendingUploads) {
      setShowBlocker(true);
    } else {
      setShowBlocker(false);
    }
  }, [isDirty, isSaved, isCompleted, isCanceled, hasPendingUploads]);
  return (
    <PageWrapper title={PageTitle.UCC1}>
      <form>
        {filingAlerts && (
          <>
            <Box sx={{ height: theme.convert.pxToRem(18) }} />
            <ObscenitySSNTable
              alerts={filingAlerts}
              handleOffensiveLanguageOverrided={(e) =>
                setIsOffensiveLanguageOverrided(e.target.checked)
              }
            />
            <Box sx={{ height: theme.convert.pxToRem(18) }} />
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: theme.convert.pxToRem(8),
          }}
        >
          <Button
            sx={{
              whiteSpace: 'nowrap',
            }}
            variant="text"
            onClick={expandAction}
          >
            {expandActionLabel}
          </Button>
        </Box>
        <Box>
          <StyledGrid container gap={3.5}>
            <CustomAccordion
              title={'Send Acknowledgement To *'}
              isOpen={accordionState[Accordion.SendAcknowledgementTo]}
              handleChange={() => handleAccordionState(Accordion.SendAcknowledgementTo)}
            >
              <SendAcknowledgementTo control={control} formState={formState} />
            </CustomAccordion>
            <CustomAccordion
              title={'Debtors *'}
              isOpen={accordionState[Accordion.Debtors]}
              handleChange={() => handleAccordionState(Accordion.Debtors)}
            >
              <DebtorsOrSecuredParties
                parties={debtors}
                type={EntityType.Debtor}
                partyName={EntityType.Debtor}
                filingId={filingId}
                removeDebSec={removeDebSec}
                openDebSecModal={openDebSecModal}
              />
            </CustomAccordion>
            <CustomAccordion
              title={'Secured Parties *'}
              isOpen={accordionState[Accordion.SecuredParties]}
              handleChange={() => handleAccordionState(Accordion.SecuredParties)}
            >
              <DebtorsOrSecuredParties
                parties={securedParties}
                type={EntityType.Secured}
                partyName="Secured Party"
                filingId={filingId}
                removeDebSec={removeDebSec}
                openDebSecModal={openDebSecModal}
              />
            </CustomAccordion>
            <CustomAccordion
              title={'Collateral Information *'}
              isOpen={accordionState[Accordion.CollateralInformation]}
              handleChange={() => handleAccordionState(Accordion.CollateralInformation)}
            >
              <CollateralInformation
                control={control}
                formState={formState}
                designationTypes={designationTypes}
                docStampTax={docStampTax}
              />
            </CustomAccordion>
            <CustomAccordion
              title={'Addendum (optional)'}
              isOpen={accordionState[Accordion.Addendum]}
              handleChange={() => handleAccordionState(Accordion.Addendum)}
              hasAdditionalInfo
              titleItem={
                <AddendumCheckbox
                  control={control}
                  formState={formState}
                  handleCheckboxAccordionState={handleCheckboxAccordionState}
                  clearErrors={clearErrors}
                  resetField={resetField}
                  setValue={setValue}
                  getValues={getValues}
                />
              }
            >
              <Addendum
                control={control}
                formState={formState}
                financingTypesData={financingTypesData}
                debtorTypesData={debtorTypesData}
                debtorFilingTypesData={debtorFilingTypesData}
              />
            </CustomAccordion>
            <CustomAccordion
              title={'Attachments (optional)'}
              isOpen={accordionState[Accordion.Attachments]}
              handleChange={() => handleAccordionState(Accordion.Attachments)}
            >
              <Attachments
                attachments={attachments}
                filingId={filingId}
                updateAttachmentsList={updateAttachmentsList}
                isAttachmentListLoading={isAttachmentListLoading}
              />
            </CustomAccordion>
          </StyledGrid>
        </Box>
        <Box sx={{ height: 40, width: '100%' }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-start' },
            alignItems: { xs: 'center', md: 'flex-end' },
            width: '100%',
            margin: '0 auto',
            flexDirection: { xs: 'column-reverse', md: 'row-reverse' },
            gap: { xs: theme.convert.pxToRem(8), md: theme.convert.pxToRem(24) },
          }}
        >
          <Box
            gap={{ xs: theme.convert.pxToRem(30), md: theme.convert.pxToRem(40) }}
            display="flex"
          >
            <StyledUCCButton
              variant="contained"
              onClick={handleOnSave}
              disabled={formState.isSubmitting}
              startIcon={isSavingUCC1 ? <Loader /> : null}
            >
              Save
            </StyledUCCButton>
            <StyledUCCButton
              type="submit"
              variant="contained"
              onClick={handleOnComplete}
              startIcon={formState.isSubmitting && !isSavingUCC1 ? <Loader /> : null}
              disabled={
                !isCompleteFormValid ||
                formState.isSubmitting ||
                (!!filingAlerts && !formState.isDirty && !isOffensiveLanguageOverrided)
              }
            >
              Complete
            </StyledUCCButton>
          </Box>
          <Button
            variant="text"
            sx={{ padding: theme.convert.pxToRem(0), margin: theme.convert.pxToRem(0) }}
            onClick={handleFormCancel}
          >
            Cancel
          </Button>
        </Box>
        <Box sx={{ height: 267, width: '100%' }} />
      </form>
    </PageWrapper>
  );
};

export default Ucc1Ui;
