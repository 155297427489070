import * as yup from 'yup';
import { CountryType, EntityType, NameType } from '../../../common/models/features/models';

export const schema = yup
  .object({
    type: yup.string().oneOf(Object.values(EntityType)),
    nameType: yup.string().oneOf(Object.values(NameType)),
    name: yup.string().when('nameType', {
      is: NameType.organization,
      then: (schema) =>
        schema.required('Organization name is required.').max(75, 'Max length is 75 characters.'),
    }),
    lastName: yup.string().when('nameType', {
      is: NameType.individual,
      then: (schema) =>
        schema.required('Surname is required.').max(25, 'Max length is 25 characters.'),
    }),
    firstName: yup.string().when('nameType', {
      is: NameType.individual,
      then: (schema) =>
        schema.required('First personal name is required.').max(25, 'Max length is 25 characters.'),
    }),
    middleName: yup.string().when('nameType', {
      is: NameType.individual,
      then: (schema) => schema.max(10, 'Max length is 10 characters.'),
    }),
    suffix: yup.string().when('nameType', {
      is: NameType.individual,
      then: (schema) => schema.max(12, 'Max length is 12 characters.'),
    }),
    isForeign: yup.boolean(),
    address1: yup
      .string()
      .max(44, 'Max length is 44 characters.')
      .required('Address line 1 is required.'),
    address2: yup.string().max(44, 'Max length is 44 characters.'),
    city: yup.string().max(25, 'Max length is 25 characters.').required('City is required.'),
    zip: yup
      .string()
      .max(10, 'Max length is 10 characters.')
      .when('isForeign', {
        is: false,
        then: (schema) =>
          schema.max(10, 'Max length is 10 characters.').required('Zip code is required.'),
      }),
    state: yup.string().when('isForeign', {
      is: false,
      then: (schema) => schema.required('State is required.'),
    }),
    country: yup.string().oneOf(Object.values(CountryType)),
  })
  .required();
