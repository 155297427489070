import { Box, useTheme } from '@mui/material';
import Loader from '../../../../common/components/loader';
import { StyledModalSubmitButton } from '../../../../common/global-styles';

interface IModalActionsContentProps {
  password: string;
  loading?: boolean;
  handleClose: () => void;
  onSubmit: (e: React.SyntheticEvent) => void;
}

export const DebitInfoDisplayModalActions = ({
  password,
  loading,
  handleClose,
  onSubmit,
}: IModalActionsContentProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        gap: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(34) },
        flexDirection: 'row-reverse',
      }}
    >
      <StyledModalSubmitButton
        disabled={password.length === 0 || loading}
        startIcon={loading ? <Loader /> : null}
        onClick={(e) => onSubmit(e)}
        variant="contained"
      >
        {loading ? 'Displaying' : 'Display'}
      </StyledModalSubmitButton>
      <StyledModalSubmitButton onClick={handleClose} variant="outlined">
        Cancel
      </StyledModalSubmitButton>
    </Box>
  );
};
