import { TextInput } from '../../../../common/components/inputs';
import { FormHelperText, Grid, InputLabel, Typography } from '@mui/material';
import { ILoginFormUI } from '../../../../common/models';
import { Controller } from 'react-hook-form';
import { FormData } from './PersonalInfoForm';
import { useMyAccount } from '../../../../common/context';
import { FormActionButtons } from '../FormActionButtons';

export const PersonalInfoFormUI = ({
  onSubmit,
  control,
  formState: { errors, isValid, isSubmitting },
}: ILoginFormUI<FormData>) => {
  const { myAccountData } = useMyAccount();

  return (
    <form onSubmit={onSubmit}>
      <Grid container padding={2} rowGap={2}>
        <Grid item xs={12} md={6} display="flex" alignItems="center">
          <Grid container alignItems="center" rowGap={1}>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="name">
                <Typography variant="h5">Name*</Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={6.5}>
              <Controller
                name="name"
                defaultValue={myAccountData.personalInformation.name}
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <TextInput
                    autoFocus
                    tabIndex={1}
                    sx={{ maxWidth: (theme) => theme.convert.pxToRem(300) }}
                    {...field}
                    inputRef={ref}
                    error={!!errors.name?.message}
                    placeholder="Enter name"
                  />
                )}
              />
              <FormHelperText error={!!errors.name?.message}>{errors.name?.message}</FormHelperText>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} display="flex" alignItems="center">
          <Grid container alignItems="center" rowGap={1}>
            <Grid item xs={12} md={5} paddingRight={2}>
              <InputLabel htmlFor="phoneNumber">
                <Typography variant="h5">Phone number</Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={6.5}>
              <Controller
                name="phoneNumber"
                defaultValue={myAccountData.personalInformation.phoneNumber || ''}
                control={control}
                rules={{ required: false }}
                render={({ field: { ref, ...field } }) => (
                  <TextInput
                    sx={{ maxWidth: (theme) => theme.convert.pxToRem(300) }}
                    {...field}
                    inputRef={ref}
                    error={!!errors.phoneNumber?.message}
                    placeholder="Enter phone number"
                  />
                )}
              />
              <FormHelperText error={!!errors.phoneNumber?.message}>
                {errors.phoneNumber?.message}
              </FormHelperText>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FormActionButtons isSubmitDisabled={!isValid || isSubmitting} name={'personalInfo'} />
    </form>
  );
};
