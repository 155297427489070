import * as yup from 'yup';
import { DownloadTypeEnum } from './DownloadTypeEnum';
import { Moment } from 'moment';
import { fileTypeDownloadOptions } from './fileTypeDownloadOptions';

export const saveSchema = yup.object().shape({
  downloadType: yup.string().oneOf([DownloadTypeEnum.FULL, DownloadTypeEnum.REGULAR]).required(),
  fileType: yup.string().oneOf(fileTypeDownloadOptions),
  fileDate: yup.mixed<Moment>().when('downloadType', {
    is: DownloadTypeEnum.REGULAR.toString(),
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
});

export type FormDataType = yup.InferType<typeof saveSchema>;
