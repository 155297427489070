import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledTabsWrapper = styled(Box)(({ theme }) => ({
  maxWidth: theme.convert.pxToRem(882),
  height: theme.convert.pxToRem(404),
  margin: `${theme.convert.pxToRem(0)} auto`,
  boxShadow: '0px 0px 8px rgba(176, 192, 237, 0.5)',
  [theme.breakpoints.up('xs')]: {
    maxWidth: theme.convert.pxToRem(351),
  },
  [theme.breakpoints.only('md')]: {
    maxWidth: theme.convert.pxToRem(688),
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: theme.convert.pxToRem(882),
  },
}));
