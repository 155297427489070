/* eslint-disable prettier/prettier */
import { Box, BoxProps, Button, Grid, styled, Typography, TypographyProps } from '@mui/material';
import { highlightSelectionColor, regularTextColor } from '../../common/constants/colors';
import { NavLink } from 'react-router-dom';
import bgImg from '../../assets/icons/homepage/background.png';
import theme from '../../common/theme';

export const HomeHeading = styled(Box)<BoxProps>`
  height: 500px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${bgImg});
`;
export const StyledButton = styled(Button)<BoxProps>`
  padding: 12px 26px;
  @media (min-width: 1024px) {
    width: 268px;
  }

  &:focus {
    border-width: 0px;
  }
`;

export const HeadingText = styled(Grid)<BoxProps>`
  background: rgba(255, 255, 255, 0.7);
  max-width: 790px;
  position: relative;

  &::before {
    content: '';
    width: 65px;
    height: 65px;
    top: -20px;
    left: 10px;
    position: absolute;
    border-style: solid;
    border-width: 8px 0 0 8px;
    border-color: ${highlightSelectionColor};
  }

  &::after {
    content: '';
    width: 65px;
    height: 65px;
    bottom: 10px;
    right: -20px;
    position: absolute;
    border-style: solid;
    border-width: 0 8px 8px 0;
    border-color: ${highlightSelectionColor};

    @media (max-width: 376px) {
      bottom: -10px;
      right: 10px;
    }
  }
`;

export const GrayHomeSection = styled(Box)`
  gap: 48px;
  display: flex;
  padding: 60px;
  align-items: center;
  flex-direction: column;

  @media (min-width: 375px) and (max-width: 767px) {
    gap: 20px;
    padding: 40px 28px;
  }
  @media (min-width: 1024px) {
    padding: 60px 80px;
  }

  @media (max-width: 375px) {
    gap: 20px;
    padding: 30px 40px;
  }
`;

export const PrimarySectionHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  textAlign: 'center',
  color: highlightSelectionColor,
  fontSize: theme.typography.pxToRem(28),
  lineHeight: theme.typography.pxToRem(36),
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(30),
    lineHeight: theme.typography.pxToRem(28),
  },
}));

export const SecondarySectionHeading = styled(Typography)<TypographyProps>(({ theme }) => ({
  width: '100%',
  fontSize: theme.typography.pxToRem(20),
  lineHeight: theme.typography.pxToRem(28),
  fontWeight: 700,
  color: regularTextColor,
}));

export const IconLink = styled(NavLink)<BoxProps>(({ theme }) => ({
  width: theme.convert.pxToRem(120),
  height: theme.convert.pxToRem(120),
  display: 'flex',
  cursor: 'pointer',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'white',
}));

export const ArrowIconBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: theme.convert.pxToRem(12),
  height: theme.convert.pxToRem(20),
  position: 'absolute',
  top: theme.convert.pxToRem(20),
  [theme.breakpoints.up('sm')]: {
    top: theme.convert.pxToRem(30),
  },
  '& button': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.convert.pxToRem(30),
    height: theme.convert.pxToRem(30),
  },
}));

export const AttentionContent = styled(Typography)<TypographyProps>`
  text-align: left;
  word-break: break-word;
  p {
    margin: 0;
  }

  > *:nth-of-type(1) {
    display: inline;
  }

  strong {
    font-weight: 600;
  }
`;

export const bottomHomeSectionContentPadding = {
  xs: 0,
  md: theme.convert.pxToRem(10),
  lg: theme.convert.pxToRem(42),
  xl: theme.convert.pxToRem(173),
};
