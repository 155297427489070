import CartAccordions from '../features/cart/CartAccordions';
import { useUserInfo } from '../common/context';
import { PageTitle } from '../common/constants/features-constants';
import PageWrapper from '../common/layouts/PageWrapper';
import { useEffect } from 'react';

const Cart = () => {
  const { cartFilingsDetails, getCartDetails } = useUserInfo();

  useEffect(() => {
    (async () => {
      getCartDetails();
    })();
  }, []);

  return (
    <PageWrapper
      title={`${PageTitle.CART} (${cartFilingsDetails?.totalFilingsCount ?? 0} filings)`}
    >
      <CartAccordions />
    </PageWrapper>
  );
};
export default Cart;
