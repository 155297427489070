import { useState, useContext, createContext } from 'react';

import { Modal } from '../components/modals/Modal';
import type {
  ILayoutWrapperProps,
  IHandleModalClose,
  IModalContext,
  handleOpenModalProps,
} from '../models';

const ModalContext = createContext<IModalContext | undefined>(undefined);

function ModalProvider({ children }: ILayoutWrapperProps) {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [modalActions, setModalActions] = useState<React.ReactNode>(null);
  const [xButtonAction, setXButtonAction] = useState<React.ReactNode>(null);
  const [title, setModalTitle] = useState<React.ReactNode>(null);
  const [subtitle, setModalSubtitle] = useState<React.ReactNode>(null);
  const [additionalInfo, setModalAdditionalInfo] = useState<React.ReactNode>(null);

  const resetModalState = () => {
    setModalTitle(undefined);
    setModalSubtitle(undefined);
    setModalContent(null);
    setModalActions(null);
    setXButtonAction(null);
    setModalAdditionalInfo(null);
  };

  const handleModalClose: IHandleModalClose = (event, reason) => {
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) return;
    setOpen(false);
    resetModalState();
  };

  const handleModalOpen = ({
    title,
    subtitle,
    modalContent,
    modalActions,
    xButtonAction,
  }: handleOpenModalProps) => {
    title && setModalTitle(title);
    setModalContent(modalContent);
    setModalActions(modalActions);
    setOpen(true);
    subtitle && setModalSubtitle(subtitle);
    xButtonAction && setXButtonAction(xButtonAction);
  };

  const handleSetModalContent = (content: React.ReactNode) => {
    setModalContent(content);
  };
  const handleSetModalActions = (actions: React.ReactNode) => {
    setModalActions(actions);
  };

  const handleSetModalXButton = (xButtonAction: React.ReactNode) => {
    setXButtonAction(xButtonAction);
  };

  const value = {
    open,
    modalContent,
    modalActions,
    handleModalClose,
    handleModalOpen,
    handleSetModalContent,
    handleSetModalActions,
    handleSetModalXButton,
    setModalTitle,
    setModalAdditionalInfo,
  };

  return (
    <ModalContext.Provider value={value}>
      {children}
      {open ? (
        <Modal
          open={open}
          handleClose={handleModalClose}
          modalActions={modalActions}
          xButtonAction={xButtonAction}
          additionalInfo={additionalInfo}
          title={title}
          subtitle={subtitle}
        >
          {modalContent}
        </Modal>
      ) : null}
    </ModalContext.Provider>
  );
}

function useModal() {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useContextModal must be used within a ModalProvider');
  }
  return context;
}

export { ModalProvider, useModal };
