import { Controller } from 'react-hook-form';
import { Typography, Grid, TextField, FormHelperText, InputLabel } from '@mui/material';
import Loader from '../../../../common/components/loader';
import {
  StyledBoxWrapper,
  StyledSubmitButton,
} from '../../../../common/components/user-auth-components/common-auth-styles';
import type { FormData } from './EmailLoginForm';
import type { ILoginFormUI } from '../../../../common/models';

const EmailLoginFormUI = ({
  onSubmit,
  formState: { errors, isValid, isSubmitting },
  control,
}: ILoginFormUI<FormData>) => (
  <form onSubmit={onSubmit}>
    <StyledBoxWrapper p={2}>
      <Typography textAlign="center" variant="body3" pb="2.5rem">
        We will email you a one-time code for a password-free sign in.
      </Typography>
      <Grid container alignItems="center">
        <Grid item xs={12} md={3} sx={{ paddingBottom: { xs: 1 } }}>
          <InputLabel htmlFor="email">
            <Typography variant="h4" fontWeight={400}>
              Email *
            </Typography>
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={9}>
          <Controller
            name="email"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...field}
                inputRef={ref}
                placeholder="Enter email"
                error={!!errors.email?.message}
                type="email"
                required
                autoFocus
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={9}>
          <FormHelperText error={!!errors.email?.message}>{errors.email?.message}</FormHelperText>
        </Grid>
      </Grid>
      <Grid container alignItems="center" pt="2.5rem">
        <Grid item xs={12}>
          <StyledSubmitButton
            disabled={!isValid || isSubmitting}
            variant="contained"
            type="submit"
            startIcon={isSubmitting ? <Loader /> : null}
            fullWidth
          >
            {isSubmitting ? 'Sending Code' : 'Send Code'}
          </StyledSubmitButton>
        </Grid>
      </Grid>
    </StyledBoxWrapper>
  </form>
);

export default EmailLoginFormUI;
