import { Typography, styled } from '@mui/material';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.typography.pxToRem(20),

  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
}));
