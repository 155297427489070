import { API, Auth } from 'aws-amplify';
import { IResponse } from '../../common/models/features/models';
import { IFilingHistoryQueryParams } from './models';
const apiName = 'OnlineFilingAPI';

export const getFilingHistory = async (
  queryParams: IFilingHistoryQueryParams
): Promise<IResponse<any>> => {
  try {
    const myInit = {
      queryStringParameters: queryParams,
      headers: {
        csrf_token: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    };
    const res = await API.get(apiName, '/filings/history?all=true', myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};
