import { useState } from 'react';
import { AccordionDetails } from '@mui/material';
import { PersonalInfoView } from './PersonalInfoView';
import { useMyAccount, useUserInfo } from '../../../../common/context';
import { CustomAccordion } from '../../../../common/components/accordion';
import { PersonalInfoForm } from '../../forms/personal-info-form/PersonalInfoForm';

export const PersonalInfoAccordion = () => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { setFullName } = useUserInfo();

  const {
    openAccordion,
    handleChangeAccordionInfo,
    editModeAccordion,
    handleChangeEditMode,
    myAccountData,
    updatePersonalInfo,
    handleCancel,
  } = useMyAccount();

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSubmitting(true);
    await updatePersonalInfo({ name, phoneNumber });
    setFullName(name);
    setIsSubmitting(false);
  };

  return (
    <CustomAccordion
      title={'Personal Information'}
      isOpen={openAccordion.personalInfo}
      handleChange={handleChangeAccordionInfo}
    >
      <AccordionDetails>
        {editModeAccordion.personalInfo ? (
          <PersonalInfoForm
            setName={setName}
            setPhoneNumber={setPhoneNumber}
            setIsValid={setIsValid}
          />
        ) : (
          <PersonalInfoView
            name={myAccountData.personalInformation.name}
            phoneNumber={myAccountData.personalInformation.phoneNumber}
          />
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
};
