import { Button } from '@mui/material';
import { useMyAccount } from '../../../common/context';

export const EditButton = ({ name }: { name: string }) => {
  const { handleChangeEditMode } = useMyAccount();

  return (
    <Button name={name} onClick={handleChangeEditMode}>
      Edit
    </Button>
  );
};
