import { Navigate, useLocation, useOutlet } from 'react-router-dom';

import { useAuth } from '../context';
import Layout from './Layout';
import IdleTimeComponent from '../components/idle-time-component';

import Loader from '../components/loader';
import { Box, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { AuthedState } from '../context/types';
import PageWrapper from './PageWrapper';

/** TODO: Introduce PageWrapper component-wrap children, use title from ILayoutWithTitleProps to pass down **/
const AuthGuard = () => {
  const theme = useTheme();
  const { authed, userIdleTime, checkUserSession } = useAuth();
  const outlet = useOutlet();
  const location = useLocation();
  const [hasHandledInitialRedirectURI, setHasHandledInitialRedirectURI] = useState(false);
  const isOnRedirectedPages =
    location.pathname.includes('cancel-payment') ||
    location.pathname.includes('fail-payment') ||
    location.pathname.includes('success-payment');
  const shouldHandleRedirectedPages = !hasHandledInitialRedirectURI && isOnRedirectedPages;
  useEffect(() => {
    checkUserSession(true);
    // WARNING: dependency array is left out intentionally! We need to check for user session on every re-render
    // of this component to be able to handle logging out from a different tab in the same browser.
  });

  useEffect(() => {
    if (shouldHandleRedirectedPages || authed === AuthedState.CHECKING_AUTHORIZATION) {
      setHasHandledInitialRedirectURI(true);
    }
  }, [authed, shouldHandleRedirectedPages]);

  if (authed === AuthedState.NOT_AUTHED) {
    return <Navigate to="/onlinefiling/login" replace state={{ path: location.pathname }} />;
  }

  if (shouldHandleRedirectedPages || authed === AuthedState.CHECKING_AUTHORIZATION) {
    return (
      <Layout>
        <PageWrapper title={''}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            minHeight={theme.convert.pxToRem(350)}
          >
            <Loader color="blue" size={40} />
          </Box>
        </PageWrapper>
      </Layout>
    );
  }

  return (
    <Layout>
      {outlet}
      {userIdleTime && <IdleTimeComponent idleTime={userIdleTime} />}
    </Layout>
  );
};

export default AuthGuard;
