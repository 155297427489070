import { Button, Grid, Typography } from '@mui/material';
import theme from '../../common/theme';
import {
  bottomHomeSectionContentPadding,
  GrayHomeSection,
  IconLink,
  SecondarySectionHeading,
} from './home-page-custom-styled-component';

import { ReactComponent as FeesSVG } from '../../assets/icons/homepage/fees.svg';
import { ReactComponent as OnlineFilingSVG } from '../../assets/icons/homepage/online-filing.svg';
import { ReactComponent as UCCSearchSVG } from '../../assets/icons/homepage/ucc-search.svg';
import { feesPageURL, filing, uccSearchPageURL } from '../../common/constants/routes';

const HowCanWeHelp = () => {
  const helpSectionSVGs = [
    { Component: FeesSVG, url: feesPageURL, title: 'UCC Fees' },
    { Component: OnlineFilingSVG, url: filing, title: 'Online Filing' },
    { Component: UCCSearchSVG, url: uccSearchPageURL, title: 'UCC Search' },
  ];
  return (
    <GrayHomeSection sx={{ backgroundColor: theme.palette.grey[200] }}>
      <SecondarySectionHeading textAlign={{ xs: 'start' }}>
        How can we help?
      </SecondarySectionHeading>
      <Grid
        container
        justifyContent={'space-between'}
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        paddingX={bottomHomeSectionContentPadding}
      >
        {helpSectionSVGs.map(({ Component, url, title }, idx) => (
          <Grid
            item
            key={`helpSection_${idx}`}
            marginBottom={{ sm: 4, md: 0 }}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <IconLink to={url} tabIndex={-1}>
              <Button
                sx={{
                  padding: 0,
                  width: (theme) => theme.convert.pxToRem(120),
                  height: (theme) => theme.convert.pxToRem(120),
                  borderRadius: '50%',
                }}
              >
                <Component />
              </Button>
            </IconLink>
            <Typography variant="h4" marginTop={2}>
              {title}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </GrayHomeSection>
  );
};

export default HowCanWeHelp;
