import { Theme } from '@mui/material/styles';
import { dialogShadowColor } from '../constants/colors';

export const MuiDialog = {
  styleOverrides: {
    paper: ({ theme }: { theme: Theme }) => ({
      [theme.breakpoints.up('xs')]: {
        minWidth: theme.convert.pxToRem(352),
      },
      [theme.breakpoints.up('md')]: {
        minWidth: theme.convert.pxToRem(684),
      },
      borderRadius: theme.convert.pxToRem(2),
      boxShadow: `0px 2px 30px rgba(${dialogShadowColor}, 0.5)`,
    }),
  },
};
