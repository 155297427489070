export const expandActionLabels = {
  ExpandAll: 'Expand All',
  CollapseAll: 'Collapse All',
};

export const PageTitle = {
  ONLINE_FILING: 'Online Filing',
  UCC1: 'Online Filing - UCC1 Form',
  UCC3: 'Online Filing - UCC3 Form',
  DASHBOARD: 'Dashboard',
  CART: 'Cart',
  MY_ACCOUNT: 'Account',
  HELP: 'Help',
  ACH_ACCOUNTS: 'ACH Accounts',
  ANNOUNCEMENTS: 'Announcements',
  XML_IMPLEMENTATION_GUIDE: 'XML Implementation Guide',
  FORMS: 'Forms',
  DOWNLOAD: 'Download',
  FEES: 'Fees',
  SEARCH: 'Search',
  SEARCH_RESULTS_DETAIL: 'Search Results Detail',
  DEBTOR_LISTING: 'Debtor Listing',
  SECURED_LISTING: 'Secured Listing',
  FILING_HISTORY: 'Filing History',
};

export const GenericErrorMessage = 'Oops! Something went wrong. Please try again later.';

export const SEARCH_RULES_DOC_NAME = 'UCC_Debtor_Name_Compaction_and_Search_Rules.pdf';
