import { Grid, Box, Typography, useTheme } from '@mui/material';

const Continuation = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        paddingY: theme.convert.pxToRem(28),
        paddingLeft: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(40) },
        paddingRight: {
          xs: theme.convert.pxToRem(20),
          md: theme.convert.pxToRem(40),
          xl: theme.convert.pxToRem(200),
        },
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">
            Effectiveness of the Financing Statement identified above with respect to security
            interest(s) of the Secured Party authorizing this Continuation Statement is continued
            for the additional period provided by applicable law.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Continuation;
