import { Box, Button, Typography, useTheme } from '@mui/material';

import Loader from '../../../common/components/loader';
import AttachmentListItem from './AttachmentListItem';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload-icon-base.svg';

import { IAttachmentUI } from './models';
import { uploadFileColor } from '../../../common/constants/colors';
import { ATTACHMENTS_TOTAL_SIZE_MESSAGE } from '../../shared/ucc/utils';

const AttachmentsUi = ({
  filingId,
  attachments,
  uploadingAttachments,
  isDragActive,
  getRootProps,
  getInputProps,
  onRemove,
  setUploadingAttachments,
  isAttachmentListLoading,
}: IAttachmentUI) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 400,
        lineHeight: theme.typography.pxToRem(20),
      }}
    >
      <Box
        sx={{
          paddingY: theme.convert.pxToRem(28),
          paddingX: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(40) },
          display: 'flex',
          gap: theme.convert.pxToRem(7),
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Button
          {...getRootProps()}
          sx={{
            padding: theme.convert.pxToRem(24),
            border: (theme) => `1px dashed ${theme.palette.info.main}`,
            opacity: isDragActive ? 0.3 : 1,
            borderRadius: theme.convert.pxToRem(4),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: theme.convert.pxToRem(5),
            '&:hover': {
              cursor: 'pointer',
              textDecoration: 'none',
            },
          }}
        >
          <input {...getInputProps()} />
          <UploadIcon color={uploadFileColor} />
          <Typography sx={{ color: uploadFileColor, fontSize: 'inherit', lineHeight: 'inherit' }}>
            Browse File or Drag Files Here
          </Typography>
        </Button>
        <Typography
          sx={{
            color: (theme) => theme.palette.grey[500],
            fontSize: 'inherit',
            lineHeight: 'inherit',
          }}
        >
          {ATTACHMENTS_TOTAL_SIZE_MESSAGE}
        </Typography>
      </Box>
      <Box sx={{ maxHeight: theme.convert.pxToRem(300), overflowY: 'auto' }}>
        {uploadingAttachments.map((attachment) => (
          <AttachmentListItem
            key={attachment.id}
            uploadingAttachment={attachment}
            setUploadingAttachments={setUploadingAttachments}
          />
        ))}
        {!isAttachmentListLoading ? (
          attachments.map((attachment) => (
            <AttachmentListItem
              key={attachment.id}
              attachment={attachment}
              onRemove={(e) =>
                onRemove({ attachmentId: attachment.id, fileName: attachment.name, filingId })
              }
            />
          ))
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={theme.convert.pxToRem(100)}
          >
            <Loader color="blue" size={40} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AttachmentsUi;
