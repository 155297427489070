import {
  Grid,
  Typography,
  Box,
  TextField,
  FormHelperText,
  useTheme,
  InputLabel,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import type { FormData } from './StartUcc3Form';
import type { ILoginFormUI } from '../../../common/models';
import { StyledVerifyButton } from '../styled-items/StyledVerifyButton';
import Loader from '../../../common/components/loader';

interface StartUcc3FormUIProps extends ILoginFormUI<FormData> {
  setVerifyNumber: React.Dispatch<string | null>;
}

const StartUcc3FormUI = ({
  onSubmit,
  formState: { errors, isValid, isSubmitting },
  control,
  setVerifyNumber,
}: StartUcc3FormUIProps) => {
  const theme = useTheme();
  return (
    <Grid
      item
      md={10}
      sm={11}
      sx={{ paddingLeft: { xs: 5, md: 7 }, paddingTop: { xs: 1, md: 1, lg: 0 } }}
    >
      <form onSubmit={onSubmit}>
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent="start"
          rowGap={[
            theme.convert.pxToRem(12),
            theme.convert.pxToRem(13),
            theme.convert.pxToRem(13),
            theme.convert.pxToRem(0),
          ]}
        >
          <Grid item sm={12} md={12} lg={5} xl={3.5}>
            <InputLabel htmlFor="code">
              <Typography variant="body1">INITIAL FINANCING STATEMENT FILE #</Typography>
            </InputLabel>
          </Grid>
          <Grid item sm={12} md={12} lg={6} xl={7}>
            <Box
              display="flex"
              position="relative"
              maxWidth={[theme.convert.pxToRem(282), theme.convert.pxToRem(472)]}
            >
              <Controller
                name="code"
                defaultValue=""
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    placeholder="Enter file number"
                    error={!!errors.code?.message}
                    type="text"
                    required
                    onChange={(e) => {
                      onChange(e);
                      setVerifyNumber(e.target.value.toUpperCase());
                    }}
                    autoFocus
                    sx={{
                      input: {
                        width: '70%',
                        fontSize: theme.typography.pxToRem(16),
                        textTransform: 'uppercase',
                      },
                      '& > .MuiInputBase-root': {
                        height: { sm: theme.convert.pxToRem(32), md: theme.convert.pxToRem(47) },
                      },
                    }}
                  />
                )}
              />
              <StyledVerifyButton
                disabled={!isValid || isSubmitting}
                type="submit"
                startIcon={isSubmitting ? <Loader /> : null}
                fullWidth
                variant="contained"
                style={{
                  width: isSubmitting ? '40%' : '25%',
                }}
              >
                {isSubmitting ? 'Verifying' : 'Verify'}
              </StyledVerifyButton>
            </Box>
          </Grid>
        </Grid>
        <Grid container display="flex" alignItems="center" justifyContent="start">
          <Grid item sm={12} md={12} lg={5} xl={3.5}></Grid>
          <Grid item sm={12} md={12} lg={6} xl={7}>
            <FormHelperText error={!!errors.code?.message}>{errors.code?.message}</FormHelperText>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default StartUcc3FormUI;
