import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import { useModal } from '../../../../../common/context';
import { deleteUCC3DebSec } from '../../../../ucc3/ucc3.services';
import { useSnackbar } from '../../../../../common/notification';
import { StyledModalSubmitButton } from '../../../../../common/global-styles';

import { RemoveDebSec } from '../../../../ucc1/models';
import { UseRemoveDebSecModalProps } from '../../../../../common/models/features/models';
import { CloseIconButton } from '../../../../../common/global-styles/CloseIconButton';
import Loader from '../../../../../common/components/loader';

const useRemoveDebSecModal = ({ getData }: UseRemoveDebSecModalProps) => {
  const { handleModalOpen, handleModalClose, handleSetModalActions } = useModal();
  const theme = useTheme();
  const { Snack } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModal = ({ partyName, debsecId, filingId }: RemoveDebSec) => {
    handleModalOpen({
      title: `Remove ${partyName}`,
      modalContent: (
        <Typography variant="h4">{`Are you sure you want to remove this ${partyName.toLowerCase()}?`}</Typography>
      ),
      xButtonAction: <CloseIconButton onClick={handleModalClose} />,
      modalActions: (
        <Box
          sx={{
            display: 'flex',
            gap: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(34) },
            flexDirection: 'row-reverse',
          }}
        >
          <StyledModalSubmitButton
            tabIndex={0}
            autoFocus
            onClick={async (e) => {
              setIsLoading(true);
              if (filingId) {
                const { notOk, messages } = await deleteUCC3DebSec({ filingId, debsecId });
                if (!notOk) {
                  Snack.success(`${partyName} is successfully removed.`);
                  getData(filingId);
                  setIsLoading(false);
                  handleModalClose(e);
                } else {
                  const message = messages
                    ?.map(({ message }: { message: string }) => message)
                    .join(' ');
                  Snack.error(
                    typeof message === 'string' ? message : 'Your request failed to delete.'
                  );
                  setIsLoading(false);
                  handleModalClose(e);
                }
              } else {
                Snack.error('Missing filing ID number.');
                setIsLoading(false);
                handleModalClose(e);
              }
            }}
            variant="contained"
          >
            Yes, remove
          </StyledModalSubmitButton>
          <StyledModalSubmitButton
            onClick={(e) => {
              handleModalClose(e);
              Snack.info('Action canceled.');
            }}
            variant="outlined"
          >
            No, cancel
          </StyledModalSubmitButton>
        </Box>
      ),
    });
  };

  useEffect(() => {
    handleSetModalActions(
      <Box
        sx={{
          display: 'flex',
          gap: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(34) },
          flexDirection: 'row-reverse',
        }}
      >
        <StyledModalSubmitButton
          tabIndex={0}
          autoFocus
          disabled={isLoading}
          startIcon={isLoading ? <Loader /> : null}
          variant="contained"
        >
          {isLoading ? 'Yes, removing' : 'Yes, remove'}
        </StyledModalSubmitButton>
        <StyledModalSubmitButton
          onClick={(e) => {
            handleModalClose(e);
            Snack.info('Action canceled.');
          }}
          variant="outlined"
        >
          No, cancel
        </StyledModalSubmitButton>
      </Box>
    );
  }, [isLoading]);

  return { handleOpenModal };
};

export default useRemoveDebSecModal;
