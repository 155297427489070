import { Fragment } from 'react';
import {
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  useTheme,
} from '@mui/material';
import { ICompletedFilings } from '../models';
import { completedFilingsTableHeaders, completedFields, paymentSummaryFields } from '../constants';
import { calculateTotalFilings } from '../helpers';
import { StyledTableContainer } from '../styles';

const CompletedFilings = ({
  completedFilingsData,
  handleEditFiling,
  handleModalOpen,
}: ICompletedFilings) => {
  const theme = useTheme();
  if (!completedFilingsData || completedFilingsData.length === 0) {
    return (
      <Box sx={{ padding: `${theme.convert.pxToRem(20)} ${theme.convert.pxToRem(40)}` }}>
        <Typography variant="h5">No completed filings yet.</Typography>
      </Box>
    );
  }
  return (
    <StyledTableContainer
      sx={{ maxHeight: { xs: theme.convert.pxToRem(300), md: theme.convert.pxToRem(450) } }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {completedFilingsTableHeaders.map((item, index) => {
              return (
                <TableCell
                  key={item}
                  sx={{ maxWidth: 200, minWidth: 60 }}
                  style={{
                    paddingLeft:
                      index === 0 ? theme.convert.pxToRem(40) : theme.convert.pxToRem(12),
                  }}
                >
                  <Typography variant="h5">{item}</Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {completedFilingsData.map((item, index) => {
            const { id, type } = item;
            return (
              <Fragment key={index}>
                <TableRow sx={{ verticalAlign: 'top' }}>
                  {completedFields.map((field, index) => {
                    const value = item[field as keyof typeof item];
                    return (
                      <TableCell
                        sx={{ maxWidth: 200, minWidth: 60 }}
                        key={index}
                        style={{
                          paddingLeft:
                            index === 0 ? theme.convert.pxToRem(40) : theme.convert.pxToRem(12),
                        }}
                        rowSpan={item.paymentSummary ? item?.paymentSummary?.length + 1 : 1}
                      >
                        <Typography variant="body1"> {value?.toString()}</Typography>
                      </TableCell>
                    );
                  })}
                  {!item.paymentSummary || item?.paymentSummary?.length === 0 ? (
                    <>
                      {[...Array(5)].map((index) => (
                        <TableCell key={index} />
                      ))}
                      <TableCell>
                        <Box display="flex">
                          <Button
                            variant="text"
                            sx={{ padding: theme.convert.pxToRem(0), minWidth: 'auto' }}
                            onClick={() => handleEditFiling({ id, type })}
                          >
                            Edit
                          </Button>
                          <Box width={30} />
                          <Button
                            variant="text"
                            sx={{ padding: theme.convert.pxToRem(0), minWidth: 'auto' }}
                            onClick={() => handleModalOpen({ id, type })}
                          >
                            Remove
                          </Button>
                        </Box>
                      </TableCell>
                    </>
                  ) : null}
                </TableRow>
                {item.paymentSummary
                  ? item?.paymentSummary.map((sumary, index) => {
                      return (
                        <TableRow key={index}>
                          {paymentSummaryFields.map((field, index) => {
                            const value = sumary[field as keyof typeof sumary];
                            return (
                              <TableCell key={index} sx={{ maxWidth: 200, minWidth: 60 }}>
                                <Typography variant="body1">
                                  {field === 'amount' && typeof value === 'number'
                                    ? `$${parseInt(value?.toString()).toFixed(2)}`
                                    : value?.toString()}
                                </Typography>
                              </TableCell>
                            );
                          })}
                          {index === 0 ? (
                            <>
                              <TableCell sx={{ maxWidth: 200, minWidth: 60 }}>
                                <Typography variant="body1">{item.status}</Typography>
                              </TableCell>
                              <TableCell sx={{ maxWidth: 200, minWidth: 60 }}>
                                <Typography variant="body1" fontWeight={600}>
                                  $
                                  {item.paymentSummary
                                    ?.reduce((acc, val) => {
                                      const amount = val.amount ? val.amount : 0;
                                      return acc + amount;
                                    }, 0)
                                    .toFixed(2)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Box display="flex">
                                  <Button
                                    variant="text"
                                    sx={{ padding: theme.convert.pxToRem(0), minWidth: 'auto' }}
                                    onClick={() => handleEditFiling({ id, type })}
                                  >
                                    Edit
                                  </Button>
                                  <Box width={30} />
                                  <Button
                                    variant="text"
                                    sx={{ padding: theme.convert.pxToRem(0), minWidth: 'auto' }}
                                    onClick={() => handleModalOpen({ id, type })}
                                  >
                                    Remove
                                  </Button>
                                </Box>
                              </TableCell>
                            </>
                          ) : (
                            <>
                              {[...Array(3)].map((_, item) => (
                                <TableCell key={item} />
                              ))}
                            </>
                          )}
                        </TableRow>
                      );
                    })
                  : null}
              </Fragment>
            );
          })}
          <TableRow>
            {[...Array(6)].map((_, item) => (
              <TableCell key={item} sx={{ borderBottom: 'none' }} />
            ))}
            <TableCell sx={{ borderBottom: 'none' }}>
              <Typography variant="h5">Total</Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none' }}>
              <Typography variant="h5">
                ${calculateTotalFilings(completedFilingsData).toFixed(2)}
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none' }} />
          </TableRow>
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default CompletedFilings;
