import { ICheckBoxFormUI, IFormUI } from '../../common/models';
import { FilingType } from '../../common/models/ucc';
import {
  CommonUCC1UCC3Props,
  CountryType,
  EntityType,
  ICommonUCCResponsePayload,
  IFilingId,
  IResponseCRUDInfo,
  NameType,
  Status,
} from '../../common/models/features/models';
import { type FormDataUCC3 } from './schema';
import { Accordion } from './constants';
import {
  UseFormClearErrors,
  UseFormGetValues,
  UseFormReset,
  UseFormResetField,
  UseFormSetValue,
} from 'react-hook-form/dist/types';

export enum CollateralChangeType {
  None = 'None',
  Deleted = 'Deleted',
  Added = 'Added',
  RestatedCollateralDescription = 'RestatedCollateralDescription',
  Assigned = 'Assigned ',
}

export interface ISetValueType {
  setValue: UseFormSetValue<FormDataUCC3>;
}

export interface IResetField {
  resetField: UseFormResetField<FormDataUCC3>;
}

export interface IGetValues {
  getValues: UseFormGetValues<FormDataUCC3>;
}

export enum EntityTypesLabel {
  DebtorLabel = 'Click here and select the name of DEBTOR authorizing this amendment.',
  SecuredLabel = 'Cick here and select the name of SECURED PARTY authorizing this amendment.',
}

const NoneType = 'None';

export interface IAddress extends IResponseCRUDInfo {
  id: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  isActive: boolean;
}

export interface IUCC3ResponsePayload extends ICommonUCCResponsePayload {
  authEntitySequenceNumber: number | null;
  ucc1Number: string | null;
  collateralChangeDescription: string | null;
  isFinancingStatement: boolean;
  eventTermination: boolean;
  eventContinuation: boolean;
  eventAmendReleaseCollateral: boolean;
  eventAssignment: boolean;
  eventAmendment: boolean;
  lastCheckDate: Date;
  collateralChangeType: CollateralChangeType;
  type: FilingType;
  status: Status;
  authEntityType: EntityType | typeof NoneType;
}

export type UCC3Type = Pick<
  IUCC3ResponsePayload,
  | 'id'
  | 'ucc1Number'
  | 'lastCheckDate'
  | 'isFinancingStatement'
  | 'eventTermination'
  | 'eventContinuation'
  | 'eventAmendReleaseCollateral'
  | 'collateralChangeDescription'
  | 'eventAssignment'
  | 'eventAmendment'
  | 'authEntitySequenceNumber'
  | 'packetId'
  | 'uccNumber'
  | 'additionalPartyPages'
  | 'attachmentPages'
  | 'totalFilingFee'
  | 'qaUserId'
  | 'qaDate'
  | 'postDate'
  | 'redactedImageFilePath'
  | 'unredactedImageFilePath'
  | 'isSkipped'
  | 'isPosted'
  | 'isBulkload'
  | 'ackContactName'
  | 'ackContactPhone'
  | 'ackContactEmail'
  | 'filerRefData'
  | 'deletedAt'
  | 'deletedBy'
  | 'isActive'
  | 'createdAt'
  | 'updatedAt'
  | 'updatedBy'
  | 'createdBy'
  | 'collateralChangeType'
  | 'type'
  | 'status'
  | 'authEntityType'
>;

export interface UseDebSecModalArgs
  extends Pick<IDebSecModalContent, 'filingId' | 'partyName' | 'type'> {
  getData: (filingId: number) => Promise<void>;
}

export interface AssignmentProps
  extends Pick<UCC3UIProps, 'securedParties'>,
    IFilingId,
    Pick<ICheckBoxFormUI<FormDataUCC3>, 'control'> {
  getData: (filingId: number) => Promise<void>;
}

export interface IDebSecResponsePayload extends IResponseCRUDInfo {
  id: number;
  address: IAddress;
  addressId: number;
  entityActionType: string;
  filing: unknown | null;
  filingId: number;
  firstName: string | null;
  isActive: boolean;
  isBulkload: boolean;
  isForeign: boolean;
  isOrganization: boolean;
  jurisdiction: string | null;
  lastName: string | null;
  middleName: string | null;
  name: string;
  organizationId: number | null;
  organizationType: string | null;
  sequenceNumber: number;
  suffix: string | null;
  taxId: null;
  type: EntityType;
}

export interface IAssignmentCheckboxProps
  extends Pick<UCC3UIProps, 'getData' | 'filingId'>,
    ICheckBoxFormUI<FormDataUCC3>,
    ISetValueType {}

export type DebtorOrSecuredType = Pick<
  IDebSecResponsePayload,
  | 'address'
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'name'
  | 'suffix'
  | 'id'
  | 'type'
  | 'filingId'
  | 'createdAt'
  | 'isOrganization'
  | 'entityActionType'
  | 'sequenceNumber'
>;

export interface IUCC3Props extends IFilingId {
  collateralChangeTypesData: CollateralChangeType[] | null;
  dataUCC3: UCC3Type | null;
  entityTypes: EntityType[] | null;
  debtors: DebtorOrSecuredType[];
  securedParties: DebtorOrSecuredType[];
  getData: (filingId: number) => Promise<void>;
  attachments: IAttachment[];

  updateAttachmentsList(prop: IFilingId): void;

  isAttachmentListLoading: boolean;
}

export type DebSecFullType = 'Debtor' | 'Secured Party';

export interface IPartyName {
  partyName: DebSecFullType;
}

export interface IClearErrors {
  clearErrors: UseFormClearErrors<FormDataUCC3>;
}

export interface IResetFields {
  reset: UseFormReset<FormDataUCC3>;
}

export interface UCC3UIProps
  extends IFormUI<FormDataUCC3>,
    IFilingId,
    ISetValueType,
    IClearErrors,
    IResetField,
    IGetValues,
    CommonUCC1UCC3Props {
  accordionState: boolean[];
  expandActionLabel: string;
  isOffensiveLanguageOverrided: boolean;
  showBlocker: boolean;
  setShowBlocker: React.Dispatch<React.SetStateAction<boolean>>;

  handleAccordionState(prop: Accordion): void;

  handleCheckboxAccordionState(prop: Accordion): void;

  setIsOffensiveLanguageOverrided(prop: boolean): void;

  expandAction(): void;

  handleOnSave(e: React.SyntheticEvent): void;

  handleOnComplete(e: React.SyntheticEvent): void;

  setAccordionState: React.Dispatch<React.SetStateAction<boolean[]>>;
  eventAssignment: boolean;
  eventAmendment: boolean;

  handleFormCancel(): void;

  collateralChangeTypesData: CollateralChangeType[] | null;
  ucc1Number: string | null;
  entityTypes: EntityType[] | null;
  debtors: DebtorOrSecuredType[];
  securedParties: DebtorOrSecuredType[];
  attachments: IAttachment[];

  updateAttachmentsList(prop: IFilingId): void;

  getData: (filingId: number) => Promise<void>;
  filingAlerts: IFilingAlert[] | null;
  isSavingUCC3: boolean;
  isAttachmentListLoading: boolean;
  isCompleteFormValid: boolean;
  doFormCancel: () => void;
}

export interface IAttachment {
  id: number;
  name: string;
  filingId: number;
  fileSize: number;
  fileName: string;
  filePath: string;
  fileType: string;
  pageCount: number;
  attachmentUrl: string;
  getData: (filingId: number) => Promise<void>;
  eventAmendment: boolean;
}

export interface IDebSecFilingId extends IFilingId, Partial<IDebsec> {}

export interface IDebSecResponsePayload extends IResponseCRUDInfo {
  id: number;
  address: IAddress;
  addressId: number;
  entityActionType: string;
  filing: unknown | null;
  filingId: number;
  firstName: string | null;
  isActive: boolean;
  isBulkload: boolean;
  isForeign: boolean;
  isOrganization: boolean;
  jurisdiction: string | null;
  lastName: string | null;
  middleName: string | null;
  name: string;
  organizationId: number | null;
  organizationType: string | null;
  sequenceNumber: number;
  suffix: string | null;
  taxId: null;
  type: EntityType;
}

export interface IDebsec {
  debsecId: number;
}

export type UndoActionType = 'Remove' | 'Edit';

export interface IUseUndoRemoveModalProps {
  getData: (filingId: number) => Promise<void>;
}

export interface IOpenModalArgs extends IFilingId, IDebsec {
  action: UndoActionType;
}

export interface IDebSecModal extends Partial<IDebsec>, IFilingId, IPartyName {
  isEdit: boolean;
  type: EntityType;
}

export interface IRequestPayload extends IFilingId {
  id?: number;
  type: EntityType;
  address1: string;
  address2: string;
  city: string;
  country: CountryType;
  firstName: string;
  isForeign: boolean;
  middleName: string;
  name: string;
  nameType: NameType;
  state: string;
  suffix: string;
  lastName: string;
  zip: string;
  isOffensiveLanguageOverrided: boolean;
}

export interface IDebSecModalContent extends IDebSecModal {
  onSuccess(prop: IOnSuccess): void;
}

export interface IOnSuccess extends IPartyName, IFilingId {
  isEdit: boolean;
}

export type RemoveDebSec = Omit<IDebSecModal, 'isEdit'> & IDebsec;

export interface IDebSecIdRequestPayload<T> extends IDebSecFilingId {
  payload: T;
}

/** TODO: UCC1 and Ucc3 should be refactored into the common types **/
export enum FilingAlertType {
  Obscenity = 'Obscenity',
  SSN = 'SSN',
}

export interface IFilingAlert {
  type: FilingAlertType;
  content: string;
  alertText: string;
  fieldName: string;
  startPosition: number;
  wordPosition: number;
  length: number;
  alertFilterId: string | null;
}

export interface DebtorOrSecuredPartyProps {
  party: DebtorOrSecuredType;
  doUndo: (args: IOpenModalArgs) => void;
  isDisabled: boolean;

  openDebSecModal(props: IDebSecModal): void;
}

export interface DateTimeFormatOptions {
  dateFormat: 'numeric' | '2-digit';
  timeFormat?: 'numeric' | '2-digit';
  excludeSeconds?: boolean;
  removeCommaBetweenDateAndTime?: boolean;
}
