import { createContext, useContext, useState } from 'react';
import type { ILayoutWrapperProps, IPassword, MouseEvent } from '../models';
import {
  getAccountNumber as getAccountNumberAPI,
  updateAcknowledgementInfo as updateAcknowledgementInfoAPI,
  updatePersonalInfo as updatePersonalInfoAPI,
} from '../../infrastructure/api/my-account-api';
import { getMyAccountInfo as getMyAccountInfoAPI } from '../../infrastructure/api/user-info-api';
import { useAuth } from './index';
import { useSnackbar } from '../notification';

import type {
  IMyAccountContext,
  InitDataState,
  InitEditState,
  UpdateAcknowledgementInfo,
  UpdatePersonalInfo,
} from './types';
import useUnsavedChangesModal from '../components/modals/unsaved-changes/useUnsavedChangesModal';

const MyAccountContext = createContext<IMyAccountContext | undefined>(undefined);

const initEditState = {
  personalInfo: false,
  acknowledgementInfo: false,
};

const initDataState = {
  personalInformation: {
    name: '',
    phoneNumber: '',
  },
  acknowledgementInformation: {
    contactName: '',
    phoneNumber: '',
    email: '',
  },
  passwordAndSecurity: {
    email: '',
  },
  haveAccountNumber: false,
};

function MyAccountProvider({ children }: ILayoutWrapperProps) {
  const { Snack } = useSnackbar();
  const { isEditMode } = useAuth();
  const { handleUnsavedChanges } = useUnsavedChangesModal();

  const [loadingMyAccountData, setLoadingMyAccountData] = useState(true);
  const [myAccountData, setMyAccountData] = useState<InitDataState>(initDataState);
  const [openAccordion, setOpenAccordion] = useState({
    personalInfo: true,
    acknowledgementInfo: true,
    debitAccountInfo: true,
    passwordAndSecurity: true,
  });
  const [editModeAccordion, setEditModeAccordion] = useState<InitEditState>(initEditState);

  const handleChangeAccordionInfo = () => {
    setOpenAccordion((prevState) => {
      return {
        ...prevState,
        personalInfo: !prevState.personalInfo,
      };
    });
  };

  const handleChangeAccordionAck = () => {
    setOpenAccordion((prevState) => {
      return {
        ...prevState,
        acknowledgementInfo: !prevState.acknowledgementInfo,
      };
    });
  };

  const handleChangeAccordionPasswordSecurity = () => {
    setOpenAccordion((prevState) => {
      return {
        ...prevState,
        passwordAndSecurity: !prevState.passwordAndSecurity,
      };
    });
  };

  const handleChangeAccordionDebit = () => {
    setOpenAccordion((prevState) => {
      return {
        ...prevState,
        debitAccountInfo: !prevState.debitAccountInfo,
      };
    });
  };

  const handleChangeEditMode: MouseEvent = (e) => {
    e.stopPropagation();
    const target = e.target as HTMLButtonElement;
    const name = target.name;
    const onProceed = () => {
      setOpenAccordion((prevState) => {
        return {
          ...prevState,
          [name]: true,
        };
      });
      setEditModeAccordion((prevState) => {
        const editName = prevState[name as keyof typeof prevState];
        return {
          ...initEditState,
          [name]: !editName,
        };
      });
    };
    if (isEditMode) {
      handleUnsavedChanges({ onProceed });
    } else {
      onProceed();
    }
  };

  const handleCancel: MouseEvent = (e) => {
    e.stopPropagation();
    const target = e.target as HTMLButtonElement;
    const name = target.name;
    const onProceed = () =>
      setEditModeAccordion((prevState) => ({
        ...prevState,
        [name]: false,
      }));
    if (isEditMode) {
      handleUnsavedChanges({ onProceed });
    } else {
      onProceed();
    }
  };

  const value = {
    myAccountData,
    loadingMyAccountData,
    openAccordion,
    editModeAccordion,
    handleChangeAccordionInfo,
    handleChangeAccordionAck,
    handleChangeAccordionDebit,
    handleChangeAccordionPasswordSecurity,
    handleChangeEditMode,
    handleCancel,
    setMyAccountData,
    setEditModeAccordion,
    async getMyAccountInfo() {
      try {
        const accountInfo = await getMyAccountInfoAPI();
        setMyAccountData(accountInfo.payload);
      } catch (err: any) {
        Snack.error(err.message || 'Unknown error occured.');
      } finally {
        setLoadingMyAccountData(false);
      }
    },
    async getAccountNumber({ password }: IPassword) {
      try {
        const accountInfo = await getAccountNumberAPI({ password });
        return accountInfo.payload;
      } catch (err: any) {
        Snack.error('Incorrect password entered. Please try again.');
        return false;
      }
    },
    async updatePersonalInfo({ name, phoneNumber }: UpdatePersonalInfo): Promise<void> {
      try {
        await updatePersonalInfoAPI({ name, phoneNumber });
        setMyAccountData((prevState: InitDataState) => {
          return {
            ...prevState,
            personalInformation: {
              name,
              phoneNumber,
            },
          };
        });
        setEditModeAccordion((prevState: InitEditState) => {
          return {
            ...prevState,
            personalInfo: false,
          };
        });
        Snack.success('Personal information updated successfully.');
      } catch (error: any) {
        Snack.error(error.message || 'Error occured while updating personal information.');
      }
    },
    async updateAcknowledgementInfo({
      contactName,
      phoneNumber,
      email,
    }: UpdateAcknowledgementInfo) {
      try {
        await updateAcknowledgementInfoAPI({ contactName, phoneNumber, email });
        setMyAccountData((prevState: InitDataState) => {
          return {
            ...prevState,
            acknowledgementInformation: {
              contactName,
              phoneNumber,
              email,
            },
          };
        });
        setEditModeAccordion((prevState: InitEditState) => {
          return {
            ...prevState,
            acknowledgementInfo: false,
          };
        });
        Snack.success('Successfully updated.');
      } catch (error: any) {
        Snack.error(error.message || 'Error occured while updating acknowledgement information.');
      }
    },
  };

  return <MyAccountContext.Provider value={value}>{children}</MyAccountContext.Provider>;
}

function useMyAccount() {
  const context = useContext(MyAccountContext);
  if (context === undefined) {
    throw new Error('useMyAccount must be used within a MyAccountProvider');
  }
  return context;
}

export { MyAccountProvider, useMyAccount };
