import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAuth, useModal } from '../../../../common/context';
import PasswordLoginFormUI from './PasswordLoginFormUI';

const schema = yup
  .object({
    email: yup
      .string()
      .max(100, 'Max length is 100 characters.')
      .required('Email is required.')
      .email('Please enter a valid email address.')
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Please enter a valid email address.'),
    password: yup.string().required('Password is required.'),
  })
  .required();

export type FormData = yup.InferType<typeof schema>;

const PasswordLoginForm = () => {
  const { signIn } = useAuth();
  const { open } = useModal();
  const { handleSubmit, setFocus, formState, control } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  useEffect(() => {
    !open && setFocus('email');
  }, [open]);

  return (
    <PasswordLoginFormUI
      onSubmit={handleSubmit(signIn)}
      control={control}
      formState={formState}
      isModalOpen={open}
    />
  );
};

export default PasswordLoginForm;
