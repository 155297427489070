import { FC } from 'react';
import SearchGridWhiteHeading from './SearchGridWhiteHeading';
import { FileDetailsInterface } from '../types';
import { uccSearchDetailsDebtorPartiesURL } from '../../../common/constants/routes';
import DebtorSecuredList from './DebtorSecuredList';
import { useViewAllRedirectUrl } from '../useViewAllRedirectUrl';

const DebtorParties: FC<{ fileData: FileDetailsInterface }> = ({ fileData }) => {
  const { debtorPartiesTotalCount, debtors, ucc1Number } = fileData;
  const { redirectUrl } = useViewAllRedirectUrl(ucc1Number);
  return (
    <>
      <SearchGridWhiteHeading
        title="Debtor Parties"
        description="Current Debtor Parties"
        result={debtorPartiesTotalCount}
      />

      <DebtorSecuredList
        data={debtors}
        maxDisplay={3}
        viewAllUrl={uccSearchDetailsDebtorPartiesURL + redirectUrl}
      />
    </>
  );
};

export default DebtorParties;
