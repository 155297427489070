import { useCallback, useEffect, useRef, useState } from 'react';
import { useSnackbar } from '../../common/notification';
import { MetaType, ResponseMessageType } from '../shared/types';
import { GenericErrorMessage } from '../../common/constants/features-constants';

export const useSearchDataFetch = <T, TProps = undefined>(
  fetcher: (props?: TProps) => Promise<ResponseMessageType<T>>,
  showGenericMessage = false,
  skipImmediateFetch?: boolean,
  shouldAssignMeta?: boolean
) => {
  const { Snack } = useSnackbar();
  const isFirstFetchDone = useRef(skipImmediateFetch);
  const [data, setData] = useState<T>();
  const [meta, setMeta] = useState<MetaType | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(!skipImmediateFetch);

  const resetData = (data: any) => {
    setData(data);
  };

  const doFetch = useCallback(
    async (props?: TProps) => {
      try {
        setIsFetching(true);
        const res = await fetcher(props);

        if (!res.notOk) {
          setData(res.payload);
          if (shouldAssignMeta) {
            setMeta(res.meta);
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (showGenericMessage) {
          Snack.error(GenericErrorMessage);
        } else {
          Snack.error(e?.response?.data?.friendlyMessageSummary);
        }
      } finally {
        setIsFetching(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [fetcher]
  );

  useEffect(() => {
    if (isFirstFetchDone.current) return;

    (async () => {
      await doFetch();
      isFirstFetchDone.current = true;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { data, meta, isFetching, doFetch, resetData };
};
