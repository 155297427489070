import { Theme } from '@mui/material/styles';
import { colors } from '../constants';

import { ReactComponent as SuccessIcon } from '../../assets/icons/success-icon.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning-icon.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error-icon.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info-icon.svg';

export const MuiAlert = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      fontFamily: 'Open Sans',
      borderRadius: theme.convert.pxToRem(5),
      fontSize: theme.typography.pxToRem(16),
      color: colors.regularTextColor,
      width: '60vw',
    }),
    standardSuccess: ({ theme }: { theme: Theme }) => ({
      backgroundColor: `${theme.palette.success.light}`,
      borderLeft: `10px solid ${theme.palette.success.main}`,
      boxShadow: `0px 10px 10px rgba(${colors.dividerBackground}, 0.25)`,
    }),
    standardError: ({ theme }: { theme: Theme }) => ({
      backgroundColor: `${theme.palette.error.light}`,
      borderLeft: `10px solid ${theme.palette.error.main}`,
      boxShadow: `0px 10px 10px rgba(${colors.dividerBackground}, 0.25)`,
    }),
    standardWarning: ({ theme }: { theme: Theme }) => ({
      backgroundColor: `${theme.palette.warning.light}`,
      borderLeft: `10px solid ${theme.palette.warning.main}`,
      boxShadow: `0px 10px 10px rgba(${colors.dividerBackground}, 0.25)`,
    }),
    standardInfo: ({ theme }: { theme: Theme }) => ({
      backgroundColor: `${theme.palette.info.light}`,
      borderLeft: `10px solid ${theme.palette.info.main}`,
      boxShadow: `0px 10px 10px rgba(${colors.dividerBackground}, 0.25)`,
    }),
  },
  defaultProps: {
    iconMapping: {
      success: <SuccessIcon />,
      error: <ErrorIcon />,
      warning: <WarningIcon />,
      info: <InfoIcon />,
    },
  },
};
