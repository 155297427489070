import { Fragment } from 'react';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  useTheme,
} from '@mui/material';

import { IFilingHistory } from '../models';
import { ReactComponent as SortArrow } from '../../../assets/icons/sort-arrow-icon.svg';
import { StyledTableContainer } from '../../cart/styles';

import {
  filingHistoryTableHeaders,
  completedHistoryFields,
  paymentSummaryFields,
} from '../constants';

const FilingHistory = ({
  filingHistoryData,
  handleSortOrderChange,
  searchClicked,
}: IFilingHistory) => {
  const theme = useTheme();
  if (!filingHistoryData || filingHistoryData.length === 0) {
    if (searchClicked) {
      return (
        <Box sx={{ padding: `${theme.convert.pxToRem(20)} ${theme.convert.pxToRem(40)}` }}>
          <Typography variant="h5">No results found. Please try again.</Typography>
        </Box>
      );
    }
    return (
      <Box sx={{ padding: `${theme.convert.pxToRem(20)} ${theme.convert.pxToRem(40)}` }}>
        <Typography variant="h5">No UCC filings in your Filing History yet.</Typography>
      </Box>
    );
  }
  return (
    <StyledTableContainer
      sx={{ maxHeight: { xs: theme.convert.pxToRem(300), md: theme.convert.pxToRem(450) } }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {filingHistoryTableHeaders.map((item) => {
              if (item === 'Status' || item === 'Filing Date') {
                return (
                  <TableCell key={item} style={{ paddingLeft: theme.convert.pxToRem(33) }}>
                    <Typography variant="h5" noWrap>
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        {item}
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: theme.convert.pxToRem(8),
                          }}
                        >
                          <SortArrow
                            onClick={() => handleSortOrderChange('Ascending', item)}
                            style={{
                              marginBottom: theme.convert.pxToRem(1.6),
                              cursor: 'pointer',
                            }}
                          />
                          <SortArrow
                            onClick={() => handleSortOrderChange('Descending', item)}
                            style={{
                              transform: 'rotate(180deg)',
                              cursor: 'pointer',
                            }}
                          />
                        </Box>
                      </Box>
                    </Typography>
                  </TableCell>
                );
              } else {
                return (
                  <TableCell key={item} style={{ paddingLeft: theme.convert.pxToRem(33) }}>
                    <Typography variant="h5" noWrap>
                      {item}
                    </Typography>
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {filingHistoryData.map((item, index) => {
            return (
              <Fragment key={index}>
                <TableRow sx={{ verticalAlign: 'top' }}>
                  {completedHistoryFields.map((field, index) => {
                    const value = item[field as keyof typeof item];
                    return (
                      <TableCell
                        key={index}
                        style={{ paddingLeft: theme.convert.pxToRem(33) }}
                        rowSpan={item.paymentSummary ? item?.paymentSummary?.length + 1 : 1}
                      >
                        <Typography variant="body1" noWrap>
                          {' '}
                          {value?.toString()}
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
                {item.paymentSummary
                  ? item?.paymentSummary.map((summary, index) => {
                      return (
                        <TableRow key={index}>
                          {paymentSummaryFields.map((field, index) => {
                            const value = summary[field as keyof typeof summary];
                            return (
                              <TableCell
                                key={index}
                                style={{ paddingLeft: theme.convert.pxToRem(33) }}
                              >
                                <Typography variant="body1" noWrap>
                                  {field === 'amount' && typeof value === 'number'
                                    ? `$${parseInt(value?.toString()).toFixed(2)}`
                                    : value?.toString()}
                                </Typography>
                              </TableCell>
                            );
                          })}
                          {index === 0 ? (
                            <>
                              <TableCell style={{ paddingLeft: theme.convert.pxToRem(33) }}>
                                <Typography variant="body1" noWrap>
                                  {(() => {
                                    if (item.filingDate) {
                                      const isoString = item.filingDate;
                                      const date = new Date(isoString);
                                      const formattedDate = date.toLocaleDateString('en-US', {
                                        month: '2-digit',
                                        day: '2-digit',
                                        year: 'numeric',
                                      });
                                      return formattedDate;
                                    } else {
                                      return '';
                                    }
                                  })()}
                                </Typography>
                              </TableCell>
                              <TableCell style={{ paddingLeft: theme.convert.pxToRem(33) }}>
                                <Typography variant="h5" noWrap>
                                  $
                                  {item.paymentSummary
                                    ?.reduce((acc, val) => {
                                      const amount = val.amount ? val.amount : 0;
                                      return acc + amount;
                                    }, 0)
                                    .toFixed(2)}
                                </Typography>
                              </TableCell>
                              <TableCell></TableCell>
                            </>
                          ) : (
                            <>
                              {[...Array(3)].map((_, item) => (
                                <TableCell key={item} />
                              ))}
                            </>
                          )}
                        </TableRow>
                      );
                    })
                  : null}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default FilingHistory;
