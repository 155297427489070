import { CognitoUser } from '@aws-amplify/auth';

import type {
  IChallangeAnswer,
  IChallangeCode,
  IEmail,
  IForgotPassword,
  IResetPassword,
  ISignIn,
  ISignUp,
  IUpdatePassword,
  MouseEvent,
  UnsavedChangesHandler,
} from '../models';

export interface IAuthContext {
  createNewPing(): void;

  signIn(signInArgs: ISignIn): void;

  emailSignIn(signInArgs: IEmail): void;

  signOut(callback?: UnsavedChangesHandler): void;

  signUp(payload: ISignUp): Promise<boolean>;

  resendMail(payload: IEmail): Promise<boolean>;

  createNewPassword({ user, code }: IChallangeAnswer): void;

  forgotPassword({ username, code, newPassword }: IForgotPassword): Promise<boolean | undefined>;

  resetPassword(payload: IResetPassword): Promise<void>;

  updatePassword(payload: IUpdatePassword): Promise<boolean>;

  authed: AuthedState;
  user: CognitoUser | null;
  isLoadingUser: boolean;
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;

  getUserData(): void;

  checkUserSession(skipErrorReporting?: boolean): void;

  checkAuthUser(): void;

  getUserSettings(): void;

  updateUserEmail(args: IEmail): Promise<boolean | undefined>;

  checkUniqueEmail(args: IEmail): Promise<boolean | undefined>;

  verifyEmailUserCode({ code, email }: IChallangeCode & IEmail): Promise<boolean | undefined>;

  userIdleTime?: number;

  answerCustomChallenge(props: IChallangeCode): Promise<boolean>;
}

export type OpenAccordion = {
  personalInfo: boolean;
  acknowledgementInfo: boolean;
  debitAccountInfo: boolean;
  passwordAndSecurity: boolean;
};

export type EditModeAccordion = {
  personalInfo: boolean;
  acknowledgementInfo: boolean;
};

export type UpdatePersonalInfo = {
  name: string;
  phoneNumber: string;
};

export type UpdateAcknowledgementInfo = {
  phoneNumber: string;
  contactName: string;
  email: string;
};

export type InitDataState = {
  personalInformation: {
    name: string;
    phoneNumber: string;
  };
  acknowledgementInformation: {
    contactName: string;
    phoneNumber: string;
    email: string;
  };
  passwordAndSecurity: {
    email: string;
  };
  haveAccountNumber: boolean;
};

export type InitEditState = {
  personalInfo: boolean;
  acknowledgementInfo: boolean;
};

export interface IMyAccountContext {
  getMyAccountInfo: () => void;
  getAccountNumber: ({ password }: { password: string }) => Promise<boolean | string>;
  myAccountData: any;
  loadingMyAccountData: boolean;
  openAccordion: OpenAccordion;
  editModeAccordion: EditModeAccordion;
  handleChangeAccordionInfo: () => void;
  handleChangeAccordionAck: () => void;
  handleChangeAccordionDebit: () => void;
  handleChangeAccordionPasswordSecurity: () => void;
  handleChangeEditMode: MouseEvent;
  handleCancel: MouseEvent;
  setMyAccountData: React.Dispatch<any>;
  setEditModeAccordion: React.Dispatch<any>;
  updatePersonalInfo: ({ name, phoneNumber }: UpdatePersonalInfo) => Promise<void>;
  updateAcknowledgementInfo: ({
    contactName,
    phoneNumber,
    email,
  }: UpdateAcknowledgementInfo) => Promise<void>;
}

export interface ICartFilingsDetails {
  pendingFilingsCount: number | null;
  incompleteFilingsCount: number | null;
  completedFilingsCount: number | null;
  totalFilingsCount: number | null;
  packetId: number | null;
}

export interface IUserContext {
  getUserInfo: () => void;
  getCartDetails: () => void;
  loadingInfo: boolean;
  isLoadingCartDetails: boolean;
  delayFilingsFetch: boolean;
  fullName: string;
  setDelayFilingsFetch: React.Dispatch<React.SetStateAction<boolean>>;
  setFullName: React.Dispatch<React.SetStateAction<string>>;
  cartFilingsDetails: ICartFilingsDetails | null;
  lastLogin: string;

  setCartFilingsDetails(props: ICartFilingsDetails | null): void;
}

export type ExceptionType =
  | 'NotAuthorizedException'
  | 'ExpiredCodeException'
  | 'CodeMismatchException'
  | 'CodeDeliveryDetails'
  | 'UsernameExistsException'
  | 'AliasExistsException'
  | 'PasswordResetRequiredException'
  | 'PreventUserExistenceErrors'
  | 'UserNotFoundException'
  | 'ForbiddenException'
  | 'InvalidPasswordException'
  | 'LimitExceededException';
export type ExceptionTypeMessage = string | null;
export type ErrorMessage = string | undefined;

export interface IParseErrorMessage {
  code: unknown;
  message: unknown;
  defaultMessage: string;
}

export interface IErrorMessage {
  code: string;
  message: string;
}

export enum AuthedState {
  NOT_AUTHED = 'Not Authed',
  AUTHED = 'Authed',
  CHECKING_AUTHORIZATION = 'Checking Authorization',
}
