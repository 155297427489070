import React from 'react';
import { TextFieldProps, TextField } from '@mui/material';

const EmailInputRef: React.ForwardRefRenderFunction<
  HTMLDivElement,
  Omit<TextFieldProps, 'type' | 'size'>
> = (props, ref) => {
  return <TextField {...props} type="email" inputRef={ref}></TextField>;
};

export const EmailInput = React.forwardRef<HTMLDivElement, Omit<TextFieldProps, 'type' | 'size'>>(
  EmailInputRef
);
