import { CompanyInfo } from './CompanyInfo';
import { PublicRecordsInfo } from './PublicRecordsInfo';
import { PartnerInfo } from './PartnerInfo';
import { Grid, useTheme } from '@mui/material';

export function FooterInfo() {
  const theme = useTheme();
  return (
    <Grid
      container
      flexDirection={{ sm: 'column', md: 'row' }}
      justifyContent={'space-between'}
      paddingX={{
        sm: theme.convert.pxToRem(43),
        md: theme.convert.pxToRem(20),
        lg: theme.convert.pxToRem(40),
        xl: theme.convert.pxToRem(80),
      }}
      gap={{
        xs: 4,
        md: 0,
      }}
    >
      <Grid item xs={12} md={4} lg={4} display={'flex'} justifyContent={'center'}>
        <CompanyInfo />
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        lg={4}
        display={'flex'}
        justifyContent={'center'}
        paddingX={{
          md: theme.convert.pxToRem(10),
          lg: 0,
        }}
      >
        <PublicRecordsInfo />
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        lg={4}
        display={'flex'}
        justifyContent={{ xs: 'center', md: 'end' }}
      >
        <PartnerInfo />
      </Grid>
    </Grid>
  );
}
