import { Grid, FormHelperText, Typography, InputLabel } from '@mui/material';
import Loader from '../../../../common/components/loader';
import { Controller } from 'react-hook-form';

import { EmailInput } from '../../../../common/components/inputs';

import {
  StyledBoxWrapper,
  StyledSubmitButton,
} from '../../../../common/components/user-auth-components/common-auth-styles';
import type { FormData } from './ResetPasswordForm';
import type { ILoginFormUI } from '../../../../common/models';

const ResetPasswordFormUI = ({
  onSubmit,
  control,
  formState: { errors, isValid, isSubmitting },
}: ILoginFormUI<FormData>) => (
  <form onSubmit={onSubmit}>
    <StyledBoxWrapper p={2}>
      <Typography variant="body3" textAlign="center" sx={{ whiteSpace: { md: 'nowrap' } }}>
        We will email you a one-time link to help you reset your password.
      </Typography>
      <Grid container alignItems="center" pt="2.5rem">
        <Grid item xs={12} md={3} sx={{ paddingBottom: { xs: 1 } }}>
          <InputLabel htmlFor="email">
            <Typography variant="h4" fontWeight={400}>
              Email *
            </Typography>
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={9}>
          <Controller
            name="email"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <EmailInput
                {...field}
                inputRef={ref}
                placeholder="Enter email"
                error={!!errors.email?.message}
                autoFocus
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={9}>
          <FormHelperText error={!!errors.email?.message}>{errors.email?.message}</FormHelperText>
        </Grid>
      </Grid>
      <Grid container alignItems="center" pt="2.5rem">
        <Grid item xs={12}>
          <StyledSubmitButton
            variant="contained"
            type="submit"
            fullWidth
            disabled={!isValid || isSubmitting}
            startIcon={isSubmitting ? <Loader /> : null}
          >
            {isSubmitting ? 'Sending Link' : 'Send Link'}
          </StyledSubmitButton>
        </Grid>
      </Grid>
    </StyledBoxWrapper>
  </form>
);

export default ResetPasswordFormUI;
