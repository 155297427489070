import { Box, Button, Typography } from '@mui/material';
import { useBeforeUnload } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

import Loader from '../../../common/components/loader';
import SendAcknowledgementTo from '../send-acknowledgement-to';
import Authorization from '../authorization';
import { CustomAccordion } from '../../../common/components/accordion';
import Termination from '../termination';
import TerminationCheckbox from '../termination/TerminationCheckbox';
import Continuation from '../continuation/';
import ContinuationCheckbox from '../continuation/ContinuationCheckbox';
import AmendmentCheckbox from '../amendment/AmendmentCheckbox';
import Amendment from '../amendment/Amendment';
import AmendReleaseCollateral from '../amend-release-collateral/AmendReleaseCollateral';
import AmendReleaseCollateralCheckbox from '../amend-release-collateral/AmendReleaseCollateralCheckbox';
import Attachments from '../attachments/Attachments';
import { Assignment, AssignmentCheckbox } from '../assignment';
import { StyledUCCButton } from '../../start-ucc-filing/styled-items/StyledUccButton';
import ObscenitySSNTable from '../../ucc1/obscenity-ssn-table/ObscenitySsnTable';
import { Accordion } from '../constants';
import { PageTitle } from '../../../common/constants/features-constants';
import { StyledGrid } from '../../shared/ucc/styles';
import theme from '../../../common/theme';

import { UCC3UIProps } from '../models';
import PageWrapper from '../../../common/layouts/PageWrapper';
import useEditMode from '../../../common/hooks/useEditMode';

const Ucc3Ui = ({
  accordionState,
  eventAssignment,
  handleAccordionState,
  handleCheckboxAccordionState,
  expandAction,
  expandActionLabel,
  control,
  formState,
  setValue,
  filingId,
  handleOnSave,
  handleOnComplete,
  handleFormCancel,
  ucc1Number,
  entityTypes,
  debtors,
  securedParties,
  collateralChangeTypesData,
  getData,
  eventAmendment,
  attachments,
  updateAttachmentsList,
  isOffensiveLanguageOverrided,
  setIsOffensiveLanguageOverrided,
  filingAlerts,
  isSavingUCC3,
  clearErrors,
  resetField,
  getValues,
  isDirty,
  isSaved,
  isCompleted,
  isCanceled,
  hasPendingUploads,
  isAttachmentListLoading,
  isCompleteFormValid,
  doFormCancel,
  showBlocker,
  setShowBlocker,
}: UCC3UIProps) => {
  useEditMode(hasPendingUploads);
  useBeforeUnload(
    useCallback(
      (e) => {
        if (showBlocker) {
          e.preventDefault();
          e.returnValue = '';
          return true;
        }
      },
      [showBlocker]
    )
  );

  /*
   * There are few different cases but the major one is:
   * - If the form is dirty and still not saved nor completed nor canceled - show blocker
   * - Any other case you might think of will not trigger the blocker
   * */
  useEffect(() => {
    if ((isDirty && !isSaved && !isCompleted && !isCanceled) || hasPendingUploads) {
      setShowBlocker(true);
    } else {
      setShowBlocker(false);
    }
  }, [isDirty, isSaved, isCompleted, isCanceled, hasPendingUploads]);

  return (
    <PageWrapper title={PageTitle.UCC3}>
      <form>
        {filingAlerts && (
          <>
            <Box sx={{ height: theme.convert.pxToRem(18) }} />
            <ObscenitySSNTable
              alerts={filingAlerts}
              handleOffensiveLanguageOverrided={(e) =>
                setIsOffensiveLanguageOverrided(e.target.checked)
              }
            />
            <Box sx={{ height: theme.convert.pxToRem(28) }} />
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: theme.convert.pxToRem(20),
            paddingBottom: theme.convert.pxToRem(2),
          }}
        >
          <Box alignSelf="start" sx={{ paddingBottom: theme.convert.pxToRem(6) }}>
            <Typography variant="h3">UCC FINANCING STATEMENT #{ucc1Number}</Typography>
          </Box>
          <Box width={5} />
          <Box alignSelf="end">
            <Button
              sx={{
                whiteSpace: 'nowrap',
              }}
              variant="text"
              onClick={expandAction}
            >
              {expandActionLabel}
            </Button>
          </Box>
        </Box>
        <Box>
          <StyledGrid container gap={3.5}>
            <CustomAccordion
              title={'Send Acknowledgement To *'}
              isOpen={accordionState[Accordion.SendAcknowledgementTo]}
              handleChange={() => handleAccordionState(Accordion.SendAcknowledgementTo)}
            >
              <SendAcknowledgementTo control={control} formState={formState} />
            </CustomAccordion>
            <Box
              minWidth={{ sm: 351, lg: 925 }}
              width={1}
              margin="auto"
              borderBottom={`1px solid ${theme.palette.grey[500]}`}
            />
            <Box width={1} margin="auto" display="flex" alignItems="baseline" gap={1}>
              <Typography variant="h3" paddingLeft={theme.convert.pxToRem(20)}>
                Event
              </Typography>
              <Typography variant="h5" textAlign="left">
                (Check the box if there is any applied.)
              </Typography>
            </Box>

            <CustomAccordion
              title={'Termination'}
              isOpen={accordionState[Accordion.Termination]}
              handleChange={() => handleAccordionState(Accordion.Termination)}
              titleItem={
                <TerminationCheckbox
                  control={control}
                  formState={formState}
                  handleCheckboxAccordionState={handleCheckboxAccordionState}
                />
              }
            >
              <Termination />
            </CustomAccordion>
            <CustomAccordion
              title={'Continuation'}
              isOpen={accordionState[Accordion.Continuation]}
              handleChange={() => handleAccordionState(Accordion.Continuation)}
              titleItem={
                <ContinuationCheckbox
                  control={control}
                  formState={formState}
                  handleCheckboxAccordionState={handleCheckboxAccordionState}
                />
              }
            >
              <Continuation />
            </CustomAccordion>
            <CustomAccordion
              title={'Amend/Release Collateral'}
              isOpen={accordionState[Accordion.AmendReleaseCollateral]}
              handleChange={() => handleAccordionState(Accordion.AmendReleaseCollateral)}
              titleItem={
                <AmendReleaseCollateralCheckbox
                  control={control}
                  formState={formState}
                  setValue={setValue}
                  handleCheckboxAccordionState={handleCheckboxAccordionState}
                  clearErrors={clearErrors}
                  resetField={resetField}
                  getValues={getValues}
                />
              }
            >
              <AmendReleaseCollateral
                control={control}
                formState={formState}
                collateralChangeTypesData={collateralChangeTypesData}
              />
            </CustomAccordion>
            <CustomAccordion
              title={'Assignment'}
              isOpen={accordionState[Accordion.Assigment]}
              handleChange={() => handleAccordionState(Accordion.Assigment)}
              titleItem={
                <AssignmentCheckbox
                  control={control}
                  formState={formState}
                  handleCheckboxAccordionState={handleCheckboxAccordionState}
                  filingId={filingId}
                  getData={getData}
                  setValue={setValue}
                />
              }
            >
              <Assignment
                filingId={filingId}
                getData={getData}
                securedParties={securedParties}
                control={control}
              />
            </CustomAccordion>
            <CustomAccordion
              title={'Amendment'}
              isOpen={accordionState[Accordion.Amendment]}
              handleChange={() => handleAccordionState(Accordion.Amendment)}
              titleItem={
                <AmendmentCheckbox
                  control={control}
                  formState={formState}
                  handleCheckboxAccordionState={handleCheckboxAccordionState}
                  getData={getData}
                  filingId={filingId}
                  setValue={setValue}
                  eventAmendment={eventAmendment}
                />
              }
            >
              <Amendment
                filingId={filingId}
                debtors={debtors}
                securedParties={securedParties}
                getData={getData}
                control={control}
              />
            </CustomAccordion>
            <Box
              minWidth={{ sm: 351, lg: 925 }}
              width={1}
              margin="auto"
              borderBottom={`1px solid ${theme.palette.grey[500]}`}
            ></Box>
            <CustomAccordion
              title={'Authorization (optional)'}
              isOpen={accordionState[Accordion.Authorization]}
              handleChange={() => handleAccordionState(Accordion.Authorization)}
            >
              <Authorization
                entityTypes={entityTypes}
                control={control}
                formState={formState}
                setValue={setValue}
                debtors={debtors}
                securedParties={securedParties}
              />
            </CustomAccordion>
            <CustomAccordion
              title={'Attachments (optional)'}
              isOpen={accordionState[Accordion.Attachments]}
              handleChange={() => handleAccordionState(Accordion.Attachments)}
            >
              <Attachments
                attachments={attachments}
                filingId={filingId}
                updateAttachmentsList={updateAttachmentsList}
                isAttachmentListLoading={isAttachmentListLoading}
              />
            </CustomAccordion>
          </StyledGrid>
        </Box>
        <Box sx={{ height: 40, width: '100%' }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-start' },
            alignItems: { xs: 'center', md: 'flex-end' },
            width: '100%',
            margin: '0 auto',
            flexDirection: {
              xs: 'column',
              sm: 'column',
              md: 'row-reverse',
              lg: 'row-reverse',
              xl: 'row-reverse',
            },
            gap: {
              xs: theme.convert.pxToRem(30),
              md: theme.convert.pxToRem(24),
              lg: theme.convert.pxToRem(24),
              xl: theme.convert.pxToRem(24),
            },
          }}
        >
          <Box
            gap={{ xs: theme.convert.pxToRem(30), md: theme.convert.pxToRem(40) }}
            display="flex"
            sx={{
              width: { xs: '100%', sm: '100%', md: 'auto', lg: 'auto', xl: 'auto' },
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
                xl: 'row',
              },
            }}
          >
            <StyledUCCButton
              variant="contained"
              onClick={handleOnSave}
              disabled={formState.isSubmitting}
              startIcon={isSavingUCC3 ? <Loader /> : null}
            >
              {formState.isSubmitting && isSavingUCC3 ? 'Saving' : 'Save'}
            </StyledUCCButton>
            <StyledUCCButton
              type="submit"
              variant="contained"
              onClick={handleOnComplete}
              startIcon={formState.isSubmitting && !isSavingUCC3 ? <Loader /> : null}
              disabled={
                !isCompleteFormValid ||
                formState.isSubmitting ||
                (!!filingAlerts && !formState.isDirty && !isOffensiveLanguageOverrided)
              }
            >
              {formState.isSubmitting && !isSavingUCC3 ? 'Completing' : 'Complete'}
            </StyledUCCButton>
          </Box>
          <Button
            variant="text"
            sx={{ padding: theme.convert.pxToRem(0), margin: theme.convert.pxToRem(0) }}
            onClick={handleFormCancel}
          >
            Cancel
          </Button>
        </Box>
        <Box sx={{ height: 267, width: '100%' }} />
      </form>
    </PageWrapper>
  );
};

export default Ucc3Ui;
