import { Typography } from '@mui/material';
import type { IIdleTimer } from 'react-idle-timer';

import { useAuth, useModal } from '../../../context';
import { StyledModalSubmitButton } from '../../../global-styles';

type SessionTimeoutHandler = (props: Pick<IIdleTimer, 'pause' | 'activate'>) => void;

const useSessionModal = () => {
  const { handleModalOpen, handleModalClose } = useModal();
  const { signOut } = useAuth();

  const handleSessionExpired = () =>
    handleModalOpen({
      title: 'Session Expired',
      modalContent: (
        <Typography variant="body2">Your session has expired. Please login again.</Typography>
      ),
    });

  const handleSessionTimeout: SessionTimeoutHandler = ({ pause, activate }) =>
    handleModalOpen({
      title: 'Session Timeout',
      subtitle: 'To protect your privacy, your session is about to expire due to inactivity.',
      modalContent: (
        <Typography variant="body2">
          To keep this session active, please click “Stay Logged In”.
        </Typography>
      ),
      modalActions: (
        <>
          <StyledModalSubmitButton
            variant="outlined"
            onClick={() => {
              pause();
              handleModalClose();
              signOut();
            }}
          >
            Log Out
          </StyledModalSubmitButton>
          <StyledModalSubmitButton
            autoFocus
            variant="contained"
            onClick={() => {
              activate();
              handleModalClose();
            }}
          >
            Stay Logged In
          </StyledModalSubmitButton>
        </>
      ),
    });
  return { handleSessionExpired, handleSessionTimeout, handleModalOpen, handleModalClose };
};

export default useSessionModal;
