import { Theme } from '@mui/material/styles';

export const MuiTextField = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      width: '100%',
      background: theme.palette.common.white,
      '& input': {
        padding: `${theme.convert.pxToRem(14)} ${theme.convert.pxToRem(12)}`,
      },
      '& input:focus': {
        '@supports (-webkit-touch-callout: none)': {
          fontSize: theme.typography.pxToRem(16),
        },
      },
    }),
  },
};

export const MuiOutlinedInput = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      height: theme.convert.pxToRem(48),
      fontSize: '0.875rem',
      '&.Mui-focused': {
        borderRadius: theme.convert.pxToRem(4),
        borderColor: theme.palette.info.main,
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[200],
      },
    }),
  },
};
