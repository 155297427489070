import { AccordionDetails } from '@mui/material';
import { PasswordAndSecurityView } from './PasswordAndSecurityView';
import { useMyAccount } from '../../../../common/context';

export const PasswordAndSecurity = () => {
  const { myAccountData } = useMyAccount();

  return (
    <AccordionDetails>
      <PasswordAndSecurityView email={myAccountData.passwordAndSecurity.email} />
    </AccordionDetails>
  );
};
