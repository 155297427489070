import type {
  ErrorMessage,
  ExceptionType,
  ExceptionTypeMessage,
  IParseErrorMessage,
  IErrorMessage,
} from './types';

/**
 * List here all exceptions that should be handled with custom error messages.
 * If same {CODE} exception type is responsible for different error messages:
 *  - RegEx on {MESSAGE} should be used for mapping
 *  - exceptionTypeMapper value should be NULL, for the key of {CODE}
 *  */
const exceptionTypeMapper: Partial<Record<ExceptionType, ExceptionTypeMessage>> = {
  NotAuthorizedException: null,
};

const getMessageByTypeRegEx = ({ code, message }: IErrorMessage): ErrorMessage => {
  let parsedMessage;

  switch (code) {
    case 'NotAuthorizedException':
      // Accout is locked due to exceeded number of attempts
      if (message.includes('attempts') && message.includes('exceed')) {
        parsedMessage =
          'Your account has been locked following multiple failed login attempts. Please try again after few minutes.';
      } else {
        // Email account is deactivated | Incorrect username or password
        parsedMessage = 'Invalid credentials. Please try again.';
      }
  }

  return parsedMessage;
};

export const parseErrorMessage = ({
  code,
  message,
  defaultMessage = 'Unknown error occured',
}: IParseErrorMessage): string => {
  let parsedMessage: ExceptionTypeMessage | undefined = exceptionTypeMapper[code as ExceptionType];

  if (parsedMessage === null && typeof message === 'string' && typeof code === 'string') {
    parsedMessage = getMessageByTypeRegEx({ code, message });
  }

  return parsedMessage || defaultMessage;
};
