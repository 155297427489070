import { Box, Button, Grid, LinearProgress, Typography, useTheme } from '@mui/material';

import { AttachmentListItemProps } from './models';
import { itemDeviderBorder } from '../../../common/constants/colors';
import { cancelUploadAttachments } from '../ucc1.services';
import { useSnackbar } from '../../../common/notification';

const AttachmentListItem = ({
  attachment,
  uploadingAttachment,
  onRemove,
  setUploadingAttachments,
}: AttachmentListItemProps) => {
  const theme = useTheme();
  const { Snack } = useSnackbar();

  const onCancel = () => {
    if (uploadingAttachment?.promise && setUploadingAttachments) {
      cancelUploadAttachments({ promise: uploadingAttachment.promise });
      setUploadingAttachments((state) =>
        state.filter((item) => item.id !== uploadingAttachment.id)
      );
      Snack.success('Upload is successfully canceled.');
    }
  };

  const getFileSizeInfo = (fileSize: number) => {
    const sizeInKB = fileSize / 1000;
    const sizeInMB = sizeInKB / 1000;
    if (sizeInMB >= 1) {
      return `${sizeInMB.toFixed(1)}MB`;
    }
    return `${Math.round(sizeInKB)}kB`;
  };

  return (
    <Grid
      container
      sx={{
        borderBottom: `1px solid ${itemDeviderBorder}`,
        padding: `${theme.convert.pxToRem(20)} ${theme.convert.pxToRem(40)}`,
        alignItems: 'center',
      }}
    >
      <Grid xs={5.5} md={5.5} lg={3} xl={3} item sx={{ whiteSpace: 'pre-line' }}>
        <Typography variant="h5">{attachment?.name || uploadingAttachment?.fileName}</Typography>
      </Grid>
      <Grid xs={3.5} md={4.5} lg={7} xl={7.5} item paddingX={1}>
        {uploadingAttachment?.promise ? (
          <LinearProgress
            sx={{
              width: { lg: theme.convert.pxToRem(308) },
              height: theme.convert.pxToRem(10),
              borderRadius: theme.convert.pxToRem(4),
            }}
          />
        ) : attachment?.fileSize ? (
          <Typography variant="body1">{getFileSizeInfo(attachment?.fileSize)}</Typography>
        ) : null}
      </Grid>
      <Grid xs={3} md={2} lg={2} xl={1.5} item display="flex" justifyContent="flex-end">
        {uploadingAttachment?.promise ? (
          <Button sx={{ justifyContent: 'flex-end' }} onClick={onCancel} variant="text">
            Cancel
          </Button>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: theme.convert.pxToRem(20),
              justifyContent: 'flex-end',
            }}
          >
            <Button
              component="a"
              sx={{ display: { xs: 'none', md: 'flex' } }}
              href={attachment?.attachmentUrl}
              target="_blank"
              variant="text"
            >
              Download
            </Button>
            <Button onClick={onRemove} variant="text">
              Remove
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default AttachmentListItem;
