import { IFormUI } from '../../common/models';
import {
  UseFormClearErrors,
  UseFormReset,
  UseFormResetField,
  UseFormSetValue,
} from 'react-hook-form/dist/types';
import {
  CommonUCC1UCC3Props,
  CountryType,
  EntityType,
  ICommonUCCResponsePayload,
  IFilingId,
  IResponseCRUDInfo,
  NameType,
} from '../../common/models/features/models';
import { type FormDataUCC1 } from './schema';

import { Accordion } from './constants';
import { FilingType } from '../../common/models/ucc';
import { IGetValues } from '../ucc3/models';

export enum FilingAlertType {
  Obscenity = 'Obscenity',
  SSN = 'SSN',
}

export interface IFilingAlert {
  type: FilingAlertType;
  content: string;
  alertText: string;
  fieldName: string;
  startPosition: number;
  wordPosition: number;
  length: number;
  alertFilterId: string | null;
}

export interface IResponseDebSec {
  id: number;
  filingAlerts: IFilingAlert[];
}

export interface IAddress extends IResponseCRUDInfo {
  id: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  isActive: boolean;
}

export type DebSecFullType = 'Debtor' | 'Secured Party';

export interface IDebSecResponsePayload extends IResponseCRUDInfo {
  id: number;
  address: IAddress;
  addressId: number;
  entityActionType: string;
  filing: unknown | null;
  filingId: number;
  firstName: string | null;
  isActive: boolean;
  isBulkload: boolean;
  isForeign: boolean;
  isOrganization: boolean;
  jurisdiction: string | null;
  lastName: string | null;
  middleName: string | null;
  name: string;
  organizationId: number | null;
  organizationType: string | null;
  sequenceNumber: number;
  suffix: string | null;
  taxId: null;
  type: EntityType;
}

export enum DebtorType {
  None = 'None',
  Trust = 'Trust',
  DecedentEstate = 'DecedentEstate',
  PropertyHeldinTrust = 'PropertyHeldinTrust',
}

export enum AltDesignationType {
  None = 'None',
  AGLien = 'AGLien',
  BaileeBailor = 'BaileeBailor',
  SellerBuyer = 'SellerBuyer',
  NonUccFiling = 'NonUccFiling',
  ConsigneeConsignor = 'ConsigneeConsignor',
  LesseeLessor = 'LesseeLessor',
}

export enum DebtorFilintType {
  None = 'None',
  TransmittingUtility = 'TransmittingUtility',
  ManufacturedHome = 'ManufacturedHome',
}

export enum DocStampTaxType {
  DocStampDue = 'DocStampDue',
  DocStampNotRequired = 'DocStampNotRequired',
}

export enum FinancingStatementCoverageType {
  None = 'None',
  TimberCut = 'TimberCut',
  ExtractedCollateral = 'ExtractedCollateral',
  FixtureFiling = 'FixtureFiling',
}

export interface IUCC1ResponsePayload extends ICommonUCCResponsePayload {
  addendumPages: number;
  additionalCollateralDescription: string | null;
  altDesignationType: AltDesignationType;
  debtorFilingType: string;
  debtorType: DebtorType;
  docStampTaxType: DocStampTaxType;
  financingStatement: string | null;
  financingStatementCoverageType: FinancingStatementCoverageType;
  hasAddendum: boolean;
  miscellaneous: string | null;
  realEstateDescription: string | null;
  recordOwnerNameAddress: string | null;
  status: string;
  type: FilingType;
}

export type UCC1Type = Pick<
  IUCC1ResponsePayload,
  | 'id'
  | 'ackContactName'
  | 'ackContactEmail'
  | 'ackContactPhone'
  | 'type'
  | 'status'
  | 'hasAddendum'
  | 'uccNumber'
  | 'packetId'
  | 'isActive'
  | 'isPosted'
  | 'isBulkload'
  | 'isSkipped'
  | 'additionalCollateralDescription'
  | 'altDesignationType'
  | 'docStampTaxType'
  | 'filerRefData'
  | 'financingStatement'
  | 'miscellaneous'
  | 'realEstateDescription'
  | 'recordOwnerNameAddress'
  | 'financingStatementCoverageType'
  | 'debtorType'
  | 'debtorFilingType'
>;

export type DebtorOrSecuredType = Pick<
  IDebSecResponsePayload,
  | 'address'
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'name'
  | 'suffix'
  | 'id'
  | 'type'
  | 'filingId'
  | 'createdAt'
  | 'isOrganization'
  | 'entityActionType'
  | 'sequenceNumber'
>;

export interface IAttachment {
  id: number;
  name: string;
  filingId: number;
  fileSize: number;
  fileName: string;
  filePath: string;
  fileType: string;
  pageCount: number;
  attachmentUrl: string;
}

export interface IUCC1CommonProps {
  debtors: DebtorOrSecuredType[];
  securedParties: DebtorOrSecuredType[];
  attachments: IAttachment[];
}

export interface IUCC1Props extends IFilingId, IUCC1CommonProps {
  dataUCC1: UCC1Type | null;

  updateDebSecList(prop: IFilingId): void;

  updateAttachmentsList(prop: IFilingId): void;

  designationTypes: AltDesignationType[] | null;
  docStampTax: DocStampTaxType[] | null;
  financingTypesData: FinancingStatementCoverageType[] | null;
  debtorTypesData: DebtorType[] | null;
  debtorFilingTypesData: DebtorFilintType[] | null;
  getData: (filingId: number) => Promise<void>;
  isAttachmentListLoading: boolean;
}

export interface IDebtorsOrSecuredParties extends IPartyName, IFilingId {
  parties: DebtorOrSecuredType[];

  removeDebSec(props: RemoveDebSec): void;

  type: EntityType;

  openDebSecModal(props: IDebSecModal): void;
}

export interface ISetValueType {
  setValue: UseFormSetValue<FormDataUCC1>;
}

export interface IClearErrors {
  clearErrors: UseFormClearErrors<FormDataUCC1>;
}

export interface IResetField {
  resetField: UseFormResetField<FormDataUCC1>;
}

export interface IResetFields {
  reset: UseFormReset<FormDataUCC1>;
}

export interface IUCC1UIProps
  extends IFormUI<FormDataUCC1>,
    IFilingId,
    IUCC1CommonProps,
    ISetValueType,
    IClearErrors,
    IResetField,
    IGetValues,
    CommonUCC1UCC3Props {
  accordionState: boolean[];
  expandActionLabel: string;
  filingAlerts: IFilingAlert[] | null;
  isSavingUCC1: boolean;
  isOffensiveLanguageOverrided: boolean;

  showBlocker: boolean;
  setShowBlocker: React.Dispatch<React.SetStateAction<boolean>>;

  setIsOffensiveLanguageOverrided(prop: boolean): void;

  handleAccordionState(prop: Accordion): void;

  handleCheckboxAccordionState(prop: Accordion): void;

  expandAction(): void;

  handleOnSave(e: React.SyntheticEvent): void;

  handleOnComplete(e: React.SyntheticEvent): void;

  handleFormCancel(): void;

  removeDebSec(props: RemoveDebSec): void;

  openDebSecModal(props: IDebSecModal): void;

  updateAttachmentsList(filingId: IFilingId): void;

  designationTypes: AltDesignationType[] | null;
  docStampTax: DocStampTaxType[] | null;
  financingTypesData: FinancingStatementCoverageType[] | null;
  debtorTypesData: DebtorType[] | null;
  debtorFilingTypesData: DebtorFilintType[] | null;
  isAttachmentListLoading: boolean;
  isCompleteFormValid: boolean;
  doFormCancel: () => void;
}

export interface IDebSecFilingId extends IFilingId, Partial<IDebsec> {}

export enum ActionType {
  Save = 'Save',
  Complete = 'Complete',
  Delete = 'Delete',
  Change = 'Change',
}

export interface IRequestUCC1Payload {
  id?: number;
  name?: string;
  email?: string;
  phoneNumber?: string;
  actionType: ActionType;
  isOffensiveLanguageOverrided?: boolean;
}

export interface IRequestPayload extends IFilingId {
  id?: number;
  type: EntityType;
  address1: string;
  address2: string;
  city: string;
  country: CountryType;
  firstName: string;
  isForeign: boolean;
  middleName: string;
  name: string;
  nameType: NameType;
  state: string;
  suffix: string;
  lastName: string;
  zip: string;
  isOffensiveLanguageOverrided: boolean;
}

export interface IDebSecIdRequestPayload<T> extends IDebSecFilingId {
  payload: T;
}

export interface IDebsec {
  debsecId: number;
}

export interface IPartyName {
  partyName: DebSecFullType;
}

export interface IDebSecModal extends Partial<IDebsec>, IFilingId, IPartyName {
  isEdit: boolean;
  type: EntityType;
}

export interface IOnSuccess extends IPartyName, IFilingId {
  isEdit: boolean;
}

export interface IDebSecModalContent extends IDebSecModal {
  onSuccess(prop: IOnSuccess): void;
}

export type RemoveDebSec = Omit<IDebSecModal, 'isEdit'> & IDebsec;

export interface IHandleOnRemove extends IFilingId, IDebsec, IPartyName {
  evt: React.SyntheticEvent;
}

export interface IDebtorsOrSecuredPartiesHeaderProps {
  title: string;
  hasBorderTop?: boolean;
}
