import { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, useTheme } from '@mui/material';

import { schema } from './schema';
import DebSecModalContentUi from './DebSecModalContentUi';
import Loader from '../../../common/components/loader';
import ObscenitySSNTable from '../obscenity-ssn-table';
import {
  createUCC1DebSec,
  getListOfStates,
  getUCC1DebSecById,
  updateUCC1DebSec,
} from '../ucc1.services';
import { defaultValues } from './constants';
import { getValueForField, mapper } from './utils';
import { useAuth, useModal } from '../../../common/context';
import { useSnackbar } from '../../../common/notification';
import { StyledActionButton } from '../../../common/global-styles';
import { YesNoModal } from '../../../common/yes-no-modal';
import { parseErrorMessages, uppercaseSelectedDataFields } from '../../../common/helpers';
import { CloseIconButton } from '../../../common/global-styles/CloseIconButton';

import type {
  IDebSecModalContent,
  IDebSecResponsePayload,
  IRequestPayload,
  IResponseDebSec,
} from '../models';
import { FormData, IDynamicFromStateType, StateType } from './models';
import { CountryType, IResponse, NameType } from '../../../common/models/features/models';
import { useBeforeUnload } from 'react-router-dom';
import useEditMode from '../../../common/hooks/useEditMode';

const DebSecModalContent = ({
  partyName,
  isEdit,
  debsecId,
  filingId,
  type,
  onSuccess,
}: IDebSecModalContent) => {
  const theme = useTheme();

  const { setModalAdditionalInfo, handleSetModalActions, handleModalClose, handleSetModalXButton } =
    useModal();
  const { Snack } = useSnackbar();
  const { isEditMode } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [isObscenitySSNCheckFailed, setIsObscenitySSNCheckFailed] = useState(false);
  const [statesList, setStatesList] = useState<StateType[]>([]);
  const [savedFromData, setSavedFormData] = useState<IDebSecResponsePayload | null>(null);
  const [isOffensiveLanguageOverrided, setIsOffensiveLanguageOverrided] = useState(false);
  const [shouldOpenUnsavedChangesModal, setShouldOpenUnsavedChangesModal] = useState(false);
  const [dynamicFormState, setDynamicFormState] = useState<IDynamicFromStateType>({
    nameType: NameType.organization,
    isForeign: false,
  });

  useBeforeUnload(
    useCallback(
      (e) => {
        if (isEditMode) {
          e.preventDefault();
          e.returnValue = '';
          return true;
        }
      },
      [isEditMode]
    )
  );

  const { handleSubmit, formState, control, setValue, reset, clearErrors } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      type,
    },
  });

  const { nameType, isForeign } = dynamicFormState;
  const { isDirty, isValid, isSubmitting } = formState;

  useEffect(() => {
    if (isForeign) {
      clearErrors('zip');
    }
  }, [clearErrors, isForeign]);

  const getStates = async () => {
    const res = await getListOfStates();
    if (!res.notOk) {
      setStatesList(res.payload);
    } else {
      Snack.error(parseErrorMessages(res.messages));
    }
  };

  const getUCC1DebSecData = async () => {
    const res = await getUCC1DebSecById({ filingId, debsecId });
    if (!res.notOk) {
      const { payload } = res;
      setDynamicFormState({
        nameType: payload.isOrganization === false ? NameType.individual : NameType.organization,
        isForeign: payload.isForeign,
      });
      setSavedFormData(payload);
    } else {
      Snack.error(parseErrorMessages(res.messages));
    }
  };

  const initialize = async () => {
    await getStates();

    if (isEdit) {
      await getUCC1DebSecData();
    }
    setIsLoading(false);
  };

  const handleModalCancel = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      if (isDirty || isObscenitySSNCheckFailed) {
        setShouldOpenUnsavedChangesModal(true);
      } else {
        handleModalClose(e);
        Snack.info('Action canceled.');
      }
    },
    [isDirty, isObscenitySSNCheckFailed]
  );

  useEditMode(isDirty);

  useEffect(() => {
    if (savedFromData) {
      mapper.map(({ key, action }) => setValue(key, action(savedFromData)));
      if (savedFromData.isForeign) {
        setDynamicFormState((state) => ({ ...state, isForeign: savedFromData.isForeign }));
      }
    }
  }, [savedFromData]);

  useEffect(() => {
    handleSetModalXButton(<CloseIconButton onClick={handleModalCancel} />);
  }, [handleModalCancel]);

  useEffect(() => {
    if (
      !isValid ||
      isSubmitting ||
      (isObscenitySSNCheckFailed && !isDirty && !isOffensiveLanguageOverrided)
    ) {
      handleSetModalActions(
        <Box
          sx={{
            display: 'flex',
            gap: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(37) },
            flexDirection: 'row-reverse',
            width: '100%',
          }}
        >
          <StyledActionButton
            form="debsec-modal-form"
            type="submit"
            variant="contained"
            startIcon={isSubmitting ? <Loader /> : null}
            disabled
          >
            {isSubmitting ? 'Saving' : 'Save'}
          </StyledActionButton>
          <StyledActionButton onClick={handleModalCancel} variant="outlined">
            Cancel
          </StyledActionButton>
        </Box>
      );
    } else {
      handleSetModalActions(
        <Box
          sx={{
            display: 'flex',
            gap: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(37) },
            flexDirection: 'row-reverse',
            width: '100%',
          }}
        >
          <StyledActionButton form="debsec-modal-form" type="submit" variant="contained">
            Save
          </StyledActionButton>
          <StyledActionButton onClick={handleModalCancel} variant="outlined">
            Cancel
          </StyledActionButton>
        </Box>
      );
    }
  }, [
    isValid,
    isSubmitting,
    handleModalCancel,
    isObscenitySSNCheckFailed,
    isDirty,
    isOffensiveLanguageOverrided,
  ]);

  useEffect(() => {
    setValue('isForeign', isForeign);
    setValue('country', isForeign ? CountryType.OTC : CountryType.US);
    setValue('state', isForeign ? '' : getValueForField('state', savedFromData));
  }, [isForeign]);

  useEffect(() => {
    // Reset values to saved or default when switching between Organization/Individual
    let preservedValues:
      | Pick<FormData, 'name' | 'firstName' | 'lastName' | 'middleName' | 'suffix'>
      | Record<string, never> = {};

    if (nameType === NameType.individual) {
      preservedValues = {
        name: '',
        firstName: getValueForField('firstName', savedFromData),
        lastName: getValueForField('lastName', savedFromData),
        middleName: getValueForField('middleName', savedFromData),
        suffix: getValueForField('suffix', savedFromData),
      };
    } else {
      preservedValues = {
        name: getValueForField('name', savedFromData),
        firstName: '',
        lastName: '',
        middleName: '',
        suffix: '',
      };
    }

    reset(
      (formValues) => ({
        ...formValues,
        ...preservedValues,
        nameType,
      }),
      {
        keepErrors: true,
        keepDirty: true,
      }
    );
  }, [nameType]);

  useEffect(() => {
    initialize();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDynamicFormState({
      ...dynamicFormState,
      [event.target.name]:
        event.target.name === 'isForeign' ? event.target.checked : event.target.value,
    });
  };

  const onSubmit = async (data: FormData) => {
    if (isEdit && !isObscenitySSNCheckFailed && !formState.isDirty) {
      onSuccess({ filingId, isEdit, partyName });
      return;
    }

    let res: IResponse<IResponseDebSec> | undefined = undefined;
    if (isObscenitySSNCheckFailed) {
      setIsObscenitySSNCheckFailed(false);
    }

    const payload: IRequestPayload = {
      ...(uppercaseSelectedDataFields(data, [
        'name',
        'lastName',
        'firstName',
        'middleName',
        'suffix',
        'address1',
        'address2',
        'city',
        'zip',
      ]) as FormData),
      filingId,
      id: isEdit ? debsecId : undefined,
      isOffensiveLanguageOverrided,
    };

    if (!isEdit) {
      res = await createUCC1DebSec({ filingId, payload });
    } else {
      res = await updateUCC1DebSec({ filingId, payload, debsecId });
    }

    if (res.notOk) {
      if (res.payload?.filingAlerts !== null && res.payload?.filingAlerts?.length > 0) {
        setIsObscenitySSNCheckFailed(true);
        reset({}, { keepValues: true });
        setModalAdditionalInfo(
          <ObscenitySSNTable
            alerts={res.payload?.filingAlerts}
            handleOffensiveLanguageOverrided={(e) => {
              setIsOffensiveLanguageOverrided(e.target.checked);
            }}
          />
        );
      } else {
        Snack.error(parseErrorMessages(res.messages));
      }
    }

    if (!res.notOk) {
      onSuccess({ filingId, isEdit, partyName });
    }
  };

  const handleYesAction = () => {
    setShouldOpenUnsavedChangesModal(false);
    handleModalClose();
    Snack.info('Action canceled.');
  };

  if (isLoading && isEdit) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 100 }}>
        <Loader size={40} color="blue" />
      </Box>
    );
  }

  return (
    <>
      <DebSecModalContentUi
        partyName={partyName}
        selectedNameType={nameType}
        isForeign={isForeign}
        handleSubmit={handleSubmit(onSubmit)}
        control={control}
        formState={formState}
        handleChange={handleChange}
        statesList={statesList}
      />
      <YesNoModal
        open={shouldOpenUnsavedChangesModal}
        handleModalClose={() => setShouldOpenUnsavedChangesModal(false)}
        yesAction={handleYesAction}
        noAction={() => setShouldOpenUnsavedChangesModal(false)}
      />
    </>
  );
};

export default DebSecModalContent;
