import { StyledLogoTextContainer } from './StyledLogoTextContainer';
import { StyledLogoBackground } from './StyledLogoBackground';
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import { StyledAnchor } from '../../header/StyledAnchor';

import { ReactComponent as Location } from '../../../../assets/icons/location.svg';
import { ReactComponent as Email } from '../../../../assets/icons/email.svg';
import { ReactComponent as Phone } from '../../../../assets/icons/phone.svg';
import { ReactComponent as Time } from '../../../../assets/icons/time.svg';

import { colors } from '../../../constants';
import { StyledTypography } from './StyledTypography';

const StyledCompanyInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.convert.pxToRem(7),
}));

export function CompanyInfo() {
  return (
    <StyledCompanyInfo>
      <StyledLogoTextContainer>
        <StyledLogoBackground background={colors.defaultBackground} />
        <Typography variant="h5">Florida UCC, LLC</Typography>
      </StyledLogoTextContainer>
      <StyledLogoTextContainer>
        <StyledLogoBackground>
          <Location title="Location" />
        </StyledLogoBackground>
        <StyledTypography>
          2002 Old St. Augustine Rd. Bldg. D Tallahassee, FL 32301
        </StyledTypography>
      </StyledLogoTextContainer>
      <StyledLogoTextContainer>
        <StyledLogoBackground>
          <Email title="Email" />
        </StyledLogoBackground>
        <StyledAnchor tabIndex={-1} href="mailto:help@floridaucc.com">
          <StyledTypography>help@floridaUCC.com</StyledTypography>
        </StyledAnchor>
      </StyledLogoTextContainer>
      <StyledLogoTextContainer>
        <StyledLogoBackground>
          <Phone title="Phone" />
        </StyledLogoBackground>
        <StyledTypography>850.222.8526</StyledTypography>
      </StyledLogoTextContainer>
      <StyledLogoTextContainer>
        <StyledLogoBackground>
          <Time title="Time" />
        </StyledLogoBackground>
        <StyledTypography>8:00AM – 5:00PM EST weekdays excluding state holidays</StyledTypography>
      </StyledLogoTextContainer>
    </StyledCompanyInfo>
  );
}
