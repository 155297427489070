import { Controller, useWatch } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { StyledTextAreaInput } from '../../../common/components/inputs';
import { StyledStartUccRadioButton } from '../../start-ucc-filing/styled-items/StyledStartUccFilingRadioButton';
import { IFormUI } from '../../../common/models';
import { FormDataUCC3 } from '../schema';
import { EntityType } from '../../../common/models/features/models';
import { DebtorOrSecuredType, EntityTypesLabel, ISetValueType } from '../models';
import { getDebtorOrSecuredName } from '../../ucc1/utils';
import { useMemo } from 'react';
import { getPartyName } from '../utils';

interface IAuthorization extends IFormUI<FormDataUCC3>, ISetValueType {
  entityTypes: EntityType[] | null;
  securedParties: DebtorOrSecuredType[];
  debtors: DebtorOrSecuredType[];
}

type IResetAuthFormValues =
  | 'authDebType'
  | 'debSecType'
  | 'authSecType'
  | 'authDebtors'
  | 'authSecured';

const sort = (arr: DebtorOrSecuredType[]) =>
  [...arr].sort((a, b) => a.sequenceNumber - b.sequenceNumber);

const Authorization = ({
  control,
  formState: { errors },
  entityTypes,
  setValue,
  debtors,
  securedParties,
}: IAuthorization) => {
  const theme = useTheme();

  const sortedDebtors = useMemo(() => sort(debtors), [debtors]);
  const sortedSecured = useMemo(() => sort(securedParties), [securedParties]);

  const [filerRefData, authDebType, debSecType, authSecType] = useWatch({
    control,
    name: ['filerRefData', 'authDebType', 'debSecType', 'authSecType'],
  });

  const resetAuthFormValues = (...props: IResetAuthFormValues[]) => {
    props.forEach((element: IResetAuthFormValues) => {
      setValue(element, '');
    });
  };

  return (
    <Box
      sx={{
        paddingY: theme.convert.pxToRem(28),
        paddingX: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(40) },
      }}
    >
      <Grid container direction="column" gap={2}>
        <Grid container gap={1}>
          <Grid item xs={12} md={12} sx={{ paddingBottom: { xs: 1 } }}>
            <Typography variant="h5">
              Name of debtor or secured party of authorizing this amendment: (name of assignor, if
              this is an assignment):
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} sx={{ paddingBottom: { xs: 1 } }}>
            <Typography variant="body1" width={{ xl: 1090 }}>
              If this is an Amendment authorized by a Debtor, which adds collateral or adds the
              authorizing Debtor, or if this is a Termination authorized by a Debtor,
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <Controller
              control={control}
              name="debSecType"
              render={({ field: { ref, onChange, ...field } }) => (
                <FormControl error={!!errors.debSecType?.message}>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormControlLabel
                        {...field}
                        onChange={(e) => {
                          resetAuthFormValues(
                            'authDebType',
                            'authSecType',
                            'authDebtors',
                            'authSecured'
                          );
                          onChange(e);
                        }}
                        value={'None'}
                        checked={!!debSecType}
                        sx={{
                          '.MuiFormControlLabel-label': { fontWeight: 600 },
                          alignItems: 'baseline',
                        }}
                        control={<StyledStartUccRadioButton />}
                        label="N/A"
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <Controller
              control={control}
              name="authDebType"
              render={({ field: { ref, onChange, ...field } }) => (
                <>
                  <FormControl error={!!errors.authDebType?.message}>
                    <Grid container>
                      <Grid item xs={12}>
                        <FormControlLabel
                          {...field}
                          onChange={(e) => {
                            resetAuthFormValues('debSecType', 'authSecType', 'authSecured');
                            onChange(e);
                          }}
                          checked={!!authDebType}
                          value={'Debtor'}
                          sx={{
                            '.MuiFormControlLabel-label': { fontWeight: 600 },
                            alignItems: 'baseline',
                          }}
                          control={<StyledStartUccRadioButton />}
                          label={EntityTypesLabel['DebtorLabel' as keyof typeof EntityTypesLabel]}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </>
              )}
            />
            <FormHelperText error={!!errors.authDebType?.message}>
              {errors.authDebType?.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Controller
              name="authDebtors"
              control={control}
              render={({ field: { ref, ...field } }) => {
                return (
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      {...field}
                      inputRef={ref}
                      displayEmpty
                      disabled={!authDebType}
                      error={!!errors.authDebtors?.message}
                      variant="standard"
                      id="authDebtors"
                      label="Debtors"
                      fullWidth
                      sx={{
                        width: { sm: 284, md: 400 },
                      }}
                      renderValue={(selected) => {
                        if (!selected || selected === '0') {
                          return (
                            <MenuItem disabled value="">
                              <em>Select Debtor</em>
                            </MenuItem>
                          );
                        }
                        return getPartyName(debtors, selected);
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>Select Debtor</em>
                      </MenuItem>
                      {sortedDebtors.map((item: DebtorOrSecuredType) => {
                        if (item.sequenceNumber > 0) {
                          return (
                            <MenuItem key={item.id} value={item.sequenceNumber}>
                              {getDebtorOrSecuredName(item)}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <Typography variant="h5">Otherwise, </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <Controller
              control={control}
              name="authSecType"
              render={({ field: { ref, onChange, ...field } }) => (
                <FormControl error={!!errors.authSecType?.message}>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormControlLabel
                        {...field}
                        onChange={(e) => {
                          resetAuthFormValues('debSecType', 'authDebType', 'authDebtors');
                          onChange(e);
                        }}
                        checked={!!authSecType}
                        value={'Secured'}
                        sx={{
                          '.MuiFormControlLabel-label': { fontWeight: 600 },
                          alignItems: 'baseline',
                        }}
                        control={<StyledStartUccRadioButton />}
                        label={EntityTypesLabel['SecuredLabel' as keyof typeof EntityTypesLabel]}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              )}
            />
            <FormHelperText error={!!errors.authSecType?.message}>
              {errors.authSecType?.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={4} sm={12}>
            <Controller
              name="authSecured"
              control={control}
              render={({ field: { ref, ...field } }) => {
                return (
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      labelId="label-auth-secured"
                      {...field}
                      inputRef={ref}
                      displayEmpty
                      disabled={!authSecType}
                      error={!!errors.authSecured?.message}
                      variant="standard"
                      id="authSecured"
                      fullWidth
                      sx={{
                        width: { sm: 284, md: 400 },
                      }}
                      renderValue={(selected) => {
                        if (!selected || selected === '0') {
                          return (
                            <MenuItem disabled value="">
                              <em>Select Secured Party</em>
                            </MenuItem>
                          );
                        }
                        return getPartyName(securedParties, selected);
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>Select Secured Party</em>
                      </MenuItem>
                      {sortedSecured.map((item: DebtorOrSecuredType) => {
                        if (item.sequenceNumber > 0) {
                          return (
                            <MenuItem key={item.id} value={item.sequenceNumber}>
                              {getDebtorOrSecuredName(item)}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container gap={1}>
          <Grid item md={12} xs={12} sx={{ paddingBottom: { xs: 1 } }}>
            <Typography variant="h5">Optional Filer Reference Data:</Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={12} xl={9} position="relative">
            <Controller
              name="filerRefData"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <FormControl error={!!errors.filerRefData?.message} fullWidth>
                  <StyledTextAreaInput
                    placeholder="Enter optional filer reference data"
                    error={!!errors.filerRefData?.message}
                    {...field}
                    minRows={3}
                  />
                </FormControl>
              )}
            />
            <Typography
              sx={{
                position: 'absolute',
                right: theme.convert.pxToRem(10),
                bottom: `${
                  errors.filerRefData?.message
                    ? theme.convert.pxToRem(25)
                    : theme.convert.pxToRem(5)
                }`,
                fontSize: theme.typography.pxToRem(12),
                color: theme.palette.grey[500],
              }}
            >
              {filerRefData?.length ?? 0} / 50 characters
            </Typography>
            <FormHelperText error={!!errors.filerRefData?.message}>
              {errors.filerRefData?.message}
            </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Authorization;
