import { TableContainer, styled } from '@mui/material';

export const StyledTableContainer = styled(TableContainer)(
  ({ theme }) => `
::-webkit-scrollbar {
  width: ${theme.convert.pxToRem(8)};
  height: ${theme.convert.pxToRem(8)};
}

::-webkit-scrollbar-track {
  background: ${theme.palette.grey[200]};
}

::-webkit-scrollbar-thumb {
  background: ${theme.palette.grey[700]};
  border-radius: ${theme.convert.pxToRem(3)};
}
`
);
