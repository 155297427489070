import { useEffect } from 'react';
import { useAuth } from '../context';

const useEditMode = (condition: boolean) => {
  const { setIsEditMode } = useAuth();

  useEffect(() => {
    setIsEditMode(condition);
    return () => setIsEditMode(false);
  }, [condition, setIsEditMode]);
};

export default useEditMode;
