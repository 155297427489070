import { Box, Grid, styled } from '@mui/material';

import { itemDeviderBorder } from '../../../common/constants/colors';

export const StyledGrid = styled(Grid)(({ theme }) => ({
  borderTop: `1px solid ${itemDeviderBorder}`,
  padding: `${theme.convert.pxToRem(12)} ${theme.convert.pxToRem(20)}`,
}));

export const StyledTableInfoWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.convert.pxToRem(12)} ${theme.convert.pxToRem(20)} ${theme.convert.pxToRem(
    12
  )} ${theme.convert.pxToRem(20)}`,
  display: 'flex',
  flexDirection: 'row',
  gap: theme.convert.pxToRem(15),
  alignItems: 'flex-start',
}));
