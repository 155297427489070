import { API } from 'aws-amplify';
import {
  apiName,
  emptyInit,
  verifyFilingPath,
  publicSearchApiName,
} from '../../common/constants/api';

export const getUccFilingAPI = (number: string) => {
  return API.get(publicSearchApiName, `${verifyFilingPath}/${number}`, emptyInit);
};

export const getUccFilingDeptorAPI = (number: string) => {
  const parameters = {
    queryStringParameters: {
      entityRelToFiling: 0,
    },
  };
  return API.get(publicSearchApiName, `${verifyFilingPath}/${number}/deb-sec-names`, parameters);
};

export const postUCC1 = async () => {
  try {
    const res = await API.post(apiName, `/ucc1`, emptyInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data };
  }
};

export const postUCC3 = async (filingId: string | null) => {
  try {
    const res = await API.post(apiName, `/ucc3`, {
      body: {
        ucc1Number: filingId,
      },
    });
    return res;
  } catch (err: any) {
    return { ...err?.response?.data };
  }
};
