import { FC } from 'react';
import {
  StyledTableContainer,
  StyledTableContentGrid,
  StyledTableHeadingGrid,
} from './details/StyledComponents';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import SearchResultsHeading from './details/SearchResultsHeading';
import { FilingDetailEntityInterface } from './types';
import { ReactComponent as BackArrow } from '../../assets/icons/arrow-back.svg';
import Loader from '../../common/components/loader';
import { useNavigate } from 'react-router-dom';
import { formatDebSec } from './formatDebSec';

interface Props {
  data?: FilingDetailEntityInterface[];
  uccNumber?: string;
  isLoading: boolean;
  title: string;
}

const DebtorSecuredList: FC<Props> = ({ data, uccNumber, isLoading, title }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };
  return (
    <>
      <Box marginBottom={theme.convert.pxToRem(20)}>
        <Button onClick={handleBackClick} startIcon={<BackArrow />}>
          Back
        </Button>
      </Box>
      {isLoading ? (
        <Box display={'flex'} justifyContent={'center'} marginTop={10}>
          <Loader color="blue" size={50} />
        </Box>
      ) : (
        <StyledTableContainer>
          <Grid container flexDirection={'column'} minWidth={theme.convert.pxToRem(1140)}>
            <SearchResultsHeading content={`${title}: ${uccNumber ?? ''}`} />
            {data && (
              <>
                <StyledTableHeadingGrid
                  item
                  container
                  display={'flex'}
                  justifyContent={'space-between'}
                >
                  <Typography fontWeight={600}>Name & Address</Typography>
                </StyledTableHeadingGrid>
                {data.map((item, index) => (
                  <StyledTableContentGrid
                    key={item.name + index}
                    item
                    xs={12}
                    borderBottom={
                      index + 1 === data.length ? '' : `1px solid ${theme.palette.grey[300]}`
                    }
                  >
                    <Typography>{item.name}</Typography>
                    <Typography>{formatDebSec(item)}</Typography>
                  </StyledTableContentGrid>
                ))}
              </>
            )}
          </Grid>
        </StyledTableContainer>
      )}
    </>
  );
};

export default DebtorSecuredList;
