import { useEffect, useMemo, useState } from 'react';
import { getFees } from './fees.services';

enum FeeType {
  UCC5_FEE = 'UCC5_FEE',
  ADDITIONAL_FEE = 'ADDITIONAL_FEE',
  UCC1_STANDARD_FORM_FEE = 'UCC1_STANDARD_FORM_FEE',
  UCC3_STANDARD_FORM_FEE = 'UCC3_STANDARD_FORM_FEE',
  UCC3_TERMINATION_AFTER_FEE = 'UCC3_TERMINATION_AFTER_FEE',
  UCC3_NON_STANDARD_FORM_FEE = 'UCC3_NON_STANDARD_FORM_FEE',
  UCC1_NON_STANDARD_FORM_FEE = 'UCC1_NON_STANDARD_FORM_FEE',
  UCC3_TERMINATION_BEFORE_FEE = 'UCC3_TERMINATION_BEFORE_FEE',
}

interface FeeContent {
  fee: number;
  type: FeeType;
  included?: string;
  description: string[];
}

interface FeeTypeContent {
  name: string;
  fees: FeeContent[];
}

const ucc1Included = '1 debtor, 1 secured and the 1st page';
const ucc3Included = '1 debtor or 1 secured party change, if an amendment or assignment is done';

const standardFormDescription = [
  'Approved Florida and national forms.',
  'Example - Florida forms approved on or after 05/2013 or national forms approved on or after 04/2011.',
];
const nonStandardFormDescription = [
  'All other forms.',
  'Example - Florida forms approved prior to 05/2013 or national forms approved prior to 04/2011.',
];

const createFeeContents = (woFees: Omit<FeeContent, 'fee'>[]): FeeContent[] =>
  woFees.map((f) => ({ ...f, fee: 0 }));

const contentWithoutFees: FeeTypeContent[] = [
  {
    name: 'UCC1',
    fees: createFeeContents([
      {
        included: ucc1Included,
        description: standardFormDescription,
        type: FeeType.UCC1_STANDARD_FORM_FEE,
      },
      {
        included: ucc1Included,
        description: nonStandardFormDescription,
        type: FeeType.UCC1_NON_STANDARD_FORM_FEE,
      },
    ]),
  },
  {
    name: 'UCC3',
    fees: createFeeContents([
      {
        included: ucc3Included,
        description: standardFormDescription,
        type: FeeType.UCC3_STANDARD_FORM_FEE,
      },
      {
        included: ucc3Included,
        description: nonStandardFormDescription,
        type: FeeType.UCC3_NON_STANDARD_FORM_FEE,
      },
    ]),
  },
  {
    name: 'UCC3 - Termination',
    fees: createFeeContents([
      {
        type: FeeType.UCC3_TERMINATION_BEFORE_FEE,
        description: ['To terminate any UCC before 10/01/1992.'],
      },
      {
        type: FeeType.UCC3_TERMINATION_AFTER_FEE,
        description: ['To terminate any UCC on or after 10/01/1992.'],
      },
    ]),
  },
  {
    name: 'UCC5',
    fees: createFeeContents([
      {
        type: FeeType.UCC5_FEE,
        description: ['To add more information about the concerned UCC.'],
      },
    ]),
  },
  {
    name: 'Additional fees',
    fees: createFeeContents([
      {
        type: FeeType.ADDITIONAL_FEE,
        description: [
          'Any additional documents or pages (Addendum and other documents and pages)',
          'Any additional debtors and secured parties',
          'Anything not referenced above',
        ],
      },
    ]),
  },
];

export const useFees = () => {
  const [loading, setLoading] = useState(true);
  const [fees, setFees] = useState<{ key: FeeType; value: number }[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const result = await getFees();

        if (!result.notOk) {
          setFees(result.payload);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const content = useMemo(
    () =>
      contentWithoutFees.map((c) => ({
        ...c,
        fees: c.fees.map((f) => ({
          ...f,
          fee: fees.find((fee) => fee.key === f.type)?.value ?? 0,
        })),
      })),
    [fees]
  );

  return { loading, content };
};
