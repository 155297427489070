import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from '../common/components/loader';
import UCC1Component from '../features/ucc1';
import {
  getUCC1ById,
  getUCC1DebSec,
  getAttachments,
  getCollateralAddendumData,
} from '../features/ucc1/ucc1.services';
import { parseDebSec, parseUcc1 } from '../features/ucc1/utils';
import { parseErrorMessages } from '../common/helpers';
import { useSnackbar } from '../common/notification';

import {
  DebtorOrSecuredType,
  UCC1Type,
  IAttachment,
  DocStampTaxType,
  AltDesignationType,
  FinancingStatementCoverageType,
  DebtorFilintType,
  DebtorType,
} from '../features/ucc1/models';

import type { IFilingId } from '../common/models/features/models';
import { UnsavedHandlerContextProvider } from '../features/shared/ucc/unsaved-handler-context/UnsavedHandlerContext';
import { errorPage, notFound } from '../common/constants/routes';

const UCC1 = () => {
  const { filingId: fId } = useParams();
  const { Snack } = useSnackbar();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [dataUCC1, setDataUCC1] = useState<UCC1Type | null>(null);
  const [debtors, setDebtors] = useState<DebtorOrSecuredType[]>([]);
  const [securedParties, setSecuredParties] = useState<DebtorOrSecuredType[]>([]);
  const [attachments, setAttachments] = useState<IAttachment[]>([]);
  const [designationTypes, setDesignationTypes] = useState<AltDesignationType[] | null>(null);
  const [docStampTax, setDocStampTax] = useState<DocStampTaxType[] | null>(null);
  const [financingTypesData, setFinancingTypesData] = useState<
    FinancingStatementCoverageType[] | null
  >(null);
  const [debtorTypesData, setDebtorTypesData] = useState<DebtorType[] | null>(null);
  const [debtorFilingTypesData, setDebtorFilingTypesData] = useState<DebtorFilintType[] | null>(
    null
  );

  const [isAttachmentListLoading, setIsAttachmentListLoading] = useState(false);

  const filingId = Number(fId);

  const updateDebSecList = async ({ filingId }: IFilingId) => {
    const debsecRes = await getUCC1DebSec({ filingId });

    if (debsecRes.notOk) {
      const message = parseErrorMessages(debsecRes.messages);
      Snack.error(message);
      return navigate(errorPage);
    }
    const { debtors: deb, securedParties: sec } = parseDebSec(debsecRes.payload);

    setDebtors(deb);
    setSecuredParties(sec);
  };

  const updateAttachmentsList = async ({ filingId }: IFilingId) => {
    setIsAttachmentListLoading(true);
    const attachmentsData = await getAttachments({ filingId });
    setIsAttachmentListLoading(false);
    if (attachmentsData.notOk) {
      Snack.error(parseErrorMessages(attachmentsData.messages));
    } else {
      setAttachments(attachmentsData.payload);
    }
  };

  const getData = async (filingId: number) => {
    const [ucc1Data] = await Promise.all([
      getUCC1ById({ filingId }),
      updateAttachmentsList({ filingId }),
    ]);

    if (ucc1Data.notOk) {
      if (ucc1Data.messages?.length > 0) {
        Snack.error(parseErrorMessages(ucc1Data.messages));
      } else {
        Snack.error('Invalid UCC1 number');
      }
      return navigate(notFound);
    }

    const ucc = parseUcc1(ucc1Data.payload);
    setDataUCC1(ucc);

    const response = await getCollateralAddendumData();
    if (!response.notOk) {
      setDocStampTax(response.docStampTax.payload);
      setDesignationTypes(response.designationTypes.payload);
      setFinancingTypesData(response.financingTypes.payload);
      setDebtorTypesData(response.debtorTypes.payload);
      setDebtorFilingTypesData(response.debtorFilingTypes.payload);
    }

    await updateDebSecList({ filingId });
    setIsLoading(false);
  };

  useEffect(() => {
    if (filingId && isLoading) {
      getData(filingId);
    }
  }, [filingId, isLoading]);

  if (isLoading || !filingId) {
    return (
      <Box
        sx={{
          transform: 'translate(50%, 50%)',
        }}
      >
        <Loader size={40} color="blue" />
      </Box>
    );
  }

  return (
    <UnsavedHandlerContextProvider>
      <UCC1Component
        filingId={filingId}
        dataUCC1={dataUCC1}
        debtors={debtors}
        securedParties={securedParties}
        updateDebSecList={updateDebSecList}
        attachments={attachments}
        updateAttachmentsList={updateAttachmentsList}
        designationTypes={designationTypes}
        docStampTax={docStampTax}
        financingTypesData={financingTypesData}
        debtorTypesData={debtorTypesData}
        debtorFilingTypesData={debtorFilingTypesData}
        getData={getData}
        isAttachmentListLoading={isAttachmentListLoading}
      />
    </UnsavedHandlerContextProvider>
  );
};

export default UCC1;
