import { API } from 'aws-amplify';
import {
  apiName,
  emptyInit,
  getAccInfoPath,
  updatePersonalInfoPath,
  updateAcknowledgementInfoPath,
  accountNumberPath,
  updatePasswordAndSecurityPath,
} from '../../common/constants/api';
import { UpdatePersonalInfo, UpdateAcknowledgementInfo } from '../../common/context/types';
import { IEmail, IPassword } from '../../common/models';

export const updatePersonalInfo = ({ name, phoneNumber }: UpdatePersonalInfo) => {
  const payload = {
    name,
    phoneNumber: phoneNumber || null,
  };
  return API.put(apiName, updatePersonalInfoPath, { body: payload });
};

export const updateAcknowledgementInfo = ({
  contactName,
  phoneNumber,
  email,
}: UpdateAcknowledgementInfo) => {
  const payload = {
    contactName: contactName || null,
    phoneNumber: phoneNumber || null,
    email: email || null,
  };
  return API.put(apiName, updateAcknowledgementInfoPath, {
    body: payload,
  });
};

export const updatePasswordAndSecurity = ({ email }: IEmail) => {
  return API.put(apiName, updatePasswordAndSecurityPath, {
    body: { email },
  });
};

export const getAccountNumber = ({ password }: IPassword) => {
  return API.get(
    apiName,
    `${accountNumberPath}?password=${encodeURIComponent(password)}`,
    emptyInit
  );
};
