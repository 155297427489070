import React from 'react';

import { DebtorOrSecuredType, IPartyName, RemoveDebSec } from '../../../features/ucc1/models';
import { IOpenModalArgs } from '../../../features/ucc3/models';
import { ILayoutWrapperProps } from '../index';

export interface IMessage {
  type: string;
  code: string;
  message: string;
  args: string[];
}

export enum CountryType {
  OTC = 'OTC',
  US = 'USA',
}

export enum NameType {
  organization = 'Organization',
  individual = 'Individual',
}

export interface IResponse<T> {
  status: string;
  notOk: true;
  messages: IMessage[];
  payload: T;
  friendlyMessageSummary: string;
  messageSummary: string;
}

export interface IResponseWithMeta<T> extends IResponse<T> {
  meta: {
    currentPage: number;
    pageSize: number;
    totalCount: number;
    uniqueTotal: number;
  };
}

export interface IFilingId {
  filingId: number;
}

export enum EntityType {
  Debtor = 'Debtor',
  Secured = 'Secured',
}

export enum Status {
  Pending = 'Pending',
  Submitted = 'Submitted',
  Posted = 'Posted',
  QACompleted = 'QACompleted',
  Change = 'Change',
  Delete = 'Delete',
  Completed = 'Completed',
  Flagged = 'Flagged',
  Payment = 'Payment',
  Incompleted = 'Incompleted',
}

export interface IResponseCRUDInfo {
  createdAt: Date;
  createdBy: number;
  deletedAt: Date | null;
  deletedBy: number | null;
  updatedAt: Date | null;
  updatedBy: number | null;
}

export interface ICommonUCCResponsePayload extends IResponseCRUDInfo {
  id: number;
  packetId: number;
  additionalPartyPages: number;
  attachmentPages: number;
  totalFilingFee: number;
  qaUserId: number | null;
  ackContactName: string | null;
  ackContactPhone: string | null;
  ackContactEmail: string | null;
  qaDate: string | null;
  postDate: string | null;
  unredactedImageFilePath: string | null;
  redactedImageFilePath: string | null;
  uccNumber: string | null;
  filerRefData: string | null;
  isSkipped: boolean;
  isPosted: boolean;
  isBulkload: boolean;
  isActive: boolean;
}

export interface IDebtorsOrSecuredParties extends IPartyName, IFilingId {
  parties: DebtorOrSecuredType[] | null;
  type: EntityType;
  isDisabled: boolean;
  infoMessage?: string;

  doUndo(args: IOpenModalArgs): void;

  openDebSecModal(isEdit: boolean, debSecId?: number | undefined): void;

  removeDebSec(props: RemoveDebSec): void;
}

export interface UseRemoveDebSecModalProps {
  getData: (filingId: number) => Promise<void>;
}

export interface IPageWrapper extends ILayoutWrapperProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  headTitle?: string;
  headDescription?: string;
}

export interface IPrintableAreaProps {
  children: React.ReactNode;
}

export interface CommonUCC1UCC3Props {
  isDirty: boolean;
  isSaved: boolean;
  isCompleted: boolean;
  isCanceled: boolean;
  hasPendingUploads: boolean;
}

export interface Announcement {
  title: string;
  content: string;
  publishDate: string;
}
