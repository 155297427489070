import { IMessage } from '../models/features/models';

export const parseErrorMessages = (messages?: Partial<IMessage>[], optionalMessage?: string) => {
  const message = messages?.reduce((acc, { message }) => `${acc}${message ?? ''}`, '') || '';
  return message?.length > 0
    ? message
    : optionalMessage ??
        "Oops, something wasn't right. Please fix the error(s) below and continue.";
};

export const uppercaseSelectedDataFields = (data: Record<string, any>, keys: string[]) => {
  keys.forEach((key) => {
    if (typeof data[key] === 'string') {
      data[key] = data[key].toUpperCase();
    }
  });
  return data;
};
