import { createContext, useContext, useState } from 'react';
import type { ILayoutWrapperProps } from '../models';
import {
  getCartDetails as getCartDetailsAPI,
  getMyAccountInfo as getMyAccountInfoAPI,
  verifyCart,
} from '../../infrastructure/api/user-info-api';
import { useSnackbar } from '../notification';
import { parseErrorMessages } from '../helpers';

import type { ICartFilingsDetails, IUserContext } from './types';

const UserInfoContext = createContext<IUserContext | undefined>(undefined);

function UserInfoProvider({ children }: ILayoutWrapperProps) {
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [isLoadingCartDetails, setIsLoadingCartDetails] = useState(true);
  const [cartFilingsDetails, setCartFilingsDetails] = useState<ICartFilingsDetails | null>(null);
  const [fullName, setFullName] = useState<string>('');
  const [lastLogin, setLastLogin] = useState<string>('');
  const [delayFilingsFetch, setDelayFilingsFetch] = useState(false);
  const { Snack } = useSnackbar();

  const value = {
    loadingInfo,
    delayFilingsFetch,
    isLoadingCartDetails,
    cartFilingsDetails,
    setDelayFilingsFetch,
    setCartFilingsDetails,
    fullName,
    setFullName,
    lastLogin,
    async getUserInfo() {
      const userInfo = await getMyAccountInfoAPI();
      if (userInfo.notOk) {
        Snack.error(
          parseErrorMessages(
            userInfo.messages,
            'Unknown error occured while trying to get your data'
          )
        );
      } else {
        setFullName(
          userInfo.payload.personalInformation.name ?? userInfo.payload.passwordAndSecurity.email
        );
        setLastLogin(userInfo.payload.lastLoginDate);
      }
      setLoadingInfo(false);
    },
    async getCartDetails() {
      const varifyCart = await verifyCart();
      const cartDetails = await getCartDetailsAPI();
      if (varifyCart.notOk || cartDetails.notOk) {
        Snack.error(
          parseErrorMessages(
            cartDetails.messages,
            'Unknown error occured while trying to get cart filing data'
          )
        );
      } else {
        setCartFilingsDetails(cartDetails.payload);
      }
      setIsLoadingCartDetails(false);
    },
  };

  return <UserInfoContext.Provider value={value}>{children}</UserInfoContext.Provider>;
}

function useUserInfo() {
  const context = useContext(UserInfoContext);
  if (context === undefined) {
    throw new Error('useUserInfo must be used within a UserInfoProvider');
  }
  return context;
}

export { UserInfoProvider, useUserInfo };
