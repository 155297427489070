import Header from '../common/components/header';
import Footer from '../common/components/footer/';
import { StyledLayout } from '../common/layouts/StyledLayout';
import { ReactComponent as ErrorPageIcon } from '../assets/icons/error-page-icon.svg';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { homePageURL } from '../common/constants/routes';

const UnexpectedError = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <StyledLayout>
      <Header />
      <Box sx={{ height: { xs: theme.convert.pxToRem(70), md: theme.convert.pxToRem(122) } }} />
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          marginTop: theme.convert.pxToRem(95),
          marginBottom: theme.convert.pxToRem(300),
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: theme.convert.pxToRem(20),
        }}
      >
        <ErrorPageIcon style={{ marginBottom: theme.convert.pxToRem(20) }} />
        <Typography variant="h1">500 Error</Typography>
        <Typography variant="body1" marginBottom={theme.convert.pxToRem(20)}>
          An unexpected error happened.
        </Typography>
        <Button
          onClick={() => navigate(homePageURL)}
          variant="contained"
          sx={{ width: theme.convert.pxToRem(220) }}
        >
          Go Back
        </Button>
      </Box>
      <Footer />
    </StyledLayout>
  );
};

export default UnexpectedError;
