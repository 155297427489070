import React from 'react';
import { Box } from '@mui/material';

import useDebSecModal from '../../shared/ucc/modals/deb-sec-modal/useDebSecModal';
import DebtorsOrSecuredParties from '../debtors-or-secured-parties/DebtorsOrSecuredParties';
import DebtorsOrSecuredPartiesHeader from '../../ucc1/debtors-or-secured-parties/DebtorsOrSecuredPartiesHeader';

import { EntityType } from '../../../common/models/features/models';
import { AmendmentDebtorsContentProps } from './models';
import { useWatch } from 'react-hook-form';

const AmendmentDebtorsContent = ({
  debtors,
  filingId,
  doUndo,
  removeDebSec,
  getData,
  control,
}: AmendmentDebtorsContentProps) => {
  const eventAmendment = useWatch({
    control,
    name: 'eventAmendment',
  });
  const { handleOpenModal } = useDebSecModal({
    filingId: filingId,
    type: EntityType.Debtor,
    partyName: 'Debtor',
    getData,
  });
  const shouldShowHeader = debtors && debtors.length > 0;
  return (
    <Box>
      {shouldShowHeader && <DebtorsOrSecuredPartiesHeader title="Debtor(s)" />}
      <DebtorsOrSecuredParties
        parties={debtors}
        type={EntityType.Debtor}
        isDisabled={!eventAmendment}
        doUndo={doUndo}
        openDebSecModal={handleOpenModal}
        removeDebSec={removeDebSec}
        partyName={'Debtor'}
        filingId={filingId}
      />
    </Box>
  );
};

export default AmendmentDebtorsContent;
