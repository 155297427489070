import React, { FC, useCallback, useRef, useState } from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { TextDocument } from './TextDocument';
import { StyledModalSubmitButton } from '../global-styles';
import { CloseIconButton } from '../global-styles/CloseIconButton';
import { isTermsOfUseAcceptedValidCookie } from './local-storage/utils';
import { isTermsOfUseAcceptedCookieName } from './local-storage/constants';
import { Content, MainTitle, SubTitle } from '../../features/termsofUse/styledElements';

import { IHandleModalClose } from '../models';
import { homePageURL } from '../constants/routes';
import { data } from '../../features/termsofUse/constants';

const DisclaimerWrapper: FC<{ children: React.ReactNode }> = ({ children }) => {
  const checkboxElement = useRef<HTMLButtonElement | null>(null);

  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const [isAccepted, setIsAccepted] = useState<boolean>(() => isTermsOfUseAcceptedValidCookie());
  const [open, setOpen] = useState(!isAccepted);

  const handleCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  }, []);

  const onNextClick = useCallback(() => {
    const data = { value: 'true', timestamp: new Date().getTime() };
    localStorage.setItem(isTermsOfUseAcceptedCookieName, JSON.stringify(data));
    setIsAccepted(true);

    navigate(location.pathname + location.search);
  }, [navigate, location.pathname, location.search]);

  const handleModalClose: IHandleModalClose = (_, reason) => {
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) return;
    setOpen(false);
  };

  const handleCheckboxKeyPress = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter' && checked) {
      onNextClick();
    }
  };

  if (isAccepted) {
    return <>{children}</>;
  }

  return (
    <Dialog open={open} onClose={handleModalClose}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: theme.convert.pxToRem(20),
          paddingTop: theme.convert.pxToRem(20),
        }}
      >
        <CloseIconButton onClick={() => navigate(homePageURL)} />
      </Box>
      <Box
        sx={{
          paddingLeft: {
            xs: theme.convert.pxToRem(20),
            md: theme.convert.pxToRem(48),
            marginTop: theme.convert.pxToRem(-9),
          },
        }}
      >
        <DialogTitle sx={{ padding: 0, marginBottom: theme.convert.pxToRem(10) }} variant="h2">
          Terms of Use
        </DialogTitle>
      </Box>
      <DialogContent
        sx={{
          paddingTop: 0,
          paddingLeft: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(48) },
          paddingRight: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(48) },
        }}
      >
        <Grid
          container
          paddingTop={theme.convert.pxToRem(10)}
          paddingBottom={theme.convert.pxToRem(7)}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'start'}
        >
          <Grid item marginBottom={theme.convert.pxToRem(12)}>
            <Typography variant="body2">Please read the following agreement carefully.</Typography>
          </Grid>
          <Grid
            height={'350px'}
            sx={{
              height: theme.convert.pxToRem(350),
              padding: theme.convert.pxToRem(20),
              border: '1px solid #242A42',
              overflowY: 'scroll',
              width: isIOS ? theme.convert.pxToRem(311) : 'auto',
            }}
            item
            tabIndex={0}
          >
            <TextDocument
              data={data}
              SubTitle={SubTitle}
              Paragraph={Content}
              DocumentTitle={MainTitle}
            />
          </Grid>
          <Grid item marginTop={theme.convert.pxToRem(10)}>
            <FormControlLabel
              sx={{ width: 'fit-content' }}
              control={
                <Checkbox
                  autoFocus
                  ref={checkboxElement}
                  checked={checked}
                  onChange={handleCheckboxChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  onKeyUp={handleCheckboxKeyPress}
                />
              }
              label={
                <Typography
                  variant="body1"
                  fontWeight={600}
                  noWrap
                  letterSpacing={isIOS ? -0.7 : ''}
                >
                  I accept the terms in the agreement
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow: '0px 0px 8px rgba(176, 192, 237, 0.5)',
          paddingY: theme.convert.pxToRem(20),
          paddingRight: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(48) },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(36) },
            flexDirection: 'row-reverse',
          }}
        >
          <StyledModalSubmitButton variant="contained" disabled={!checked} onClick={onNextClick}>
            Next
          </StyledModalSubmitButton>
          <StyledModalSubmitButton variant="outlined" onClick={() => navigate(homePageURL)}>
            Cancel
          </StyledModalSubmitButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DisclaimerWrapper;
