import { Tabs as MuiTabs, FormControl, MenuItem, Typography, useTheme } from '@mui/material';
import { colors } from '../../constants';
import { TabPanel } from './TabPanel';
import { SelectChangeEvent } from '@mui/material/Select';

import { StyledTabsWrapper } from './StyledTabsWrapper';
import { StyledTab } from './StyledTab';
import { StyledSelect } from './StyledSelect';
import { CenterContentBox } from '../../global-styles';

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

interface ITabsUIProps {
  tabId: number;
  handleChange(event: React.SyntheticEvent, newValue: number): void;
  handleSelectChange(event: SelectChangeEvent<unknown>): void;
  tabLabels: string[];
  tabPanels: React.ReactNode[];
  showTabs: boolean;
}

const TabsUI = ({
  tabId,
  handleChange,
  handleSelectChange,
  tabLabels,
  tabPanels,
  showTabs,
}: ITabsUIProps) => {
  const theme = useTheme();
  return (
    <StyledTabsWrapper>
      {showTabs ? (
        <MuiTabs
          TabIndicatorProps={{
            style: {
              background: `${colors.highlightSelectionColor}`,
              top: theme.convert.pxToRem(0),
              height: theme.convert.pxToRem(3),
            },
          }}
          sx={{ '& .Mui-selected': { background: `${colors.defaultBackground}` } }}
          centered
          textColor="primary"
          value={tabId}
          onChange={handleChange}
          aria-label="tabs"
        >
          {tabLabels.map((label, index) => (
            <StyledTab
              tabIndex={0}
              label={<Typography variant="h5">{label}</Typography>}
              {...a11yProps(index)}
              key={index}
            />
          ))}
        </MuiTabs>
      ) : (
        <FormControl fullWidth>
          <StyledSelect
            labelId="tabs-select-label"
            value={String(tabId)}
            onChange={handleSelectChange}
            variant="filled"
            disableUnderline
          >
            {tabLabels.map((label, index) => (
              <MenuItem value={index} key={index}>
                <Typography variant="h5">{label}</Typography>
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      )}

      <CenterContentBox
        sx={{ paddingTop: { xs: theme.convert.pxToRem(30), md: theme.convert.pxToRem(66) } }}
      >
        {tabPanels.map((panel, index) => (
          <TabPanel value={tabId} index={index} key={index}>
            {panel}
          </TabPanel>
        ))}
      </CenterContentBox>
    </StyledTabsWrapper>
  );
};

export default TabsUI;
