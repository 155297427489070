import React from 'react';
import { Box } from '@mui/material';

import AmendmentDebtorsContent from './AmendmentDebtorsContent';
import AmendmentSecuredContent from './AmendmentSecuredContent';
import useUndoActionModal from '../../shared/ucc/modals/undo-action-modal/useUndoActionModal';
import useRemoveDebSecModal from '../../shared/ucc/modals/remove-deb-sec-modal/useRemoveDebSecModal';

import { AmendmentProps } from './models';

const Amendment = ({ filingId, securedParties, debtors, getData, control }: AmendmentProps) => {
  const { handleOpenModal: undoModalOpen } = useUndoActionModal({
    getData,
  });
  const { handleOpenModal: removeDebSec } = useRemoveDebSecModal({
    getData,
  });

  return (
    <Box>
      <AmendmentDebtorsContent
        debtors={debtors}
        filingId={filingId}
        doUndo={undoModalOpen}
        removeDebSec={removeDebSec}
        getData={getData}
        control={control}
      />
      <AmendmentSecuredContent
        securedParties={securedParties}
        filingId={filingId}
        doUndo={undoModalOpen}
        removeDebSec={removeDebSec}
        getData={getData}
        control={control}
      />
    </Box>
  );
};

export default Amendment;
