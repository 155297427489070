import { Accordion } from './constants';
import { FilingType } from '../../common/models/ucc';
import { ICommonUCCResponsePayload, Status } from '../../common/models/features/models';

export interface IHandleActionFilingProps {
  id: number;
  type: string;
}

export interface IFilingsActions {
  handleEditFiling: (props: IHandleActionFilingProps) => void;
  handleModalOpen: (props: IHandleActionFilingProps) => void;
}

export interface ICartAccordionsUIProps extends IFilingsActions {
  handleOnNewFiling: () => void;
  accordionState: boolean[];

  handleAccordionState(prop: Accordion): void;

  toggleTooltip(id: number): void;

  activeToolTip: number | null;
  pendingFilingsData: IFilingsResponse[] | null;
  completedFilingsData: IFilingsResponse[] | null;
  isCheckoutButtonDisabled: boolean;

  onCartCheckout(): void;

  isCartCheckoutLoading: boolean;
}

export interface IPaymentSummary {
  description: string | null;
  quantity: number | null;
  price: number | null;
  amount: number | null;
}

export interface IFilingsResponse extends ICommonUCCResponsePayload {
  paymentSummary: IPaymentSummary[] | null;
  type: FilingType;
  status: string | null;
}

export interface IPendingFilings extends IFilingsActions {
  pendingFilingsData: IFilingsResponse[] | null;
  toggleTooltip: (id: number) => void;
  activeToolTip: number | null;
}

export interface ICompletedFilings extends IFilingsActions {
  completedFilingsData: IFilingsResponse[] | null;
}

export type StatusProps = Status.Completed | Status.Incompleted | Status.Pending;

export enum IPacketStatus {
  PENDING = 'Pending',
  SUBMITTED = 'Submitted',
  POSTED = 'Posted',
  QA_COMPLETED = 'QACompleted',
  VOID = 'Void',
  FLAGGED = 'Flagged',
  REVIEW = 'Review',
  POST_FAILED = 'PostFailed',
  PENDING_PAYMENT = 'PendingPayment',
  PAYMENT = 'Payment',
}

export interface IPacket {
  status: IPacketStatus;
  isBulkloaded: boolean;
  lockedUserId: number | null;
  isHold: boolean;
  deletedAt: string | null;
  deletedBy: number | null;
  isActive: boolean;
  createdAt: string;
  updatedAt: string | null;
  updatedBy: number | null;
  createdBy: number | null;
  id: number;
}

export enum IPayAccountStatus {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  TERMINATED = 'Terminated',
  ON_HOLD_SUSPENDED = 'OnHoldSuspended',
}

export interface IPayAccount {
  clientId: number;
  accountNumber: string | null;
  approveDate: string | null;
  submitDate: string | null;
  status: IPayAccountStatus;
  deletedAt: string | null;
  deletedBy: number | null;
  isActive: boolean;
  createdAt: string;
  updatedAt: string | null;
  updatedBy: number | null;
  createdBy: number | null;
  id: number;
}

export enum PaymentStatus {
  PENDING = 'Pending',
  SUCCESSFUL = 'Successful',
  FAILED = 'Failed',
  CANCELLED = 'Cancelled',
  ABORTED = 'Aborted',
}

export interface IPayment {
  avsResponse: string | null;
  ccpToken: string | null;
  createdAt: string;
  createdBy: number | null;
  deletedAt: string | null;
  deletedBy: string | null;
  failCode: string | null;
  id: number;
  isActive: boolean;
  packet: IPacket | null;
  packetId: number;
  payAccount: IPayAccount | null;
  payAccountId: number | null;
  remittanceId: string | null;
  securityId: string | null;
  status: PaymentStatus;
  token: string | null;
  transactionAmount: number;
  transactionDate: string;
  transactionId: string | null;
  updatedAt: string | null;
  updatedBy: number | null;
}

export interface ISuccessfulSubmissionContentProps {
  payment: IPayment | null;
}

export interface IPaymentId {
  paymentId: number;
}

export interface IPaymentRecordPayload extends IPaymentId {
  totalFee: number;
}
