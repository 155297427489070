import { Box, Grid, Typography, useTheme } from '@mui/material';
import PageWrapper from '../common/layouts/PageWrapper';
import FormsTable from '../common/components/forms/FormsTable';
import { PageTitle } from '../common/constants/features-constants';

const formsApprovedBySecretaryOfState: {
  title: string;
  forms?: string[];
}[] = [
  {
    title: 'Effective immediately; Forms approved by the Secretary of State, State of Florida',
    forms: [
      'STANDARD FORM - FORM UCC-1 (Rev. 05/2013)',
      'STANDARD FORM - FORM UCC-1 ADDENDUM (Rev. 05/2013)',
      'STANDARD FORM - FORM UCC-1 ADDITIONAL PARTY (Rev. 05/2013)',
      'STANDARD FORM - FORM UCC-1 ADDITIONAL INFORMATION (Rev. 05/2013)',
      'STANDARD FORM - FORM UCC-3 (Rev. 05/2013)',
      'STANDARD FORM - FORM UCC-3 ADDENDUM (Rev. 05/2013)',
      'STANDARD FORM - FORM UCC-3 ADDITIONAL PARTY (Rev. 05/2013)',
      'STANDARD FORM - FORM UCC-5 (Rev. 06/2013)',
    ],
  },
  {
    title:
      'Effective 7/1/2023, IACA revised several National UCC Forms. Florida UCC, LLC will accept forms dated 2011 or later, as approved forms until further notice.',
  },
];

export const Forms = () => {
  const theme = useTheme();
  return (
    <PageWrapper
      title={<Box marginBottom={0}>UCC {PageTitle.FORMS}</Box>}
      headTitle={PageTitle.FORMS}
    >
      <Typography
        fontSize={{
          xs: theme.typography.pxToRem(14),
          lg: theme.typography.pxToRem(16),
        }}
        lineHeight={{
          xs: theme.typography.pxToRem(20),
          lg: theme.typography.pxToRem(24),
        }}
        marginBottom={3}
      >
        The Florida UCC-1, UCC-1 Addendum, UCC-1 Additional Party, UCC-1 Additional Information,
        UCC-3, UCC-3 Addendum, UCC-3 Additional Party and UCC-5 Information Statement forms approved
        by the Florida Secretary of State are available for download from this site. To select a
        form to download, click on the download icon under the respective format for the form. These
        forms are only available online.
      </Typography>
      <FormsTable />
      <Grid container flexDirection={'column'} gap={2} marginTop={3}>
        <Grid item>
          <Typography fontWeight={600} variant="h5">
            FLORIDAUCC, LLC will accept only the following as Approved Forms:
          </Typography>
        </Grid>
        <Grid item>
          <ol style={{ paddingLeft: '1rem' }}>
            {formsApprovedBySecretaryOfState.map((formsApproved) => {
              return (
                <li key={formsApproved.title} style={{ marginBottom: '1rem' }}>
                  <Typography
                    fontSize={{
                      xs: theme.typography.pxToRem(14),
                      lg: theme.typography.pxToRem(16),
                    }}
                    lineHeight={{
                      xs: theme.typography.pxToRem(20),
                      lg: theme.typography.pxToRem(24),
                    }}
                  >
                    {formsApproved?.title}
                  </Typography>
                  <ul style={{ paddingLeft: '0.5rem', listStyle: 'initial' }}>
                    {formsApproved?.forms &&
                      formsApproved?.forms.map((formDescription) => {
                        return (
                          <li key={formDescription}>
                            <Typography
                              fontSize={{
                                xs: theme.typography.pxToRem(14),
                                lg: theme.typography.pxToRem(16),
                              }}
                              lineHeight={{
                                xs: theme.typography.pxToRem(20),
                                lg: theme.typography.pxToRem(24),
                              }}
                            >
                              {formDescription}
                            </Typography>
                          </li>
                        );
                      })}
                  </ul>
                </li>
              );
            })}
          </ol>
        </Grid>
        <Grid item>
          <Typography
            fontSize={{
              xs: theme.typography.pxToRem(14),
              lg: theme.typography.pxToRem(16),
            }}
            lineHeight={{
              xs: theme.typography.pxToRem(20),
              lg: theme.typography.pxToRem(24),
            }}
          >
            Note: UCC1 and UCC3 addendum and additional party forms are optional and are considered
            additional pages when submitted. There is a $3.00/page fee for additional pages.
          </Typography>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};
