import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, useTheme } from '@mui/material';

import { useSearchPageQueryParams } from '../useSearchPageQueryParams';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg';

interface NavigationProps {
  prevUrl: string;
  nextUrl: string;
  lastPage: number;
}

const Navigation: FC<NavigationProps> = ({ nextUrl, prevUrl, lastPage }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pageNumber } = useSearchPageQueryParams();
  return (
    <Grid
      container
      display={'flex'}
      justifyContent={'space-between'}
      marginTop={theme.convert.pxToRem(11)}
    >
      <Grid item>
        <Box>
          <Button
            onClick={() => navigate(prevUrl)}
            startIcon={
              <ArrowLeft
                fill={
                  !pageNumber || parseInt(pageNumber) <= 1
                    ? theme.palette.grey[500]
                    : theme.palette.info.main
                }
              />
            }
            disabled={!pageNumber || parseInt(pageNumber) <= 1}
          >
            Previous
          </Button>
        </Box>
      </Grid>
      <Grid item>
        <Box>
          <Button
            onClick={() => navigate(nextUrl)}
            endIcon={
              <ArrowRight
                fill={
                  !pageNumber || parseInt(pageNumber) >= lastPage
                    ? theme.palette.grey[500]
                    : theme.palette.info.main
                }
              />
            }
            disabled={!pageNumber || parseInt(pageNumber) >= lastPage}
          >
            Next
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Navigation;
