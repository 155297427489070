import { Home } from '../../pages/Home';
import { PrivacyPolicy } from '../../pages/PrivacyPolicy';
import { TermsOfUse } from '../../pages/TermsOfUse';
import {
  achAccountPageURL,
  announcementsPageURL,
  downloadPageURL,
  feesPageURL,
  formsPageURL,
  helpPageURL,
  homePageURL,
  privacyPolicyPageURL,
  termsOfUsePageURL,
  uccSearchDetailsDebtorPartiesURL,
  uccSearchDetailsFilingHistoryURL,
  uccSearchDetailsPageURL,
  uccSearchDetailsSecuredPartiesURL,
  uccSearchPageURL,
  xmlImplementationGuidePageURL,
} from '../../common/constants/routes';
import { Fees } from '../../pages/Fees';
import { Help } from '../../pages/Help';
import { Forms } from '../../pages/Forms';
import { Download } from '../../pages/Download';
import { ACHAccount } from '../../pages/ACHAccount';
import { Announcements } from '../../pages/Announcements';
import { XMLImplementationGuide } from '../../pages/XMLImplementationGuide';
import { UCCSearch } from '../../pages/UCCSearch';
import { UCCSearchDetails } from '../../pages/UCCSearchDetails';
import { UCCSearchDetailsFilingHistory } from '../../pages/UCCSearchDetailsFilingHistory';
import { UCCSearchDetailsSecuredParties } from '../../pages/UCCSearchDetailsSecuredParties';
import { UCCSearchDetailsDebtorParties } from '../../pages/UCCSearchDetailsDebtorParties';

export const publicSearchRouteConfigs: { path: string; element: JSX.Element }[] = [
  { path: homePageURL, element: <Home /> },
  { path: feesPageURL, element: <Fees /> },
  { path: helpPageURL, element: <Help /> },
  { path: formsPageURL, element: <Forms /> },
  { path: downloadPageURL, element: <Download /> },
  { path: uccSearchPageURL, element: <UCCSearch /> },
  { path: termsOfUsePageURL, element: <TermsOfUse /> },
  { path: achAccountPageURL, element: <ACHAccount /> },
  { path: privacyPolicyPageURL, element: <PrivacyPolicy /> },
  { path: announcementsPageURL, element: <Announcements /> },
  { path: xmlImplementationGuidePageURL, element: <XMLImplementationGuide /> },
  { path: uccSearchDetailsPageURL, element: <UCCSearchDetails /> },
  { path: uccSearchDetailsFilingHistoryURL, element: <UCCSearchDetailsFilingHistory /> },
  { path: uccSearchDetailsSecuredPartiesURL, element: <UCCSearchDetailsSecuredParties /> },
  { path: uccSearchDetailsDebtorPartiesURL, element: <UCCSearchDetailsDebtorParties /> },
];
