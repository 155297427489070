import React from 'react';
import './loader-styles.css';

interface LoaderProps {
  size?: number;
  color?: 'blue';
}

const Loader: React.FC<LoaderProps> = ({ size = 20, color = '#6B6B6B' }) => {
  const styleColor = color === 'blue' ? '#2E73DC' : '#6B6B6B';
  return (
    <svg
      className="fa-spin-pulse"
      width={size}
      height={size}
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"
        fill={styleColor}
      />
    </svg>
  );
};

export default Loader;
