import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import StartUcc3FormUI from './StartUcc3FormUi';
import { getUccFilingAPI, getUccFilingDeptorAPI } from '../start-ucc.services';
import { useSnackbar } from '../../../common/notification';

interface IStartUcc3FormProps {
  setFileData: React.Dispatch<any>;
  setDebtorsData: React.Dispatch<any>;
  setVerifyNumber: React.Dispatch<string | null>;
}

const schema = yup
  .object({
    code: yup
      .string()
      .required('File number is required.')
      .test(
        'len',
        (val = '') => `Financing Statement File# - ${val.value} doesn't exist`,
        (val = '') => val.length === 12
      ),
  })
  .required();

export type FormData = yup.InferType<typeof schema>;

const StartUcc3Form = ({ setFileData, setDebtorsData, setVerifyNumber }: IStartUcc3FormProps) => {
  const { handleSubmit, formState, control } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });
  const { Snack } = useSnackbar();

  const getUccFilingData = async (code: string) => {
    try {
      const data = await getUccFilingAPI(code);
      return data.payload;
    } catch (error: any) {
      const message =
        error.response.status === 404
          ? 'Initial Filing number doesn’t exist'
          : 'Something went wrong while verifying the file number';
      Snack.error(message);
      setDebtorsData(null);
      setFileData(null);
      return false;
    }
  };

  const onSubmit = async ({ code }: { code: string }) => {
    const data = await getUccFilingData(code);
    if (data) {
      try {
        const debtorsData = await getUccFilingDeptorAPI(code);
        setFileData(data);
        const { payload } = debtorsData;
        setDebtorsData(payload);
      } catch (error: any) {
        Snack.error(
          error?.response?.messages[0]?.message ||
            'Something went wrong while verifying the file number'
        );
        setDebtorsData(null);
        setFileData(null);
      }
    }
  };

  return (
    <StartUcc3FormUI
      onSubmit={handleSubmit(onSubmit)}
      formState={formState}
      control={control}
      setVerifyNumber={setVerifyNumber}
    />
  );
};

export default StartUcc3Form;
