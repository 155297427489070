import { Button, Grid, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import SearchGridWhiteHeading from './SearchGridWhiteHeading';
import { StyledNavigationTypography, StyledTableHeadingGrid } from './StyledComponents';
import { FileDetailsInterface } from '../types';
import { useFileImage } from './use-file-image';
import { formatDate, TimeZone } from '../../../common/helpers/format-date';

interface DocumentImagesProps {
  fileData: FileDetailsInterface;
}

const DocumentImages: FC<DocumentImagesProps> = ({ fileData }) => {
  const theme = useTheme();
  const { onDownloadClick } = useFileImage();

  return (
    <>
      <SearchGridWhiteHeading
        title="Document Images"
        description="Pages in all forms/attachments"
        result={fileData?.numberOfPagesInAllAssociatedForms}
      />

      <StyledTableHeadingGrid item container>
        <Grid xs={4} item>
          <Typography variant="body2" fontWeight={600}>
            Document Number
          </Typography>
        </Grid>
        <Grid xs={3} item>
          <Typography variant="body2" fontWeight={600}>
            Type
          </Typography>
        </Grid>
        <Grid xs={3} item>
          <Typography variant="body2" fontWeight={600}>
            Filing Date
          </Typography>
        </Grid>
        <Grid xs={2} item>
          <Typography variant="body2" fontWeight={600}>
            Pages
          </Typography>
        </Grid>
      </StyledTableHeadingGrid>
      <Grid
        item
        height={48}
        paddingX={theme.convert.pxToRem(40)}
        paddingY={theme.convert.pxToRem(12)}
        container
      >
        <Grid xs={4} item>
          {!fileData.fileImageExists ? (
            <Typography>{fileData?.ucc1Number} (No Image On File)</Typography>
          ) : (
            <Button onClick={() => onDownloadClick(fileData?.ucc1Number)}>
              <StyledNavigationTypography>{fileData?.ucc1Number}</StyledNavigationTypography>
            </Button>
          )}
        </Grid>
        <Grid xs={3} item>
          <Typography>{fileData?.documentType}</Typography>
        </Grid>
        <Grid xs={3} item>
          <Typography>{formatDate(fileData?.fileDate, TimeZone.EST)}</Typography>
        </Grid>
        <Grid xs={2} item>
          <Typography>{fileData?.documentPagesCount}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default DocumentImages;
