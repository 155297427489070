export enum TimeZone {
  UTC,
  EST,
}

export const formatDate = (date: string, zone?: TimeZone) => {
  if (!date) return '';

  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  };

  if (zone === TimeZone.UTC) {
    options.timeZone = 'utc';
  } else if (zone === TimeZone.EST) {
    options.timeZone = 'America/New_York';
  }

  return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
};

export const isoToFormattedDate = (isoString: string): string => {
  const date = new Date(isoString);
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};
