import { useEffect } from 'react';
import { FormControlLabel, FormGroup, Checkbox, useTheme, Typography, Box } from '@mui/material';
import { useWatch, Controller } from 'react-hook-form';

import { StyledModalSubmitButton } from '../../../common/global-styles';
import { useModal } from '../../../common/context';
import { useSnackbar } from '../../../common/notification';
import { CloseIconButton } from '../../../common/global-styles/CloseIconButton';

import { ICheckBoxFormWithFieldsUCC1UI } from '../../../common/models';
import { FormDataUCC1 } from '../schema';
import { Accordion } from '../constants';
import { hasValue } from '../../shared/ucc/utils';

const AddendumCheckbox = ({
  control,
  formState,
  setValue,
  handleCheckboxAccordionState,
  clearErrors,
  resetField,
  getValues,
}: ICheckBoxFormWithFieldsUCC1UI<FormDataUCC1>) => {
  const { handleModalOpen, handleModalClose, handleSetModalXButton } = useModal();
  const { Snack } = useSnackbar();
  const theme = useTheme();
  const hasAddendum = useWatch({
    control,
    name: 'hasAddendum',
  });

  const clearDescriptionErrors = () => {
    clearErrors('miscellaneous');
    clearErrors('additionalCollateralDescription');
    clearErrors('recordOwnerNameAddress');
    clearErrors('realEstateDescription');
  };

  const handleProceed = () => {
    resetFields();
    clearDescriptionErrors();
    handleModalClose();
    Snack.success('Addendum is successfully unchecked.');
  };

  const handleCancel = () => {
    setValue('hasAddendum', true);
    handleModalClose();
  };

  const xButtonAction = <CloseIconButton onClick={handleCancel} />;

  useEffect(() => {
    if (hasAddendum) {
      handleCheckboxAccordionState(Accordion.Addendum);
    }
  }, [hasAddendum]);

  const resetFields = () => {
    resetField('miscellaneous', {
      keepTouched: false,
      keepError: false,
      defaultValue: '',
    });
    resetField('hasAddendum', {
      keepDirty: false,
      keepTouched: false,
      keepError: false,
      defaultValue: false,
    });
    resetField('additionalCollateralDescription', {
      keepDirty: false,
      keepTouched: false,
      keepError: false,
      defaultValue: '',
    });
    resetField('recordOwnerNameAddress', {
      keepDirty: false,
      keepTouched: false,
      keepError: false,
      defaultValue: '',
    });
    resetField('realEstateDescription', {
      keepDirty: false,
      keepTouched: false,
      keepError: false,
      defaultValue: '',
    });
    resetField('financingStatementCoverageType', {
      keepDirty: false,
      keepTouched: false,
      keepError: false,
      defaultValue: null,
    });
    resetField('debtorType', {
      keepDirty: false,
      keepTouched: false,
      keepError: false,
      defaultValue: null,
    });
    resetField('debtorFilingType', {
      keepDirty: false,
      keepTouched: false,
      keepError: false,
      defaultValue: null,
    });
  };

  const fieldsToCheck = [
    'miscellaneous',
    'additionalCollateralDescription',
    'recordOwnerNameAddress',
    'realEstateDescription',
    'financingStatementCoverageType',
    'debtorType',
    'debtorFilingType',
  ];

  const checks = {
    financingStatementCoverageType: (value: string) => value !== null,
    debtorType: (value: string) => value !== null,
    debtorFilingType: (value: string) => value !== null,
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      if (hasValue(getValues, fieldsToCheck, checks)) {
        handleModalOpen({
          title: 'Unchecking Addendum',
          modalContent: (
            <Typography variant="h4">
              Are you sure you want to uncheck the Addendum? If you proceed all data you entered
              will be lost.
            </Typography>
          ),
          modalActions: (
            <Box
              sx={{
                display: 'flex',
                gap: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(34) },
                flexDirection: 'row-reverse',
              }}
            >
              <StyledModalSubmitButton variant="contained" onClick={handleProceed} autoFocus>
                Proceed
              </StyledModalSubmitButton>
              <StyledModalSubmitButton variant="outlined" onClick={handleCancel}>
                Cancel
              </StyledModalSubmitButton>
            </Box>
          ),
        });
        handleSetModalXButton(xButtonAction);
      } else {
        resetFields();
        clearDescriptionErrors();
      }
    }
  };

  return (
    <Controller
      control={control}
      name="hasAddendum"
      render={({ field }) => (
        <FormGroup {...field}>
          <FormControlLabel
            checked={hasAddendum}
            sx={{
              '.MuiFormControlLabel-label': {
                fontWeight: 600,
                fontSize: theme.typography.pxToRem(16),
                lineHeight: theme.typography.pxToRem(18),
                marginLeft: -1,
              },
            }}
            control={<Checkbox checked={hasAddendum} onChange={handleCheckboxChange} />}
            label="Check the box if there is an addendum."
          />
        </FormGroup>
      )}
    />
  );
};

export default AddendumCheckbox;
