import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { filing } from '../../common/constants/routes';
import { HeadingText, HomeHeading, StyledButton } from './home-page-custom-styled-component';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <HomeHeading paddingY={{ xs: 5 }}>
      <Grid
        container
        flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}
        paddingX={{ xs: '10%', lg: '5%', xl: theme.convert.pxToRem(90) }}
        gap={{ xs: 3, lg: 5, xl: 10 }}
      >
        <HeadingText
          item
          paddingX={{
            xs: theme.convert.pxToRem(30),
            md: theme.convert.pxToRem(40),
            lg: theme.convert.pxToRem(30),
          }}
          paddingY={{
            xs: theme.convert.pxToRem(20),
            sm: theme.convert.pxToRem(30),
          }}
          sm={12}
          md={11}
          lg={7}
          // width={{ xs: '100%', md: '90%', lg: '70%', xl: '80%' }}
          height={{
            xs: theme.convert.pxToRem(328),
            md: theme.convert.pxToRem(338),
            xl: theme.convert.pxToRem(309),
          }}
        >
          <Typography variant="h2" marginBottom={4}>
            Welcome to the Florida Secured Transaction Registry
          </Typography>
          <Typography
            variant="h4"
            fontSize={{
              xs: theme.typography.pxToRem(14),
              md: theme.typography.pxToRem(20),
            }}
            lineHeight={{
              xs: theme.typography.pxToRem(20),
              md: theme.typography.pxToRem(28),
            }}
          >
            {`The "Florida Secured Transaction Registry" is a centralized Uniform Commercial Code
            (UCC) filing and retrieval system containing initial financing statements, amendments,
            assignments, and other UCC filings as authorized by Florida Statutes Chapter 679.`}
          </Typography>
        </HeadingText>
        <Grid
          lg={4}
          item
          display={'flex'}
          flexDirection={{ xs: 'row', lg: 'column' }}
          justifyContent={{ xs: 'center', md: 'end', xl: 'end' }}
        >
          <StyledButton
            fullWidth={isMobile}
            variant={'contained'}
            onClick={useCallback(() => navigate(filing), [navigate])}
          >
            <Typography
              fontSize={theme.typography.pxToRem(20)}
              lineHeight={theme.typography.pxToRem(28)}
              whiteSpace="nowrap"
              letterSpacing={0.6}
              fontWeight={700}
              color="white"
            >
              START ONLINE FILING
            </Typography>
          </StyledButton>
        </Grid>
      </Grid>
    </HomeHeading>
  );
};

export default Hero;
