import { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Loader from '../../common/components/loader';
import { cart, confirmationBase } from '../../common/constants/routes';
import { submitZeroCase } from './checkout.services';
import { useSnackbar } from '../../common/notification';
import { parseErrorMessages } from '../../common/helpers';
import { StyledUCCButton } from '../start-ucc-filing/styled-items/StyledUccButton';
import { IPaymentId } from './models';
import Section from '../../common/layouts/Section';
import { useUserInfo } from '../../common/context';

const CheckoutZeroCase = ({ paymentId }: Partial<IPaymentId>) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { Snack } = useSnackbar();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const { getCartDetails } = useUserInfo();

  const onSubmit = async () => {
    if (!paymentId) {
      return Snack.error('Payment id number is missing.');
    }
    setIsSubmitDisabled(true);

    const res = await submitZeroCase({ paymentId });

    if (!res.notOk) {
      getCartDetails();
      return navigate(`${confirmationBase}/${paymentId}`);
    }

    setIsSubmitDisabled(false);
    Snack.error(parseErrorMessages(res.messages));
    navigate(cart);
  };

  return (
    <Box>
      <Section title="checkout">
        <Typography variant="body1">You don’t owe any amount during this transaction.</Typography>
        <Typography>
          After you submit the change, you will get a confirmation of the change.
        </Typography>
        <Box sx={{ height: theme.convert.pxToRem(20) }} />
        <Typography variant="h5">Total Balance Due: $0.00</Typography>
      </Section>
      <Box sx={{ height: theme.convert.pxToRem(40) }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'column', 'row-reverse', 'row-reverse'],
          justifyContent: 'flex-start',
          gap: [1.5, 1.5, 5, 5, 5],
        }}
      >
        <StyledUCCButton
          autoFocus
          onClick={onSubmit}
          variant="contained"
          startIcon={isSubmitDisabled ? <Loader /> : null}
          disabled={isSubmitDisabled}
        >
          {isSubmitDisabled ? 'Submitting' : 'Submit'}
        </StyledUCCButton>
        <StyledUCCButton onClick={() => navigate(cart)} variant="outlined">
          Cancel
        </StyledUCCButton>
      </Box>
      <Box sx={{ height: 789 }} />
    </Box>
  );
};

export default CheckoutZeroCase;
