import { Box, Button, Typography, useTheme } from '@mui/material';

import PageWrapper from '../common/layouts/PageWrapper';
import { PageTitle } from '../common/constants/features-constants';
import { downloadDocument } from '../common/helpers/downloadDocument';

export const XMLImplementationGuide = () => {
  const theme = useTheme();

  const introElements = [
    { title: 'Post Filing (/filings) API - Used to file UCC filings' },
    { title: 'Get Status (filings/{receiptId})  API - Used to get status of the filing' },
    {
      title:
        'Get Acknowledgements (/filingAcknowledgements/{packetNumber}) API - Used to get acknowledgement info including UCC Number',
    },
    { title: 'Get Filing (/filingImages/{fileNumber}) API - Used to get the filing image' },
  ];

  const nextStepElements = [
    { title: 'Contact FloridaUCC' },
    {
      title: 'Setup an account in the test system',
      children: [
        { content: 'Validate the implementation process' },
        { content: 'Verify that the filing process works as expected' },
      ],
    },
    { title: 'Move to Production' },
  ];
  return (
    <PageWrapper
      title={PageTitle.XML_IMPLEMENTATION_GUIDE}
      headTitle={PageTitle.XML_IMPLEMENTATION_GUIDE}
    >
      <Typography variant="h5">Intro</Typography>
      <Typography variant="body1">
        Florida UCC, LLC has developed and implemented an XML (Extensible Markup Language) Bulk
        Filing Service designed for use by filers of UCC Financing Statements. The service allows
        filers to send XML filing documents via HTTP Post for processing. The document will provide
        all APIs that are being used for XML filing which are:
      </Typography>
      <ul style={{ margin: 0 }}>
        {introElements.map((element) => (
          <li key={element.title}>
            <Typography variant="body1">{element.title}</Typography>
          </li>
        ))}
      </ul>
      <Typography variant="h5" marginTop={theme.convert.pxToRem(20)}>
        Next Steps
      </Typography>
      <ul style={{ margin: 0 }}>
        {nextStepElements.map((element) => (
          <li key={element.title}>
            <Typography variant="body1">{element.title}</Typography>
            {element.children && (
              <ul style={{ margin: 0, listStyleType: 'circle' }}>
                {element.children.map((child) => (
                  <li key={child.content}>
                    <Typography variant="body1">{child.content}</Typography>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      <Typography marginTop={2}>
        For more information, download the implementation guide.
      </Typography>
      <Box
        sx={{
          minWidth: theme.convert.pxToRem(310),
          display: 'flex',
          justifyContent: 'center',
          marginTop: '48px',
        }}
      >
        <Button
          style={{ padding: `${theme.convert.pxToRem(12)} ${theme.convert.pxToRem(38)}` }}
          variant="contained"
          onClick={() => downloadDocument('XMLImplementationGuide.pdf')}
        >
          <Typography
            fontSize={theme.typography.pxToRem(20)}
            lineHeight={theme.typography.pxToRem(28)}
            fontWeight={600}
            letterSpacing={0.6}
            color="white"
          >
            DOWNLOAD THE GUIDE
          </Typography>
        </Button>
      </Box>
    </PageWrapper>
  );
};
