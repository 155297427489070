import { useHomePageAnnouncements } from './use-home-page-announcements';
import Loader from '../../common/components/loader';
import { Box, ButtonBase, Typography, useTheme } from '@mui/material';
import { ReactComponent as ArrowLeft } from '../../assets/icons/homepage/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/homepage/arrow-right.svg';
import { ArrowIconBox, AttentionContent } from './home-page-custom-styled-component';

const noAnnouncementsContent =
  'We’re excited to launch our new website. Please browse this website to find information on forms and filing procedures, fees, data downloads, and walk-in services offered by <b>FloridaUCC LLC</b>.';

export const HomeAnnouncementsCarousel = () => {
  const theme = useTheme();
  const { loading, announcement, hasAnnouncements, goPrevious, goNext, hasOneAnnouncementOnly } =
    useHomePageAnnouncements();

  if (loading)
    return (
      <Box display="flex" justifyContent="center">
        <Loader color="blue" size={40} />
      </Box>
    );

  if (!hasAnnouncements) {
    return (
      <Typography
        variant={'body1'}
        textAlign={'justify'}
        dangerouslySetInnerHTML={{ __html: noAnnouncementsContent }}
      />
    );
  }

  return (
    <Box
      position={'relative'}
      display={'flex'}
      minHeight={theme.convert.pxToRem(175)}
      alignItems={'start'}
      justifyContent={'space-between'}
      width={1}
    >
      <ArrowIconBox
        left={{ xs: theme.convert.pxToRem(-30), md: theme.convert.pxToRem(-45) }}
        onClick={() => !hasOneAnnouncementOnly && goPrevious()}
        sx={{
          cursor: hasOneAnnouncementOnly ? 'default' : 'pointer',
          userSelect: 'none',
        }}
      >
        <ButtonBase focusRipple>
          <ArrowLeft fill={hasOneAnnouncementOnly ? '#909090' : '#202020'} />
        </ButtonBase>
      </ArrowIconBox>
      <Box display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }}>
          {announcement?.title ? (
            <AttentionContent
              variant={'body1'}
              dangerouslySetInnerHTML={{ __html: announcement.title }}
            />
          ) : (
            <Box height={16} marginBottom={theme.convert.pxToRem(8)} />
          )}
        </Box>
        {announcement?.content && (
          <AttentionContent
            display="inline"
            variant={'body1'}
            dangerouslySetInnerHTML={{ __html: announcement.content }}
          />
        )}
      </Box>
      <ArrowIconBox
        right={{ xs: theme.convert.pxToRem(-20), md: theme.convert.pxToRem(-30) }}
        onClick={() => !hasOneAnnouncementOnly && goNext()}
        sx={{
          cursor: hasOneAnnouncementOnly ? 'default' : 'pointer',
          userSelect: 'none',
        }}
      >
        <ButtonBase focusRipple>
          <ArrowRight fill={hasOneAnnouncementOnly ? '#909090' : '#202020'} />
        </ButtonBase>
      </ArrowIconBox>
    </Box>
  );
};
export { AttentionContent };
