import * as yup from 'yup';
import { utils } from '../../common/constants';

export const saveSchema = yup.object({
  ackContactName: yup.string().max(75, 'Max length is 75 characters.').optional(),
  ackContactPhone: yup
    .string()
    .test(
      'phoneFormat',
      'Phone number must not contain any characters or special symbols.',
      function (value) {
        const { createError, path } = this;
        if (value && value !== '') {
          if (!utils.phoneNumberRegEx.test(value)) {
            return createError({
              path,
              message: 'Phone number must not contain any characters or special symbols.',
            });
          }
          if (value.length < 10) {
            return createError({
              path,
              message: 'Phone number must be at least 10 digits long.',
            });
          }
          if (value.length > 25) {
            return createError({
              path,
              message: 'Max length is 25 characters.',
            });
          }
        }
        return true;
      }
    ),
  ackContactEmail: yup
    .string()
    .max(100, 'Max length is 100 characters.')
    .matches(utils.emailOrEmptyStringRegEx, 'Please enter a valid email address.')
    .optional(),
  isFinancingStatement: yup.boolean(),
  eventAssignment: yup.boolean(),
  eventTermination: yup.boolean(),
  eventContinuation: yup.boolean(),
  eventAmendReleaseCollateral: yup.boolean(),
  collateralChangeType: yup.string(),
  collateralChangeDescription: yup.string().max(15000, 'Max length is 15000 characters.'),
  filerRefData: yup.string().max(50, 'Max length is 50 characters.'),
  authEntitySequenceNumber: yup.string().nullable(),
  authEntityType: yup.string(),
  debSecType: yup.string(),
  authDebType: yup.string(),
  authSecType: yup.string(),
  authDebtors: yup.string(),
  authSecured: yup.string(),
  eventAmendment: yup.boolean(),
});

export const submitSchema = yup.object({
  ackContactName: yup
    .string()
    .required('Contact name is required.')
    .max(75, 'Max length is 75 characters.'),
  ackContactPhone: yup
    .string()
    .required('Phone number is required.')
    .min(10, 'Phone number must be at least 10 digits long.')
    .max(25, 'Max length is 25 characters.')
    .matches(
      utils.phoneNumberRegEx,
      'Phone number must not contain any characters or special symbols.'
    ),
  ackContactEmail: yup
    .string()
    .required('Contact email is required.')
    .max(100, 'Max length is 100 characters.')
    .matches(utils.emailRegEx, 'Please enter a valid email address.'),
  isFinancingStatement: yup.boolean(),
  eventAssignment: yup.boolean(),
  eventTermination: yup.boolean(),
  eventContinuation: yup.boolean(),
  eventAmendReleaseCollateral: yup.boolean(),
  collateralChangeType: yup.string().when('eventAmendReleaseCollateral', {
    is: true,
    then: (submitSchema) =>
      submitSchema.required('Amendment/Release Collateral type is required.').notOneOf(['None']),
    otherwise: (submitSchema) => submitSchema.optional(),
  }),
  collateralChangeDescription: yup
    .string()
    .max(15000, 'Max length is 15000 characters.')
    .when('eventAmendReleaseCollateral', {
      is: true,
      then: (submitSchema) => submitSchema.required('Collateral description is required.'),
      otherwise: (submitSchema) => submitSchema.optional(),
    }),
  filerRefData: yup.string().max(50, 'Max length is 50 characters.'),
  authEntitySequenceNumber: yup.string().nullable(),
  authEntityType: yup.string(),
  debSecType: yup.string(),
  authDebType: yup.string(),
  authSecType: yup.string(),
  authDebtors: yup.string().when('authDebType', {
    is: 'Debtor',
    then: (submitSchema) => submitSchema.required().notOneOf(['0']),
    otherwise: (submitSchema) => submitSchema.optional(),
  }),
  authSecured: yup.string().when('authSecType', {
    is: 'Secured',
    then: (submitSchema) => submitSchema.required().notOneOf(['0']),
    otherwise: (submitSchema) => submitSchema.optional(),
  }),
  eventAmendment: yup.boolean(),
});

export type FormDataUCC3 = yup.InferType<typeof saveSchema>;
