import { FC } from 'react';
import { StyledTableHeadingGrid } from './StyledComponents';
import { Grid, Typography, useTheme } from '@mui/material';
import { formatDate, TimeZone } from '../../../common/helpers/format-date';
import { FileDataInterface } from '../types';

const FilingInfo: FC<{ fileData: FileDataInterface }> = ({ fileData }) => {
  const theme = useTheme();
  return (
    <>
      <StyledTableHeadingGrid item container>
        <Grid xs={2} item>
          <Typography fontWeight={600}>Status</Typography>
        </Grid>
        <Grid xs={2} item>
          <Typography fontWeight={600}>Date Filed</Typography>
        </Grid>
        <Grid xs={2} item>
          <Typography fontWeight={600}>Expires</Typography>
        </Grid>
        <Grid xs={3} item>
          <Typography fontWeight={600}>Filings Completed Through</Typography>
        </Grid>
        <Grid xs={3} item>
          <Typography fontWeight={600}>Summary For Filing</Typography>
        </Grid>
      </StyledTableHeadingGrid>
      <Grid
        item
        height={48}
        paddingX={theme.convert.pxToRem(40)}
        paddingY={theme.convert.pxToRem(12)}
        container
      >
        <Grid xs={2} item>
          <Typography>{fileData.status}</Typography>
        </Grid>
        <Grid xs={2} item>
          <Typography>{formatDate(fileData?.fileDate, TimeZone.EST)}</Typography>
        </Grid>
        <Grid xs={2} item>
          <Typography>
            {fileData?.isTransUtil
              ? 'TRANS UTIL'
              : formatDate(fileData?.expirationDate, TimeZone.EST)}
          </Typography>
        </Grid>
        <Grid xs={3} item>
          <Typography>{formatDate(fileData?.filingsCompletedThrough, TimeZone.EST)}</Typography>
        </Grid>
        <Grid xs={3} item>
          <Typography>{fileData?.ucc1Number}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default FilingInfo;
