import { IFilingsResponse } from './models';

export const calculateTotalFilings = (data: IFilingsResponse[]) => {
  return data.reduce((acc, item) => {
    if (item.paymentSummary) {
      item.paymentSummary.forEach(function (val) {
        const amount = val.amount ? val.amount : 0;
        acc += amount;
      });
    }
    return acc;
  }, 0);
};
