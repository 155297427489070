import React, { useReducer } from 'react';

export interface ContextState {
  isSaved: boolean;
  isCompleted: boolean;
  isCanceled: boolean;
  hasPendingUploads: boolean;
}

export interface UnsavedHandlerAction {
  type: UnsavedHandlerSelectors;
  payload: any;
}

enum UnsavedHandlerSelectors {
  SET_IS_SAVED = '(Unsaved Handler) SET_IS_SAVED',
  SET_IS_COMPLETED = '(Unsaved Handler) SET_IS_COMPLETED',
  SET_IS_CANCELED = '(Unsaved Handler) SET_IS_CANCELED',
  SET_HAS_PENDING_UPLOADS = '(Unsaved Handler) SET_HAS_PENDING_UPLOADS',
}

const reducerHandlerSelector: Record<
  UnsavedHandlerSelectors,
  (payload: any, state: ContextState) => ContextState
> = {
  [UnsavedHandlerSelectors.SET_IS_SAVED]: (payload, state) => {
    return {
      ...state,
      isSaved: payload,
    };
  },
  [UnsavedHandlerSelectors.SET_IS_COMPLETED]: (payload, state) => {
    return {
      ...state,
      isCompleted: payload,
    };
  },
  [UnsavedHandlerSelectors.SET_IS_CANCELED]: (payload, state) => {
    return {
      ...state,
      isCanceled: payload,
    };
  },
  [UnsavedHandlerSelectors.SET_HAS_PENDING_UPLOADS]: (payload, state) => {
    return {
      ...state,
      hasPendingUploads: payload,
    };
  },
};

const unsavedHandlerReducer = (state: ContextState, action: UnsavedHandlerAction): ContextState => {
  return reducerHandlerSelector[action.type](action.payload, state);
};

const initialState: ContextState = {
  isSaved: false,
  isCompleted: false,
  isCanceled: false,
  hasPendingUploads: false,
};

export const UnsavedHandlerContext = React.createContext<any>(initialState);

type Props = {
  children: React.ReactNode;
};

const init = (initialState: ContextState) => {
  return {
    ...initialState,
  };
};

export const UnsavedHandlerContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(unsavedHandlerReducer, initialState, init);

  const setIsSaved = (isSaved: boolean) => {
    dispatch({
      type: UnsavedHandlerSelectors.SET_IS_SAVED,
      payload: isSaved,
    });
  };

  const setIsCompleted = (isCompleted: boolean) => {
    dispatch({
      type: UnsavedHandlerSelectors.SET_IS_COMPLETED,
      payload: isCompleted,
    });
  };

  const setIsCanceled = (isCanceled: boolean) => {
    dispatch({
      type: UnsavedHandlerSelectors.SET_IS_CANCELED,
      payload: isCanceled,
    });
  };

  const setHasPendingUploads = (hasPendingUploads: boolean) => {
    dispatch({
      type: UnsavedHandlerSelectors.SET_HAS_PENDING_UPLOADS,
      payload: hasPendingUploads,
    });
  };

  return (
    <UnsavedHandlerContext.Provider
      value={{
        isSaved: state.isSaved,
        isCompleted: state.isCompleted,
        isCanceled: state.isCanceled,
        hasPendingUploads: state.hasPendingUploads,
        setIsSaved,
        setIsCompleted,
        setIsCanceled,
        setHasPendingUploads,
      }}
    >
      {children}
    </UnsavedHandlerContext.Provider>
  );
};
