import { Box, Typography } from '@mui/material';

import { boxShadowColor } from '../constants/colors';

import { ILayoutWrapperProps } from '../models';
interface ISection extends ILayoutWrapperProps {
  title: React.ReactNode;
  outerContent?: React.ReactNode;
  classes?: string;
}
const Section = ({ title, children, outerContent, classes = '' }: ISection) => (
  <Box
    component="section"
    sx={{
      display: 'flex',
      flexDirection: 'column',
      boxShadow: `0px 0px 8px rgba(${boxShadowColor}, 0.5)`,
    }}
    className={classes}
  >
    <Typography
      sx={{
        padding: (theme) => `${theme.convert.pxToRem(19)} ${theme.convert.pxToRem(40)}`,
        textTransform: 'capitalize',
        backgroundColor: (theme) => theme.palette.primary.light,
      }}
      variant="h4"
      fontWeight={600}
    >
      {title}
    </Typography>
    {outerContent && <>{outerContent}</>}
    <Box sx={{ padding: (theme) => `${theme.convert.pxToRem(28)} ${theme.convert.pxToRem(40)}` }}>
      {children}
    </Box>
  </Box>
);
export default Section;
