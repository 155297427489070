import { styled } from '@mui/material';
import { StyledSubmitButton } from '../../../common/components/user-auth-components/common-auth-styles';

export const StyledVerifyButton = styled(StyledSubmitButton)((props) => ({
  borderBottomLeftRadius: 'unset',
  borderTopLeftRadius: 'unset',
  width: '25%',
  position: 'absolute',
  right: `${props.disabled ? '1px' : 0}`,
  top: '1px ',
  height: `${props.disabled ? '43px' : '44px'}`,
  [props.theme.breakpoints.down('md')]: {
    height: `30px`,
  },
  [props.theme.breakpoints.down('sm')]: {
    height: `30px`,
  },
  borderTopRightRadius: '3px',
  borderBottomRightRadius: '3px',
  fontSize: props.theme.typography.pxToRem(16),
}));
