import { AppBar, styled } from '@mui/material';
import { colors } from '../../constants';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: 10,
  position: 'fixed',
  backgroundColor: colors.defaultBackground,
  boxShadow: `0px 4px 4px rgba(${colors.dividerBackground}, 0.25)`,
  height: theme.convert.pxToRem(80),
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    minHeight: theme.convert.pxToRem(48),
  },
}));
