import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledUccFilingSubtitleWrapper = styled(Grid)(({ theme }) => ({
  padding: `${theme.convert.pxToRem(0)} ${theme.convert.pxToRem(28)}`,
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  minHeight: 62,
  color: theme.palette.text.primary,
  background: theme.palette.primary.light,
}));
