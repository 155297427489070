import * as yup from 'yup';
import { utils } from '../../common/constants';

export const saveSchema = yup.object({
  ackContactName: yup.string().max(75, 'Max length is 75 characters.'),
  ackContactPhone: yup
    .string()
    .test(
      'empty-or-10-characters-check',
      'Phone number must be at least 10 digits long.',
      (phoneNumber) => !phoneNumber || phoneNumber.length >= 10
    )
    .max(25, 'Max length is 25 characters.')
    .matches(
      utils.phoneNumberOrEmptyStringRegEx,
      'Phone number must not contain any characters or special symbols.'
    ),
  ackContactEmail: yup
    .string()
    .max(100, 'Max length is 100 characters.')
    .matches(utils.emailOrEmptyStringRegEx, 'Please enter a valid email address.')
    .optional(),
  // Collateral
  financingStatement: yup
    .string()
    .max(
      15000,
      'Max length is 15000 characters. You can enter additional collateral description in the Addendum Page.'
    ),
  altDesignationType: yup.string().nullable(),
  docStampTaxType: yup.string().nullable(),
  filerRefData: yup.string().max(50, 'Max length is 50 characters.'),
  // Addendum
  hasAddendum: yup.boolean(),
  miscellaneous: yup.string().max(250, 'Max length is 250  characters.'),
  financingStatementCoverageType: yup.string().nullable(),
  realEstateDescription: yup.string().max(300, 'Max length is 300 characters.'),
  recordOwnerNameAddress: yup.string().max(450, 'Max length is 450  characters.'),
  additionalCollateralDescription: yup.string().max(500, 'Max length is 500 characters.'),
  debtorType: yup.string().nullable(),
  debtorFilingType: yup.string().nullable(),
});

export const submitSchema = yup.object({
  ackContactName: yup
    .string()
    .required('Contact name is required.')
    .max(75, 'Max length is 75 characters.'),
  ackContactPhone: yup
    .string()
    .required('Phone number is required.')
    .min(10, 'Phone number must be at least 10 digits long.')
    .max(25, 'Max length is 25 characters.')
    .matches(
      utils.phoneNumberRegEx,
      'Phone number must not contain any characters or special symbols.'
    ),
  ackContactEmail: yup
    .string()
    .required('Contact email is required.')
    .max(100, 'Max length is 100 characters.')
    .matches(utils.emailRegEx, 'Please enter a valid email address.'),
  // Collateral
  financingStatement: yup
    .string()
    .required('Collateral description is required.')
    .max(
      15000,
      'Max length is 15000 characters. You can enter additional collateral description in the Addendum Page.'
    ),
  altDesignationType: yup.string().nullable(),
  docStampTaxType: yup.string().required('Florida documentary stamp is required.'),
  filerRefData: yup.string().max(50, 'Max length is 50 characters.'),
  // Addendum
  hasAddendum: yup.boolean(),
  miscellaneous: yup.string().max(250, 'Max length is 250 characters.').nullable(),
  financingStatementCoverageType: yup.string().nullable(),
  realEstateDescription: yup.string().max(300, 'Max length is 300 characters.').nullable(),
  recordOwnerNameAddress: yup.string().max(450, 'Max length is 450 characters.').nullable(),
  additionalCollateralDescription: yup
    .string()
    .max(500, 'Max length is 500  characters.')
    .nullable(),
  debtorType: yup.string().nullable(),
  debtorFilingType: yup.string().nullable(),
});

export type FormDataUCC1 = yup.InferType<typeof saveSchema>;
