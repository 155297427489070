import { Box, Grid, Button, Typography } from '@mui/material';

import type { IDebtorsOrSecuredParties } from '../models';
import { StyledAddButton } from '../../shared/ucc/styles';
import { getDebtorOrSecuredName } from '../utils';
import { itemDeviderBorder } from '../../../common/constants/colors';
import theme from '../../../common/theme';

const DebtorsOrSecuredParties = ({
  parties = [],
  type,
  partyName,
  filingId,
  removeDebSec,
  openDebSecModal,
}: IDebtorsOrSecuredParties) => (
  <Box>
    <Box sx={{ maxHeight: theme.convert.pxToRem(720), overflowY: 'auto' }}>
      {parties.map((party) => (
        <Grid
          container
          key={party.id}
          sx={{
            padding: {
              xs: `${theme.convert.pxToRem(20)} ${theme.convert.pxToRem(20)}`,
              md: `${theme.convert.pxToRem(20)} ${theme.convert.pxToRem(40)}`,
            },
            gap: { xs: 1.5, md: 3 },
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
          }}
        >
          <Grid item xs={12} md={3}>
            <Typography variant="h5">{getDebtorOrSecuredName(party)}</Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={4}>
            <Typography variant="h6">{party.address.address1}</Typography>
            <Typography variant="h6">{party.address.address2}</Typography>
            <Typography variant="h6">
              {party.address.city}, {party.address.state} {party.address.zip}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            lg={2}
            sx={{
              display: 'flex',
              justifyContent: { md: 'flex-end' },
              alignItems: { md: 'flex-start' },
            }}
          >
            <Button
              sx={{
                minWidth: { xs: 0, md: 64 },
                paddingLeft: 0,
                p: { md: 0 },
              }}
              onClick={() =>
                openDebSecModal({
                  partyName,
                  isEdit: true,
                  debsecId: party.id,
                  type,
                  filingId,
                })
              }
              variant="text"
            >
              Edit
            </Button>
            <Box width={{ xs: 20, md: 0 }} />
            <Button
              sx={{ minWidth: { xs: 0, md: 64 }, p: { md: 0 } }}
              onClick={() =>
                removeDebSec({
                  partyName,
                  debsecId: party.id,
                  type,
                  filingId,
                })
              }
              variant="text"
            >
              Remove
            </Button>
          </Grid>
        </Grid>
      ))}
    </Box>
    <Box sx={{ borderTop: (theme) => `1px solid ${itemDeviderBorder}`, overflow: 'none' }}>
      <StyledAddButton
        variant="text"
        onClick={() =>
          openDebSecModal({
            partyName,
            isEdit: false,
            type,
            filingId,
          })
        }
      >
        <Box sx={{ fontSize: (theme) => theme.typography.pxToRem(20) }}>+</Box>
        <Box sx={{ width: 12 }} />
        <Box sx={{ '&:hover': { textDecoration: 'underline' } }}>Add {partyName}</Box>
      </StyledAddButton>
    </Box>
  </Box>
);

export default DebtorsOrSecuredParties;
