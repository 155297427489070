import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import { CognitoUser } from '@aws-amplify/auth';

import CreatePasswordForm from '../features/create-password/create-password-form';
import { useModal } from '../common/context';
import { signIn } from '../infrastructure/api/auth-api';
import { ParamsType } from '../features/create-password/create-password-form/types';
import type { ISignIn } from '../common/models';
import { StyledModalSubmitButton } from '../common/global-styles';
import Loader from '../common/components/loader';

const CreatePassword = () => {
  const navigate = useNavigate();
  const { handleModalOpen, handleModalClose } = useModal();

  const [searchParams] = useSearchParams();
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [parameters, setParameters] = useState<ParamsType>({
    type: '',
    email: '',
    code: '',
  });

  useEffect(() => {
    const type = searchParams.get('type');
    const email = searchParams.get('email');
    const code = searchParams.get('code');

    if (!type || !email || !code) {
      navigate('/onlinefiling/login');
      handleModalOpen({
        title: 'Ooops!',
        modalContent: <Typography>Unexpected problem occurred. Please try again later.</Typography>,
      });
    }

    if (type && email && code) {
      setParameters({ type, email, code });
      if (type === 'confirm-account') {
        handleSignIn({ email, password: code });
      }
    }
  }, []);

  const handleSignIn = async ({ email, password }: ISignIn) => {
    try {
      const user = await signIn({ email, password });
      setUser(user);
    } catch (error) {
      handleModalOpen({
        title: 'Your link has expired',
        modalContent: (
          <Typography>
            It seems that the registration link is expired and is no longer valid. Please register
            again.
          </Typography>
        ),
        modalActions: (
          <StyledModalSubmitButton variant="outlined" onClick={handleModalClose}>
            Close
          </StyledModalSubmitButton>
        ),
      });
      navigate('/onlinefiling/login');
    }
  };

  return (
    <>
      {parameters.type === 'confirm-account' ? (
        user ? (
          <CreatePasswordForm user={user} parameters={parameters} />
        ) : (
          <Box display="flex" justifyContent="center">
            <Loader size={40} color="blue" />
          </Box>
        )
      ) : (
        <CreatePasswordForm user={null} parameters={parameters} />
      )}
    </>
  );
};

export default CreatePassword;
