import { CustomAccordion } from '../../../common/components/accordion';
import { Grid, Box } from '@mui/material';
import { DebitInfo } from './debit-info';
import { useMyAccount } from '../../../common/context';
import { PasswordAndSecurity } from './password-and-security';
import { PersonalInfoAccordion } from './personal-info';
import { AcknowledgementInfoAccordion } from './acknowledgement-info';

export const MyAccountAccordionsUI = () => {
  const {
    myAccountData,
    openAccordion,
    handleChangeAccordionPasswordSecurity,
    handleChangeAccordionDebit,
  } = useMyAccount();

  return (
    <Grid container gap={2.5}>
      <PersonalInfoAccordion />
      <AcknowledgementInfoAccordion />
      {myAccountData.haveAccountNumber ? (
        <CustomAccordion
          title="Debit Account Information"
          handleChange={handleChangeAccordionDebit}
          isOpen={openAccordion.debitAccountInfo}
        >
          <DebitInfo />
        </CustomAccordion>
      ) : null}
      <CustomAccordion
        title={'Password & Security'}
        isOpen={openAccordion.passwordAndSecurity}
        handleChange={handleChangeAccordionPasswordSecurity}
      >
        <PasswordAndSecurity />
      </CustomAccordion>
      <Box sx={{ height: 274, width: '100%' }} />
    </Grid>
  );
};
