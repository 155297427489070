import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IconButton } from '@mui/material';
import { useModal } from '../../../../common/context';
import { useSnackbar } from '../../../../common/notification';
import { YesNoModal } from '../../../../common/yes-no-modal';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateEmailFormActions } from './UpdateEmailFormActions';
import { CloseIconButton } from '../../../../common/global-styles/CloseIconButton';
import UpdateEmailFormUI from './UpdateEmailFormUI';
import { emailRegex } from '../../../../common/constants/regex';

export interface IUpdateEmailFormProps {
  onSubmit(props: { newEmail: string; confirmEmail: string }): void;
}

const schema = yup
  .object({
    newEmail: yup
      .string()
      .required('New email is required.')
      .matches(emailRegex, 'Please enter a valid email address.')
      .max(100, 'Max length is 100 characters.'),
    confirmEmail: yup
      .string()
      .required('Confirm email is required.')
      .max(100, 'Max length is 100 characters.')
      .matches(emailRegex, 'Please enter a valid email address.')
      .oneOf([yup.ref('newEmail')], 'New email and confirm email must match.'),
  })
  .required();

export type FormData = yup.InferType<typeof schema>;

export const UpdateEmailForm = ({ onSubmit }: IUpdateEmailFormProps) => {
  const { handleSetModalActions, handleSetModalXButton } = useModal();
  const { handleSubmit, setFocus, formState, control, getValues } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { newEmail: '', confirmEmail: '' },
  });
  const { Snack } = useSnackbar();

  const [shouldOpenUnsavedChangesModal, setShouldOpenUnsavedChangesModal] = useState(false);
  const { handleModalClose } = useModal();

  const { isValid, isSubmitting, isDirty } = formState;

  const { newEmail, confirmEmail } = getValues();

  const yesAction = () => {
    setShouldOpenUnsavedChangesModal(false);
    handleModalClose();
    Snack.info('Action canceled.');
  };

  const cancelHandler = () => {
    if (isDirty) {
      setShouldOpenUnsavedChangesModal(true);
    } else {
      handleModalClose();
    }
  };

  const xButtonAction = <CloseIconButton onClick={cancelHandler} />;

  useEffect(() => {
    handleSetModalXButton && handleSetModalXButton(xButtonAction);
    handleSetModalActions &&
      handleSetModalActions(
        <UpdateEmailFormActions
          onSubmit={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
          isValid={isValid}
          secondaryButton
          onModalClose={cancelHandler}
        />
      );
  }, [isValid, isSubmitting, newEmail, confirmEmail]);

  useEffect(() => {
    setFocus('newEmail');
  }, []);

  return (
    <>
      <UpdateEmailFormUI
        onSubmit={handleSubmit(onSubmit)}
        formState={formState}
        control={control}
      />
      <YesNoModal
        open={shouldOpenUnsavedChangesModal}
        handleModalClose={() => setShouldOpenUnsavedChangesModal(false)}
        noAction={() => setShouldOpenUnsavedChangesModal(false)}
        yesAction={yesAction}
      />
    </>
  );
};
