import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from '../common/components/loader';
import { useSnackbar } from '../common/notification';
import UCC3Component from '../features/ucc3';
import {
  getUCC3ById,
  getEntityTypes,
  getUCC3DebSec,
  getAmendReleaseCollateralData,
  getUCC3Attachments,
  updateLastCheckDate,
} from '../features/ucc3/ucc3.services';
import { parseUcc3, divideDebSec } from '../features/ucc3/utils';
import {
  UCC3Type,
  DebtorOrSecuredType,
  CollateralChangeType,
  IAttachment,
} from '../features/ucc3/models';
import { EntityType, IFilingId, Status } from '../common/models/features/models';
import { parseErrorMessages } from '../common/helpers';
import { UnsavedHandlerContextProvider } from '../features/shared/ucc/unsaved-handler-context/UnsavedHandlerContext';
import { errorPage, notFound } from '../common/constants/routes';

const UCC3 = () => {
  const { filingId: fId } = useParams();
  const { Snack } = useSnackbar();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [dataUCC3, setDataUCC3] = useState<UCC3Type | null>(null);
  const [collateralChangeTypeData, setCollateralChangeTypeData] = useState<
    CollateralChangeType[] | null
  >(null);
  const [attachments, setAttachments] = useState<IAttachment[]>([]);
  const [entityTypes, setEntityTypes] = useState<EntityType[] | null>(null);
  const [debtors, setDebtors] = useState<DebtorOrSecuredType[]>([]);
  const [securedParties, setSecuredParties] = useState<DebtorOrSecuredType[]>([]);

  const [isAttachmentListLoading, setIsAttachmentListLoading] = useState(false);

  const filingId = Number(fId);

  const updateAttachmentsList = async ({ filingId }: IFilingId) => {
    setIsAttachmentListLoading(true);
    const attachmentsData = await getUCC3Attachments({ filingId });
    setIsAttachmentListLoading(false);
    if (attachmentsData.notOk) {
      Snack.error(parseErrorMessages(attachmentsData.messages));
    } else {
      setAttachments(attachmentsData.payload);
    }
  };

  const getDebtorsAndSecuredData = async (filingId: number) => {
    const debsecRes = await getUCC3DebSec({ filingId });

    if (debsecRes.notOk) {
      Snack.error('Error happend while fetching UCC3 info.');
      navigate(errorPage);
    }

    const { debtors, securedParties } = divideDebSec(debsecRes.payload);
    setDebtors(debtors);
    setSecuredParties(securedParties);
  };

  const getEntityTypesData = async () => {
    const entityTypes = await getEntityTypes();

    if (!entityTypes.notOk) {
      setEntityTypes(entityTypes.payload);
    }
  };

  const getCollateralData = async () => {
    const response = await getAmendReleaseCollateralData();
    if (!response.notOk) {
      setCollateralChangeTypeData(response.payload);
    } else {
      Snack.error('Error happend while fetching UCC3 info.');
      navigate(errorPage);
    }
  };

  const getData = async (filingId: number) => {
    try {
      const [ucc3Data] = await Promise.all([
        getUCC3ById({ filingId }),
        updateAttachmentsList({ filingId }),
      ]);
      const isUCC3Incomplete = ucc3Data.payload.status === Status.Incompleted;

      if (ucc3Data.notOk) {
        if (ucc3Data.messages?.length > 0) {
          Snack.error(parseErrorMessages(ucc3Data.messages));
        } else {
          Snack.error('Invalid UCC3 number');
        }
        return navigate(notFound);
      }

      let lastCheckDateResponse;

      if (isUCC3Incomplete) {
        lastCheckDateResponse = await updateLastCheckDate({ filingId });
      }

      if (!lastCheckDateResponse?.notOk || !isUCC3Incomplete) {
        await getDebtorsAndSecuredData(filingId);
      }

      await getCollateralData();
      await getEntityTypesData();

      const ucc = parseUcc3(ucc3Data.payload);

      setDataUCC3(ucc);
    } catch (err: any) {
      Snack.error('Error happened while fetching UCC3 info.');
      navigate(errorPage);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (filingId && isLoading) {
      getData(filingId);
    }
  }, [filingId, isLoading]);

  if (isLoading || !filingId) {
    return (
      <Box
        sx={{
          transform: 'translate(50%, 50%)',
        }}
      >
        <Loader size={40} color="blue" />
      </Box>
    );
  }
  return (
    <UnsavedHandlerContextProvider>
      <UCC3Component
        filingId={filingId}
        dataUCC3={dataUCC3}
        entityTypes={entityTypes}
        debtors={debtors}
        securedParties={securedParties}
        collateralChangeTypesData={collateralChangeTypeData}
        attachments={attachments}
        updateAttachmentsList={updateAttachmentsList}
        getData={getData}
        isAttachmentListLoading={isAttachmentListLoading}
      />
    </UnsavedHandlerContextProvider>
  );
};

export default UCC3;
