import React from 'react';
import { Box } from '@mui/material';

import DebtorsOrSecuredParties from '../debtors-or-secured-parties/DebtorsOrSecuredParties';
import useDebSecModal from '../../shared/ucc/modals/deb-sec-modal/useDebSecModal';

import { EntityType } from '../../../common/models/features/models';
import { AmendmentSecuredContentProps } from './models';
import DebtorsOrSecuredPartiesHeader from '../../ucc1/debtors-or-secured-parties/DebtorsOrSecuredPartiesHeader';
import { useWatch } from 'react-hook-form';

const AmendmentSecuredContent = ({
  securedParties,
  filingId,
  doUndo,
  removeDebSec,
  getData,
  control,
}: AmendmentSecuredContentProps) => {
  const eventAmendment = useWatch({
    control,
    name: 'eventAmendment',
  });
  const { handleOpenModal } = useDebSecModal({
    filingId: filingId,
    type: EntityType.Secured,
    partyName: 'Secured Party',
    getData,
  });
  const shouldShowHeader = securedParties && securedParties.length > 0;
  return (
    <Box>
      {shouldShowHeader && <DebtorsOrSecuredPartiesHeader title="Secured Party(s)" hasBorderTop />}
      <DebtorsOrSecuredParties
        parties={securedParties}
        type={EntityType.Secured}
        isDisabled={!eventAmendment}
        doUndo={doUndo}
        openDebSecModal={handleOpenModal}
        removeDebSec={removeDebSec}
        partyName={'Secured Party'}
        filingId={filingId}
      />
    </Box>
  );
};

export default AmendmentSecuredContent;
