import { useCallback, useEffect, useState } from 'react';
import { Announcement } from '../../common/models/features/models';
import { getHomepageAnnouncements } from '../../common/services/announcements.services';

export const useHomePageAnnouncements = () => {
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState(0);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getHomepageAnnouncements();

        if (!res.notOk) setAnnouncements(res.payload);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const goNext = useCallback(
    () => setCurrent((prev) => (prev + 1) % announcements.length),
    [announcements]
  );
  const goPrevious = useCallback(
    () => setCurrent((prev) => (prev ? prev - 1 : announcements.length - 1)),
    [announcements]
  );

  return {
    goNext,
    loading,
    goPrevious,
    announcement: announcements[current],
    hasAnnouncements: Boolean(announcements.length),
    hasOneAnnouncementOnly: Boolean(announcements.length === 1),
  };
};
