import { type FileRejection } from 'react-dropzone';
import type { DebtorOrSecuredType, IUCC1ResponsePayload, UCC1Type } from './models';

export const getDebtorOrSecuredName = (debtorOrSecured: DebtorOrSecuredType) => {
  if (debtorOrSecured.isOrganization) {
    return debtorOrSecured.name;
  }
  return `${debtorOrSecured.firstName} ${debtorOrSecured.lastName} ${
    debtorOrSecured.middleName ?? ''
  }${debtorOrSecured.suffix ? `, ${debtorOrSecured.suffix}` : ''}`;
};

export const parseUcc1 = (data: IUCC1ResponsePayload): UCC1Type => {
  const {
    id,
    ackContactName,
    ackContactEmail,
    ackContactPhone,
    type,
    status,
    hasAddendum,
    uccNumber,
    packetId,
    isActive,
    isPosted,
    isBulkload,
    isSkipped,
    altDesignationType,
    additionalCollateralDescription,
    docStampTaxType,
    filerRefData,
    financingStatement,
    miscellaneous,
    realEstateDescription,
    recordOwnerNameAddress,
    financingStatementCoverageType,
    debtorType,
    debtorFilingType,
  } = data;
  return {
    id,
    ackContactName,
    ackContactEmail,
    ackContactPhone,
    type,
    status,
    hasAddendum,
    uccNumber,
    packetId,
    isActive,
    isPosted,
    isBulkload,
    isSkipped,
    altDesignationType,
    additionalCollateralDescription,
    docStampTaxType,
    filerRefData,
    financingStatement,
    miscellaneous,
    realEstateDescription,
    recordOwnerNameAddress,
    financingStatementCoverageType,
    debtorType,
    debtorFilingType,
  };
};

export const sortDebSecByNewest = (debsec: DebtorOrSecuredType[]) =>
  debsec.sort(
    (objA, objB) =>
      Number(new Date(objB.createdAt).getTime()) - Number(new Date(objA.createdAt).getTime())
  );

export const parseDebSec = (
  data: DebtorOrSecuredType[]
): { debtors: DebtorOrSecuredType[]; securedParties: DebtorOrSecuredType[] } => {
  const debtors: DebtorOrSecuredType[] = [];
  const securedParties: DebtorOrSecuredType[] = [];
  data.forEach(
    ({
      address,
      firstName,
      lastName,
      middleName,
      name,
      suffix,
      id,
      type,
      filingId,
      createdAt,
      isOrganization,
      entityActionType,
      sequenceNumber,
    }) => {
      const debtor = {
        address,
        firstName,
        lastName,
        middleName,
        name,
        suffix,
        id,
        type,
        filingId,
        createdAt,
        isOrganization,
        entityActionType,
        sequenceNumber,
      };
      type === 'Debtor' ? debtors.push(debtor) : securedParties.push(debtor);
    }
  );

  return {
    debtors,
    securedParties,
  };
};

export const parseFileErrorRejections = (fileRejection: FileRejection) => {
  const error = fileRejection.errors[0];
  return error.code === 'file-invalid-type'
    ? 'Invalid file format. Please upload files in PDF or TIFF format only.'
    : error.code === 'file-too-large'
    ? 'File size is too large. Please upload files under 10MB in size.'
    : error.message;
};
