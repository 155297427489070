import { API } from 'aws-amplify';
import { IHandleActionFilingProps, StatusProps } from '../models';
import { IResponse } from '../../../../common/models/features/models';

const apiName = 'OnlineFilingAPI';
const myInit = {};

export const removeCartFiling = async ({ id, type }: IHandleActionFilingProps) => {
  try {
    const res = await API.del(apiName, `/${type.toLowerCase()}/${id}`, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const getAllUCCFormFilings = async (status: StatusProps): Promise<IResponse<any>> => {
  try {
    const res = await API.get(
      apiName,
      `/filings?status=${status.toLowerCase()}&all=true&sortOrder=Ascending`,
      myInit
    );
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};
