import { Drawer, styled } from '@mui/material';

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '.MuiDrawer-paper': {
    top: theme.convert.pxToRem(50),
    width: '100%',
    boxSizing: 'border-box',
    border: 'none',
    boxShadow: '0px 4px 4px 0px rgba(176, 192, 237, 0.25)',
  },

  [theme.breakpoints.down('sm')]: {
    '.MuiDrawer-paper': {
      width: '100%',
      top: theme.convert.pxToRem(50),
    },
  },

  [theme.breakpoints.only('md')]: {
    '.MuiDrawer-paper': {
      top: '82px',
      width: '280px',
    },
  },
}));
