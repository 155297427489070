import { SyntheticEvent, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grow, Theme, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';

import { StyledMenu } from './StyledMenu';
import { StyledButton } from './StyledButton';
import { StyledMenuItem } from './StyledMenuItem';
import { StyledListItemButton } from '../header/StyledListItemButton';
import { ReactComponent as CartIcon } from '../../../assets/icons/cart.svg';
import { ReactComponent as Polygon } from '../../../assets/icons/close-polygon.svg';
import useUnsavedChangesModal from '../modals/unsaved-changes/useUnsavedChangesModal';

import { cart, dashboard, myAccount } from '../../constants/routes';
import { AuthedState } from '../../context/types';
import { useAuth, useUserInfo } from '../../context';
import { StyledInheritButton } from '../../../features/start-ucc-filing/styled-items/StyledInheritButton';
import { colors } from '../../constants';

interface ILoggedInUserMenuProps {
  handleCloseDrawer?(): void;
}

export default function LoggedInUserMenu({ handleCloseDrawer }: ILoggedInUserMenuProps) {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { signOut, authed } = useAuth();
  const { loadingInfo, fullName, isLoadingCartDetails, cartFilingsDetails } = useUserInfo();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { handleUnsavedChanges } = useUnsavedChangesModal();

  const isLoggedInUserMenuActive =
    location.pathname === myAccount || location.pathname === dashboard;

  const isCartActive = location.pathname === cart;

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const isMediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.only('md'));

  const open = Boolean(anchorEl);

  const handleClick = useCallback(
    (event: SyntheticEvent<HTMLElement>) => {
      if (!isSmallScreen) {
        setAnchorEl(event.currentTarget);
      } else {
        if (anchorEl) {
          setAnchorEl(null);
        } else {
          setAnchorEl(event.currentTarget);
        }
      }
    },
    [anchorEl, isSmallScreen]
  );

  const handleClose = () => {
    handleCloseDrawer?.();
    setAnchorEl(null);
  };

  if (authed === AuthedState.NOT_AUTHED) {
    return null;
  }

  return (
    <Box
      display="flex"
      alignItems={{ xs: 'start', md: 'start', lg: 'center' }}
      flexDirection={{ xs: 'column', md: 'column', lg: 'row' }}
    >
      {loadingInfo || isLoadingCartDetails ? null : (
        <>
          <Box borderBottom={isCartActive ? `3px solid ${theme.palette.info.dark}` : ''}>
            <StyledInheritButton
              tabIndex={0}
              onClick={() => {
                navigate(cart);
                handleClose();
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                marginBottom: { xs: 0.5, md: 0.5, lg: 0 },
                paddingY: theme.convert.pxToRem(3),
                '&:hover': {
                  textDecoration: 'none',
                },
              }}
            >
              <CartIcon
                fill={isCartActive ? theme.palette.info.dark : theme.palette.text.primary}
              />
              <Typography variant="h4">
                Cart&nbsp;
                {`(${cartFilingsDetails?.totalFilingsCount ?? 0})`}
              </Typography>
            </StyledInheritButton>
          </Box>
          <Box width={theme.convert.pxToRem(20)} />
          <Box
            overflow="hidden"
            borderBottom={isLoggedInUserMenuActive ? `3px solid ${theme.palette.info.dark}` : ''}
          >
            <StyledButton
              id="grow-button"
              aria-controls={open ? 'grow-menu' : 'false'}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : 'false'}
              onClick={handleClick}
              sx={{
                paddingY: theme.convert.pxToRem(3),
                paddingX: theme.convert.pxToRem(0),
              }}
            >
              <Tooltip
                title={fullName.charAt(0).toUpperCase() + fullName.slice(1)}
                PopperProps={open ? { style: { zIndex: 0 } } : {}}
              >
                <Typography
                  variant="h4"
                  maxWidth={theme.convert.pxToRem(200)}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textTransform: 'capitalize',
                  }}
                  align="left"
                >
                  Hi,&nbsp;{fullName}!
                </Typography>
              </Tooltip>
              <Box width={8} />
              <Polygon
                style={{
                  color: '#646464',
                  transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'transform 0.2s ease-in-out',
                }}
              />
            </StyledButton>
          </Box>
        </>
      )}
      {!isSmallScreen && (
        <StyledMenu
          id="grow-menu"
          disableScrollLock={true}
          MenuListProps={{
            'aria-labelledby': 'grow-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          TransitionComponent={Grow}
        >
          <StyledMenuItem
            onClick={() => {
              navigate(dashboard);
              handleClose();
            }}
          >
            <Typography variant="h6">Dashboard</Typography>
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => {
              navigate(myAccount);
              handleClose();
            }}
          >
            <Typography variant="h6">Account</Typography>
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => {
              signOut(handleUnsavedChanges);
              handleClose();
            }}
          >
            <Typography variant="h6">Sign Out</Typography>
          </StyledMenuItem>
        </StyledMenu>
      )}
    </Box>
  );
}
