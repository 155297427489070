import { API } from 'aws-amplify';

const apiName = 'OnlineFilingAPI';

export const getOnlineSetting = async (settingKeys: string[]) => {
  const parameters = {
    queryStringParameters: {
      settingKeys,
    },
  };

  const res = await API.get(apiName, '/settings', parameters);
  return res.payload[0].value;
};
