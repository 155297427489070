import { Box, useTheme } from '@mui/material';
import { StyledActionButton } from '../../../../common/global-styles';

interface IModalActionsContentProps {
  handleClose: () => void;
  handleResend: () => void;
  counter: number;
  resendButtonDisabled: boolean;
}

export const RegisterModalActionsContent = ({
  handleClose,
  handleResend,
  resendButtonDisabled,
  counter,
}: IModalActionsContentProps) => {
  const theme = useTheme();
  return (
    <>
      <StyledActionButton
        onClick={handleResend}
        variant="contained"
        disabled={resendButtonDisabled}
      >
        {resendButtonDisabled ? 'Resend in ' + counter : 'Resend'}
      </StyledActionButton>
      <Box sx={{ width: { xs: theme.convert.pxToRem(20), sm: theme.convert.pxToRem(34) } }} />
      <StyledActionButton onClick={handleClose} variant="contained">
        Ok
      </StyledActionButton>
    </>
  );
};
