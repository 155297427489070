import { Box, useTheme } from '@mui/material';
import Hero from '../features/home-page/Hero';
import Announcements from '../features/home-page/Announcements';
import HowCanWeHelp from '../features/home-page/HowCanWeHelp';
import ResourceAndServices from '../features/home-page/ResourceAndServices';

export const Home = () => {
  const theme = useTheme();
  return (
    <Box
      marginTop={{ xs: theme.convert.pxToRem(-20), md: theme.convert.pxToRem(-42) }}
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Hero />
      <Announcements />
      <HowCanWeHelp />
      <ResourceAndServices />
    </Box>
  );
};
