import { FC } from 'react';
import { Button } from '@mui/material';

import { NavLink } from 'react-router-dom';
import { homePageURL } from '../../constants/routes';
import { StyledImage } from './StyledImage';
import { ReactComponent as UCCLogo } from '../../../assets/icons/ucclogo.svg';
import UCCLogoPNG from '../../../assets/icons/ucclogo.png';

interface Props {
  isSmall?: boolean;
}

export const UCCHomeLogo: FC<Props> = ({ isSmall }) => (
  <NavLink to={homePageURL} tabIndex={-1}>
    <Button sx={{ padding: 0 }}>
      {isSmall ? (
        <StyledImage src={UCCLogoPNG} alt="UCC logo" width="93px" />
      ) : (
        <UCCLogo width="133px" />
      )}
    </Button>
  </NavLink>
);
