import { TableContainer } from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from '../../../common/theme';

export const StyledTableContainer = styled(TableContainer)<{
  component?: React.ElementType;
  variant?: string;
}>`
  border: 1px solid ${theme.palette.grey[200]};
  fill: ${theme.palette.common.white};
  filter: drop-shadow(0px 0px 8px rgba(176, 192, 237, 0.5));
`;
