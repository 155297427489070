import { useEffect, useState } from 'react';
import {
  RegisterModalActionsContent,
  RegisterModalContent,
} from '../../../common/components/modal-contents';
import { useModal, useAuth } from '../../../common/context';
import { ISignUp, IEmail } from '../../../common/models';
import { REGISTER_TIMEOUT_SECONDS } from '../../../common/constants/timers';

export const useRegisterModal = () => {
  const { handleModalClose, handleModalOpen, handleSetModalActions } = useModal();
  const { signUp, resendMail } = useAuth();

  const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
  const [counter, setCounter] = useState(REGISTER_TIMEOUT_SECONDS);
  const [stateEmail, setEmail] = useState('');
  const [stateFirstname, setFirstName] = useState('');
  const [stateLastName, setLastName] = useState('');

  const [timerWorker] = useState(
    new Worker(new URL('../../../common/helpers/timer.worker', import.meta.url))
  );

  useEffect(() => {
    timerWorker.onmessage = (event: MessageEvent) => {
      setCounter(event.data);
    };
  }, []);

  const handleResend = async () => {
    const payload = {
      email: stateEmail,
    };
    const responseData = await resendMail(payload);
    if (responseData) {
      setResendButtonDisabled(true);
      setCounter(REGISTER_TIMEOUT_SECONDS);
      timerWorker.postMessage({ type: 'reset', value: REGISTER_TIMEOUT_SECONDS });
      timerWorker.postMessage({ type: 'start' });
      onOpenHandler({ email: stateEmail });
    }
  };

  const handleRegister = async ({ email, firstName, lastName }: ISignUp) => {
    const payload = {
      email,
      firstName,
      lastName,
    };
    if (
      resendButtonDisabled &&
      stateEmail === payload.email &&
      stateFirstname === firstName &&
      stateLastName === lastName
    ) {
      onOpenHandler({ email });
    } else {
      const responseData = await signUp(payload);
      if (responseData) {
        setEmail(email);
        setFirstName(firstName);
        setLastName(lastName);
        setResendButtonDisabled(true);
        setCounter(REGISTER_TIMEOUT_SECONDS);
        timerWorker.postMessage({ type: 'reset', value: REGISTER_TIMEOUT_SECONDS });
        timerWorker.postMessage({ type: 'start' });
        onOpenHandler({ email });
      }
    }
  };

  const onOpenHandler = ({ email }: IEmail) => {
    handleModalOpen({
      title: 'Check your email',
      modalContent: <RegisterModalContent email={email} />,
      modalActions: (
        <RegisterModalActionsContent
          handleClose={handleModalClose}
          handleResend={handleResend}
          resendButtonDisabled={true}
          counter={resendButtonDisabled ? counter : REGISTER_TIMEOUT_SECONDS}
        />
      ),
    });
  };

  useEffect(() => {
    if (counter === 0) {
      setResendButtonDisabled(false);
      setCounter(0);
      timerWorker.postMessage({ type: 'stop' });
    }

    handleSetModalActions &&
      handleSetModalActions(
        <RegisterModalActionsContent
          handleClose={handleModalClose}
          handleResend={handleResend}
          resendButtonDisabled={counter > 0}
          counter={counter}
        />
      );
  }, [counter]);

  return { handleRegister };
};
