import { TextInput } from '../../../../common/components/inputs';
import { FormHelperText, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useMyAccount } from '../../../../common/context';
import { ILoginFormUI } from '../../../../common/models';
import { FormData } from './AcknowledgementInfoForm';
import { FormActionButtons } from '../FormActionButtons';

export const AcknowledgementInfoFormUI = ({
  onSubmit,
  control,
  formState: { errors, isValid, isSubmitting },
}: ILoginFormUI<FormData>) => {
  const { myAccountData } = useMyAccount();
  const {
    acknowledgementInformation: { contactName, email, phoneNumber },
  } = myAccountData;

  return (
    <form onSubmit={onSubmit}>
      <Grid container padding={2} rowGap={2}>
        <Grid item xs={12} alignItems="center">
          <Grid container alignItems="center" rowGap={1}>
            <Grid item xs={12} md={2.5} paddingRight={1}>
              <InputLabel htmlFor="contactName">
                <Typography variant="h5">Contact name</Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                name="contactName"
                defaultValue={contactName ?? ''}
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <TextInput
                    autoFocus
                    tabIndex={1}
                    sx={{ maxWidth: (theme) => theme.convert.pxToRem(300) }}
                    {...field}
                    inputRef={ref}
                    error={!!errors.contactName?.message}
                    placeholder="Enter contact name"
                  />
                )}
              />
              <FormHelperText error={!!errors.contactName?.message}>
                {errors.contactName?.message}
              </FormHelperText>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} alignItems="center">
          <Grid container alignItems="center" rowGap={1}>
            <Grid item xs={12} md={5} paddingRight={1}>
              <InputLabel htmlFor="phoneNumber">
                <Typography variant="h5">Phone number</Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="phoneNumber"
                defaultValue={phoneNumber ?? ''}
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <TextInput
                    sx={{ maxWidth: (theme) => theme.convert.pxToRem(300) }}
                    {...field}
                    inputRef={ref}
                    error={!!errors.phoneNumber?.message}
                    placeholder="Enter phone number"
                  />
                )}
              />
              <FormHelperText error={!!errors.phoneNumber?.message}>
                {errors.phoneNumber?.message}
              </FormHelperText>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6} alignItems="center">
          <Grid container alignItems="center" rowGap={1}>
            <Grid item xs={12} md={5} paddingRight={1}>
              <InputLabel htmlFor="email">
                <Typography variant="h5">Contact email</Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={7}>
              <Controller
                name="email"
                defaultValue={email ?? ''}
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    sx={{ maxWidth: (theme) => theme.convert.pxToRem(300) }}
                    {...field}
                    type="email"
                    inputRef={ref}
                    error={!!errors.email?.message}
                    placeholder="Enter contact email"
                  />
                )}
              />
              <FormHelperText error={!!errors.email?.message}>
                {errors.email?.message}
              </FormHelperText>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FormActionButtons name="acknowledgementInfo" isSubmitDisabled={!isValid || isSubmitting} />
    </form>
  );
};
