import { useNavigate } from 'react-router-dom';
import { Grid, Button, Typography } from '@mui/material';

import RegisterForm from '../features/register/forms/register-form';
import { StyledClickHereGridRegister } from './StyledClickHereGrid';

const Register = () => {
  const navigate = useNavigate();

  return (
    <>
      <StyledClickHereGridRegister sx={{ gap: 0 }}>
        <RegisterForm />
        <Grid pt={0.5} pb={10} container alignItems="center">
          <Grid item xs={8.4} md={7} xl={8.5}>
            <Typography variant="h4" fontWeight={400}>
              Already have an account?
            </Typography>
          </Grid>
          <Grid item xs={3.5} md={5} xl={3.5}>
            <Button onClick={() => navigate('/onlinefiling/login')}>Login Here</Button>
          </Grid>
        </Grid>
      </StyledClickHereGridRegister>
    </>
  );
};

export default Register;
