import { styled } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import { colors } from '../../constants';

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderLeft: '4px solid transparent',
  paddingLeft: theme.convert.pxToRem(50),
  paddingRight: theme.convert.pxToRem(35),
  height: theme.convert.pxToRem(44),
  width: '100vw',
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
    borderLeft: `4px solid ${colors.highlightSelectionColor}`,
  },
}));
