import { Grid, Button, styled } from '@mui/material';

export const StyledGrid = styled(Grid)({
  flexBasis: '100%',
  margin: 0,
  maxWidth: 'none',
});

export const StyledAddButton = styled(Button)(({ theme }) => ({
  padding: `${theme.convert.pxToRem(12)} ${theme.convert.pxToRem(40)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'palette.info.main',
  '&:hover': { textDecoration: 'none' },
}));
