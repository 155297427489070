import { styled } from '@mui/material/styles';

import { StyledSubmitButton } from '../components/user-auth-components/common-auth-styles';

export const StyledModalSubmitButton = styled(StyledSubmitButton)(({ theme }) => ({
  px: theme.convert.pxToRem(14),
  [theme.breakpoints.up('xs')]: {
    width: theme.convert.pxToRem(125),
  },
  [theme.breakpoints.up('md')]: {
    width: theme.convert.pxToRem(174),
  },
}));
