import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { textButtonDefaultColor } from '../constants/colors';

export const StyledLinkButton = styled(Button)(({ theme }) => ({
  height: 40,
  fontFamily: 'Open Sans',
  fontWeight: '600',
  fontSize: theme.typography.pxToRem(14),
  color: textButtonDefaultColor,
  lineHeight: theme.typography.pxToRem(22),
  padding: theme.convert.pxToRem(0),
  minWidth: theme.convert.pxToRem(0),
}));
