import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { PageTitle } from '../common/constants/features-constants';
import PageWrapper from '../common/layouts/PageWrapper';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { SingleDatePicker } from 'react-dates';
import moment, { Moment } from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from '../common/notification';
import { yupResolver } from '@hookform/resolvers/yup';
import { DownloadTypeEnum } from '../features/download/DownloadTypeEnum';
import { fileTypeDownloadOptions } from '../features/download/fileTypeDownloadOptions';
import { useDownload } from '../features/download/useDownload';
import { ReactComponent as CalendarIcon } from '../assets/icons/calendar.svg';
import DisclaimerWrapper from '../common/components/DisclaimerWrapper';
import { FormDataType, saveSchema } from '../features/download/FormDataType';
import { ReactComponent as AlertIcon } from '../assets/icons/alert.svg';
import { downloadDocument } from '../common/helpers/downloadDocument';
import { useSearchDataFetch } from '../features/search/useSearchDataFetch';
import { getFilingsCompletedThroughDate } from '../features/search/search.services';
import { downloadFilingsRangeDaysBeforeFilingsThrough } from '../features/download/constants';
import { StyledInheritLink } from '../features/start-ucc-filing/styled-items/StyledInheritButton';

export const Download = () => {
  const { data: throughDate } = useSearchDataFetch<string>(getFilingsCompletedThroughDate);
  const theme = useTheme();
  const { Snack } = useSnackbar();
  const maximumDateRange = moment(throughDate).local().endOf('day');
  const minRangeDate = moment(maximumDateRange).subtract(
    downloadFilingsRangeDaysBeforeFilingsThrough,
    'days'
  );
  const downloadButtonRef = useRef<HTMLButtonElement | null>(null);
  const isScreenGraterThanMd = useMediaQuery(theme.breakpoints.up('lg'));

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
  } = useForm<FormDataType>({
    resolver: yupResolver(saveSchema),
    mode: 'onSubmit',
    defaultValues: {
      downloadType: DownloadTypeEnum.REGULAR,
      fileType: '',
      fileDate: null,
    },
  });
  const downloadType = watch('downloadType');
  const fileDate = watch('fileDate');

  const { getDownloadFile, downloadFile } = useDownload();

  const [focusedInput, setFocusedInput] = useState<boolean>(false);

  const onSubmit: SubmitHandler<FormDataType> = async (data: FormDataType, event) => {
    event?.preventDefault();
    await getDownloadFile(data);
  };

  useEffect(() => {
    if (downloadFile) {
      window.open(downloadFile, '_blank');
    }
  }, [downloadFile]);

  const isDayBlocked = (date: Moment) => {
    return date.isAfter(maximumDateRange) || date.isBefore(minRangeDate);
  };

  const [oldDate, setOldDate] = useState<Moment | null>(null);
  return (
    <DisclaimerWrapper>
      <PageWrapper title={'UCC Secured Transactions Download'} headTitle={PageTitle.DOWNLOAD}>
        {isScreenGraterThanMd ? (
          <Box marginBottom={40}>
            <Typography marginBottom={theme.convert.pxToRem(20)} variant="body1">
              Specifications for data layout are in{' '}
              <StyledInheritLink to="" onClick={() => downloadDocument('readme.txt')}>
                regular download
              </StyledInheritLink>{' '}
              or{' '}
              <StyledInheritLink to="" onClick={() => downloadDocument('readme_fulldownload.txt')}>
                full download
              </StyledInheritLink>{' '}
              This information can be helpful when configuring other software to import the data.
              The UCC Filings Completed Through Date is {moment(throughDate).format('MM-DD-YYYY')}.
              <br />
              <br />
              Regular Data files are available for the thirty-day period prior to the &quot;Filing
              Through&quot; date shown above. The files are generated each business day and contain
              data for filings completed through the &quot;Filing Through&quot; date. The standard
              for processing UCC filings is three business days.
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                display={'flex'}
                rowSpacing={3}
                flexDirection={{ xs: 'column', lg: 'row' }}
                justifyContent={{ xs: 'center', lg: 'space-between' }}
                alignItems={{ xs: 'start', lg: 'end' }}
                marginTop={theme.convert.pxToRem(30)}
                style={{ backgroundColor: theme.palette.primary.light, minHeight: 'auto' }}
                paddingBottom={theme.convert.pxToRem(21)}
                paddingLeft={theme.convert.pxToRem(36)}
                paddingRight={{
                  xs: theme.convert.pxToRem(20),
                  lg: theme.convert.pxToRem(40),
                  xl: theme.convert.pxToRem(51),
                }}
              >
                <Grid item xs={12} md={6} lg={3} xl={3}>
                  <Controller
                    control={control}
                    defaultValue={DownloadTypeEnum.REGULAR}
                    name="downloadType"
                    render={({ field }) => (
                      <FormControl fullWidth error={!!errors.downloadType?.message}>
                        <Box gap={1} display={'flex'} flexDirection={'column'}>
                          <Typography variant="body1" fontWeight={600} marginBottom={1}>
                            Download Type*
                          </Typography>
                          <RadioGroup {...field}>
                            <Grid
                              container
                              display="flex"
                              flexDirection={'row'}
                              justifyContent="flex-start"
                              alignItems={'center'}
                            >
                              <FormControlLabel
                                sx={{ display: 'flex' }}
                                value={'Regular'}
                                control={<Radio size="small" name="downloadType" />}
                                label={'Regular'}
                              />
                              <FormControlLabel
                                sx={{ display: 'flex' }}
                                value={'Full'}
                                control={<Radio size="small" name="downloadType" />}
                                label={'Full'}
                              />
                            </Grid>
                          </RadioGroup>
                        </Box>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3} xl={3}>
                  <Controller
                    control={control}
                    name="fileType"
                    render={({ field: { ref, ...field } }) => (
                      <Box gap={1} display={'flex'} flexDirection={'column'}>
                        <Typography variant="body1" fontWeight={600}>
                          File Type*
                        </Typography>
                        <FormControl style={{ width: 160, height: 42 }}>
                          <Select
                            displayEmpty
                            size="medium"
                            required
                            style={{
                              backgroundColor: theme.palette.background.default,
                              height: theme.convert.pxToRem(42),
                            }}
                            {...field}
                            inputRef={ref}
                            error={!!errors?.fileType?.message}
                            id="fileType"
                          >
                            <MenuItem value="" disabled>
                              <Typography>Select file type</Typography>
                            </MenuItem>
                            {fileTypeDownloadOptions.map((option) => {
                              return (
                                <MenuItem key={option} value={option}>
                                  <Typography>{option}</Typography>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                  ></Controller>
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={4}>
                  <Controller
                    control={control}
                    name="fileDate"
                    render={({ field }) => {
                      const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
                        event.preventDefault();
                        event.stopPropagation();
                        field.onChange(oldDate);
                        setFocusedInput(false);
                      };
                      return (
                        <Box gap={1} display={'flex'} flexDirection={'column'}>
                          <Grid
                            container
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'start'}
                          >
                            <Typography
                              textAlign={'left'}
                              variant="body1"
                              fontWeight={600}
                              color={
                                downloadType === DownloadTypeEnum.FULL
                                  ? theme.palette.grey[500]
                                  : ''
                              }
                            >
                              File Generated Date{' '}
                              <Typography
                                textAlign={'left'}
                                fontSize={{
                                  lg: theme.typography.pxToRem(12),
                                  xl: theme.typography.pxToRem(14),
                                }}
                                fontWeight={400}
                                lineHeight={theme.typography.pxToRem(24)}
                                marginLeft={theme.convert.pxToRem(2)}
                                color={
                                  downloadType === DownloadTypeEnum.FULL
                                    ? theme.palette.grey[500]
                                    : ''
                                }
                                style={{ whiteSpace: 'nowrap' }}
                                component={'span'}
                              >
                                (not required for full download)
                              </Typography>
                            </Typography>
                          </Grid>
                          <SingleDatePicker
                            initialVisibleMonth={() => moment(throughDate)}
                            openDirection="down"
                            isDayBlocked={isDayBlocked}
                            enableOutsideDays={false}
                            customInputIcon={<CalendarIcon />}
                            inputIconPosition="after"
                            numberOfMonths={1}
                            focused={focusedInput}
                            keepFocusOnInput
                            onFocusChange={(newFocus) => {
                              if (!newFocus.focused) {
                                return setFocusedInput(false);
                              }
                              setFocusedInput(true);
                            }}
                            placeholder={
                              downloadType === DownloadTypeEnum.FULL ? '' : 'Select date'
                            }
                            date={
                              downloadType === DownloadTypeEnum.FULL ? null : field?.value ?? null
                            }
                            id="fileDate"
                            onDateChange={(date) => {
                              if (date?.isAfter(maximumDateRange) || date?.isBefore(minRangeDate)) {
                                Snack.error(
                                  `File Generated Date must be between ${minRangeDate.format(
                                    'MM/DD/YYYY'
                                  )} and ${maximumDateRange.format('MM/DD/YYYY')}.`
                                );
                                return;
                              }
                              field.onChange(date);
                            }}
                            disabled={downloadType === DownloadTypeEnum.FULL}
                            anchorDirection={'left'}
                            transitionDuration={0}
                            hideKeyboardShortcutsPanel
                            keepOpenOnDateSelect
                            isOutsideRange={() => false}
                            daySize={34}
                            renderCalendarInfo={() => (
                              <Box
                                width={1}
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                              >
                                <Box paddingRight="20px">
                                  <Button onClick={handleCancel} onTouchStart={() => handleCancel}>
                                    Cancel
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      setFocusedInput(false);
                                      setOldDate(fileDate ?? null);
                                    }}
                                  >
                                    Apply
                                  </Button>
                                </Box>
                              </Box>
                            )}
                          />
                        </Box>
                      );
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={2}
                  xl={2}
                  display={'flex'}
                  justifyContent={{ xs: 'start', lg: 'end' }}
                >
                  <Button
                    ref={downloadButtonRef}
                    disabled={!isValid}
                    type="submit"
                    variant="contained"
                    style={{
                      width: theme.convert.pxToRem(174),
                      height: theme.convert.pxToRem(40),
                      padding: `${theme.convert.pxToRem(10)}  ${theme.convert.pxToRem(0)}`,
                      borderRadius: theme.convert.pxToRem(5),
                    }}
                  >
                    Download
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        ) : (
          <Box
            component={'div'}
            flexShrink={'unset'}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'flex-start'}
          >
            <Box marginRight={{ xs: theme.convert.pxToRem(8), md: theme.convert.pxToRem(11) }}>
              <AlertIcon />
            </Box>
            <Typography
              fontSize={{
                xs: theme.typography.pxToRem(14),
                lg: theme.typography.pxToRem(16),
              }}
              lineHeight={{
                xs: theme.typography.pxToRem(20),
                lg: theme.typography.pxToRem(24),
              }}
            >
              The download function is not available on mobile devices. Please switch back to
              desktop and try again. Thank you.
            </Typography>
          </Box>
        )}
      </PageWrapper>
    </DisclaimerWrapper>
  );
};
