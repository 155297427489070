import { Typography } from '@mui/material';

import ListAccordions from '../ListAccordions';
import { StyledOrderedList } from '../../../features/start-ucc-filing/styled-items/StyledOrderedList';
import { downloadDocument } from '../../helpers/downloadDocument';
import { StyledInheritLink } from '../../../features/start-ucc-filing/styled-items/StyledInheritButton';

const coverSheets: {
  pdf: string;
  title: string;
  content: string;
}[] = [
  {
    pdf: 'ACHDebitAcctFaxCoverSheet.pdf',
    title: 'FAX cover sheets',
    content: 'for processing submitted via fax service',
  },
  {
    pdf: 'ACHDebitAcctMailCoverSheet.pdf',
    title: 'MAIL cover sheets',
    content: 'for processing submitted via mail service',
  },
  {
    pdf: 'ACHDebitAcctWalkInCoverSheet.pdf',
    title: 'WALK-IN cover sheets',
    content: 'for processing submitted via walk-in service',
  },
];

const accordions = [
  {
    id: 0,
    title: 'Establishing Automatic Clearing House (ACH) Debit Accounts with FLORIDAUCC, LLC',
    content: (
      <>
        <Typography marginBottom={2}>
          Individuals or Organizations who submit processing requests frequently, may establish an
          ACH Debit Account with FLORIDAUCC, LLC Requestors may specify any bank account they hold,
          at any participating Federal Reserve Bank, to be used as an ACH debit account for UCC
          processing.
        </Typography>
        <Typography>
          In order to establish a debit account with FLORIDAUCC, LLC, requestors need to provide the
          following:
        </Typography>
        <ol style={{ margin: 0 }}>
          <li>
            A completed{' '}
            <StyledInheritLink
              to={''}
              onClick={() => downloadDocument('ACHDebitAcctApplicationWeb.pdf')}
            >
              Authorization Agreement for Automatic Debits
            </StyledInheritLink>{' '}
            form
          </li>
          <li>A voided check from the bank account specified for UCC processing.</li>
        </ol>
        <Typography marginTop={2}>
          The completed agreement form and voided check should be mailed to:
        </Typography>
        <Typography textAlign="center" marginBottom={2}>
          FLORIDAUCC, LLC <br /> Care of: Image API, LLC <br /> 2002 Old St. Augustine Road, Bldg. D
          <br />
          Tallahassee, FL 32301
        </Typography>
        <Typography>
          FLORIDAUCC, LLC is required to have the Authorization Agreement for Automatic Debits and a
          voided check from the bank account specified for UCC processing in order to establish ACH
          debit accounts. FLORIDAUCC, LLC estimates that it will take three business days to
          establish an ACH account.
        </Typography>
      </>
    ),
  },
  {
    id: 1,
    title: 'Confirming the Creation of an ACH Debit Account',
    content:
      'FLORIDAUCC, LLC will respond to each applicant for an ACH debit account within three (3) business days of receipt of the Authorization Agreement for Automatic Debits and the associated voided check. FLORIDAUCC, LLC will let the applicant know if the account is created or if there are problems creating the account.',
  },
  {
    id: 2,
    title: 'FLORIDAUCC, LLC ACH Debit Account Number',
    content:
      'FLORIDAUCC, LLC will assign a unique ACH debit account number to each ACH debit account that is created. In order to process a request using an ACH debit account, the ACH debit account number must appear on the cover sheet submitted with the processing request. For security reasons, the ACH debit account number will not be the same as the bank account number provided by the applicant.',
  },
  {
    id: 3,
    title: 'Submitting UCC Processing Requests Using ACH Debit Accounts',
    content: (
      <>
        <Typography marginBottom={2}>
          All work to be processed using an ACH debit account must be accompanied by an ACH Debit
          Account Cover Sheet. There are three types of ACH debit account cover sheets:
        </Typography>
        <StyledOrderedList style={{ margin: 0 }}>
          {coverSheets.map((coverSheet) => (
            <li key={coverSheet.title}>
              <StyledInheritLink to="" onClick={() => downloadDocument(coverSheet.pdf)}>
                {coverSheet.title}
              </StyledInheritLink>{' '}
              - {coverSheet.content}
            </li>
          ))}
        </StyledOrderedList>
      </>
    ),
  },
  {
    id: 4,
    title: 'Time to Process UCC Processing Requests Using ACH Debit Accounts',
    content:
      "Image API's contract with the State of Florida, Division of Corporations, specifies that all UCC processing requests will be processed within three business days of receipt.",
  },
  {
    id: 5,
    title: 'Processing Fees Associated with UCC Processing Requests',
    content:
      'Processing fees for all UCC processing requests are established by Florida Law. All UCC fees are nonrefundable processing fees. No refunds will be issued by FLORIDAUCC, LLC for UCC documents that cannot be filed.',
  },
  {
    id: 6,
    title: 'Transferring Money Using ACH Debit Accounts',
    content: (
      <>
        <Typography marginBottom={2}>
          When customers submit UCC processing requests and reference an ACH debit account,
          FLORIDAUCC, LLC will automatically transfer money from the ACH debit account to the
          Florida Secured Transaction Registry account. The transfers will be made only at the
          completion of each UCC processing transaction. A receipt for each transaction will be
          affixed to the UCC documents that are returned to the customer.
        </Typography>
        <Typography>
          It is the responsibility of each ACH Debit Account holder to maintain adequate funds in
          the bank account specified as the debit account for UCC processing.
        </Typography>
      </>
    ),
  },
  {
    id: 7,
    title: 'Responding to UCC Processing Requests',
    content: (
      <>
        <Typography marginBottom={2}>
          FLORIDAUCC, LLC will respond to each processing request it receives. If a UCC processing
          request is filed, the first page of the UCC filing document will be returned to the
          customer. If a UCC processing request is not filed, FLORIDAUCC, LLC will generate a letter
          outlining the reasons the processing request was not filed, and return the letter, and a
          copy of the processing request documents, to the person indicated. The letter will also
          include receipt information that shows the date and the fees associated with the
          processing.
        </Typography>
        <Typography marginBottom={2}>
          FAX Requests - If a customer submits a UCC processing request using fax service,
          FLORIDAUCC, LLC will fax its response to the fax number provided on the ACH Debit Account
          Authorization Filing Cover Sheet. FLORIDAUCC, LLC will make five (5) attempts to fax the
          document to the fax number provided on the cover sheet. If circumstances with the
          requester&apos;s fax service prohibit successful transmission for five attempts,
          FLORIDAUCC, LLC will mail the document to the mailing address provided on the ACH Debit
          Account Authorization Filing Cover Sheet.
        </Typography>
        <Typography marginBottom={2}>
          Mail Requests - If a customer submits a UCC processing request using mail service,
          FLORIDAUCC, LLC will return its response via the United States Postal Service. FLORIDAUCC,
          LLC will mail its response to the address provided in the &quot;Return Copy&quot; section
          of the UCC form.
        </Typography>
        <Typography>
          Walk-In Requests - If a customer submits a UCC processing request using walk-in service,
          FLORIDAUCC, LLC will return its response via the walk-in service counter. FLORIDAUCC, LLC
          is not responsible for storing responses at the walk-in service counter for more than five
          (5) business days.
        </Typography>
      </>
    ),
  },
  {
    id: 8,
    title: 'Receipts for Processing Using ACH Accounts',
    content:
      'FLORIDAUCC, LLC will provide a receipt for each UCC transaction processed using an ACH debit account. The receipt for each transaction will be affixed to the UCC documents that are returned to the customer.',
  },
  {
    id: 9,
    title: 'Contacting FLORIDAUCC, LLC',
    content: (
      <>
        <Typography fontWeight={600}>Email:</Typography>
        <Typography>
          <StyledInheritLink to={'mailto:help@FLORIDAUCC.com'}>
            help@FLORIDAUCC.com
          </StyledInheritLink>
        </Typography>
        <Typography fontWeight={600} marginTop={2}>
          Help Desk Telephone:
        </Typography>
        <Typography>
          850-222-8526, Hours of Operation: 8:00AM – 5:00PM EST weekdays excluding state holidays.
        </Typography>
        <Typography fontWeight={600} marginTop={2}>
          Mailing address for UCC Filings and Correspondence:
        </Typography>
        <Typography>FLORIDAUCC, LLC PO Box 5588, Tallahassee, FL 32314</Typography>
        <Typography fontWeight={600} marginTop={2}>
          Walk In Location:
        </Typography>
        <Typography>
          FLORIDAUCC, LLC, 2002 Old St. Augustine Road, Bldg. D, Tallahassee, FL 32301
        </Typography>
        <Typography fontWeight={600} marginTop={2}>
          Overnight Deliveries:
        </Typography>
        <Typography>
          FLORIDAUCC, LLC, Care of: Image API, LLC, 2002 Old St. Augustine Road, Bldg. D,
          Tallahassee, FL 32301
        </Typography>
      </>
    ),
  },
];

const FaqAccordionsAch = () => {
  return <ListAccordions accordions={accordions} initiallyOpen={[0]} />;
};

export default FaqAccordionsAch;
