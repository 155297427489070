import { API } from 'aws-amplify';

import { IHandleActionFilingProps, IPaymentRecordPayload, StatusProps } from './models';
import { IResponse } from '../../common/models/features/models';
import { cartCheckoutPath } from '../../common/constants/api';

const apiName = 'OnlineFilingAPI';
const myInit = {};

export const removeCartFiling = async ({ id, type }: IHandleActionFilingProps) => {
  try {
    const res = await API.del(apiName, `/${type.toLowerCase()}/${id}`, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const getAllUCCFormFilings = async (
  statuses: StatusProps[],
  packetId?: number | null
): Promise<IResponse<any>> => {
  try {
    const statusQuery = statuses.map((status) => `status=${status.toLowerCase()}`).join('&');
    const packetQuery = packetId ? `packetId=${packetId}&` : '';
    const res = await API.get(
      apiName,
      `/filings?${packetQuery}${statusQuery}&all=true&sortOrder=Ascending`,
      myInit
    );
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const cartCheckout = async (): Promise<IResponse<IPaymentRecordPayload>> => {
  try {
    const res = await API.post(apiName, `${cartCheckoutPath}`, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};
