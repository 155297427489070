import { useTheme } from '@mui/material';
import { ReactComponent as ValidCheck } from '../../../assets/icons/success-icon.svg';
import { ReactComponent as ErrorCheck } from '../../../assets/icons/error-icon.svg';

interface IPasswordValidationIcon {
  error: boolean;
}

export const PasswordValidationIcon = ({ error }: IPasswordValidationIcon) => {
  const theme = useTheme();
  if (error) {
    return <ErrorCheck width={theme.convert.pxToRem(15)} height={theme.convert.pxToRem(15)} />;
  }
  return <ValidCheck width={theme.convert.pxToRem(15)} height={theme.convert.pxToRem(15)} />;
};
