import { styled, Typography } from '@mui/material';
import { TextDocument, TextSection } from '../common/components/TextDocument';
import PageWrapper from '../common/layouts/PageWrapper';

const data: TextSection[] = [
  {
    title: 'Privacy Policy',
    paragraphs: [
      'Thank you for visiting the FloridaUCC, LLC website. FloridaUCC, LLC is the contract-vendor  for  the  Florida  Department  of  State  responsible  for  the  Florida  Secured Transaction Registry (the “Registry”).',
      'This  policy  describes  the  privacy  practices  of  Florida  UCC,  LLC  regarding  information collected from visitors to our website. It contains information about what data is collected and  how  that  information  is  used.  This  policy  may  be  updated  periodically,  so  please check back from time to time.',
      'Your privacy is very important to us. Our policy is to collect no personal information about you  when  you  visit  our  website,  except  the  information  you  provide  in  making  on-line filings and the information described below.',
    ],
  },
  {
    title: 'Website Use Information:',
    paragraphs: [
      `Please be aware that when you fill out any form or submit any document to our website, the  information  contained  therein  will  be  available  to  the  public  both  on-line  and  in database format. Once you sign in to our services either to search the website or to file any document, you  are  not  anonymous  to  us,  and  the  information  you  provide  will  be saved and will be available to the public for a designated period of time in order to comply with the State of Florida's public records law and archiving policies.    Florida law does, however, provide an exemption from the public records law for “bank account numbers and debit, charge, and credit card numbers held by an agency.”`,
      'When you visit our website, the web server automatically recognizes the internet domain and IP address from which you accessed our site. Information gathered may include the date and time of visits, the pages viewed, time spent at our website, and the websites visited  just  before  our  website.  This  is  done  so  that  the  content  of  the  site  can  be improved.',
      'When you fill out an electronic form, submit documents to us, or otherwise send us written communications  (such  as  emails)  that  contain  personal  information,  that  personal information  becomes  public  record.     Please  note  that  Florida’s  public  records  law requires that all information received in connection with state business be made available to  anyone  upon  request,  unless  the  information  is  subject  to  a  specific  statutory exemption.',
    ],
  },
  {
    title: 'What About Other Websites Linked to Our Website?',
    paragraphs: [
      `We are not responsible for the practices employed by websites linked to or from our website nor the information or content contained therein. Often links to other websites are provided solely as pointers to information on topics that may be useful to the users of our website.`,
      'If  you  have  questions  about  our  privacy  policies  or  have  suggestions  on  how  these policies can be improved, please feel free to contact us by telephone at 850.222.8526.',
    ],
  },
];

const Content = styled(Typography)(({ theme }) => ({
  lineHeight: 1.5,
  textAlign: 'justify',
  marginBottom: theme.convert.pxToRem(10),
}));

const SubTitle = styled(Content)(({ theme }) => ({
  fontSize: theme.convert.pxToRem(18),
  fontWeight: 'bold',
  marginTop: theme.convert.pxToRem(10),
  lineHeight: 1,
  textAlign: 'inherit',
}));

const MainTitle = styled(SubTitle)(({ theme }) => ({
  fontSize: theme.convert.pxToRem(24),
  marginBottom: theme.convert.pxToRem(20),
  marginTop: 0,
}));

export const PrivacyPolicy = () => (
  <PageWrapper title="">
    <TextDocument data={data} SubTitle={SubTitle} Paragraph={Content} DocumentTitle={MainTitle} />
  </PageWrapper>
);
