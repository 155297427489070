import { Box, Button, Grid, styled, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg';
import { useNavigate } from 'react-router-dom';

interface NavigationProps {
  prevUrl: string | null;
  nextUrl: string | null;
  totalExactMatches?: number | null;
  showAdditionalSearchTextAfterExactSearch?: boolean;
}

const StyledBoxForAdditionalMessage = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    bottom: '-15px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const Navigation: FC<NavigationProps> = ({
  nextUrl,
  prevUrl,
  totalExactMatches,
  showAdditionalSearchTextAfterExactSearch,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Grid
      container
      display={'flex'}
      justifyContent={'space-between'}
      marginTop={theme.convert.pxToRem(11)}
      position={'relative'}
    >
      <Grid item>
        <Box>
          <Button
            disabled={!prevUrl}
            onClick={() => navigate(prevUrl ?? '')}
            startIcon={<ArrowLeft fill={prevUrl ? '#2E73DC' : '#909090'} />}
          >
            Previous
          </Button>
        </Box>
      </Grid>
      <Grid item>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant={'body2'} display={{ sm: 'none', md: 'flex' }}>
            {showAdditionalSearchTextAfterExactSearch &&
              `${totalExactMatches} records matched this search`}
          </Typography>
          <Button
            disabled={!nextUrl}
            onClick={() => navigate(nextUrl ?? '')}
            endIcon={<ArrowRight fill={nextUrl ? '#2E73DC' : '#909090'} />}
          >
            Next
          </Button>
        </Box>
      </Grid>
      <StyledBoxForAdditionalMessage>
        <Typography variant={'body2'}>
          {showAdditionalSearchTextAfterExactSearch &&
            `${totalExactMatches} records matched this search`}
        </Typography>
      </StyledBoxForAdditionalMessage>
    </Grid>
  );
};

export default Navigation;
