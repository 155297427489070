import { TooltipProps, Zoom, Tooltip } from '@mui/material';

export const CustomTooltip = (props: TooltipProps) => {
  const { placement, arrow, children } = props;
  return (
    <Tooltip
      placement={placement ?? 'right-start'}
      arrow={arrow ?? true}
      TransitionComponent={Zoom}
      {...props}
    >
      {children}
    </Tooltip>
  );
};
