import { Grid, Box, Typography, useTheme } from '@mui/material';

interface ITableCellItem {
  border: string;
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
}

export const TableCellItem = ({
  border,
  name,
  addressLine1,
  addressLine2,
  city,
  state,
  zipCode,
}: ITableCellItem) => {
  const theme = useTheme();
  return (
    <Grid item md={4} xs={4} borderBottom={border} paddingY={theme.convert.pxToRem(15)}>
      <Box paddingX={theme.convert.pxToRem(15)}>
        <Typography variant="h6">{name}</Typography>
        <Typography variant="h6">{addressLine1}</Typography>
        <Typography variant="h6">{addressLine2}</Typography>
        <Typography variant="h6">
          {city}
          {state && `, ${state}`}
          {zipCode && `, ${zipCode}`}
        </Typography>
      </Box>
    </Grid>
  );
};
