import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactComponent as ClosePolygon } from '../../../assets/icons/close-polygon.svg';
import type { ICustomAccordionProps } from '../../models';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  '&:focus': {
    backgroundColor: '#0F75BC4D',
  },
}));

export const CustomAccordion = ({
  title,
  isOpen,
  handleChange,
  actions,
  children,
  titleItem,
  hasAdditionalInfo,
  searchFeature,
  noFixedHeight,
}: ICustomAccordionProps) => {
  const theme = useTheme();

  useEffect(() => {
    document.getSelection()?.removeAllRanges();
  }, [isOpen]);

  return (
    <Grid item xs={10} justifyContent="center" alignItems="center" marginX="auto" minWidth={'100%'}>
      <Accordion
        expanded={isOpen}
        onChange={handleChange}
        disableGutters
        sx={{
          boxShadow: 'none',
          '&.Mui-expanded': {
            boxShadow: '0px 0px 8px rgba(176, 192, 237, 0.5)',
          },
        }}
      >
        <StyledAccordionSummary
          sx={{
            height: noFixedHeight ? 'auto' : hasAdditionalInfo ? { xs: 94, md: 62 } : 62,
            minHeight: noFixedHeight ? 62 : 'auto',
            alignItems: hasAdditionalInfo ? { xs: 'baseline', md: 'center' } : 'center',
            paddingX: 0,
          }}
          expandIcon={<ClosePolygon height={20} width={20} color={theme.palette.text.primary} />}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: hasAdditionalInfo ? { xs: 'baseline', md: 'center' } : 'center',
              flexGrow: 1,
              width: 1,
            }}
          >
            <Box
              sx={{
                display: hasAdditionalInfo ? { xs: 'block', md: 'flex' } : 'flex',
                alignItems: hasAdditionalInfo ? { xs: 'baseline', md: 'center' } : 'center',
                justifyContent: 'space-between',
                flexDirection: hasAdditionalInfo ? { xs: 'column', md: 'row' } : 'row',
                whiteSpace: hasAdditionalInfo ? 'noWrap' : 'normal',
                width: 1,
              }}
            >
              <Box
                sx={{
                  display: hasAdditionalInfo ? { xs: 'block', md: 'flex' } : 'flex',
                  alignItems: hasAdditionalInfo ? { xs: 'baseline', md: 'center' } : 'center',
                  flexDirection: hasAdditionalInfo ? { xs: 'column', md: 'row' } : 'row',
                  whiteSpace: hasAdditionalInfo ? 'noWrap' : 'normal',
                }}
              >
                <Typography
                  variant="h4"
                  style={{ fontWeight: 600 }}
                  noWrap
                  sx={{
                    whiteSpace: hasAdditionalInfo ? 'noWrap' : 'normal',
                    alignItems: 'baseline',
                    justifyItems: 'flex-start',
                  }}
                >
                  {title}
                </Typography>
                <Box
                  marginLeft={hasAdditionalInfo ? { xs: -4.4, md: 3 } : 3}
                  onClick={(e) => e.stopPropagation()}
                >
                  {titleItem}
                </Box>
              </Box>
              {actions ? (
                <Box
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    display: ['flex', 'flex', 'block'],
                    flexDirection: ['column', 'column'],
                    alignItems: ['center'],
                    paddingRight: [
                      theme.convert.pxToRem(20),
                      theme.convert.pxToRem(20),
                      theme.convert.pxToRem(20),
                      theme.convert.pxToRem(40),
                      theme.convert.pxToRem(40),
                    ],
                  }}
                >
                  {actions.map((action) => {
                    return (
                      <Button
                        key={action.buttonText}
                        disabled={action?.disabled}
                        name={action?.name}
                        onClick={action.buttonAction}
                        startIcon={action?.icon}
                      >
                        {action.buttonText}
                      </Button>
                    );
                  })}
                </Box>
              ) : (
                <></>
              )}
              {searchFeature && (
                <Box
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    paddingRight: [
                      theme.convert.pxToRem(20),
                      theme.convert.pxToRem(20),
                      theme.convert.pxToRem(0),
                      theme.convert.pxToRem(0),
                      theme.convert.pxToRem(0),
                    ],
                  }}
                >
                  {searchFeature}
                </Box>
              )}
            </Box>
          </Box>
        </StyledAccordionSummary>
        {children}
      </Accordion>
    </Grid>
  );
};
