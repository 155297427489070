import { styled } from '@mui/material/styles';
import { colors } from '../constants';

interface DividerProps {
  bg?: string;
  height?: string;
}

export const Divider = styled('div')<DividerProps>(({ theme, bg, height }) => ({
  marginTop: theme.convert.pxToRem(20),
  marginBottom: theme.convert.pxToRem(10),
  paddingTop: theme.convert.pxToRem(2),
  paddingBottom: theme.convert.pxToRem(2),
  width: '100%',
  backgroundColor: bg || `rgba(${colors.dividerBackground}, 0.25)`,
  height: height || '',
}));
