import {
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Controller } from 'react-hook-form';

import { IFormUI } from '../../../common/models';
import { FormDataUCC1 } from '../schema';

const SendAcknowledgementTo = ({ control, formState: { errors } }: IFormUI<FormDataUCC1>) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: `${theme.convert.pxToRem(28)} ${theme.convert.pxToRem(0)} ${theme.convert.pxToRem(
          40
        )}`,
      }}
    >
      <Grid
        container
        sx={{ paddingX: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(40) } }}
        direction="column"
      >
        <Grid container alignItems="center">
          <Grid item xs={12} md={3} lg={2} xl={1.7} sx={{ paddingBottom: { xs: 1, md: 0 } }}>
            <InputLabel htmlFor="ackContactName">
              <Typography variant="h5">Contact Name *</Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={9} lg={7} xl={10.3}>
            <Controller
              name="ackContactName"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  sx={{ maxWidth: theme.convert.pxToRem(300) }}
                  {...field}
                  inputRef={ref}
                  error={!!errors.ackContactName?.message}
                  placeholder="Enter contact name"
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" sx={{ paddingBottom: theme.convert.pxToRem(7) }}>
          <Grid item xs={12} md={3} lg={2} xl={1.7}></Grid>
          <Grid item xs={12} md={9} lg={7} xl={10.3}>
            <FormHelperText sx={{ margin: 0 }} error={!!errors.ackContactName?.message}>
              {errors.ackContactName?.message || ' '}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={3} lg={2} xl={1.7} sx={{ paddingBottom: { xs: 1 } }}>
            <InputLabel htmlFor="ackContactPhone">
              <Typography variant="h5">Phone Number *</Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={9} lg={10} xl={10.3}>
            <Controller
              name="ackContactPhone"
              control={control}
              rules={{ required: false }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  sx={{ maxWidth: theme.convert.pxToRem(300) }}
                  {...field}
                  inputRef={ref}
                  error={!!errors.ackContactPhone?.message}
                  placeholder="0000000000"
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" sx={{ paddingBottom: theme.convert.pxToRem(7) }}>
          <Grid item xs={12} md={3} lg={2} xl={1.7}></Grid>
          <Grid item xs={12} md={9} lg={7} xl={10.3}>
            <FormHelperText sx={{ margin: 0 }} error={!!errors.ackContactPhone?.message}>
              {errors.ackContactPhone?.message || ' '}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={3} lg={2} xl={1.7} sx={{ paddingBottom: { xs: 1 } }}>
            <InputLabel htmlFor="ackContactEmail">
              <Typography variant="h5">Contact Email *</Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={9} lg={10} xl={10.3}>
            <Controller
              name="ackContactEmail"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  sx={{ maxWidth: theme.convert.pxToRem(300) }}
                  error={!!errors.ackContactEmail?.message}
                  type="text"
                  placeholder="Enter contact email"
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={3} lg={2} xl={1.7}></Grid>
          <Grid item xs={12} md={9} lg={7} xl={10.3}>
            <FormHelperText sx={{ margin: 0 }} error={!!errors.ackContactEmail?.message}>
              {errors.ackContactEmail?.message || ' '}
            </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SendAcknowledgementTo;
