import { Typography, Grid, Box } from '@mui/material';
import { useTheme } from '@mui/material';
export const UpdateEmailTimerContent = ({ counter }: { counter: number }) => {
  const theme = useTheme();
  return (
    <Grid container gap={2}>
      <Grid container alignItems="center">
        <Typography>
          Your email address was updated and you will be logged out in:
          <Box
            component="span"
            marginLeft={theme.convert.pxToRem(5)}
            color={theme.palette.info.dark}
          >
            {counter} seconds
          </Box>
        </Typography>
      </Grid>
      <Grid container>
        <Grid item md={12}>
          <Typography>Please login again using your new email address.</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
