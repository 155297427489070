import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledLogoTextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'start',
  justifyContent: 'flex-start',
  gap: theme.convert.pxToRem(4),
}));
