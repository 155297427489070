import { TextSection } from '../../common/components/TextDocument';

export const data: TextSection[] = [
  {
    title: 'DISCLAIMER',
    paragraphs: [
      'FLORIDAUCC, LLC (“FLORIDAUCC”), as the contract vendor for the Florida Department of State, is pleased to offer internet access to this website for filing and searching the Florida Secured Transaction Registry (the “Registry”), as permitted by Chapter 679, Florida Statutes. By accessing this website, you agree to accept the terms and conditions set forth below:',
    ],
  },
  {
    title: 'USER’S RESPONSIBILITIES',
    paragraphs: [
      'FLORIDAUCC seeks to provide up-to-date, accurate, reliable and complete information on this website and uses reasonable efforts to properly process, file, and index the records of the Registry.  However, FLORIDAUCC cannot guarantee or warrant the accuracy, reliability, or completeness of the information in the Registry, primarily because the information is supplied by third parties.  As a result, a party filing or searching for a record assumes responsibility for the correctness and accuracy of the filing and should access the Registry to verify that a record was correctly filed and indexed.',
    ],
  },
  {
    title: 'SEARCH LOGIC',
    paragraphs: [
      'The Florida Department of State requires the search function of this website to utilize a standard search logic called “Compact Name.” The rules regulating that search logic govern a user’s ability to locate filed records and can be found on this website’s search screen. An additional search feature referred to as “Actual Name” is also available to provide a supplemental method to determine if an initial financing statement, amendment, or termination statement has been filed relating to a particular person or entity. Please be advised that neither FLORIDAUCC, nor the Florida Department of State, represents or warrants that the search logic used by the Registry is the same as the search logic employed by other states or that use of that search logic will result in a one hundred percent (100%) error-free result. This website and the filing and indexing of records is governed by the laws of the State of Florida, which may be different from the laws of other states and the Uniform Commercial Code. Please consult legal counsel if you have questions concerning these terms of use or the filing and indexing requirements of Florida law.',
    ],
  },
  {
    title: 'DISCLAIMER OF WARRANTIES',
    paragraphs: [
      'This website, and the filing and search capability thereof, is provided by FLORIDAUCC “AS IS” and without any warranty of any kind either express or implied, including but not limited to, warranties of merchantability and fitness for a particular purpose. Except as expressly provided below, under no circumstances shall FLORIDAUCC or the Florida Department of State be liable for any damages resulting from the use of this website, including but not limited to, any direct, indirect, incidental, special, punitive, or consequential damages. With respect to any mistake or error in inputting or indexing information into the Registry which precludes an initial financing statement, amendment, or termination from being located using the designated search logic, FLORIDAUCC’s liability shall be limited to correcting the mistake or error, without charge, upon written notification by a person or entity authorized to file the initial financing statement or amendment in question. Neither FLORIDAUCC nor the Florida Department of State warrants this website will operate in an uninterrupted or error-free manner or that the website is free of viruses. ',
    ],
  },
  {
    title: 'CHOICE OF FORUM',
    paragraphs: [
      'In any dispute arising out of or relating to the use of this website, the user agrees that suit may only be filed in Leon County, Florida, and that the courts of the State of Florida have exclusive jurisdiction.',
    ],
  },
  {
    title: 'UNLAWFUL USE PROHIBITED',
    paragraphs: [
      'You may not use this website or the Registry for any improper or illegal purpose. Any attempt to gain unauthorized access to this website, to damage or impair its computer systems and networks, or to otherwise gain access to another user’s account through password mining, hacking, cracking, or other improper means is strictly prohibited and will be prosecuted to the fullest extent of the law. Under Florida law, it is a felony to make a false, fictitious, or fraudulent statement, or to file any false document, with the Florida Secured Transaction Registry. See Section 817.535, Fla. Stat. FLORIDAUCC reserves the right to reject any filing containing profanity, obscene materials, or other illegal or improper references or documents.',
    ],
  },
  { title: 'DO NOT FILE ANY DOCUMENT CONTAINING A SOCIAL SECURITY NUMBER' },
];
