import { Typography, Box, Grid, useTheme, Button } from '@mui/material';
import { ReactComponent as InfoIcon } from '../../../assets/icons/exclamation-circle.svg';
import { CustomTooltip } from '../../../common/components/tooltip/CustomTooltip';
import { formatDate } from '../../../common/helpers/format-date';
import { IPendingFilings } from '../../shared/ucc/models';
import { Status } from '../../../common/models/features/models';
import { StyledLinkButton } from '../../../common/global-styles/LinkButton';

const PendingFilings = ({
  pendingFilingsData,
  handleEditFiling,
  handleModalOpen,
  toggleTooltip,
  activeToolTip,
}: IPendingFilings) => {
  const theme = useTheme();

  if (!pendingFilingsData || pendingFilingsData.length === 0) {
    return (
      <Box sx={{ padding: `${theme.convert.pxToRem(20)} ${theme.convert.pxToRem(40)}` }}>
        <Typography variant="h5">No pending filings yet.</Typography>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        maxHeight: { xs: theme.convert.pxToRem(300), md: theme.convert.pxToRem(450) },
        overflow: 'auto',
      }}
    >
      {pendingFilingsData.map((item, index) => {
        const { id, type, createdAt, ackContactName, status } = item;
        return (
          <Box
            key={id}
            borderBottom={
              pendingFilingsData.length - 1 !== index
                ? `1px solid ${theme.palette.primary.light}`
                : 'none'
            }
          >
            <Grid
              container
              padding={`${theme.convert.pxToRem(20)} ${theme.convert.pxToRem(40)}`}
              gap={1}
            >
              <Grid item md={12} sm={12}>
                <Box display="flex" alignItems="center" sx={{ wordBreak: 'break-word' }}>
                  <StyledLinkButton
                    variant="text"
                    style={{ textTransform: 'unset' }}
                    onClick={() => handleEditFiling({ id, type })}
                  >
                    {ackContactName ? ackContactName : 'Please enter placeholder name'}
                  </StyledLinkButton>

                  {status === Status.Incompleted ? (
                    <CustomTooltip
                      title="Updates have been made to the UCC form. Please click Edit to review the changes and complete the filing."
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      open={activeToolTip === id}
                    >
                      <Button onClick={() => toggleTooltip(id)}>
                        <InfoIcon />
                      </Button>
                    </CustomTooltip>
                  ) : null}
                </Box>
              </Grid>
              <Grid item md={12} sm={12}>
                <Typography>UCC File Type: {type}</Typography>
              </Grid>
              <Grid item md={12}>
                <Grid container>
                  <Grid item md={6} sm={12}>
                    <Typography>
                      Create Date: {createdAt ? formatDate(createdAt.toString()) : ''}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sm={12}
                    display="flex"
                    sx={{ justifyContent: { md: 'end', sm: 'start' } }}
                  >
                    <Box height={40} />
                    <Button
                      variant="text"
                      sx={{ padding: 0, minWidth: 'auto' }}
                      onClick={() => handleEditFiling({ id, type })}
                    >
                      Edit
                    </Button>
                    <Box width={30} />
                    <Button
                      variant="text"
                      sx={{ padding: 0, minWidth: 'auto' }}
                      onClick={() => handleModalOpen({ id, type })}
                    >
                      Remove
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </Box>
  );
};

export default PendingFilings;
