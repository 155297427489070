import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const StyledUCCButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: 40,
  [theme.breakpoints.up('md')]: {
    width: 220,
    height: 45,
  },
}));
