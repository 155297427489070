import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import Loader from '../../common/components/loader';
import CartAccordionsUI from './cart-accordions-ui/CartAccordionsUi';
import { YesNoModal } from '../../common/yes-no-modal';
import { useUserInfo } from '../../common/context';
import { useSnackbar } from '../../common/notification';
import { Accordion, accordionInitialState } from './constants';
import { cartCheckout, getAllUCCFormFilings, removeCartFiling } from './cart.services';
import { parseErrorMessages } from '../../common/helpers';
import { checkoutBase } from '../../common/constants/routes';
import type { StatusProps } from './models';
import { IFilingsResponse, IHandleActionFilingProps } from './models';
import { Status } from '../../common/models/features/models';

const CartAccordions = () => {
  const navigate = useNavigate();
  const { isLoadingCartDetails, getCartDetails, cartFilingsDetails, delayFilingsFetch } =
    useUserInfo();
  const { Snack } = useSnackbar();

  const [accordionState, setAccordionState] = useState(accordionInitialState);
  const [isFilingsDataLoading, setIsFilingsDataLoading] = useState(true);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [activeToolTip, setactiveTooltip] = useState<number | null>(null);
  const [filingToBeRemoved, setFilingToBeRemoved] = useState<IHandleActionFilingProps | null>(null);
  const [pendingFilingsData, setPendingFilingsData] = useState<IFilingsResponse[] | null>(null);
  const [completedFilingsData, setCompletedFilingsData] = useState<IFilingsResponse[] | null>(null);
  const [isCartCheckoutLoading, setIsCartCheckoutLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isCheckoutButtonDisabled, setIsCheckoutButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsCheckoutButtonDisabled(
      hasError ||
        (pendingFilingsData && pendingFilingsData.length > 0) ||
        completedFilingsData?.length === 0
    );
  }, [hasError, pendingFilingsData?.length, completedFilingsData?.length]);

  const handleAccordionState = (accordion: Accordion) =>
    setAccordionState((prevState) =>
      prevState.map((state, i) => (i === accordion ? !state : state))
    );

  const toggleTooltip = (id: number) => setactiveTooltip(activeToolTip === id ? null : id);

  const handleOnNewFiling = () => {
    navigate('/onlinefiling/filing');
  };

  const handleEditFiling = ({ id, type }: IHandleActionFilingProps) => {
    navigate(`/onlinefiling/filing/${type.toLowerCase()}/${id}`);
  };

  const handleModalOpen = ({ id, type }: IHandleActionFilingProps) => {
    setFilingToBeRemoved({ id, type });
    setOpenRemoveModal(true);
  };

  const handleNoActionModal = () => {
    Snack.info('Action canceled.');
    setFilingToBeRemoved(null);
    setOpenRemoveModal(false);
  };

  const handleRemoveActionModal = async () => {
    const id = filingToBeRemoved?.id;
    const type = filingToBeRemoved?.type;
    if (id && type) {
      setIsLoading(true);
      const response = await removeCartFiling({ id, type });
      if (!response.notOk) {
        getCartDetails();
        setIsFilingsDataLoading(true);
        getFilingsData();
        Snack.success('UCC form is successfully removed.');
        setIsLoading(false);
      } else {
        Snack.error(
          parseErrorMessages(
            response.messages,
            'Something went wrong while trying to remove the UCC form.'
          )
        );
        setIsLoading(false);
        setOpenRemoveModal(false);
      }
    }
    setFilingToBeRemoved(null);
    setOpenRemoveModal(false);
  };

  const onCartCheckout = async () => {
    setIsCartCheckoutLoading(true);
    const response = await cartCheckout();
    if (!response.notOk) {
      const { paymentId } = response.payload;
      navigate(`${checkoutBase}/${paymentId}`);
    } else {
      getFilingsData();
      Snack.error(
        parseErrorMessages(
          response.messages,
          'Something went wrong while trying to checkout your form(s).'
        )
      );
    }
    setIsCartCheckoutLoading(false);
  };

  const getFilingsData = async () => {
    const statuses: StatusProps[][] = [[Status.Pending, Status.Incompleted], [Status.Completed]];
    const requests = statuses.map((statusGroup) =>
      getAllUCCFormFilings(statusGroup, cartFilingsDetails?.packetId)
    );

    const responses = await Promise.all(requests);

    responses.forEach(({ notOk, messages, payload }, index) => {
      const statusGroup = statuses[index];

      if (notOk) {
        setHasError(true);
        Snack.error(
          parseErrorMessages(
            messages,
            `Something went wrong while trying to get ${statusGroup.join(' and ')} filings.`
          )
        );
        return;
      }
      const filingsData = payload || [];
      if (statusGroup.includes(Status.Completed)) {
        setCompletedFilingsData(filingsData);
      } else {
        setPendingFilingsData(filingsData);
      }
    });

    setIsFilingsDataLoading(false);
  };

  useEffect(() => {
    !delayFilingsFetch && cartFilingsDetails?.packetId && getFilingsData();
  }, [cartFilingsDetails?.packetId, delayFilingsFetch]);

  return isLoadingCartDetails || isFilingsDataLoading ? (
    <Grid container justifyContent="center">
      <Loader size={40} color="blue" />
    </Grid>
  ) : (
    <>
      <CartAccordionsUI
        accordionState={accordionState}
        handleAccordionState={handleAccordionState}
        handleOnNewFiling={handleOnNewFiling}
        handleEditFiling={handleEditFiling}
        handleModalOpen={handleModalOpen}
        toggleTooltip={toggleTooltip}
        activeToolTip={activeToolTip}
        pendingFilingsData={pendingFilingsData}
        completedFilingsData={completedFilingsData}
        isCheckoutButtonDisabled={isCheckoutButtonDisabled}
        onCartCheckout={onCartCheckout}
        isCartCheckoutLoading={isCartCheckoutLoading}
      />
      <YesNoModal
        open={openRemoveModal}
        handleModalClose={handleNoActionModal}
        noAction={handleNoActionModal}
        yesAction={handleRemoveActionModal}
        yesButtonTitle={isLoading ? 'Yes, removing' : 'Yes, remove'}
        noButtonTitle="No, cancel"
        disabled={isLoading}
        startIcon={isLoading ? <Loader /> : null}
        title="Remove UCC Form"
        content="Are you sure you want to remove this UCC form?"
      />
    </>
  );
};

export default CartAccordions;
