export enum Accordion {
  Pending = 0,
  FilingHistory,
}

export const accordionInitialState = [true, false];

export const filingHistoryTableHeaders = [
  'Type',
  'UCC Number',
  'Status',
  'Pages',
  'Fees',
  'Quantity',
  'Amount',
  'Filing Date',
  'Total',
  '',
];

export const completedHistoryFields = ['type', 'uccNumber', 'status', 'totalPagesCount'];
export const paymentSummaryFields = ['description', 'quantity', 'amount'];

export const allowedSortColumns: Record<string, boolean> = {
  Status: true,
};
