import { Button, Grid } from '@mui/material';
import { useMyAccount } from '../../../common/context';

interface Props {
  name: string;
  isSubmitDisabled: boolean;
}

export const FormActionButtons = ({ name, isSubmitDisabled }: Props) => {
  const { handleCancel } = useMyAccount();

  return (
    <Grid item xs={12} display={'flex'} flexDirection={'row-reverse'} px={2.5}>
      <Button type="submit" disabled={isSubmitDisabled}>
        Save
      </Button>
      <Button onClick={handleCancel} name={name}>
        Cancel
      </Button>
    </Grid>
  );
};
