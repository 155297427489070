import { FC } from 'react';
import { FilingDetailEntityInterface } from '../types';
import { Grid, Typography, useTheme } from '@mui/material';
import {
  StyledNavigationTypography,
  StyledTableContentGrid,
  StyledTableHeadingGrid,
} from './StyledComponents';
import { StyledNavLink } from '../../../common/components/header/StyledNavLink';
import { formatDebSec } from '../formatDebSec';

interface Props {
  data: FilingDetailEntityInterface[];
  maxDisplay: number;
  viewAllUrl: string;
}

const DebtorSecuredList: FC<Props> = ({ data, maxDisplay, viewAllUrl }) => {
  const theme = useTheme();
  return (
    <>
      <StyledTableHeadingGrid item container display={'flex'} justifyContent={'space-between'}>
        <Grid item>
          <Typography variant="body2" fontWeight={600}>
            Name & Address
          </Typography>
        </Grid>
        {data && data.length > 3 && (
          <Grid item>
            <StyledNavLink to={viewAllUrl}>
              <StyledNavigationTypography
                variant="body2"
                color={theme.palette.info.main}
                style={{ textDecoration: 'underline' }}
                fontWeight={600}
              >
                View All
              </StyledNavigationTypography>
            </StyledNavLink>
          </Grid>
        )}
      </StyledTableHeadingGrid>
      {data?.slice(0, maxDisplay ?? 3).map((item, index) => (
        <StyledTableContentGrid key={item.name + index} item xs={4}>
          <Typography>{item.name}</Typography>
          <Typography>{formatDebSec(item)}</Typography>
        </StyledTableContentGrid>
      ))}
    </>
  );
};

export default DebtorSecuredList;
