import { Grid, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';

const SearchResultsHeading: FC<{ content?: React.ReactNode }> = ({ content }) => {
  const theme = useTheme();
  return (
    <Grid
      item
      height={theme.convert.pxToRem(64)}
      borderLeft={`${theme.convert.pxToRem(15)} solid ${theme.palette.info.dark}`}
      display={'flex'}
      alignItems={'center'}
    >
      <Typography
        fontSize={20}
        fontWeight={600}
        lineHeight={theme.typography.pxToRem(24)}
        paddingLeft={theme.convert.pxToRem(21)}
      >
        {content}
      </Typography>
    </Grid>
  );
};

export default SearchResultsHeading;
