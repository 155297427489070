import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledModalFormWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledBoxWrapper = styled(StyledModalFormWrapper)(({ theme }) => ({
  margin: `${theme.convert.pxToRem(0)} auto`,
  [theme.breakpoints.up('md')]: {
    width: theme.convert.pxToRem(420),
  },
  height: 'auto',
}));
