import { IconButton } from '@mui/material';
import { ReactComponent as CloseButton } from '../../assets/icons/close-button.svg';

interface CloseIconButtonProps {
  onClick: (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export const CloseIconButton = ({ onClick }: CloseIconButtonProps) => {
  return (
    <IconButton
      onClick={onClick}
      aria-label="close"
      sx={{ padding: (theme) => theme.convert.pxToRem(4) }}
    >
      <CloseButton />
    </IconButton>
  );
};
