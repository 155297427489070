import { Controller } from 'react-hook-form';
import { Typography, Grid, TextField, FormHelperText, InputLabel } from '@mui/material';

import { StyledModalFormWrapper } from '../../../../../common/components/user-auth-components/common-auth-styles';
import type { FormData } from './schema';
import type { ILoginFormUI } from '../../../../../common/models';

const AuthCodeFormUI = ({ onSubmit, formState: { errors }, control }: ILoginFormUI<FormData>) => (
  <form id="code-modal-form" onSubmit={onSubmit}>
    <StyledModalFormWrapper>
      <Typography variant="body2">
        We’ve sent a 6-character code to your email. The code expires in 15 minutes, so please enter
        it soon.
      </Typography>
      <Grid container alignItems="center">
        <Grid item xs={3}>
          <InputLabel htmlFor="code">
            <Typography variant="h4">Code *</Typography>
          </InputLabel>
        </Grid>
        <Grid item xs={9}>
          <Controller
            name="code"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...field}
                inputRef={ref}
                placeholder="Enter code"
                error={!!errors.code?.message}
                type="text"
                required
                autoFocus
              />
            )}
          />
          <FormHelperText error={!!errors.code?.message}>{errors.code?.message}</FormHelperText>
        </Grid>
      </Grid>
    </StyledModalFormWrapper>
  </form>
);

export default AuthCodeFormUI;
