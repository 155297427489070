import { styled, TextareaAutosize } from '@mui/material';

export const StyledTextAreaInput = styled(TextareaAutosize, {
  shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>(({ error = false, theme }) => ({
  padding: `${theme.convert.pxToRem(8)} ${theme.convert.pxToRem(6)} ${theme.convert.pxToRem(
    20
  )} ${theme.convert.pxToRem(6)}`,
  fontSize: theme.typography.pxToRem(16),
  lineHeight: theme.typography.pxToRem(20),
  fontFamily: 'Open Sans',
  textTransform: 'uppercase',
  height: theme.convert.pxToRem(51),
  width: '98.3%',
  resize: 'vertical',
  color: theme.palette.text.primary,
  border: `1px solid ${error ? theme.palette.error.main : theme.palette.text.primary}`,
  '&:focus-visible': {
    border: `2px solid ${error ? theme.palette.error.main : theme.palette.primary.main}`,
    outline: 'none',
  },
  borderRadius: theme.convert.pxToRem(4),
  '&:disabled': {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[500],
  },
}));
