import { Theme } from '@mui/material/styles';

export const MuiPagination = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      '& .MuiPaginationItem-icon': {
        color: theme.palette.text.primary,
      },
      '& .MuiPaginationItem-root': {
        color: theme.palette.info.main,
      },
      '& .Mui-selected': {
        color: theme.palette.text.primary,
        background: `${theme.palette.primary.light} !important`,
        fontWeight: 600,
        borderRadius: 0,
      },
    }),
  },
};
