import { forwardRef } from 'react';
import { Alert } from '@mui/material';
import { CustomContentProps, useSnackbar } from 'notistack';

import { ReactComponent as SuccessIcon } from '../../assets/icons/success-icon.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning-icon.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error-icon.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info-icon.svg';

import theme from '../theme';

export const iconVariant = {
  success: (
    <SuccessIcon
      style={{
        marginRight: theme.convert.pxToRem(22),
        width: theme.convert.pxToRem(21),
        height: theme.convert.pxToRem(21),
      }}
    />
  ),
  error: (
    <ErrorIcon
      style={{
        marginRight: theme.convert.pxToRem(22),
        width: theme.convert.pxToRem(21),
        height: theme.convert.pxToRem(21),
      }}
    />
  ),
  warning: (
    <WarningIcon
      style={{
        marginRight: theme.convert.pxToRem(22),
        width: theme.convert.pxToRem(21),
        height: theme.convert.pxToRem(21),
      }}
    />
  ),
  info: (
    <InfoIcon
      style={{
        marginRight: theme.convert.pxToRem(22),
        width: theme.convert.pxToRem(21),
        height: theme.convert.pxToRem(21),
      }}
    />
  ),
};

const Notification = forwardRef<HTMLDivElement, CustomContentProps>(function Notification(
  props,
  ref
) {
  const { closeSnackbar } = useSnackbar();
  const { id, variant, message, ...other } = props;

  return (
    <Alert
      ref={ref}
      severity={variant !== 'default' ? variant : 'success'}
      onClose={() => {
        closeSnackbar(id);
      }}
      sx={{ minWidth: theme.convert.pxToRem(290) }}
    >
      {message}
    </Alert>
  );
});

export const NotificationComponents = {
  success: Notification,
  error: Notification,
  info: Notification,
  warning: Notification,
};

export default Notification;
