import { API } from 'aws-amplify';
import { IResponse } from '../../common/models/features/models';
import { IPaymentSuccessful, IToken } from './models';

const apiName = 'OnlineFilingAPI';
const primaryPath = 'payments';
const myInit = {};

export const checkSuccessPayment = async ({
  token,
}: IToken): Promise<IResponse<IPaymentSuccessful>> => {
  try {
    const res = await API.put(apiName, `/${primaryPath}/${token}/success-payment`, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const checkCanceledPayment = async ({ token }: IToken): Promise<IResponse<null>> => {
  try {
    const res = await API.put(apiName, `/${primaryPath}/${token}/cancel`, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};

export const checkFailedPayment = async ({ token }: IToken): Promise<IResponse<null>> => {
  try {
    const res = await API.put(apiName, `/${primaryPath}/${token}/fail`, myInit);
    return res;
  } catch (err: any) {
    return { ...err?.response?.data, notOk: true };
  }
};
